import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Tab, Nav } from 'react-bootstrap';

import CreateField from '../helpers/createField';
import OpeningtimesEditTable from './openingtimesEditTable';
import { useTranslation } from 'react-i18next';


function ReservationLocationForm(props) {
  const { t: translate } = useTranslation();

  if (props.data.hasOwnProperty('img_urls')) {
    props.data.img_urls.forEach(img_url => {
      props.img_urls.selected.push({ 'value': img_url, 'label': img_url })
      props.img_urls.options.push({ 'id': img_url, 'name': img_url })
    });
  }

  if (props.data.hasOwnProperty('id')) {
    props.id.selected = { 'value': props.data.id, 'label': props.id.hash[props.data.id] ? props.id.hash[props.data.id].name : 'unkown location id' }
  }

  if (props.data.hasOwnProperty('application')) {
    props.application.selected = { value: props.data.application, label: props.data.application }
  }

  if (props.data.hasOwnProperty('timezone')) {
    props.timezone.selected = { value: props.data.timezone, label: props.data.timezone }
  }

  let basicFields = []
  let formTabs = [];
  let formTabContent = [];
  props.fields.forEach(field => {
    if (field.type === 'object') {
      formTabs.push(
        <Nav.Item key={field.id}>
          <Nav.Link eventKey={field.id}>
            {translate(field.name)}
          </Nav.Link>
        </Nav.Item>
      );

      let objectFields = []
      field.fields.forEach(embeddedfield => {
        objectFields.push(CreateField(embeddedfield, props, field.id))
      });
      formTabContent.push(
        <Tab.Pane eventKey={field.id} key={field.id}>
          <div className='tab-container'>
            {objectFields}
          </div>
        </Tab.Pane>
      );
    } else {
      basicFields.push(CreateField(field, props))
    }
  });

  // openingtimes tab
  formTabs.push(
    <Nav.Item key='opening_times'>
      <Nav.Link eventKey='opening_times'>
        {translate('Service Hours')}
      </Nav.Link>
    </Nav.Item>
  );
  formTabContent.push(
    <Tab.Pane eventKey='opening_times' key='opening_times'>
      <div className='tab-container'>
        <OpeningtimesEditTable {...props} />
      </div>
    </Tab.Pane>
  );

  return (
    <>
      <Form>
        <Tab.Container defaultActiveKey="main">
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="main">
                {translate('Main')}
              </Nav.Link>
            </Nav.Item>
            {formTabs}
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="main">
              <div className='tab-container'>
                {basicFields}
              </div>
            </Tab.Pane>
            {formTabContent}
          </Tab.Content>
        </Tab.Container>
      </Form>
      <Button variant="primary" type={props.submit.type} onClick={props.submit.onClick}>
        {translate(props.submit.title)}
      </Button>
    </>
  );
}

export default ReservationLocationForm;