import { Button, Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';

import './css/productTable.css';
import OnlinePaymentButtons from '../portal/onlinepayments/onlinepaymentbuttons';
import OnlinePaymentsModal from './onlinePaymentModal';

export default function OnlinePaymentTable(onlinePayments) {
  const buttons = OnlinePaymentButtons.call()
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const edit = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.edit) : false
  const add = buttons.hasOwnProperty('add') ? Object.assign({}, buttons.add) : false
  const { t: translate } = useTranslation();

  let rows = []
  for (const onlinepayment of Object.values(onlinePayments)) {
    rows.push(
      <tr className='product-row' key={onlinepayment.id}>
        <td>{onlinepayment.source_type}</td>
        <td>{onlinepayment.payment_type}</td>
        <td>
          {modalview && <Button size="sm" type='button' onClick={() => { modalview.onClick(onlinepayment) }} id={onlinepayment.id}>{translate(modalview.tag)}</Button>}
          {edit && <Button size="sm" type='button' onClick={() => { edit.onClick(onlinepayment) }} id={onlinepayment.id}>{translate(edit.tag)}</Button>}
        </td>
      </tr>
    )
  }

  return (
    <>
      {add && <Button className={add.className}  size="sm" type='button' onClick={() => { add.onClick() }}>{translate(add.tag)}</Button>}
      <Table hover width='100%'>
        <thead>
          <tr>
            <th>{translate('Type')}</th>
            <th>{translate('Payment')}</th>
            <th>{translate('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
      <OnlinePaymentsModal modal={modalview.modal} />
    </>
  );
}