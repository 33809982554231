import { createSlice } from "@reduxjs/toolkit";

export const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    hash_channels_emails_all: {},
    hash_templates_all: {},
    hash_reservations_templates_all: {},
    hash_subscriptions_all: {}
  },
  reducers: {
    setApplications: (state, action) => {
      state.applications = action.payload
    },
    setApplication: (state, action) => {
      state.application = action.payload
    },
    setApplicationModalShow: (state, action) => {
      state.application_modalshow = action.payload
    },

    setSubscriptions: (state, action) => {
      state.subscriptions = action.payload
    },
    setSubscription: (state, action) => {
      state.subscription = action.payload
    },
    setSubscriptionsAll: (state, action) => {
      state.subscriptions_all = action.payload
      action.payload.forEach(subscription => {
        state.hash_subscriptions_all[subscription.id] = subscription
      });
    },
    setSubscriptionModalShow: (state, action) => {
      state.subscription_modalshow = action.payload
    },

    setTemplates: (state, action) => {
      state.templates = action.payload
    },
    setTemplate: (state, action) => {
      state.template = action.payload
    },
    setTemplatesAll: (state, action) => {
      state.templates_all = action.payload
      action.payload.forEach(template => {
        state.hash_templates_all[template.id] = template
      });
    },
    setTemplateModalShow: (state, action) => {
      state.template_modalshow = action.payload
    },

    setReservationTemplates: (state, action) => {
      state.reservation_templates = action.payload
    },
    setReservationTemplate: (state, action) => {
      state.reservation_template = action.payload
    },
    setReservationTemplatesAll: (state, action) => {
      state.reservation_templates_all = action.payload
      action.payload.forEach(template => {
        state.hash_reservations_templates_all[template.id] = template
      });
    },
    setReservationTemplateModalShow: (state, action) => {
      state.reservation_template_modalshow = action.payload
    },

    setChannelsEmails: (state, action) => {
      state.channels_emails = action.payload
    },
    setChannelsEmail: (state, action) => {
      state.channels_email = action.payload
    },
    setChannelsEmailsAll: (state, action) => {
      state.channels_emails_all = action.payload
      action.payload.forEach(channel => {
        state.hash_channels_emails_all[channel.id] = channel
      });
    },
    setChannelsEmailModalShow: (state, action) => {
      state.channels_email_modalshow = action.payload
    },
  }
});

export const {
  setApplications, setApplication, setApplicationModalShow,
  setSubscriptions, setSubscriptionsAll, setSubscription, setSubscriptionModalShow,
  setTemplates, setTemplatesAll, setTemplate, setTemplateModalShow,
  setReservationTemplates, setReservationTemplatesAll, setReservationTemplate, setReservationTemplateModalShow,
  setChannelsEmails, setChannelsEmailsAll, setChannelsEmail, setChannelsEmailModalShow
} = notificationSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getApplications = (state) => state.notification.applications
export const getApplicationModalShow = (state) => state.notification.application_modalshow
export const getApplication = (state) => state.notification.application

export const getSubscriptions = (state) => state.notification.subscriptions
export const getSubscriptionsAll = (state) => state.notification.subscriptions_all
export const getHashSubscriptionsAll = (state) => state.notification.hash_subscriptions_all
export const getSubscriptionModalShow = (state) => state.notification.subscription_modalshow
export const getSubscription = (state) => state.notification.subscription

export const getTemplates = (state) => state.notification.templates
export const getTemplatesAll = (state) => state.notification.templates_all
export const getHashTemplatesAll = (state) => state.notification.hash_templates_all
export const getTemplateModalShow = (state) => state.notification.template_modalshow
export const getTemplate = (state) => state.notification.template

export const getReservationTemplates = (state) => state.notification.reservation_templates
export const getReservationTemplatesAll = (state) => state.notification.reservation_templates_all
export const getHashReservationTemplatesAll = (state) => state.notification.hash_reservation_templates_all
export const getReservationTemplateModalShow = (state) => state.notification.reservation_template_modalshow
export const getReservationTemplate = (state) => state.notification.reservation_template

export const getChannelsEmails = (state) => state.notification.channels_emails
export const getChannelsEmailsAll = (state) => state.notification.channels_emails_all
export const getHashChannelsEmailsAll = (state) => state.notification.hash_channels_emails_all
export const getChannelsEmailModalShow = (state) => state.notification.channels_email_modalshow
export const getChannelsEmail = (state) => state.notification.channels_email

export default notificationSlice.reducer;