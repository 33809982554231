import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { setSubscriptionProductsAll } from "../../redux/dataSlices/productSlice";
import { setSubscriptionsAll, getSubscriptionsAll } from "../../redux/dataSlices/subscriptionSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import GetAllProducts from "../../helpers/getAllProducts";
import BasicCard from "../../components/card"
import SubscriptionsInclScopesTable from "../../components/subscriptionsInclScopesTable";
import HandleError from "../../helpers/handleError";
import GetSubscriptionAll from "../../actablueAPI/registrations/GetSubscriptionAll";

const Subscriptions = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id)
  const location_id = useSelector(getSelectedLocation_id)
  const subscriptions = useSelector(getSubscriptionsAll)
  const dispatch = useDispatch();
  const navTitle = "Subscriptions"
  const [refresh, setRefresh] = useState(false)

  function toggleRefresh() {
    setRefresh(!refresh)
  }

  const card = {
    size: 12,
    title: navTitle,
    data: <SubscriptionsInclScopesTable subscriptions={subscriptions} refresh={toggleRefresh} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setLoading(true))
    axios.all([
      GetSubscriptionAll({token:token}),
      GetAllProducts({ type: 'subscription', admin_id: admin_id, location_id: location_id, token: token })
    ])
    .then(axios.spread((subscriptionResponse, subscriptionProductResponse) => {
      dispatch(setSubscriptionsAll(subscriptionResponse.data))
      dispatch(setSubscriptionProductsAll(subscriptionProductResponse))
      dispatch(setLoading(false))
    }))
    .catch(error => {
      HandleError(error)
      dispatch(setLoading(false))
    })
    // eslint-disable-next-line
  }, [admin_id, location_id, refresh]);

  return (
    <>
      <BasicCard {...card} />
    </>
  )
};
export default Subscriptions;
