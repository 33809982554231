import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from '../../redux/dataSlices/tokenSlice';
import { getAccounting, setAccounting } from "../../redux/dataSlices/accountingSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card"
import AccountingForm from "../../components/accountingForm";
import { accountingFormFields } from "./accoutingFormFields.mjs";
import HandleOnChange from "../../helpers/handleOnChange";
import PutPaymentLedgers from "../../actablueAPI/accounting/PutPaymentLedgers";

const AccountingEdit = () => {
  const token = useSelector(getToken);
  const accounting = useSelector(getAccounting)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onChange = (event) => {
    let newCompany = HandleOnChange({ event: event, object: accounting })
    dispatch(setAccounting(newCompany))
  }

  // const onLocationIdChange = (event) => {
  //   let newCompany = HandleOnChangeSingleSelectFieldWithValue({event:event, object:accounting, selectfield:'location_id'})
  //   setAccounting(newCompany)
  // }

  const onSubmit = () => {
    PutPaymentLedgers({ token: token, id: accounting.id, data: accounting })
      .then(() => {
        navigate("/snelstart");
      })

  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    fields: accountingFormFields,
    field: {
      onChange: onChange
    },
    data: accounting,
  };

  const card = {
    size: 12,
    title: "Accounting edit ",
    data: <AccountingForm {...formData} />,
  };

  return (
    <>
      <BasicCard {...card} />
    </>
  )

};
export default AccountingEdit;

