import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { setSubscriptions, getSubscriptions, setSubscriptionsAll, getSubscriptionsAll, getSubscriptionRefresh } from "../../redux/dataSlices/subscriptionSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import BasicCard from "../../components/card"
import SubscribeTable from "../../components/subscribeTable";
import SubscriptionsTable from "../../components/subscriptionsTable";
import HandleError from "../../helpers/handleError";
import GetRegistration from "../../actablueAPI/registrations/GetRegistration";
import GetSubscriptionAll from "../../actablueAPI/registrations/GetSubscriptionAll";

const Subscribe = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const subscriptions = useSelector(getSubscriptions)
  const subsciptions_all = useSelector(getSubscriptionsAll)
  const refresh = useSelector(getSubscriptionRefresh)
  const dispatch = useDispatch();
  // const [merchantHimSelf, setMerchantHimSelf] = useState(role === 'reseller' || role === 'super' || role === 'admin'? true :false)
  const [merchantHimSelf, setMerchantHimSelf] = useState(true)

  const subscribe = {
    size: 12,
    title: "Subscribe",
    data: <SubscribeTable {...subscriptions} />,
  };

  const subscribeOptions = {
    size: 12,
    title: "Options",
    data: <SubscriptionsTable {...subsciptions_all} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle("Subscribe"))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setLoading(true))

    axios.all([
      GetRegistration({token:token, admin_id:admin_id, location_id:location_id}),
      GetSubscriptionAll({token:token})
    ])
      .then(axios.spread((registrationResponse, subscriptionsResponse) => {
        dispatch(setSubscriptions(registrationResponse.data))
        dispatch(setSubscriptionsAll(subscriptionsResponse.data))
        // if (role === 'merchant') {
        //   for (const registration of registrationResponse.data) {
        //     if (registration.user_id === token?.payload?.sub) {
        //       setMerchantHimSelf(true)
        //     }
        //   }
        // }
        dispatch(setLoading(false))
      }))
      .catch(error => {
        HandleError(error)
        dispatch(setLoading(false))
      })

    // eslint-disable-next-line
  }, [, admin_id, location_id, refresh]);

  return (
    <>
      <BasicCard {...subscribe} />
      {merchantHimSelf && location_id && <BasicCard {...subscribeOptions} />}
    </>
  )
};
export default Subscribe;
