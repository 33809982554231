import React, { useState, useEffect } from "react";
import moment from "moment";
import "./css/WeatherWidget.css"
import BasicCard from "./card";
import { getSelectedLocation } from "../redux/dataSlices/selectLocationSlice";
import { getStartDate } from "../redux/dataSlices/reportingDateSlice";
import { useSelector } from "react-redux";
import HandleError from "../helpers/handleError";
import { validateLocation } from "../helpers/weatherWidgetLocationValidator";
import GetLocationInfo from "../helpers/weatherWidgetGetLocationInfoFromNavigator";
import GetForecastData from "../helpers/weatherWidgetGetForecastData";
import { useTranslation } from "react-i18next";

function WeatherWidget(props) {
  const location = useSelector(getSelectedLocation)
  const startDate = useSelector(getStartDate)
  const { t: translate } = useTranslation();
  const [longitude, setLongitude] = useState(undefined)
  const [latitude, setLatitude] = useState(undefined)
  const [timezone, setTimezone] = useState('Europe/Amsterdam')
  const [city, setCity] = useState('')
  const [country, setCountry] = useState('')
  const [weatherWidgetData, setWeatherWidgetData] = useState(<sub className="invalid-location-text">No location selected or location doesn't have a set address. Showing geolocation info.</sub>)
  const [weatherWidgetTitle, setWeatherWidgetTitle] = useState()

  const weatherConditions = {
    0: { "text": "weather_clear", "icon": 1 },
    1: { "text": "weather_mainly_clear", "icon": 2 },
    2: { "text": "weather_partly_cloudy", "icon": 2 },
    3: { "text": "weather_overcast", "icon": 3 },
    45: { "text": "weather_fog", "icon": 50 },
    48: { "text": "weather_depositing_rime_fog", "icon": 50 },
    51: { "text": "weather_light_drizzle", "icon": 10 },
    53: { "text": "weather_drizzle", "icon": 10 },
    55: { "text": "weather_heavy_drizzle",  "icon": 10 },
    56: { "text": "weather_freezing_drizzle", "icon": 10 },
    57: { "text": "weather_freezing_drizzle", "icon": 10 },
    61: { "text": "weather_light_rain", "icon": 10 },
    63: { "text": "weather_rain", "icon": 10 },
    65: { "text": "weather_heavy_rain", "icon": 10 },
    66: { "text": "weather_freezing_rain", "icon": 10 },
    67: { "text": "weather_freezing_rain", "icon": 10 },
    71: { "text": "weather_light_snow_fall", "icon": 13 },
    73: { "text": "weather_snow_fall", "icon": 13 },
    75: { "text": "weather_heavy_snow_fall", "icon": 13 },
    77: { "text": "weather_snow_grains", "icon": 13 },
    80: { "text": "weather_rain_showers", "icon": 9 },
    81: { "text": "weather_rain_showers", "icon": 9 },
    82: { "text": "weather_rain_showers", "icon": 9 },
    85: { "text": "weather_snow_showers", "icon": 13 },
    86: { "text": "weather_snow_showers", "icon": 13 },
    95: { "text": "weather_thunderstorm", "icon": 11 },
    96: { "text": "weather_thunderstorm", "icon": 11 },
    99: { "text": "weather_thunderstorm", "icon": 11 },
  }

  function getWeatherConditionIcon(url, condition) {
    if (condition < 10) { condition = "0" + condition }
    var iconUrl = url + condition + getDayOrNight() + ".png";
    return iconUrl
  }

  function getDayOrNight() {
    const now = new Date();
    const sunrise = new Date();
    sunrise.setHours(6);
    const sunset = new Date();
    sunset.setHours(19);

    if (now >= sunrise && now < sunset) {
      return "d";
    } else {
      return "n";
    }
  }

  function setWeatherWidgetDataFunctionCurrentWeather(weatherData) {
    const weatherConditionIcon = getWeatherConditionIcon(process.env.REACT_APP_WEATHER_ICON_BASE_URL, weatherConditions[weatherData.current_weather.weathercode].icon)
    const roundedTemp = Math.ceil(weatherData.current_weather.temperature);

    setWeatherWidgetTitle(translate('forecast_weather', {date: new Date().toLocaleDateString()}))
    
    setWeatherWidgetData(
      <>
        {!validateLocation(location) &&
          <sub className="invalid-location-text">{translate('weather_no_location')}</sub>
        }
        <div className="weather-info">
          <div className="col-3">
            <div className="weather-condition-icon">
              <img width="64" height="64" src={weatherConditionIcon} alt='' />
            </div>
          </div>
          <div className="col-6">
          </div>
          <div className="col">
            <p className="temp-text">{roundedTemp}°</p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <b>{translate(weatherConditions[weatherData.current_weather.weathercode].text)}</b>
            <br></br>
            <sub className="location-text">{`${city}, ${(country)}`}</sub>
          </div>
        </div>
      </>
    )
  }

  function setWeatherWidgetDataFunctionHistoricFromForecast(weatherData, dayDiff) {

    // get index of startDate from time array
    const index = weatherData.daily.time.indexOf(startDate)
    if (index !== -1) {
      const weatherConditionIcon = getWeatherConditionIcon(process.env.REACT_APP_WEATHER_ICON_BASE_URL, weatherConditions[weatherData.daily.weathercode.at(index)].icon)
      const roundedTempMax = Math.ceil(weatherData.daily.temperature_2m_max.at(index));
      const roundedTempMin = Math.ceil(weatherData.daily.temperature_2m_min.at(index));

      setWeatherWidgetTitle(translate('historic_weather', {date: moment.unix(startDate).format('DD-MM-YYYY')}))

      setWeatherWidgetData(
        <>
          {!validateLocation(location) &&
            <sub className="invalid-location-text">{translate('weather_no_location')}</sub>
          }
          <div className="weather-info">
            <div className="col-4">
              <div className="weather-condition-icon">
                <img width="64" height="64" src={weatherConditionIcon} alt='' />
              </div>
              <b>{translate(weatherConditions[weatherData.daily.weathercode.at(index)].text)}</b>
              <br></br>
              <sub className="location-text">{`${city}, ${country}`}</sub>
            </div>
            <div className="col-5 mt-2">
              <p className="temp-text">{weatherData.daily.precipitation_hours.at(index)}h {weatherData.daily.precipitation_sum.at(index)}mm</p>
              <sub className="location-text">{translate("weather_rain_per_hour")}</sub>
            </div>
            <div className="col-4 mt-2">
              <p className="temp-text">{roundedTempMin}° - {roundedTempMax}°</p>
              <sub className="location-text">{translate("weather_min_max_temperature")}</sub>
            </div>
          </div>
        </>
      )
    }
  }

  function setWeatherWidgetDataFunctionHistoricDate(weatherData) {
    const weatherConditionIcon = getWeatherConditionIcon(process.env.REACT_APP_WEATHER_ICON_BASE_URL, weatherConditions[weatherData.weathercode].icon)
    const roundedTempMax = Math.ceil(weatherData.temperature_2m_max);
    const roundedTempMin = Math.ceil(weatherData.temperature_2m_min);

    setWeatherWidgetTitle(translate('historic_weather', {date: moment.unix(startDate).format('DD-MM-YYYY')}))

    setWeatherWidgetData(
      <>
        {!validateLocation(location) &&
          <sub className="invalid-location-text">{translate('weather_no_location')}</sub>
        }
        <div className="weather-info">
          <div className="col-4">
            <div className="weather-condition-icon">
              <img width="64" height="64" src={weatherConditionIcon} alt='' />
            </div>
            <b>{weatherConditions[weatherData.weathercode].text}</b>
            <br></br>
            <sub className="location-text">{`${city}, ${country}`}</sub>
          </div>
          <div className="col-5 mt-2">
            <p className="temp-text">{weatherData.precipitation_hours}h {weatherData.precipitation_sum}mm</p>
            <sub className="location-text">{translate("weather_rain_per_hour")}</sub>
          </div>
          <div className="col mt-2">
            <p className="temp-text">{roundedTempMin}° - {roundedTempMax}°</p>
            <sub className="location-text">{translate("weather_min_max_temperature")}</sub>
          </div>
        </div>
      </>
    )
  }

  const weatherCard = {
    size: props.size,
    title: weatherWidgetTitle,
    data: weatherWidgetData,
    className: props.className
  }

  useEffect(() => {
    const locationValid = validateLocation(location)

    if (locationValid) {
      setLatitude(location?.latitude)
      setLongitude(location?.longitude)
      setTimezone(location?.timezone)
      setCity(location?.address?.place)
      setCountry(location?.address?.country)
    }

    if (!locationValid && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude)
          setLongitude(position.coords.longitude)
          setTimezone('Europe/Amsterdam')
          GetLocationInfo({ latitude: position.coords.latitude, longitude: position.coords.longitude })
            .then(response => {
              let timezone = response?.data?.localityInfo?.informative?.find(obj => obj.description === 'time zone');
              setLatitude(response?.data?.latitude)
              setLongitude(response?.data?.longitude)
              setTimezone(timezone.name)
              setCity(response?.data?.locality)
              setCountry(response?.data?.countryName)
            })
            .catch(error => {
              HandleError(error)
            })
        },
        (error) => {
          console.error("Error Code = " + error.code + " - " + error.message);
        }
      )
    }

    // eslint-disable-next-line
  }, [startDate, location]);

  useEffect(() => {
    let selectedDate = moment.unix(startDate)
    let now = moment()
    let diff = moment.duration(now.diff(selectedDate))
    if (diff._data.days < 7 && diff._data.months < 1) {
      GetForecastData({ latitude: latitude, longitude: longitude, current_weather: true, past_days: 7, timezone: timezone })
        .then(response => {
          if (response.data !== '') {
            if (diff._data.days === 0) {
              setWeatherWidgetDataFunctionCurrentWeather(response.data)
            }
            if (diff._data.days !== 0) {
              setWeatherWidgetDataFunctionHistoricFromForecast(response.data, diff._data.days)
            }
          }
        })
        .catch(error => {
          HandleError(error)
        })
    } else {
      GetForecastData({ latitude: latitude, longitude: longitude, current_weather: true, timezone: timezone, archive: true, selectedDate: selectedDate })
        .then(response => {
          if (response.data !== '') {
            setWeatherWidgetDataFunctionHistoricDate(response.data.daily)
          }
        })
        .catch(error => {
          HandleError(error)
        })
    }
    // eslint-disable-next-line
  }, [startDate, latitude, longitude, timezone, city, country])

  return (
    <BasicCard {...weatherCard} />
  );
}

export default WeatherWidget;