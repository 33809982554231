import React, { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { setTaskId, getTaskId, setTaskData, getImportModalShow, setImportModalShow, setImportProfiles, getImportProfiles, getHashImportProfiles, setImportProfile, getImportProfile, getMatchColumnsForm, setMatchColumnsForm, getImportSaveModalShow, setImportSaveModalShow } from "../../redux/dataSlices/importSlice";
import { read, utils } from 'xlsx'
import BasicCard from "../../components/card"
import ImportForm from "../../components/importForm";
import { ImportFormFields, SaveFormFields } from "./importFormFields.jsx";
import HandleOnChange from "../../helpers/handleOnChange";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import ImportDataTable from "../../components/importDataTable";
import ImportMatchColumnsForm from "../../components/importMatchColumnsForm";
import { ImportDoubleSkuEan, ImportJournalCategories, ImportOnlyNew, ImportTypeChoices, ImportUniqueId } from "../../selectfieldchoices/importselect";
import ImportSaveModal from "../../components/importsaveModal";
import ImportProgressModal from "../../components/importprogressModal";
import HandleError from "../../helpers/handleError";
import GetImportProfilesAll from "../../actablueAPI/import/GetImportProfilesAll.jsx";
import { getHashRelationsAll, getRelationsAll, setRelationsAll } from "../../redux/dataSlices/relationSlice.js";
import GetRelationsByTypeAll from "../../actablueAPI/relations/GetRelationsByTypeAll.jsx";
import PostAnalyseTaskProgress from "../../actablueAPI/import/PostAnalyseTaskPostgress.jsx";
import PostImportProfile from "../../actablueAPI/import/PostImportProfile.jsx";
import PutImportProfile from "../../actablueAPI/import/PutImportProfile.jsx";
import GetWarehouseWarehouse from "../../actablueAPI/warehouse/GetWarehouseWarehouse.jsx";
import { getHashWarehousesAll, getWarehousesAll, setWarehousesAll } from "../../redux/dataSlices/warehouseSlice.js";

const Importing = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const task_id = useSelector(getTaskId)
  const modal_show = useSelector(getImportModalShow)
  const save_modal_show = useSelector(getImportSaveModalShow)
  const import_profile = useSelector(getImportProfile)
  const import_profiles = useSelector(getImportProfiles)
  const hash_import_profiles = useSelector(getHashImportProfiles)
  const match_columns_form = useSelector(getMatchColumnsForm)
  const relationsAll = useSelector(getRelationsAll)
  const hashRelationsAll = useSelector(getHashRelationsAll)
  const warehousesAll = useSelector(getWarehousesAll)
  const hashWarehousesAll = useSelector(getHashWarehousesAll)
  const dispatch = useDispatch();
  const [importForm, setImportForm] = useState({unique_id:'SKU', double_sku_ean:'NOTHING', only_new:'NO', journalcategories:'NO'})
  const [file, setFile] = useState({})
  const [dataFromFile, setDataFromFile] = useState({})
  const [headers, setHeaders] = useState([])
  const { t: translate } = useTranslation();

  const onViewHide = (event) => {
    dispatch(setImportModalShow(false));
    window.location.reload()
  };

  const onChange = (event) => {
    // let newImportForm = HandleOnChange({ event: event, object: importForm, admin_id: admin_id })
    setFile(event.target?.files[0])
    // setImportForm(newImportForm)
  }

  const onImportTypeChange = (event) => {
    let newImportForm = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: importForm, selectfield: 'importtype' })
    setImportForm(newImportForm)
  };

  const onImportProfileChange = (event) => {
    let newImportForm = JSON.parse(JSON.stringify(importForm))
    newImportForm.importprofile = event.value
    const options = hash_import_profiles[event.value].options
    newImportForm.unique_id = options.unique_id || importForm.unique_id
    newImportForm.double_sku_ean = options.double_sku_ean || importForm.double_sku_ean
    newImportForm.only_new = options.only_new || importForm.only_new
    newImportForm.journalcategories = options.journalcategories || importForm.journalcategories
    newImportForm.supplier_id = options.supplier_id || importForm.supplier_id
    setImportForm(newImportForm)
  };
  
  const onUniqueIdChange = (event) => {
    let newImportForm = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: importForm, selectfield: 'unique_id' })
    setImportForm(newImportForm)
  };

  const onDoubleSkuEanChange = (event) => {
    let newImportForm = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: importForm, selectfield: 'double_sku_ean' })
    setImportForm(newImportForm)
  };

  const onOnlyNewChange = (event) => {
    let newImportForm = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: importForm, selectfield: 'only_new' })
    setImportForm(newImportForm)
  };

  const onJournalcategoriesChange = (event) => {
    let newImportForm = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: importForm, selectfield: 'journalcategories' })
    setImportForm(newImportForm)
  };

  const onSupplierChange = (event) => {
    let newImportForm = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: importForm, selectfield: 'supplier_id' })
    setImportForm(newImportForm)
  };

  const onWarehouseChange = (event) => {
    let newImportForm = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: importForm, selectfield: 'warehouse' })
    setImportForm(newImportForm)
  };

  const onRackChange = (event) => {
    let newImportForm = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: importForm, selectfield: 'rack' })
    setImportForm(newImportForm)
  };

  const onUpload = () => {
    // check extension
    // let extension = file.name.split('.')[1]

    const reader_products_journalcategories = new FileReader()
    reader_products_journalcategories.readAsBinaryString(file)
    reader_products_journalcategories.onload = (event) => {
      const data = event.target.result
      const workbook = read(data, { type: "binary" })
      const sheetName = workbook.SheetNames[0]
      const sheet = workbook.Sheets[sheetName]
      const parsedData = utils.sheet_to_json(sheet, {defval: null})
      setDataFromFile({ data: parsedData })
      let newHeaders = Object.keys(parsedData[0]).map((key) => ({ id: key, name: key }))
      setHeaders(newHeaders)
    }

    // profile
    dispatch(setImportProfile(hash_import_profiles[importForm.importprofile]))
    
    // proces import_profile
    if (hash_import_profiles[importForm.importprofile]) {
      let mappingProfile = JSON.parse(hash_import_profiles[importForm.importprofile]?.mapping_profile?.option)
      let newMatchColumnsForm = Object.assign ({}, match_columns_form)
      for (const [key, value] of Object.entries(mappingProfile)) {
        newMatchColumnsForm[key] = value
      }
      dispatch(setMatchColumnsForm(newMatchColumnsForm))
    }
  };

  const onChangeSaveFormName = (event) => {
    let newImportProfile = HandleOnChange({ event: event, object: import_profile })
    dispatch(setImportProfile(newImportProfile))
  }

  const importSaveModalHide = () => {
    dispatch(setImportSaveModalShow(false));

    if (import_profile.id && !import_profile.new_profile) {
      let newProfile = Object.assign({}, import_profile)
      let newMappingProfile = Object.assign({}, newProfile.mapping_profile)
      newMappingProfile.option = JSON.stringify(match_columns_form)
      newProfile.mapping_profile =newMappingProfile
      newProfile.options = {id:import_profile.options.id, unique_id:importForm.unique_id, journalcategories:importForm.journalcategories, double_sku_ean:importForm.double_sku_ean, only_new:importForm.only_new, supplier_id:importForm.supplier_id}

      PutImportProfile({token:token, data:newProfile})
      .catch(error => {
        HandleError(error)
      })

    }

    if (!import_profile.id || import_profile.new_profile) {
      let newProfile = Object.assign({}, import_profile)
      newProfile.administration_id = admin_id
      newProfile.location_id = location_id
      newProfile.mapping_profile = {option:JSON.stringify(match_columns_form)}
      newProfile.options = {unique_id:importForm.unique_id, journalcategories:importForm.journalcategories, double_sku_ean:importForm.double_sku_ean, only_new:importForm.only_new, supplier_id:importForm.supplier_id}

      PostImportProfile({token:token, data:newProfile})
      .catch(error => {
        HandleError(error)
      })
    }

  };

  const importSaveModal = {
    modal: {
      show: save_modal_show,
      onHide: importSaveModalHide
    }
  }

  const saveFormData = {
    fields: SaveFormFields(),
    field: {
      onChange: onChangeSaveFormName
    },
    data: import_profile
  }

  const importProgressModal = {
    modal: {
      show: modal_show,
      onHide: onViewHide
    }
  }

  const importingFormData = {
    submit: {
      title: 'Upload',
      type: 'submit',
      onClick: onUpload
    },
    fields: ImportFormFields(importForm),
    field: {
      onChange: onChange
    },
    data: importForm,
    hashRelationsAll: hashRelationsAll,
    importtype: {
      name: 'importtype',
      options: ImportTypeChoices(),
      // selected: {},
      onChange: onImportTypeChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    importprofile: {
      name: 'importprofile',
      options: import_profiles,
      // selected: {},
      onChange: onImportProfileChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    unique_id: {
      name: 'unique_id',
      options: ImportUniqueId(),
      // selected:  { value: 'SKU', label: 'SKU' },
      onChange: onUniqueIdChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    double_sku_ean: {
      name: 'double_sku_ean',
      options: ImportDoubleSkuEan(),
      // selected:  { value: 'SKU', label: 'SKU' },
      onChange: onDoubleSkuEanChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    only_new: {
      name: 'only_new',
      options: ImportOnlyNew(),
      // selected:  { value: 'SKU', label: 'SKU' },
      onChange: onOnlyNewChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    journalcategories: {
      name: 'journalcategories',
      options: ImportJournalCategories(),
      // selected:  { value: 'SKU', label: 'SKU' },
      onChange: onJournalcategoriesChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    supplier_id: {
      name: 'supplier_d',
      options: relationsAll,
      // selected: {},
      onChange: onSupplierChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    warehouse: {
      name: 'warehouse',
      options: warehousesAll,
      // selected: {},
      onChange: onWarehouseChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    rack: {
      name: 'rack',
      options: hashWarehousesAll[importForm.warehouse]?.racks,
      // selected: {},
      onChange: onRackChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    }
  }

  const card = {
    size: 12,
    title: 'Importing',
    // data: location_id ? <ImportForm {...importingFormData} /> : 'Choose location',
    data: <ImportForm {...importingFormData} />,
  };

  let matchCard = {
    size: 12,
    title: "Data",
    data: <ImportMatchColumnsForm importForm={importForm} headers={headers} data={dataFromFile} matchColumnsForm={match_columns_form}/>,
  }

  const dataCard = {
    size: 12,
    title: 'Import example (10 rows)',
    data: <ImportDataTable {...dataFromFile} />,
  }

  useEffect(() => {
    dispatch(setNavBarTitle('Importing'))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setImportForm({unique_id:'SKU', double_sku_ean:'NOTHING', only_new:'NO', journalcategories:'NO'})
    setFile({})
    setDataFromFile({})
    setHeaders([])
    dispatch(setMatchColumnsForm())
    GetImportProfilesAll({token:token, admin_id:admin_id, location_id: location_id})
    .then(importProfilesAll => {
      dispatch(setImportProfiles(importProfilesAll))
    })
    .catch(error => {
      HandleError(error)
    })
    // eslint-disable-next-line
  }, [admin_id, location_id]);

  useEffect(() => {
    switch (importForm.importtype) {
      case 'products':
      case 'products+locations':
        GetRelationsByTypeAll({token:token, admin_id:admin_id, type:'SUPPLIER'})
        .then(relationsAll => {
          dispatch(setRelationsAll(relationsAll))
        })
        .catch(error => {
          HandleError(error)
        })
        break;
      // case 'relations':
      //   break
      // case 'actablueExportFile':
      //   break
      case 'stock':
        axios.all([
          GetRelationsByTypeAll({token:token, admin_id:admin_id, type:'SUPPLIER'}),
          GetWarehouseWarehouse({token:token, admin_id:admin_id, location_id:location_id})
        ])
        .then(axios.spread((relationsAll, warehouseResponse) => {
          dispatch(setRelationsAll(relationsAll))
          dispatch(setWarehousesAll(warehouseResponse.data.content))
        }))
        .catch(error => {
          HandleError(error)
        })
        break
      default:
        break;
    }
    // eslint-disable-next-line
  }, [importForm.importtype]);


  useEffect(() => {
    let intervalId = {}
    function getProgres() {
      if (task_id) {
        PostAnalyseTaskProgress({data:{task_id:task_id}})
        .then(response => {
          if (response.data.result.state === 'PENDING') {
            dispatch(setTaskData(response.data.result))
          }

          if (response.data.result.state === 'PROGRESS') {
            dispatch(setTaskData(response.data.result))
          }        

          if (response.data.result.state === 'SUCCESS') {
            dispatch(setTaskData(response.data.result))
            clearInterval(intervalId)
            dispatch(setTaskId(undefined))
            dispatch(setImportModalShow(false))
            window.location.reload()
          } 
        })
        .catch(error => {
            clearInterval(intervalId)
            dispatch(setTaskId(undefined))
            dispatch(setImportModalShow(false))
            window.location.reload()
            HandleError(error)
        })
      } 
    }

    if (task_id) {
      dispatch(setImportModalShow(true))
      intervalId = setInterval(() => {
        getProgres()
      }, 1000);
    } 
    
    if (!task_id) {
      dispatch(setTaskData(undefined))
      clearInterval(intervalId)
    }
    // eslint-disable-next-line
  }, [task_id]);

  return (
    <>
      <ImportProgressModal {...importProgressModal} />
      <ImportSaveModal modal={importSaveModal.modal} formData={saveFormData}/>
      <BasicCard {...card} />
      {Object.keys(dataFromFile).length > 0 && <BasicCard {...matchCard} />}
      {Object.keys(dataFromFile).length > 0 && <BasicCard {...dataCard} />}
    </>
  )
};
export default Importing;