export default function AnalyseTransactionDescription (transactions) {
  let totalRevenue = 0
  let descriptReport = []

  for (let transaction of transactions) {
    totalRevenue += Number(transaction.invoice.payment_amount_inclusive_vat)
    try {
      const descript = JSON.parse(transaction.descript)
      if (descript.Country) {
        if (descriptReport[descript.Country]) {
          descriptReport[descript.Country].revenue += Number(transaction.invoice.payment_amount_inclusive_vat)
          if (descript.Gender) {
            descriptReport[descript.Country][descript.Gender].revenue += Number(transaction.invoice.payment_amount_inclusive_vat)
          }
        }
        if (!descriptReport[descript.Country]) {
          descriptReport[descript.Country] = {
            country: descript.Country,
            revenue: Number(transaction.invoice.payment_amount_inclusive_vat),
            percentage: 0
          }
          if (descript.Gender) {
            descriptReport[descript.Country][descript.Gender] = {revenue: Number(transaction.invoice.payment_amount_inclusive_vat)}
          }
        }
      }
      if (descript.Gender) {
        if (descriptReport[descript.Gender]) {
          descriptReport[descript.Gender].revenue += Number(transaction.invoice.payment_amount_inclusive_vat)
        }
        if (!descriptReport[descript.Gender]) {
          descriptReport[descript.Gender] = {
            gender: descript.Gender,
            revenue: Number(transaction.invoice.payment_amount_inclusive_vat),
            percentage: 0
          }
        }
      }
    } catch (error) {
      // console.log('error', error)
      if (descriptReport['No country selected']) {
        descriptReport['No country selected'].revenue += Number(transaction.invoice.payment_amount_inclusive_vat)
      }
      if (!descriptReport['No country selected']) {
        descriptReport['No country selected'] = {
          revenue: Number(transaction.invoice.payment_amount_inclusive_vat),
          percentage: 0
        }
      }
    }
  };

  // add percentage to descriptReport
  for (let [country, countryData] of Object.entries(descriptReport)) {
    if (countryData.revenue) {
      countryData.percentage = ((countryData.revenue * 100) / totalRevenue)//.toFixed(2)
    }
    for (let [gender, genderData] of Object.entries(countryData)) {
      if (genderData.revenue) {
        genderData.percentage = ((genderData.revenue * 100) / totalRevenue)//.toFixed(2)
      }
    }
  }

  return descriptReport
}