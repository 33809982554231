import { Button, Table } from 'react-bootstrap'
import TableButton from './tableButton';
import { useTranslation } from 'react-i18next';
import EmployeeButtons from '../portal/employees/employeeButtons';
import EmployeeModal from './employeeModal';

import './css/productTable.css';

export default function EmployeeTable(props) {
  const employees = props.employees || []
  const roles = props.roles
  const buttons = EmployeeButtons.call()
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const edit = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.edit) : false
  const add = buttons.hasOwnProperty('add') ? Object.assign({}, buttons.add) : false
  const { t: translate } = useTranslation();

  let rows = []
  for (const employee of Object.values(employees)) {
    let role = 'private role'
    let foundRoles = {}
    if (employee.roles && roles) {
      foundRoles = roles.filter(role => JSON.stringify(role.roles.toSorted() || {}).toLowerCase() === JSON.stringify(employee.roles.toSorted()).toLowerCase())
    }
    if (!employee.roles && roles) {
      foundRoles = roles.filter(role => !role.roles)
    }
    if (foundRoles.length > 0) {
      let newRole = ''
      for (const role of foundRoles) {
        newRole = `${newRole} ${role.name}`
      }
      role = newRole
    }

    rows.push(
      <tr className='product-row' key={employee.id}>
        <td>{employee.name}</td>
        <td>{employee.initials}</td>
        <td>{employee.operator_id}</td>
        <td>{employee.pin_code}</td>
        <td>{role}</td>
        <td>
          {modalview && <Button size="sm" type='button' onClick={() => { modalview.onClick(employee) }} id={employee.id}>{translate(modalview.tag)}</Button>}
          {edit && <Button size="sm" type='button' onClick={() => { edit.onClick(employee) }} id={employee.id}>{translate(edit.tag)}</Button>}
        </td>
      </tr>
    )
  }

  return (
    <>
      {add && <TableButton {...add} />}
      <Table hover width='100%'>
        <thead>
          <tr>
            <th>{translate('Name')}</th>
            <th>{translate('Initials')}</th>
            <th>{translate('Operator id')}</th>
            <th>{translate('Operator PIN')}</th>
            <th>{translate('Role')}</th>
            <th>{translate('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
      <EmployeeModal modal={modalview.modal} />
    </>
  );
}