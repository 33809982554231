import PostInvoiceSearch from "../actablueAPI/invoicepayments/PostInvoiceSearch"
import HandleError from "./handleError"
import moment from "moment"


export default async function CheckExcistingInvoice({token, admin_id, location_id, order}) {
  let foundInvoice = undefined
  let searchObject = {
    amount_range: {
      min: Number(order.payment_amount_inclusive_vat),
      max: Number(order.payment_amount_inclusive_vat),
      type: 'paymentAmountInclusiveVat'
    },
    date_range: {
      from: order.creation,
      to: moment(order.creation).add(5, 'm').format('YYYY-MM-DDTHH:mm:ss.000Z'),
      field: "creation"
    },
    limit: 999
  }

  if (!order.payment_amount_inclusive_vat && order.payment_amount_exclusive_vat) {
    searchObject = {
      amount_range: {
        min: Number(order.payment_amount_exclusive_vat),
        max: Number(order.payment_amount_exclusive_vat),
        type: 'paymentAmountExclusiveVat'
      },
      date_range: {
        from: order.creation,
        to: moment(order.creation).add(1, 'm').format('YYYY-MM-DDTHH:mm:ss.000Z'),
        field: "creation"
      },
      limit: 999
    }
  }

  return await PostInvoiceSearch({token:token, admin_id:admin_id, location_id: location_id, searchObject:searchObject})
  .then(response => {
    for (const invoice of response.data) {
      if (invoice.payment_amount_inclusive_vat === order.payment_amount_inclusive_vat) {
        foundInvoice = invoice
      }
    }
    return foundInvoice
  })
  .catch(error => {
    HandleError(error)
    return foundInvoice
  })
}