import { useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { setAdministration, setAdministrationId, getAdministrationModalShow, setAdministrationModalShow } from "../../redux/dataSlices/administrationSlice";

import { getScopes } from "../../redux/dataSlices/scopesSlice";

export default function AdminButtons() {
  const modalShow = useSelector(getAdministrationModalShow)
  const scopes = useSelector(getScopes)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function onViewClick(administration) {
    dispatch(setAdministration(administration));
    dispatch(setAdministrationModalShow(true));
  };

  const onViewHide = () => {
    dispatch(setAdministrationModalShow(false));
  };

  const onclickEdit = (event) => {
    dispatch(setAdministrationId(event.target.id));
    navigate("/administration/edit");
  };

  let buttons = {
    modalview: {
      tag: "View",
      onClick: onViewClick,
      modal: {
        show: modalShow,
        onHide: onViewHide
      }
    }
  }

  // add buttons based on scopes
  // scopeOptions = ['read', 'create', 'update', 'delete'] (use index: 0:read, 1:create, 2:update, 3:delete)
  // buttonOptions = {'get':0, 'new':1, 'edit':2, 'add':2, 'del':3, 'swap':2, 'move':2, 'import':1, 'create': 1, 'export': 1, 'print': 0}
  // check level of scope, add buttons according to the level.
  // if (scopes.administrations >= 1) {
  //   buttons.add = {
  //     tag: "Add",
  //     value:'add',
  //     onClick:onclickAdd,
  //     className:'add-button'
  //   } 
  // }
  if (scopes.administrations >= 2) {
    buttons.edit = {
      tag: "Edit",
      value: 'edit',
      onClick: onclickEdit
    }
  }

  return buttons
}

