import * as XLSX from 'xlsx/xlsx.mjs';
import { setTaskData } from "../redux/dataSlices/analyseSlice";
import { setProgressModalShow } from "../redux/dataSlices/analyseSlice";
import HandleError from '../helpers/handleError';
import GetAllCategoriesWithType from '../actablueAPI/products/getAllCategoriesWithType';

export const ProductCategoryExport = ({token, admin_id, location_id, dispatch }) => {
  GetAllCategoriesWithType({type:'JOURNAL', token: token, admin_id:admin_id, location_id:location_id})
  .then(response => {
    const { exportLines, dummy } = _createExportLines(response, dispatch)


    // first object
    let firstObject = exportLines[0]
    let newFirstObject = {}
    for (const key of Object.keys(dummy)) {
      newFirstObject[key] = firstObject[key]
    }
  
    exportLines[0] = Object.assign({}, newFirstObject)
  
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, XLSX.utils.json_to_sheet(exportLines), 'sheet1')
    XLSX.writeFile(workbook, `availableproductcategories.xlsx`, { compression: true });
  
    dispatch(setProgressModalShow(false))
  })
  .catch(error => {HandleError(error)})
};

function _createExportLines(exportData, dispatch) {
  const exportLength = exportData.length
  let current = 1
  let dummy = {}
  let exportLines = []

  for (const category of exportData) {
    let task_data = {
      current: current,
      total: exportLength,
      progress: current === 0 ? 0 : current / exportLength
    }
    dispatch(setTaskData(task_data))

    let newLine = {}
    newLine.id = category.id
    newLine.title = category.title
    newLine.administration_id = category.administration_id
    newLine.location_id = category.location_id

    // create longest dummy
    if (Object.keys(newLine).length > Object.keys(dummy).length) {
      dummy = Object.assign({}, newLine)
    }
    exportLines.push(newLine)

    current = current + 1
  }

  return ({ exportLines: exportLines, dummy: dummy })
}
