import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card"
import CcvshopForm from "../../components/ccvshopForm";
import { CcvshopAddFormFields } from "./ccvshopFormFields.jsx";
import HandleOnChange from "../../helpers/handleOnChange";
import PostCcvshopCcvKeys from "../../actablueAPI/ccvshop/PostCcvshopCcvKeys.jsx";

const CcvshopAdd = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id)
  const [ccvshop, setCcvshop] = useState({ administration_id: admin_id, location_id: location_id })
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navTitle = "CCVShop add"

  const onChange = (event) => {
    let newCcvshop = HandleOnChange({ event: event, object: ccvshop })
    dispatch(setCcvshop(newCcvshop))
  }

  // const onLocationIdChange = (event) => {
  //   let newCcvshop = HandleOnChangeSingleSelectFieldWithValue({event:event, object:ccvshop, selectfield:'location_id'})
  //   dispatch(setCcvshop(newCcvshop))
  // }

  const onSubmit = () => {
    PostCcvshopCcvKeys({ token: token, admin_id: admin_id, data: ccvshop })
      .then(() => {
        navigate("/ccvshops");
      })


  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    fields: CcvshopAddFormFields(),
    field: {
      onChange: onChange
    },
    data: ccvshop,
  };

  const card = {
    size: 12,
    title: navTitle,
    data: <CcvshopForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...card} />
    </>
  )

};
export default CcvshopAdd;

