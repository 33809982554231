import { createSlice } from "@reduxjs/toolkit";

export const transactionSlice = createSlice({
  name: "transaction",
  initialState: {
    transaction_pagesize: 10,
    transaction_pagination: 0,
  },
  reducers: {
    setTransactionId: (state, action) => {
      state.transaction_id = action.payload;
    },
    setTransaction: (state, action) => {
      state.transaction = action.payload;
    },
    setTransactions: (state, action) => {
      state.transactions = action.payload
    },
    editTransactions: (state, action) => {
      for (let transaction in state.transactions.content) {
        if (state.transactions.content[transaction].id === action.payload.id) {
          state.transactions.content[transaction] = action.payload
        }
      }
    },
    setTransactionsHighLights: (state, action) => {
      state.transactionshighlights = action.payload
    },
    setTransactionModalShow: (state, action) => {
      state.transactionmodalshow = action.payload
    },
    setAccountingInfo: (state, action) => {
      state.accountingInfo = action.payload
    },
    setAccountingModalShow: (state, action) => {
      state.accountingModalshow = action.payload
    },
    setTransactionPagination: (state, action) => {
      state.transaction_pagination = (action.payload - 1)
    },
    setTransactionPagesize: (state, action) => {
      state.transaction_pagesize = action.payload
    },
  }
});

export const {
  setTransactionId, 
  setTransaction, 
  setTransactions, 
  editTransactions, 
  setTransactionsHighLights, 
  setTransactionModalShow, 
  setTransactionPagination, 
  setTransactionPagesize,
  setAccountingInfo,
  setAccountingModalShow
} = transactionSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getTransactionId = (state) => state.transaction.transaction_id
export const getTransaction = (state) => state.transaction.transaction
export const getTransactions = (state) => state.transaction.transactions
export const getTransactionsHighlights = (state) => state.transaction.transactionshighlights
export const getTransactionModalShow = (state) => state.transaction.transactionmodalshow
export const getAccountingInfo = (state) => state.transaction.accountingInfo
export const getAccountingModalShow = (state) => state.transaction.accountingModalshow
export const getTransactionPagination = (state) => state.transaction.transaction_pagination
export const getTransactionPagesize = (state) => state.transaction.transaction_pagesize

export default transactionSlice.reducer;