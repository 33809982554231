import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setError } from "../../redux/dataSlices/errorSlice";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { setReservationOptions, getReservationOptions, getHashReservationOptionsAll, getReservationObjectId } from "../../redux/dataSlices/reservationSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card";
import ReservationObjectForm from "../../components/reservationobjectForm";
import ReservationobjectFormFields from "./reservationobjectFormFields.jsx";
import HandleOnChange from "../../helpers/handleOnChange";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import HandleOnChangeMultiSelectFieldWithObjects from "../../helpers/handleOnChangeMultiSelectFieldWithObjects";
import { reservationObjectTypeChoices } from "../../selectfieldchoices/reservationobjectselect.mjs";
import PostReservationObject from "../../actablueAPI/reservations/PostReservationObject.jsx";
import GetReservationOptions from "../../actablueAPI/reservations/GetReservationOptions.jsx";
import GetReservationObjectById from "../../actablueAPI/reservations/GetReservationObjectByID.jsx";

const ReservationObjectEdit = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const reservationobject_id = useSelector(getReservationObjectId)
  const reservation_options = useSelector(getReservationOptions);
  const hash_reservation_options = useSelector(getHashReservationOptionsAll)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [reservationObject, setReservationObject] = useState({})

  const onReservationOptionChange = (event) => {
    let newReservation = HandleOnChangeMultiSelectFieldWithObjects({ event: event, object: reservationObject, selectfield: 'reservation_options', hash: hash_reservation_options })
    let selectedOptions = []
    if (newReservation.hasOwnProperty('reservation_options')) {
      newReservation.reservation_options.forEach(reservation_option => {
        let newOption = Object.assign({}, reservation_option)
        delete newOption.reservation_objects
        selectedOptions.push(newOption)
      });
    }
    newReservation.reservation_options = selectedOptions
    setReservationObject(newReservation)
  }

  const onChange = (event) => {
    let newReservationObject = HandleOnChange({ event: event, object: reservationObject })
    setReservationObject(newReservationObject)
  };

  const onTypeChange = (event) => {
    let newReservationObject = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: reservationObject, selectfield: 'type' })
    setReservationObject(newReservationObject)
  };

  const onCancel = (event) => {
    navigate("/reservationobjects");
  }

  const onSubmit = () => {
    let newReservationObject = JSON.parse(JSON.stringify(reservationObject))
    newReservationObject.type = reservationObject.type.toUpperCase()
    PostReservationObject({token:token, data:newReservationObject})
    .then(response => {
      navigate("/reservationobjects");
    })
    .catch(function (error) {
      dispatch(setError(JSON.stringify(error)))
    })
  };

  const formData = {
    cancel: {
      title: 'Cancel',
      type: 'submit',
      onClick: onCancel
    },
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    fields: ReservationobjectFormFields(),
    field: {
      onChange: onChange
    },
    data: reservationObject,
    type: {
      name: 'type',
      options: reservationObjectTypeChoices,
      selected: '',
      onChange: onTypeChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    reservation_options: {
      label: 'reservation_options',
      options: reservation_options,
      selected: [],
      onChange: onReservationOptionChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    }
  };

  const addCard = {
    size: 12,
    title: "Reservation object edit ",
    data: <ReservationObjectForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle("Reservation object edit"))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setLoading(true))
    axios.all([
      GetReservationObjectById({token:token, id:reservationobject_id}),
      GetReservationOptions({token:token, admin_id:admin_id})
    ])
    .then(axios.spread((reservationObjectResponse, reservationOptionsResponse) => {
      setReservationObject(reservationObjectResponse.data)
      dispatch(setReservationOptions(reservationOptionsResponse.data))
    }))
    .catch(function (error) {
      dispatch(setError(JSON.stringify(error)))
      dispatch(setLoading(false))
    })
// eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...addCard} />
    </>
  )
};
export default ReservationObjectEdit;
