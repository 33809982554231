import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getHashPlanningsAll, setCalendarEnd, setCalendarLoading, setCalendarStart, setHashPlannings, setPlanning, setPlanningModalShow, setPlanningsAll } from '../redux/dataSlices/planningSlice';
import "./css/planningCalendar.css"
import DeepCopy from '../helpers/DeepCopy';
import { GetAllPlannings, PutPlanning } from '../actablueAPI/planning/Planning';
import { getToken } from '../redux/dataSlices/tokenSlice';
import { setError, setResponseError } from '../redux/dataSlices/errorSlice';
import PlanningButtons from '../portal/planning/planningbuttons';
import PlanningModal from './planningModal';
import { getSelectedLocation_id } from '../redux/dataSlices/selectLocationSlice';

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

const PlanningCalendar = (props) => {
  const location_id = useSelector(getSelectedLocation_id);
  const plannings = useSelector(getHashPlanningsAll);
  const token = useSelector(getToken);
  const dispatch = useDispatch();
  const [events, setEvents] = useState([])
  const buttons = PlanningButtons.call()
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const [typed, setTyped] = useState('');
  const [anoukMode, setAnoukMode] = useState(false);

  useEffect(() => {
    const handleKeydown = (event) => {
      const newTyped = typed + event.key;
      if (newTyped.toLowerCase().includes('anouk')) {
        setAnoukMode(!anoukMode)
        setTyped('');
      } else {
        setTyped(newTyped);
      }
    };

    window.addEventListener('keydown', handleKeydown);

    return () => {
      window.removeEventListener('keydown', handleKeydown);
    };
  }, [typed, anoukMode]);

  useEffect(() => {
    if (plannings) {
      const newEvents = Object.values(plannings)?.map(planning => {
        const startDate = new Date(planning?.planning_timestamp);
        const endDate = new Date(startDate.getTime() + planning?.duration * 60000);
        return {
          title: planning?.planning_name,
          start: startDate,
          end: endDate,
          id: planning?.id,
          planning_object: planning,
        };
      });
      setEvents(newEvents);
    }
  }, [plannings]);

  const updateEventToPlanning = async ({ event, start, end }) => {
    const updatedEvents = events.map(currentEvent =>
      currentEvent.id === event.id ? { ...currentEvent, start, end } : currentEvent
    );

    const differenceInMs = end - start;
    const newDuration = differenceInMs / (1000 * 60);

    let newPlannings = DeepCopy(plannings)
    newPlannings[event?.id].duration = newDuration
    newPlannings[event?.id].planning_timestamp = new Date(start).getTime();

    setEvents(updatedEvents);
    dispatch(setCalendarLoading(true));
    await PutPlanning({ token: token, data: newPlannings[event?.id] })
      .then(response => {
        const updatedEvents = events.map(currentEvent =>
          currentEvent.id === event.id ? { ...currentEvent, start, end, planning_object: response.data } : currentEvent
        );
        newPlannings[event?.id] = response.data
        setEvents(updatedEvents);
        dispatch(setHashPlannings(newPlannings))
      })
      .catch(error => {
        setEvents(events); // Revert the changed events
        const type = error.response?.type
        const data = error.response?.data
        if (error.response) { dispatch(setResponseError({ type, data })) }
        if (!error.response) { dispatch(setError(JSON.stringify(error))) }
      })
      .finally(() => {
        dispatch(setCalendarLoading(false));
      })
  }

  const handleEventDoubleClick = (event) => {
    dispatch(setPlanning(event?.planning_object))
    dispatch(setPlanningModalShow(true))
  };

  const handleEventDrop = async ({ event, start, end }) => {
    updateEventToPlanning({ event: event, start: start, end: end });
  };

  const handleEventResize = async ({ event, start, end }) => {
    updateEventToPlanning({ event: event, start: start, end: end });
  };

  // const handleDropFromOutside = ({ event, start, end }) => {
  //   console.log('Dropped from outside:', { event, start, end });
  // };

  // Handler for navigating the calendar (today, back, next buttons)
  // const handleNavigate = (date, view) => {
  //   console.log('Navigated to:', { date, view });
  // };

  // Handler for changing the view (month, week, day buttons)
  // const handleViewChange = (date, view) => {
  //   console.log('View changed to:', { date, view });
  // };

  const handleRangeChange = (range) => {
    let start, end;
    if (Array.isArray(range)) {
      start = moment(range[0]).startOf('day').toDate();
      end = moment(range[range.length - 1]).endOf('day').toDate();
    } else {
      start = moment(range.start).startOf('day').toDate();
      end = moment(range.end).endOf('day').toDate();
    }

    const formattedStart = moment(start).format('YYYY-MM-DDTHH:mm:ss');
    const formattedEnd = moment(end).format('YYYY-MM-DDTHH:mm:ss');

    dispatch(setCalendarStart(formattedStart));
    dispatch(setCalendarEnd(formattedEnd));
    dispatch(setCalendarLoading(true));
    GetAllPlannings({ token: token, location_id: location_id, orderBy: props.orderBy, direction: props.direction, status: props.status, start: formattedStart, end: formattedEnd })
      .then(response => {
        dispatch(setPlanningsAll(response))
      })
      .catch(error => {
        const type = error.response?.type
        const data = error.response?.data
        if (error.response) { dispatch(setResponseError({ type, data })) }
        if (!error.response) { dispatch(setError(JSON.stringify(error))) }
      })
      .finally(() => {
        dispatch(setCalendarLoading(false));
      })
  };

  const eventStyleGetter = (event) => {
    let backgroundColor = '#017ab58C'; // Default color
    let color = '#FFFFFF';
    let border = '1px solid black'
    switch (event.planning_object.status) {
      case 'PENDING':
        backgroundColor = '#FFC4648C'
        border = '1px solid #FFC464'
        color = '#000000'
        break;
      case 'CANCELLED':
        backgroundColor = '#F447478C'
        border = '1px solid #F44747'
        color = '#000000'
        break;
      default:
        backgroundColor = '#69B5368C'
        border = '1px solid #69B536'
        color = '#000000'
        break;
    }

    if (anoukMode) {
      switch (event.planning_object.status) {
        case 'PENDING':
          backgroundColor = '#FFD9CE8C'
          border = '1px solid #FFD9CE'
          color = '#000000'
          break;
        case 'CANCELLED':
          backgroundColor = '#926C978C'
          border = '1px solid #926C97'
          color = '#000000'
          break;
        default:
          backgroundColor = '#8ACDEA8C'
          border = '1px solid #8ACDEA'
          color = '#000000'
          break;
      }
    }

    const customStyles = {
      backgroundColor,
      color,
      border,
      borderRadius: '5px'
    };

    return {
      style: customStyles,
    };
  };


  const EventWrapper = ({ event }) => (
    event.title // Can add more info with this
  );

  return (
    <>
      <DnDCalendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        views={['month', 'week', 'day']}
        defaultView="month"
        draggableAccessor={() => true}
        resizable
        selectable
        onEventDrop={handleEventDrop}
        onEventResize={handleEventResize}
        // onDropFromOutside={handleDropFromOutside}
        onDoubleClickEvent={handleEventDoubleClick}
        // onNavigate={handleNavigate}
        // onView={handleViewChange}
        onRangeChange={handleRangeChange}
        eventPropGetter={eventStyleGetter}
        components={{
          event: EventWrapper
        }}
        style={{ minHeight: '55rem' }}
      />
      <PlanningModal modal={modalview.modal} calendar={true} />
    </>
  );
};

export default PlanningCalendar;
