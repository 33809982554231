import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import GuestCheckButtons from '../portal/guestchecks/guestcheckbuttons';
import PaginationComponent from './pagination';
import GuestCheckModal from './guestcheckModal';
import { useTranslation } from 'react-i18next';

export default function GuestCheckTable(props) {
  const guestchecks = props.guestchecks || []
  const buttons = GuestCheckButtons.call()
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const { t: translate } = useTranslation();

  let pagination = buttons.pagination
  let pagesize = buttons.pagesize

  let rows = []

  for (const guestcheck of guestchecks) {
    // const guestcheck = guestchecks[index]
    const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit) : false
    rows.push(
      <tr className='product-row' key={guestcheck.guestcheck_number}>
        <td>{guestcheck.guestcheck_number}</td>
        <td>{guestcheck.state}</td>
        <td>{guestcheck.name}</td>
        <td>{guestcheck.last_operator}</td>
        <td>{guestcheck.last_order}</td>
        <td>{guestcheck.amount}</td>
        <td>
          <Button size="sm" type='button' onClick={() => { modalview.onClick(guestcheck) }}>{translate(modalview.tag)}</Button>
          {edit && <Button size="sm" type='button' onClick={() => { edit.onClick(guestcheck) }}>{translate(edit.tag)}</Button>}
        </td>
      </tr>
    )
  }
  
  const pages = guestchecks?.total_pages
  const page = (guestchecks?.number + 1)

  return (
    <>
      {/* {add && <TableButton {...add} />} */}
      {<PaginationComponent pagination={pagination} pagesize={pagesize} pages={pages} page={page} />}
      <Table hover width='100%'>
        <thead>
          <tr key='header'>
            <th>{translate('Number')}</th>
            <th>{translate('State')}</th>
            <th>{translate('Name')}</th>
            <th>{translate('Last operator')}</th>
            <th>{translate('Last order')}</th>
            <th>{translate('Amount')}</th>
            <th>{translate('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
        <GuestCheckModal modal={modalview.modal} />
      </Table>
    </>
  );
}