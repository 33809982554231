import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Tab, Nav } from 'react-bootstrap';

import CreateField from '../helpers/createField';
import AddressesEditTable from './addressesEditTable';
import PhoneNumbersEditTable from './phone_numbersEditTable';
import EmailsEditTable from './emailsEditTable';
import { useTranslation } from 'react-i18next';


function ContactForm(props) {
  let basicFields = []
  let formTabs = [];
  let formTabContent = [];
  const { t: translate } = useTranslation();

  props.fields.forEach(field => {
    if (field.type === 'object') {
      formTabs.push(
        <Nav.Item key={field.id}>
          <Nav.Link eventKey={field.id}>
            {translate(field.name)}
          </Nav.Link>
        </Nav.Item>
      );

      let objectFields = []
      field.fields.forEach(embeddedfield => {
        objectFields.push(CreateField(embeddedfield, props, field.id))
      });
      formTabContent.push(
        <Tab.Pane eventKey={field.id} key={field.id}>
          <div className='tab-container'>
            {objectFields}
          </div>
        </Tab.Pane>
      );
    }
    else {
      basicFields.push(CreateField(field, props))
    }
  });

  // addresses
  formTabs.push(
    <Nav.Item key='addresses'>
      <Nav.Link eventKey='addresses'>
        {translate('Addresses')}
      </Nav.Link>
    </Nav.Item>
  );
  formTabContent.push(
    <Tab.Pane eventKey='addresses' key='addresses'>
      <div className='tab-container'>
        <AddressesEditTable {...props} />
      </div>
    </Tab.Pane>
  );
  // phonenumbers
  formTabs.push(
    <Nav.Item key='phonenumbers'>
      <Nav.Link eventKey='phonenumbers'>
        {translate('Phonenumbers')}
      </Nav.Link>
    </Nav.Item>
  );
  formTabContent.push(
    <Tab.Pane eventKey='phonenumbers' key='phonenumbers'>
      <div className='tab-container'>
        <PhoneNumbersEditTable {...props} />
      </div>
    </Tab.Pane>
  );

  // emails
  formTabs.push(
    <Nav.Item key='emails'>
      <Nav.Link eventKey='emails'>
        {translate('Emails')}
      </Nav.Link>
    </Nav.Item>
  );
  formTabContent.push(
    <Tab.Pane eventKey='emails' key='emails'>
      <div className='tab-container'>
        <EmailsEditTable {...props} />
      </div>
    </Tab.Pane>
  );

  return (
    <>
      <Form>
        <Tab.Container defaultActiveKey="person">
          <Nav variant="tabs">
            {formTabs}
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="main">
              <div className='tab-container'>
                {basicFields}
              </div>
            </Tab.Pane>
            {formTabContent}
          </Tab.Content>
        </Tab.Container>
      </Form>
      <Button variant="primary" type={props.submit.type} onClick={props.submit.onClick}>
        {translate(props.submit.title)}
      </Button>
    </>
  );
}

export default ContactForm;