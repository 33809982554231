import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import BasicCard from "../../components/card"
import SnelstartTable from "../../components/snelstartTable";
import AccountingTable from "../../components/accountingTable";
import HandleError from "../../helpers/handleError";
import GetSnelstartCompany from "../../actablueAPI/snelstart/GetSnelstartCompany";
import GetPaymentLedgers from "../../actablueAPI/accounting/GetPaymentLedgers";

const Snelstart = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const dispatch = useDispatch();

  const [company, setCompany] = useState({})
  const [accounting_settings, setAccountingSettings] = useState({})

  const snelstartCard = {
    size: 12,
    title: "SnelStart",
    data: <SnelstartTable {...company} />,
  };

  const accountingCard = {
    size: 12,
    title: "Accounting",
    data: <AccountingTable {...accounting_settings} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle("SnelStart"))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setLoading(true))
    axios.all([
      // get snelstart company
      GetSnelstartCompany({ token: token, admin_id: admin_id, location_id: location_id }),
      // get accounting information
      GetPaymentLedgers({ token: token, admin_id: admin_id, location_id: location_id })
    ])
      .then(axios.spread((companyResponse, paymentledgersResponse) => {
        setCompany(companyResponse.data)
        setAccountingSettings(paymentledgersResponse.data)
        dispatch(setLoading(false))
      }))
      .catch(error => {
        HandleError(error)
        dispatch(setLoading(false))
      })
    // eslint-disable-next-line
  }, [, admin_id, location_id]);



  return (
    <>
      <BasicCard {...accountingCard} />
      <BasicCard {...snelstartCard} />
    </>
  )
};
export default Snelstart;
