import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import HandleError from "../../helpers/handleError";
import GetPlaylists from "../../actablueAPI/narrowcasting/GetPlaylists";
import NarrowcastingPlaylistsTable from "../../components/narrowcastingPlaylistsTable";
import BasicCard from "../../components/card";
import { getPlaylistPageSize, getPlaylistPagination, getPlaylists, setPlaylists } from "../../redux/dataSlices/narrowcastingSlice";

const NarrowcastingPlaylists = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const playlists = useSelector(getPlaylists);
  const dispatch = useDispatch();
  const pagination = useSelector(getPlaylistPagination);
  const pagesize = useSelector(getPlaylistPageSize);

  const playlistsCard = {
    size: 12,
    title: "Playlists",
    data: <NarrowcastingPlaylistsTable playlists={playlists} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle("Narrowcasting"))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setLoading(true))
    GetPlaylists({ token: token, admin_id: admin_id, location_id: location_id, page: pagination, size: pagesize })
      .then(response => {
        dispatch(setPlaylists(response.data))
        dispatch(setLoading(false))
      })
      .catch(error => {
        HandleError(error)
        dispatch(setLoading(false))
      })
    // eslint-disable-next-line
  }, [admin_id, location_id, pagesize, pagination]);

  return (
    <>
      <BasicCard {...playlistsCard} />
    </>
  );
};
export default NarrowcastingPlaylists;
