import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { getToken,  } from "../../redux/dataSlices/tokenSlice";
import { getRegistrationsAll, setRegistrationsAll } from "../../redux/dataSlices/registrationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import BasicCard from "../../components/card"
import SearchAndFilterSingleSelect from "../../components/searchAndFilterSingleSelectField";
import RegistrationsTable from "../../components/registrationsTable";
import HandleError from "../../helpers/handleError";
import { getHashUsersAll, setUsersAll } from "../../redux/dataSlices/userSlice";
import { getHashAdministrationsAll, setAdministrationsAll } from "../../redux/dataSlices/administrationSlice";
import { getHashRelationsAll, setRelationsAll } from "../../redux/dataSlices/relationSlice";
import { getHashLocationsAll } from "../../redux/dataSlices/locationSlice";
import GetAllRelationsWithAdmin from "../../helpers/getAllRelationsWithAdmin";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { setSubscriptionsAll } from "../../redux/dataSlices/subscriptionSlice";
import { getHashKeycloakUsersAll, getKeycloakUsersAll, setKeycloakUsersAll } from "../../redux/dataSlices/keycloakSlice";
import GetRegistrationAll from "../../actablueAPI/registrations/GetRegistrationAll";
import GetUsersList from "../../actablueAPI/serials/GetUsersList";
import GetSerialsList from "../../actablueAPI/serials/GetSerialsList";
import GetSubscriptionAll from "../../actablueAPI/registrations/GetSubscriptionAll";
import GetKeycloakUsersAll from "../../actablueAPI/keycloak/GetKeycloakUsersAll";

const Registrations = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id)
  const registrations = useSelector(getRegistrationsAll)
  const hash_users = useSelector(getHashUsersAll)
  const hash_admins = useSelector(getHashAdministrationsAll)
  const hash_locations = useSelector(getHashLocationsAll)
  const hash_relations = useSelector(getHashRelationsAll)
  const hash_keycloak_users = useSelector(getHashKeycloakUsersAll)
  const keycloak_users = useSelector(getKeycloakUsersAll)
  const dispatch = useDispatch();
  const [filteredRegistrations, setFilteredRegistrations] = useState()
  const [search, setSearch] = useState('')
  const [refresh, setRefresh] = useState(false)

  const navTitle = "Registrations"

  function toggleRefresh() {
    setRefresh(!refresh)
  }
  
  const onSearchChange = (event) => {
    setSearch(event.target.value)
    setFilteredRegistrations(registrations.filter(registration => JSON.stringify(registration).toLowerCase().includes(event.target.value.toLowerCase())))      

  }

  const onSearchKeyDown = (key) => {
    if (key.keyCode === 13) {
      onSearchClick()
    }
  }

  const onSearchClick = () => {
    if (!search) {
      setFilteredRegistrations()
    }
    if (search) {
      setFilteredRegistrations(registrations.filter(registration => JSON.stringify(registration).toLowerCase().includes(search.toLowerCase())))      
    }
  }

  const onFilterChange = (event) => {
    if (event) {
      setFilteredRegistrations(registrations.filter(registration => registration.reseller_id === event.value))
    }
    if (!event) {
      setFilteredRegistrations()
    }
  }


  const searchAndFilter = {
    search: {
      value: search,
      onChange: onSearchChange,
      onClick: onSearchClick,
      onKeyDown: onSearchKeyDown,
    },
    filter: {
      label: '',
      options: keycloak_users,
      hash: '',
      onChange: onFilterChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    }
  }

  const card = {
    size: 12,
    title: navTitle,
    data: <RegistrationsTable registrations={filteredRegistrations || registrations} hash_users={hash_users} hash_admins={hash_admins} hash_locations={hash_locations} hash_relations={hash_relations} hash_resellers={hash_keycloak_users} refresh={toggleRefresh}/>,
  };

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setLoading(true))
    axios.all([
      GetRegistrationAll({token:token}),
      GetUsersList({token:token}),
      GetSerialsList({token:token}),
      GetAllRelationsWithAdmin({ admin_id: admin_id, token: token }),
      GetSubscriptionAll({token:token}),
      GetKeycloakUsersAll({token:token, first:0, max:100})
    ])
    .then(axios.spread((registrationResponse, userResponse, administrationResponse, relatationsResponse, subscriptionsResponse, keycloakResponse) => {
      dispatch(setRegistrationsAll(registrationResponse.data))
      dispatch(setUsersAll(userResponse.data))
      dispatch(setAdministrationsAll(administrationResponse.data))
      dispatch(setRelationsAll(relatationsResponse))
      dispatch(setSubscriptionsAll(subscriptionsResponse.data))
      dispatch(setKeycloakUsersAll(keycloakResponse.data))
      dispatch(setLoading(false))
    }))
    .catch(error => {
      HandleError(error)
      dispatch(setLoading(false))
    })
    // eslint-disable-next-line
  }, [admin_id,refresh]);

  return (
    <>
      <SearchAndFilterSingleSelect {...searchAndFilter} />
      <BasicCard {...card} />
    </>
  )
};
export default Registrations;
