import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import HandleError from "../../helpers/handleError";
import NarrowcastingViewsTable from "../../components/narrowcastingViewsTable";
import BasicCard from "../../components/card";
import { getViewPageSize, getViewPagination, getViews, setViews } from "../../redux/dataSlices/narrowcastingSlice";
import GetViews from "../../actablueAPI/narrowcasting/GetViews";

const NarrowcastingViews = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const views = useSelector(getViews);
  const dispatch = useDispatch();
  const pagination = useSelector(getViewPagination);
  const pagesize = useSelector(getViewPageSize)

  const viewsCard = {
    size: 12,
    title: "Views",
    data: <NarrowcastingViewsTable views={views} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle("Narrowcasting"))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setLoading(true))
    GetViews({ token: token, admin_id: admin_id, location_id: location_id, page: pagination, size: pagesize })
      .then(response => {
        dispatch(setViews(response.data))
        dispatch(setLoading(false))
      })
      .catch(error => {
        HandleError(error)
        dispatch(setLoading(false))
      })
    // eslint-disable-next-line
  }, [admin_id, location_id, pagesize, pagination]);

  return (
    <>
      <BasicCard {...viewsCard} />
    </>
  );
};
export default NarrowcastingViews;
