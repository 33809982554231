import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { setCategoriesAll, getProductCategoriesAll, getHashProductCategoriesAll } from "../../redux/dataSlices/categorySlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card"
import { CategoryFormFields } from "./productcategoryFormFields"
import HandleOnChangeSingleSelectFieldWithObject from "../../helpers/handleOnChangeSingleSelectFieldWithObject";
import HandleOnChange from "../../helpers/handleOnChange";
import PostCategory from "../../actablueAPI/products/PostCategory";
import GetAllCategoriesWithType from "../../actablueAPI/products/getAllCategoriesWithType";
import { getScopes } from "../../redux/dataSlices/scopesSlice";
import GetAllPrinters from "../../actablueAPI/kitchenmanager/GetAllPrinters";
import { getHashPrintersAll, getHashSortCategoriesAll, getPrintersAll, getSortCategoriesAll, setPrintersAll, setSortCategoriesAll } from "../../redux/dataSlices/kitchenmanagerSlice";
import GetAllCategories from "../../actablueAPI/kitchenmanager/GetAllCategories";
import HandleError from "../../helpers/handleError";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import PostProductCategory from "../../actablueAPI/kitchenmanager/PostProductCategory";
import ProductCategoryForm from "../../components/productcategoryForm";
import { validateFormObject } from "../../helpers/validateFormObject";

const CategoryAdd = () => {
  const token = useSelector(getToken);
  const scopes = useSelector(getScopes);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const productpageCategories = useSelector(getProductCategoriesAll)
  const hash_productpage_categories = useSelector(getHashProductCategoriesAll)
  const kmPrinters = useSelector(getPrintersAll);
  const hash_printers = useSelector(getHashPrintersAll);
  const sortCategories = useSelector(getSortCategoriesAll);
  const hash_sort_categories = useSelector(getHashSortCategoriesAll);
  const [invalidFields, setInvalidFields] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [category, setCategory] = useState({ administration_id: admin_id, location_id: location_id, is_visible: true, type: 'PRODUCTPAGE' })
  const navTitle = "Product category add"

  const onChange = (event) => {
    let newCategory = HandleOnChange({ event: event, object: category })
    setCategory(newCategory)
  }

  const onParentChange = (event) => {
    let newCategory = HandleOnChangeSingleSelectFieldWithObject({ event: event, object: category, selectfield: 'parent', hash: hash_productpage_categories })
    setCategory(newCategory)
  };

  const onPrinterChange = (event) => {
    let newCategory = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: category, selectfield: 'printer', prefix: 'kitchenmanager' })
    setCategory(newCategory)
  }

  const onCategoryChange = (event) => {
    let newCategory = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: category, selectfield: 'category', prefix: 'kitchenmanager' })
    setCategory(newCategory)
  }

  const onSubmit = () => {
    const [invalid] = validateFormObject({ object: category, fields: formData?.fields, switchTab: true, dispatch: dispatch })
    
    if (invalid?.length > 0) {
      setInvalidFields(invalid)
      return;
    }

    dispatch(setLoading(true))

    PostCategory({ token: token, admin_id: admin_id, category: category })
    .then(async response => {
      let newCategory = JSON.parse(JSON.stringify(category))
      if (newCategory.hasOwnProperty('kitchenmanager') && Object.keys(newCategory?.kitchenmanager).length > 0) {
        if (scopes.sortcategories) {
          if (newCategory?.kitchenmanager?.printer && newCategory?.kitchenmanager?.category) {
            newCategory.kitchenmanager.product_category_id = response?.data?.id
            newCategory.kitchenmanager.admin_id = admin_id
            newCategory.kitchenmanager.location_id = location_id
            newCategory.kitchenmanager.name = newCategory?.title
            await PostProductCategory({ token: token, data: newCategory?.kitchenmanager })
            .catch(error => {
              console.error(error)
            })
          }
        }
      }
      navigate("/productcategories");
    })
  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    invalidFields: invalidFields,
    fields: CategoryFormFields(),
    field: {
      onChange: onChange
    },
    data: category,
    hash_printers: hash_printers,
    hash_sort_categories: hash_sort_categories,
    parent: {
      label: 'parent',
      placeholder: 'parent',
      options: productpageCategories,
      selected: {},
      onChange: onParentChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    printer: {
      label: 'printer',
      options: kmPrinters,
      selected: [],
      onChange: onPrinterChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    category: {
      label: 'category',
      options: sortCategories,
      selected: [],
      onChange: onCategoryChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
  };

  const addCard = {
    size: 12,
    title: navTitle,
    data: <ProductCategoryForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setLoading(true))
    if (scopes?.sortcategories) {
      axios.all([
        GetAllPrinters({ token: token, admin_id: admin_id, location_id: location_id }),
        GetAllCategories({ token: token, admin_id: admin_id, location_id: location_id })
      ])
      .then(axios.spread((printersResponse, categoryResponse) => {
        dispatch(setPrintersAll(printersResponse))
        dispatch(setSortCategoriesAll(categoryResponse))
      }))
      .catch(error => {
        HandleError(error)
      })
    }

    GetAllCategoriesWithType({ type: 'PRODUCTPAGE', token: token, admin_id: admin_id, location_id: location_id })
    .then(data => {
      dispatch(setCategoriesAll(data))
      dispatch(setLoading(false))
    })
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...addCard} />
    </>
  )

};
export default CategoryAdd;