import { createSlice } from "@reduxjs/toolkit";

export const categorySlice = createSlice({
  name: "category",
  initialState: {
    productcategory_pagesize: 10,
    productcategory_pagination: 0,
    journalcategory_pagesize: 10,
    journalcategory_pagination: 0,
    hash_journal_categories_all: {},
    hash_productpage_categories_all: {},
    categories_all: [],
    journal_categories_all: [],
    productpage_categories_all: [],
    selected_productcategories: [],
    selected_journalcategories: []
  },
  reducers: {
    setCategoryId: (state, action) => {
      state.category_id = action.payload;
    },
    setCategory: (state, action) => {
      state.category = action.payload;
    },
    setCategoriesAll: (state, action) => {
      state.categories_all = action.payload
    },
    setJournalCategoriesAll: (state, action) => {
      state.journal_categories_all = action.payload
      action.payload.forEach(category => {
        state.hash_journal_categories_all[category.id] = category
      });
    },
    setProductCategoriesAll: (state, action) => {
      state.productpage_categories_all = action.payload
      action.payload.forEach(category => {
        state.hash_productpage_categories_all[category.id] = category
      });
    },

    setJournalCategories: (state, action) => {
      state.journalcategories = action.payload
    },
    setJournalCategoryPagination: (state, action) => {
      state.journalcategory_pagination = (action.payload - 1)
    },
    setJournalCategoryPagesize: (state, action) => {
      state.journalcategory_pagesize = action.payload
    },

    setCategoryModalShow: (state, action) => {
      state.categorymodalshow = action.payload
    },

    setProductCategories: (state, action) => {
      state.productcategories = action.payload
    },
    setProductCategoryPagination: (state, action) => {
      state.productcategory_pagination = (action.payload - 1)
    },
    setProductCategoryPagesize: (state, action) => {
      state.productcategory_pagesize = action.payload
    },
    setSelectedProductCategories: (state, action) => {
      state.selected_productcategories = action.payload
    },
    setSelectedJournalCategories: (state, action) => {
      state.selected_journalcategories = action.payload
    },
    setCategoryProducts: (state, action) => {
      state.categoryProducts = action.payload
    }
  }
});

export const {
  setCategoryId, setCategory, setCategoryModalShow, setCategoriesAll,
  setJournalCategories, setJournalCategoriesAll, setJournalCategoryPagination, setJournalCategoryPagesize,
  setProductCategories, setProductCategoriesAll, setProductCategoryPagination, setProductCategoryPagesize,
  setSelectedProductCategories, setSelectedJournalCategories,
  setCategoryProducts
} = categorySlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getCategoryId = (state) => state.category.category_id
export const getCategory = (state) => state.category.category
export const getCategoryModalShow = (state) => state.category.categorymodalshow

export const getCategoriesAll = (state) => state.category.categories_all

export const getJournalCategories = (state) => state.category.journalcategories
export const getJournalCategoryPagination = (state) => state.category.journalcategory_pagination
export const getJournalCategoryPagesize = (state) => state.category.journalcategory_pagesize
export const getJournalCategoriesAll = (state) => state.category.journal_categories_all
export const getHashJournalCategoriesAll = (state) => state.category.hash_journal_categories_all

export const getProductCategories = (state) => state.category.productcategories
export const getProductCategoryPagination = (state) => state.category.productcategory_pagination
export const getProductCategoryPagesize = (state) => state.category.productcategory_pagesize
export const getProductCategoriesAll = (state) => state.category.productpage_categories_all
export const getHashProductCategoriesAll = (state) => state.category.hash_productpage_categories_all
export const getSelectedProductCategories = (state) => state.category.selected_productcategories

export const getSelectedJournalCategories = (state) => state.category.selected_journalcategories

export const getCategoryProducts = (state) => state.category.categoryProducts

export default categorySlice.reducer;