import { createSlice } from "@reduxjs/toolkit";

export const employeeSlice = createSlice({
  name: "employee",
  initialState: {
    employee_pagesize: 10,
    employee_pagination: 0,
    hash_employees_all: {},
    hash_employee_roles_all: {},
  },
  reducers: {
    setEmployeeId: (state, action) => {
      state.employee_id = action.payload;
    },
    setEmployee: (state, action) => {
      state.employee = action.payload;
    },
    setEmployees: (state, action) => {
      state.employees = action.payload
    },
    setEmployeesAll: (state, action) => {
      state.employees_all = action.payload
      action.payload.forEach(employee => {
        state.hash_employees_all[employee.operator_id] = employee
      });
    },
    setEmployeeModalShow: (state, action) => {
      state.employee_modalshow = action.payload
    },
    setEmployeePagination: (state, action) => {
      state.employee_pagination = (action.payload - 1)
    },
    setEmployeePageSize: (state, action) => {
      state.employee_pagesize = action.payload
    },
    setEmployeeRole: (state, action) => {
      state.employee_role = action.payload;
    },
    setEmployeeRoles: (state, action) => {
      state.employee_roles = action.payload
    },
    setEmployeeRolesAll: (state, action) => {
      state.employee_roles_all = action.payload
      action.payload.forEach(employee => {
        state.hash_employee_roles_all[employee.id] = employee
      });
    },
    setEmployeeRoleModalShow: (state, action) => {
      state.employee_role_modalshow = action.payload
    },
  }
});

export const {
  setEmployeeId, setEmployee, setEmployees, setEmployeeModalShow, setEmployeePagination, setEmployeePageSize, setEmployeesAll,
  setEmployeeRole, setEmployeeRoles, setEmployeeRolesAll, setEmployeeRoleModalShow
} = employeeSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getEmployeeId = (state) => state.employee.employee_id
export const getEmployee = (state) => state.employee.employee
export const getEmployees = (state) => state.employee.employees
export const getEmployeesAll = (state) => state.employee.employees_all
export const getHashEmployeesAll = (state) => state.employee.hash_employees_all
export const getEmployeeModalShow = (state) => state.employee.employee_modalshow
export const getEmployeePagination = (state) => state.employee.employee_pagination
export const getEmployeePageSize = (state) => state.employee.employee_pagesize

export const getEmployeeRole = (state) => state.employee.employee_role
export const getEmployeeRoles = (state) => state.employee.employee_roles
export const getEmployeeRolesAll = (state) => state.employee.employee_roles_all
export const getHashEmployeeRolesAll = (state) => state.employee.hash_employee_roles_all
export const getEmployeeRoleModalShow = (state) => state.employee.employee_role_modalshow

export default employeeSlice.reducer;