import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    hash_users_all: {},
    userroles_pagination: 0,
    userroles_pagesize: 10,
    hash_userroles_all: {}
  },
  reducers: {
    setUserId: (state, action) => {
      state.user_id = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setUsers: (state, action) => {
      state.users = action.payload
    },
    setUsersAll: (state, action) => {
      state.users_all = action.payload
      action.payload.forEach(user => {
        state.hash_users_all[user.id] = user
      });
    },
    setUserModalShow: (state, action) => {
      state.user_modalshow = action.payload
    },
    setSearch: (state, action) => {
      state.search = action.payload
    },
    setFilter: (state, action) => {
      state.filter = action.payload
    },
    setUserRole: (state, action) => {
      state.user_role = action.payload
    },
    setUserRoles: (state, action) => {
      state.user_roles = action.payload
    },
    setUserRolesAll: (state, action) => {
      state.user_roles_all = action.payload
      if (action.payload.length > 0) {
        action.payload.forEach(user_role => {
          state.hash_userroles_all[user_role.id] = user_role
        });
      }
    },
    setUserRoleModalShow: (state, action) => {
      state.user_role_modalshow = action.payload
    },
    setUserRolePagination: (state, action) => {
      state.userroles_pagination = (action.payload - 1)
    },
    setUserRolePagize: (state, action) => {
      state.userroles_pagesize = action.payload
    },
  }
});

export const {
  setUserId, setUser, setUsers, setUserModalShow, setUsersAll, setFilter, setSearch,
  setUserRole, setUserRoles, setUserRolesAll, setUserRoleModalShow, setUserRolePagination, setUserRolePagize
} = userSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getUserId = (state) => state.user.user_id
export const getUser = (state) => state.user.user
export const getUsers = (state) => state.user.users
export const getUsersAll = (state) => state.user.users_all
export const getHashUsersAll = (state) => state.user.hash_users_all
export const getUserModalShow = (state) => state.user.user_modalshow
export const getFilter = (state) => state.user.filter
export const getSearch = (state) => state.user.search

export const getUserRole = (state) => state.user.user_role
export const getUserRoles = (state) => state.user.user_roles
export const getUserRolesAll = (state) => state.user.user_roles_all
export const getHashUserRolesAll = (state) => state.user.hash_userroles_all
export const getUserRoleModalShow = (state) => state.user.user_role_modalshow
export const getUserRolePagination = (state) => state.user.userroles_pagination
export const getUserRolePageSize = (state) => state.user.userroles_pagesize

export default userSlice.reducer;