import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getReservationLocationId } from "../../redux/dataSlices/reservationSlice";
import { getLocationsAllAdmins, getHashLocationsAll } from "../../redux/dataSlices/locationSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card"
import { ReservationLocationEditFormFields } from "./reservationlocationFormFields.jsx";
import HandleOnChange from "../../helpers/handleOnChange";
import HandleOnChangeMultiSelectFieldWithValues from "../../helpers/handleOnChangeMultiSelectFieldWithValues";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import HandleOnChangeOpeningTimes from "../../helpers/handleOnChangeOpeningTimes";
import ReservationLocationForm from "../../components/reservationlocationForm";
import { applicationChoices } from "../../selectfieldchoices/reservationlocationselect.mjs";
import { timezoneChoices } from "../../selectfieldchoices/timezone.mjs";
import PostReservationLocation from "../../actablueAPI/reservations/PostReservationLocation.jsx";
import GetReservationLocationAdminById from "../../actablueAPI/reservations/GetReservationLocationAdminByID.jsx";

const LocationEdit = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const reservationlocation_id = useSelector(getReservationLocationId)
  const locations_all_admins = useSelector(getLocationsAllAdmins)
  const hash_locations_all = useSelector(getHashLocationsAll)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navTitle = "Reservationlocation edit"
  const [reservationlocation, setReservationLocation] = useState({})

  const onChange = (event) => {
    let newLocation = HandleOnChange({ event: event, object: reservationlocation })
    setReservationLocation(newLocation)
  }

  const onIdChange = (event) => {
    let newLocation = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: reservationlocation, selectfield: 'id' })
    setReservationLocation(newLocation)
  }

  const onImgUrlChange = (event) => {
    let newLocation = HandleOnChangeMultiSelectFieldWithValues({ event: event, object: reservationlocation, selectfield: 'img_urls' })
    setReservationLocation(newLocation)
  }

  const onApplicationChange = (event) => {
    let newLocation = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: reservationlocation, selectfield: 'application' })
    setReservationLocation(newLocation)
  }

  const onTimezoneChange = (event) => {
    let newLocation = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: reservationlocation, selectfield: 'timezone' })
    setReservationLocation(newLocation)
  }

  const onChangeOpeningTimes = (event) => {
    let newLocation = HandleOnChangeOpeningTimes({ event: event, object: reservationlocation })
    setReservationLocation(newLocation)
  };

  const onSubmit = () => {
    let newLocation = Object.assign({}, reservationlocation)

    if (window.location.href.includes('humbee')) { // To feed application humbee to the API as default since the form field is hidden
      newLocation.application = 'HUMBEE'
      newLocation.timezone = 'Europe/Amsterdam'
      newLocation.walk_in_time = 0
      newLocation.walk_out_time = 0
      setReservationLocation(newLocation)
    }

    PostReservationLocation({token:token, admin_id:admin_id, data:newLocation})
    .then(response => {
      navigate("/reservationlocations");
    })
  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    fields: ReservationLocationEditFormFields(),
    field: {
      onChange: onChange
    },
    data: reservationlocation,
    img_urls: {
      label: 'images',
      options: [],
      selected: [],
      onChange: onImgUrlChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    opening_times: {
      onChange: onChangeOpeningTimes
    },
    id: {
      label: 'id',
      // eslint-disable-next-line
      options: locations_all_admins.filter((item) => { if (item.administration_id === reservationlocation.administration_id) { return true } }),
      hash: hash_locations_all,
      selected: {},
      onChange: onIdChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    application: {
      label: 'application',
      options: applicationChoices,
      selected: {},
      onChange: onApplicationChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    timezone: {
      label: 'timezone',
      options: timezoneChoices,
      selected: {},
      onChange: onTimezoneChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
  };

  const editCard = {
    size: 12,
    title: {text: navTitle, option: hash_locations_all[reservationlocation.id].name},
    data: <ReservationLocationForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setLoading(true))
    GetReservationLocationAdminById({token:token, id:reservationlocation_id})
    .then(response => {
      setReservationLocation(response.data)
      dispatch(setLoading(false))
    })    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...editCard} />
    </>
  )

};
export default LocationEdit;

