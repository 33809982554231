import { Button, Table } from 'react-bootstrap';
import ContactButtons from '../portal/contacts/contactbuttons';
import PaginationAndSizeComponent from './paginationAndSize';
import TableButton from './tableButton';
import ContactModal from './contactModal';
import { useTranslation } from 'react-i18next';


export default function ContactTable(contacts) {
  const buttons = ContactButtons.call()
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const add = buttons.hasOwnProperty('add') ? Object.assign({}, buttons.add) : false
  const { t: translate } = useTranslation();

  let pagination = buttons.pagination
  let pagesize = buttons.pagesize

  if (contacts && contacts.content && contacts.content.length > 0) {
    const pages = contacts.total_pages
    const page = (contacts.number + 1)
    return (
      <>
        {add && <TableButton {...add} />}
        {<PaginationAndSizeComponent pagination={pagination} pagesize={pagesize} pages={pages} page={page} />}
        <Table hover width='100%'>
          <thead>
            <tr>
              <th width='70%'>
                {translate('Name')}
              </th>
              <th>
                {translate('Actions')}
              </th>
            </tr>
          </thead>
          <tbody>
            {contacts.content.map((object) => {
              const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit, { id: object.id }) : false
              return (
                <tr>
                  <td>{object.hasOwnProperty('person') ? object.person.full_name : ''}</td>
                  <td>
                    <Button size="sm" type='button' onClick={() => { modalview.onClick(object) }} id={object.id}>{translate(modalview.tag)}</Button>
                    {edit && <TableButton {...edit} />}
                  </td>
                </tr>
              )
            })}
          </tbody>
          <ContactModal modal={modalview.modal} />
        </Table>
      </>
    );
  } else {
    return (
      <>
        {add && <TableButton {...add} />}
        <Table width='100%'>
          <thead>
            <tr>
              <th width='70%'>
                {translate('Name')}
              </th>
              <th>
                {translate('Actions')}
              </th>
            </tr>
          </thead>
        </Table>
      </>
    );
  }
}
