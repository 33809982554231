import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import BasicCard from "../../components/card"
import HandleError from "../../helpers/handleError";
import { CardFormFields } from "./loyaltyFormFields";
import HandleOnChange from "../../helpers/handleOnChange";
import { getAdministrationsAll, getHashAdministrationsAll } from "../../redux/dataSlices/administrationSlice";
import { getHashLocationsAll, getLocationsAllAdmins } from "../../redux/dataSlices/locationSlice";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import { cardTypeChoices, typeChoices } from "../../selectfieldchoices/loyaltyselect.mjs";
import CardForm from "../../components/cardForm";
import PostCard from "../../actablueAPI/loyalty/PostCard";

const CardAdd = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const admins_all = useSelector(getAdministrationsAll)
  const hash_admins_all = useSelector(getHashAdministrationsAll)
  const locations_all_admins = useSelector(getLocationsAllAdmins)
  const hash_locations_all = useSelector(getHashLocationsAll)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [card, setCard] = useState({ administration_id: admin_id, location_id: location_id })

  const onChange = (event) => {
    if (event?.target?.id === 'minimal_points_required') event.target.value = Math.round(event.target.value)
    let newProgram = HandleOnChange({ event: event, object: card })
    setCard(newProgram)
  }

  const onAdminIdChange = (event) => {
    let newProgram = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: card, selectfield: 'administration_id' })
    newProgram.location_id = null
    setCard(newProgram)
  }

  const onSelectChange = (event) => {
    let newProgram = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: card })
    setCard(newProgram)
  }

  const onSubmit = (event) => {

    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    PostCard({ token: token, admin_id: card?.admin_id, card: card })
    .then(() => {
      navigate("/loyalty");
    })
    .catch(error => {
      HandleError(error)
    })
  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    fields: CardFormFields(),
    field: {
      onChange: onChange
    },
    data: card,
    location_id: {
      label: 'location_id',
      // eslint-disable-next-line
      options: locations_all_admins?.filter((item) => { if (item?.administration_id === card?.administration_id) { return true } }),
      hash: hash_locations_all,
      selected: {},
      onChange: onSelectChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    administration_id: {
      label: 'administration_id',
      options: admins_all,
      hash: hash_admins_all,
      selected: {},
      onChange: onAdminIdChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    type: {
      label: 'type',
      options: typeChoices,
      selected: {},
      onChange: onSelectChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    card_type: {
      label: 'card_type',
      options: cardTypeChoices,
      selected: {},
      onChange: onSelectChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
  };

  const addCard = {
    size: 12,
    title: "Card add",
    data: <CardForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle("Card add"))
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...addCard} />
    </>
  )

};



export default CardAdd;

