import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getReservationLocationsAll, setReservationLocationsAll, getReservationLocationPagination, getReservationLocationPagesize } from "../../redux/dataSlices/reservationSlice";
import { getHashLocationsAll } from "../../redux/dataSlices/locationSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import BasicCard from "../../components/card"
import ReservationLocationTable from "../../components/reservationlocationTable.jsx";
import { setError } from "../../redux/dataSlices/errorSlice";
import GetReservationLocationList from "../../actablueAPI/reservations/GetReservationLocationList.jsx";

const ReservationLocations = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const hash_locations_all = useSelector(getHashLocationsAll)
  const reservation_locations = useSelector(getReservationLocationsAll)
  const pagination = useSelector(getReservationLocationPagination);
  const pageSize = useSelector(getReservationLocationPagesize);
  const dispatch = useDispatch();

  const card = {
    size: 12,
    title: "Reservation Locations",
    data: <ReservationLocationTable locations={reservation_locations} hash_locations={hash_locations_all} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle("Reservation Locations"))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setLoading(true))
    GetReservationLocationList({token:token, admin_id:admin_id})
    .then(response => {
      dispatch(setReservationLocationsAll(response.data))
      dispatch(setLoading(false))
    })
    .catch(function (error) {
      dispatch(setError(JSON.stringify(error)))
      dispatch(setLoading(false))
    })
    // eslint-disable-next-line
  }, [, admin_id, location_id, pagination, pageSize]);

  return (
    <>
      <BasicCard {...card} />
    </>
  )
};
export default ReservationLocations;
