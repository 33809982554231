import React from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getCcvshop, setCcvshop } from "../../redux/dataSlices/ccvshopSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card"
import CcvshopForm from "../../components/ccvshopForm";
import { CcvshopEditFormFields } from "./ccvshopFormFields.jsx";
import HandleOnChange from "../../helpers/handleOnChange";
import PutCcvshopCcvKeys from "../../actablueAPI/ccvshop/PutCcvshopCcvKeys.jsx";

const CcvshopEdit = () => {
  const token = useSelector(getToken);
  const ccvshop = useSelector(getCcvshop)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navTitle = "CCVShop edit"

  const onChange = (event) => {
    let newCcvshop = HandleOnChange({ event: event, object: ccvshop })
    dispatch(setCcvshop(newCcvshop))
  }

  // const onLocationIdChange = (event) => {
  //   let newCcvshop = HandleOnChangeSingleSelectFieldWithValue({event:event, object:ccvshop, selectfield:'location_id'})
  //   dispatch(setCcvshop(newCcvshop))
  // }

  const onSubmit = () => {
    PutCcvshopCcvKeys({ token: token, id: ccvshop.id, data: ccvshop })
      .then(() => {
        navigate("/ccvshops");
      })

  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    fields: CcvshopEditFormFields(),
    field: {
      onChange: onChange
    },
    data: ccvshop,
  };

  const card = {
    size: 12,
    title: navTitle,
    data: <CcvshopForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...card} />
    </>
  )

};
export default CcvshopEdit;

