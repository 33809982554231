import { createSlice } from "@reduxjs/toolkit";

export const relationSlice = createSlice({
  name: "relation",
  initialState: {
    relation_pagesize: 10,
    relation_pagination: 0,
    relationsall: [],
    hash_relations_all: {},
    hash_customer_relations_all: {},
    hash_supplier_relations_all: {},
    relationFilter: 'CUSTOMER',
    relationSearch: ''
    },
  reducers: {
    setRelationId: (state, action) => {
      state.relation_id = action.payload;
    },
    setRelation: (state, action) => {
      state.relation = action.payload;
    },
    setRelations: (state, action) => {
      state.relations = action.payload
    },
    setRelationsAll: (state, action) => {
      state.relationsall = action.payload
      if (typeof(action.payload)=== 'object') {
        state.customer_relations_all = []
        state.supplier_relations_all = []
        action.payload.forEach(relation => {
          state.hash_relations_all[relation.id] = relation
          if (relation.hasOwnProperty('type')) {
            if (relation.type.toLowerCase() === 'customer' || relation.type.toLowerCase() === 'klant' ) {
              state.hash_customer_relations_all[relation.id] = relation
              state.customer_relations_all.push(relation)
            }
            if (relation.type.toLowerCase() === 'supplier' || relation.type.toLowerCase() === 'leverancier' ) {
              state.hash_supplier_relations_all[relation.id] = relation
              state.supplier_relations_all.push(relation)
            }
          }
          
        });
      }
    },
    setRelationModalShow: (state, action) => {
      state.relationmodalshow = action.payload
    },
    setRelationPagination: (state, action) => {
      state.relation_pagination = (action.payload - 1)
    },
    setRelationPagesize: (state, action) => {
      state.relation_pagesize = action.payload
    }, 
    setRelationSearch: (state, action) => {
      state.relationSearch = action.payload
    },
    setRelationFilter: (state, action) => {
      state.relationFilter = action.payload
    },
  }
});

export const {
  setRelationId, setRelation, setRelations, setRelationModalShow, setRelationPagination, setRelationPagesize, setRelationsAll,
  setRelationSearch, setRelationFilter
} = relationSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getRelationId = (state) => state.relation.relation_id
export const getRelation = (state) => state.relation.relation
export const getRelations = (state) => state.relation.relations
export const getRelationsAll = (state) => state.relation.relationsall
export const getHashRelationsAll = (state) => state.relation.hash_relations_all
export const getCustomerRelationsAll = (state) => state.relation.customer_relations_all
export const getHashCustomerRelationsAll = (state) => state.relation.hash_customer_relations_all
export const getSupplierRelationsAll = (state) => state.relation.supplier_relations_all
export const getHashSupplierRelationsAll = (state) => state.relation.hash_supplier_relations_all
export const getRelationModalShow = (state) => state.relation.relationmodalshow
export const getRelationPagination = (state) => state.relation.relation_pagination
export const getRelationPagesize = (state) => state.relation.relation_pagesize
export const getRelationSearch = (state) => state.relation.relationSearch
export const getRelationFilter = (state) => state.relation.relationFilter

export default relationSlice.reducer;