import { createSlice } from "@reduxjs/toolkit";

export const registrationSlice = createSlice({
  name: "registration",
  initialState: {
    registration_pagesize:10,
    registration_pagination:10,
    hash_registrations_all:{},
    invalidRegistrations:{}
  },
  reducers: {
    setRegistrationId: (state, action) => {
      state.registration_id = action.payload;
    },
    setRegistration: (state, action) => {
      state.registration = action.payload;
    },
    setRegistrations: (state, action) => {
      state.registrations = action.payload
    },
    setRegistrationsAll: (state, action) => {
      state.registrations_all = action.payload
      action.payload.forEach(registration => {
        state.hash_registrations_all[registration.id] = registration
      });
    },
    setRegistrationModalShow: (state, action) => {
      state.registration_modalshow = action.payload
    },
    setRegistrationCopyModalShow: (state, action) => {
      state.registration_copy_modalshow = action.payload
    },
    setRegistrationEditModalShow: (state, action) => {
      state.registration_edit_modalshow = action.payload
    },
    setRegistrationPagination: (state, action) => {
      state.registration_pagination = (action.payload - 1)
    },
    setRegistrationPageSize: (state, action) => {
      state.registration_pagesize = action.payload
    },
    setInvalidRegistrations: (state, action) => {
      state.invalidRegistrations[`${action.payload.id}-${action.payload.registration_id}`] = action.payload
    }
  }
});

export const {
  setRegistrationId, 
  setRegistration, 
  setRegistrations, 
  setRegistrationModalShow, 
  setRegistrationCopyModalShow, 
  setRegistrationEditModalShow,
  setRegistrationPagination, 
  setRegistrationPageSize, 
  setRegistrationsAll,
  setInvalidRegistrations
} = registrationSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getRegistrationId = (state) => state.registration.registration_id
export const getRegistration = (state) => state.registration.registration
export const getRegistrations = (state) => state.registration.registrations
export const getRegistrationsAll = (state) => state.registration.registrations_all
export const getHashRegistrationsAll = (state) => state.registration.hash_registrations_all
export const getRegistrationModalShow = (state) => state.registration.registration_modalshow
export const getRegistrationEditModalShow = (state) => state.registration.registration_edit_modalshow
export const getRegistrationCopyModalShow = (state) => state.registration.registration_copy_modalshow
export const getRegistrationPagination = (state) => state.registration.registration_pagination
export const getRegistrationPageSize = (state) => state.registration.registration_pagesize
export const getInvalidRegistrations = (state) => state.registration.invalidRegistrations

export default registrationSlice.reducer;