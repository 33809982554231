import React from "react";
import { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";

import { useNavigate } from "react-router-dom";

import BasicCard from "../../components/card"
import PrinterForm from "../../components/printerForm";

import { PrinterFormFields } from './printerFormFields.mjs';

import HandleOnChange from "../../helpers/handleOnChange";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import HandleError from "../../helpers/handleError";

import { printerPaperTypeChoices, printerTypeChoices } from "../../selectfieldchoices/printerselect.mjs";
import PostPrinter from "../../actablueAPI/shelflabels/PostPrinter";

const PrinterAdd = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [printer, setPrinter] = useState({'admin_id': admin_id, 'location_id': location_id})
  const navTitle = "Printer add"

  const onChange = (event) => {
    let newPrinter = HandleOnChange({ event: event, object: printer })
    setPrinter(newPrinter)
  }

  const onTypeChange = (event) => {
    let newPrinter = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: printer, selectfield: 'type' })
    setPrinter(newPrinter)
  }

  const onPaperTypeChange = (event) => {
    let newPrinter = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: printer, selectfield: 'paper_type' })
    setPrinter(newPrinter)
  }

  const onSubmit = async () => {
    await PostPrinter({ token: token, data: printer })
      .then(() => {
        navigate("/settings");
      })
      .catch(error => {
        HandleError(error)
      })
  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    fields: PrinterFormFields,
    field: {
      onChange: onChange
    },
    data: printer,
    type: {
      label: 'type',
      options: printerTypeChoices,
      selected: [],
      onChange: onTypeChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    paper_type: {
      label: 'paper_type',
      options: printerPaperTypeChoices,
      selected: [],
      onChange: onPaperTypeChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
  };

  const editCard = {
    size: 12,
    title: navTitle,
    data: <PrinterForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...editCard} />
    </>
  )

};
export default PrinterAdd;

