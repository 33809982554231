import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import HandleError from "../../helpers/handleError";
import GetSchedules from "../../actablueAPI/narrowcasting/GetSchedules";
import NarrowcastingSchedulesTable from "../../components/narrowcastingSchedulesTable";
import BasicCard from "../../components/card";
import { getSchedulePageSize, getSchedulePagination, getSchedules, setSchedules } from "../../redux/dataSlices/narrowcastingSlice";

const NarrowcastingSchedules = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const schedules = useSelector(getSchedules);
  const dispatch = useDispatch();
  const pagination = useSelector(getSchedulePagination);
  const pagesize = useSelector(getSchedulePageSize);

  const schedulesCard = {
    size: 12,
    title: "Schedules",
    data: <NarrowcastingSchedulesTable schedules={schedules} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle("Narrowcasting"))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setLoading(true))
    GetSchedules({ token: token, admin_id: admin_id, location_id: location_id, page: pagination, pagesize: pagesize })
      .then(responseSchedules => {
        dispatch(setSchedules(responseSchedules.data))
        dispatch(setLoading(false))
      })
      .catch(error => {
        HandleError(error)
        dispatch(setLoading(false))
      })
    // eslint-disable-next-line
  }, [admin_id, location_id, pagination, pagesize]);

  return (
    <>
      <BasicCard {...schedulesCard} />
    </>
    // Defining two functions, handleTemplateSelect and handlePublish, to handle 
    // the user's selection of a template and the publishing of the narrowcasting content.
  );
};
export default NarrowcastingSchedules;
