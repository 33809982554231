import { createSlice } from "@reduxjs/toolkit";

export const templateSlice = createSlice({
  name: "template",
  initialState: {
    template: [],
    templates: [],
    template_images: [],
    template_pagination: 1,
    template_pagesize: 10,
    templates_all: [],
    selected_ean: {},
    hash_templates_all: {},
    hash_product_links_all: {},
  },
  reducers: {
    setTemplateId: (state, action) => {
      state.template_id = action.payload;
    },
    setTemplate: (state, action) => {
      state.template = action.payload;
    },
    setTemplates: (state, action) => {
      state.templates = action.payload
    },
    setTemplatesAll: (state, action) => {
      state.templates_all = action.payload

      if (action.payload.items) {
        action.payload.items.forEach(template => {
          state.hash_templates_all[template.id] = template
        });
      }

      if (!action.payload.items) {
        action.payload.forEach(template => {
          state.hash_templates_all[template.id] = template
        });
      }

    },
    setTemplateModalShow: (state, action) => {
      state.template_modalshow = action.payload
    },
    setTemplatePagination: (state, action) => {
      state.template_pagination = action.payload
    },
    setTemplatePageSize: (state, action) => {
      state.template_pagesize = action.payload
    },
    setTemplateImages: (state, action) => {
      state.template_images = action.payload
    },
    setSelectedEan: (state, action) => {
      state.selected_ean = action.payload
    },
    setProductLink: (state, action) => {
      state.product_link = action.payload;
    },
    setProductLinks: (state, action) => {
      state.product_links = action.payload
    },
    setProductLinksAll: (state, action) => {
      state.product_links_all = action.payload
      action.payload.items.forEach(product_link => {
        state.hash_product_links_all[product_link.product_id] = product_link
      });
    },
    setTemplateElementModalShow: (state, action) => {
      state.template_element_modalshow = action.payload
    },
  }
});

export const {
  setTemplateId, setTemplate, setTemplates, setTemplateModalShow, setTemplatePagination, setTemplatePageSize, setTemplatesAll, setTemplateImages,
  setSelectedEan, setProductLink, setProductLinks, setProductLinksAll, 
  setTemplateElement, setTemplateElementModalShow
} = templateSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getTemplateId = (state) => state.template.template_id
export const getTemplate = (state) => state.template.template
export const getTemplates = (state) => state.template.templates
export const getTemplatesAll = (state) => state.template.templates_all
export const getHashTemplatesAll = (state) => state.template.hash_templates_all
export const getTemplateModalShow = (state) => state.template.template_modalshow
export const getTemplatePagination = (state) => state.template.template_pagination
export const getTemplatePageSize = (state) => state.template.template_pagesize
export const getTemplateImages = (state) => state.template.template_images
export const getSelectedEan = (state) => state.template.selected_ean
export const getProductLink = (state) => state.template.product_link
export const getProductLinks = (state) => state.template.product_links
export const getProductLinksAll = (state) => state.template.product_links_all
export const getHashProductLinksAll = (state) => state.template.hash_product_links_all
export const getTemplateElementModalShow = (state) => state.template.template_element_modalshow

export default templateSlice.reducer;