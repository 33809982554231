import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { editOrders, setOrder, setOrderPagination, getOrderModalShow, setOrderModalShow, getOrderPagesize, setOrderPagesize } from "../../redux/dataSlices/orderSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { getScopes } from "../../redux/dataSlices/scopesSlice";
import { getRole } from '../../redux/dataSlices/tokenSlice';
import { pagesize } from "../../selectfieldchoices/pagesize.mjs";
import HandleError from "../../helpers/handleError";
import { useState } from "react";
import { OrderPDF } from "../../pdfcreators/orderPDF";
import { getHashLocationsAll } from "../../redux/dataSlices/locationSlice";
import exportMultipleOrderPDF from "../../helpers/exportMultipleOrderPDF";
import GetRelationById from "../../actablueAPI/relations/GetRelationByID";
import { PickingOrderPDF } from "../../pdfcreators/pickingOrderPDF";
import GetProduct from "../../actablueAPI/products/GetProduct";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import GetProductById from "../../actablueAPI/products/GetProductByID";
import GetOrderById from "../../actablueAPI/invoicepayments/GetOrderByID";
import PutOrderById from "../../actablueAPI/invoicepayments/PutOrderByID";

export default function OrderButtons() {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id)
  const hash_locations_all = useSelector(getHashLocationsAll);
  const modalShow = useSelector(getOrderModalShow)
  const pageSize = useSelector(getOrderPagesize)
  const scopes = useSelector(getScopes)
  const role = useSelector(getRole)
  const dispatch = useDispatch();
  const [selected, setSelected] = useState({})
  const navigate = useNavigate();

  const onSelect = (object) => {
    if (selected.hasOwnProperty(object.id)) {
      const newSelected = { ...selected };
      delete newSelected[object.id];
      setSelected(newSelected);
    } else {
      const newSelected = { ...selected, [object.id]: object };
      setSelected(newSelected);
    }
  };

  const onSelectAll = (objects) => {
    const allSelected = objects.every(
      (object) => selected.hasOwnProperty(object.id)
    );

    if (allSelected) {
      setSelected({});
    } else {
      const newSelected = { ...selected };

      objects.forEach((object) => {
        newSelected[object.id] = object;
      });

      setSelected(newSelected);
    }
  };

  const onclickChangeStatus = (event) => {
    GetOrderById({token:token, admin_id:admin_id, id:event.target.id})
    .then(response => {
      let order = response.data
      delete order.order_lines
      delete order.payment_amount_inclusive_vat
      delete order.payment_amount_exclusive_vat
      order.status = event.target.value.toUpperCase()
      PutOrderById({token:token, admin_id:admin_id, id:event.target.id, data:order})
      .then(response => {
        dispatch(setOrder(response.data))
        dispatch(editOrders(response.data))
        dispatch(setLoading(false))
      })
      .catch(error => {
        HandleError(error)
        dispatch(setLoading(false))
      })
    })
    .catch(error => {
      HandleError(error)
      dispatch(setLoading(false))
    })
  };

  function onViewClick(order) {
    dispatch(setOrder(order));
    dispatch(setOrderModalShow(true));
  };

  const onViewHide = (event) => {
    dispatch(setOrderModalShow(false));
  };

  // const onclickEdit = (event) => {
  //   dispatch(setOrderId(event.target.id));
  //   navigate("/order/edit");
  // };

  const onPaginationClick = (event) => {
    dispatch(setOrderPagination(event.target.value))
  };

  const onProductPageSizeChange = (event) => {
    dispatch(setOrderPagesize(event.value))
  }

  const onclickTransaction = (order) => {
    dispatch(setOrder(order))
    navigate("/order/transaction")
  }

  const onclickCCVTransaction = (order) => {
    dispatch(setOrder(order))
    navigate("/order/transaction")
  }

  const onClickOrderTicketPdf = async (order) => {
    let relation = undefined;
    if (order?.relation_id) {
      await GetRelationById({ token: token, id: order?.relation_id, admin_id:admin_id })
        .then(response => {
          relation = response?.data;
        })
    }
    OrderPDF(order, hash_locations_all, relation);
  }

  const onClickPickingOrderPdf = async (order) => {
    let relation = undefined;
    if (order?.relation_id) {
      await GetRelationById({ token: token, id: order?.relation_id, admin_id:admin_id })
        .then(response => {
          relation = response?.data;
        })
    }

    let newOrder = JSON.parse(JSON.stringify(order))
    // get products for product id's
    for (let line of newOrder.order_lines) {
      // get original product id 
      let original_id
      switch (line.origin) {
        case 'CCVSHOP':
          await GetProduct({token:token, admin_id:admin_id, location_id:location_id, ccv_id:line.product_id})
            .then(response => {
              original_id = response.data.id
            })
          break;
        case 'EXTERNAL':
          await GetProduct({token:token, admin_id:admin_id, location_id:location_id, external_id:line.product_id})
            .then(response => {
              original_id = response.data.id
            })
          break;
        case 'LIGHTSPEED':
          await GetProduct({token:token, admin_id:admin_id, location_id:location_id, lightspeed_id:line.product_id})
            .then(response => {
              original_id = response.data.id
            })
          break;
        default:
          original_id = line.product_id
          break;
      }

      await GetProductById({token: token, id:original_id, admin_id:admin_id})
      .then(response => {
        line.product=response.data
      })
    }

    PickingOrderPDF(newOrder, hash_locations_all, relation);
  }

  const onExportClick = async () => {
    let relations_hash = [];

    for (const order of Object.values(selected)) {
      if (order?.relation_id) {
        await GetRelationById({ token: token, id: order?.relation_id, admin_id:admin_id })
          .then(response => {
            relations_hash[response?.data?.id] = response?.data;
          })
      }
    }

    exportMultipleOrderPDF(selected, hash_locations_all, relations_hash, dispatch);
  }

  const onDpdClick = (order) => { // Only used for Van Erp right now, that's why the Hunter-CRM admin_no is hardcoded !!!!!!!!!!!!!
    if (order?.ccv_order_id && order?.delivery) window.open(`http://pos.uwslijter.hunter-crm.com/ccvshop/hook_postage_label.php?id=${201394}&ccv_id_override=${order?.ccv_order_id}&order_id=${order?.id}&administration_id=${order?.administration_id}&location_id=${order?.location_id}`, "_blank");
  }

  let buttons = {
    modalview: {
      tag: 'View',
      onClick: onViewClick,
      modal: {
        show: modalShow,
        onHide: onViewHide,
        onStatusChange: onclickChangeStatus,
        exportPdf: {
          tag: 'PDF',
          onClick: onClickOrderTicketPdf
        }
      },
      buttons: {}
    },
    pagination: {
      maxbuttons: 5, //uneven number
      onClick: onPaginationClick
    },
    pagesize: {
      className: 'navbar-select',
      label: "page size selector",
      placeholder: "No selecetion",
      pagesize: pageSize,
      selected: { 'id': pageSize, 'name': pageSize },
      number_of_elements: 0,
      options: pagesize,
      onChange: onProductPageSizeChange
    },
    select: {
      onClick: onSelect,
      selected: selected
    },
    selectAll: {
      onClick: onSelectAll
    },
    export: {
      tag: 'Export to PDF',
      value: 'export',
      onClick: onExportClick
    },
    pickingOrder: {
      tag: 'Picking order',
      value: 'pickingorder',
      onClick: onClickPickingOrderPdf
    }
  }

  // add buttons based on scopes
  // scopeOptions = ['read', 'create', 'update', 'delete'] (use index: 0:read, 1:create, 2:update, 3:delete)
  // buttonOptions = {'get':0, 'new':1, 'edit':2, 'add':2, 'del':3, 'swap':2, 'move':2, 'import':1, 'create': 1, 'export': 1, 'print': 0}
  // check level of scope, add buttons according to the level.
  if (scopes.orders >= 2) {
    // buttons.accept = {
    //   tag: 'Accept',
    //   value: 'Accepted',
    //   onClick: onclickChangeStatus
    // }
    // buttons.cancel = {
    //   tag: 'Cancel',
    //   value: 'Canceled',
    //   onClick: onclickChangeStatus
    // }
    buttons.ccvtransaction = {
      tag: 'CCV order -> transaction',
      value: 'ccvtransaction',
      onClick: onclickCCVTransaction
    }
  }
  if (scopes.orderdpd) {
    buttons.dpd = {
      tag: 'DPD',
      value: 'dpd',
      onClick: onDpdClick
    }
  }
  if (role === 'super' || role === 'admin') {
    buttons.transaction = {
      tag: 'Create Transaction',
      value: 'transaction',
      onClick: onclickTransaction
    }
  }

  return buttons
}

