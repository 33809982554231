const EmailValidator = (object, field) => {
  const email = object?.planning_email;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (emailRegex.test(email)) return true;
  return false;
};

export const planningFormFields = [
  // single option fields
  { type: 'hidden', name: 'id', id: 'id', readonly: true },
  { type: 'hidden', name: 'user_id', id: 'user_id', readonly: true },
  { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true },
  { type: 'text', name: 'Name', id: 'planning_name', required: true },
  { type: 'email', name: 'Email', id: 'planning_email', required: true, validator: EmailValidator, invalidFeedback: 'Please enter a valid email.' },
  { type: 'datetime-local', name: 'Date', id: 'planning_timestamp', step: '15', required: true },
  { type: 'number', name: 'Duration', id: 'duration', required: true },
  { type: 'text', name: 'Phonenumber', id: 'planning_phonenumber', required: true },
  { type: 'text', name: 'Remark', id: 'remark', required: false },
  // single option select fields  
  { type: 'select', name: 'Type', id: 'type', formdatafield: 'type', required: true },
  { type: 'select', name: 'Status', id: 'status', formdatafield: 'status', required: true },

  // multi option select fields
  { type: 'multiselect', name: 'Planningobjects', id: 'planning_objects', formdatafield: 'planning_objects' , required: false },

  // not adjustable fields
  // {type:'datetime-local', name:'creation', id:'creation' , readonly:true},
  // {type:'datetime-local', name:'modification', id:'modification' , readonly:true},  
]

export const planningObjectFormFields = [
  // single option fields
  { type: 'hidden', name: 'id', id: 'id', readonly: true },
  { type: 'hidden', name: 'admin_id', id: 'admin_id', readonly: true },
  { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true },
  { type: 'text', name: 'Name', id: 'name', required: true },
  { type: 'text', name: 'Description', id: 'description', required: false },
  // single option select fields  
  { type: 'select', name: 'Type', id: 'type', formdatafield: 'type', required: true },
  { type: 'number', name: 'Capacity', id: 'capacity', required: true },
  { type: 'number', name: 'Minimum planning size', id: 'minimum_planning_size', required: true },
  { type: 'checkbox', name: 'Automatically maximize capacity', id: 'automatically_maximize_capacity', required: false },
  { type: 'checkbox', name: 'Automatically assignable', id: 'automatically_assignable', required: false },
  { type: 'checkbox', name: 'Counts for capacity', id: 'counts_for_capacity', required: false }
  // Opening Times?
]