import axios from "axios";

export const GetPlannings = ({ token, location_id, page = 0, size = 10, orderBy = 'id', direction = 'ascending', status, start, end, syncDate }) => {

  const baseUrl = `${process.env.REACT_APP_API_BASE_ENV}/planning/?location=${location_id}&page=${page}&size=${size}&orderBy=${orderBy}&direction=${direction}`
  const statusQuery = status ? `&status=${status}` : '';
  const startQuery = start ? `&start=${start}` : '';
  const endQuery = end ? `&end=${end}` : '';
  const syncDateQuery = syncDate ? `&syncDate=${syncDate}` : '';
  const url = `${baseUrl}${statusQuery}${startQuery}${endQuery}${syncDateQuery}`;

  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: url,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json',
      },
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  });
};

export const GetAllPlannings = ({ token, location_id, page = 0, size = 10, orderBy = 'id', direction = 'ascending', status, start, end, syncDate, payload = [], resolver = null }) => {

  const baseUrl = `${process.env.REACT_APP_API_BASE_ENV}/planning/?location=${location_id}&page=${page}&size=${size}&orderBy=${orderBy}&direction=${direction}`
  const statusQuery = status ? `&status=${status}` : '';
  const startQuery = start ? `&start=${start}` : '';
  const endQuery = end ? `&end=${end}` : '';
  const syncDateQuery = syncDate ? `&syncDate=${syncDate}` : '';
  const url = `${baseUrl}${statusQuery}${startQuery}${endQuery}${syncDateQuery}`;

  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: url,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json',
      }
    })
      .then(response => {
        let updatedPayload = [...payload];
        if (response.data.content) {
          updatedPayload = [...payload, ...response.data.content];
        }

        if (response.data.last !== true) {
          const nextPage = response.data.number + 1
          GetAllPlannings({
            token: token,
            location_id: location_id,
            page: nextPage,
            orderBy: orderBy,
            direction: direction,
            status: status,
            start: start,
            end: end,
            syncDate: syncDate,
            payload: updatedPayload,
            resolver: resolver || resolve
          });
        } else {
          if (resolver) resolver(updatedPayload);
          resolve(updatedPayload);
        }
      })
      .catch(error => { reject(error) })
  })

};

export const GetPlanningById = ({ token, id }) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_ENV}/planning/${id}`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  });
};

export const PostPlanning = ({ token, data }) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_ENV}/planning/`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
      data: data
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  });
};

export const PutPlanning = ({ token, data }) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_BASE_ENV}/planning/`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
      data: data
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  });
};

export const GetPlanningLocationAdminByID = ({ token, id }) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_ENV}/planning/locations/admin/${id}`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      },
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  });
};

export const PostPlanningLocation = ({ token, admin_id, data }) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_ENV}/planning/locations`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json',
        'administration': admin_id
      },
      data: data
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  });
};

export const GetPlanningLocationList = ({ token, admin_id, page = 0, size = 10, orderBy = 'application', direction = 'ascending' }) => {

  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_ENV}/planning/locations`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json',
      },
      params: {
        APP: 'portal',
        administration: admin_id,
        page: page,
        size: size,
        orderBy: orderBy,
        direction: direction
      },
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  })

};

export const GetAllPlanningLocations = ({ token, admin_id, page = 0, size = 10, orderBy = 'application', direction = 'ascending', payload = [], resolver = null }) => {

  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_ENV}/planning/locations`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json',
      },
      params: {
        APP: 'portal',
        administration: admin_id,
        page: page,
        size: size,
        orderBy: orderBy,
        direction: direction
      },
    })
      .then(response => {
        let updatedPayload = [...payload];
        if (response.data.content) {
          updatedPayload = [...payload, ...response.data.content];
        }

        if (response.data.last !== true) {
          const nextPage = response.data.number + 1
          GetAllPlanningLocations({
            token: token,
            admin_id: admin_id,
            page: nextPage,
            payload: updatedPayload,
            resolver: resolver || resolve
          });
        } else {
          if (resolver) resolver(updatedPayload);
          resolve(updatedPayload);
        }
      })
      .catch(error => { reject(error) })
  })

};

export const GetPlanningObjects = ({ token, admin_id, location_id, page = 0, size = 10, orderBy = 'name', direction = 'ascending' }) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_ENV}/planning/planningobjects`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      },
      params: {
        'administration_id': admin_id,
        'location_id': location_id,
        'page': page,
        'size': size,
        'orderBy': orderBy,
        'direction': direction
      },
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  });
};

export const GetAllPlanningObjects = ({ token, admin_id, location_id, page = 0, size = 10, orderBy = 'name', direction = 'ascending', payload = [], resolver = null }) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_ENV}/planning/planningobjects`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      },
      params: {
        'administration_id': admin_id,
        'location_id': location_id,
        'page': page,
        'size': size,
        'orderBy': orderBy,
        'direction': direction
      },
    })
      .then(response => {
        let updatedPayload = [...payload];
        if (response.data.content) {
          updatedPayload = [...payload, ...response.data.content];
        }

        if (response.data.last !== true) {
          const nextPage = response.data.number + 1
          GetAllPlanningObjects({
            token: token,
            admin_id: admin_id,
            page: nextPage,
            payload: updatedPayload,
            resolver: resolver || resolve
          });
        } else {
          if (resolver) resolver(updatedPayload);
          resolve(updatedPayload);
        }
      })
      .catch(error => { reject(error) })
  });
};

export const PostPlanningObject = ({ token, data }) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_ENV}/planning/planningobjects`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
      data: data
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  });
};

export const PutPlanningObject = ({ token, data }) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_BASE_ENV}/planning/planningobjects`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
      data: data
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  });
};

export const GetPlanningObjectById = ({ token, id }) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_ENV}/planning/planningobjects/${id}`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  });
};

export const PatchPlanningStatus = ({ token, id, status = "PENDING" }) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'patch',
      url: `${process.env.REACT_APP_API_BASE_ENV}/planning/${id}`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
      params: {
        'status': status
      }
    })
      .then(response => { resolve(response) })
      .catch(error => { reject(error) })
  });
};