import Form from 'react-bootstrap/Form';
import { Row, Container } from 'react-bootstrap';

import CreateFieldTemplate from '../helpers/createFieldTemplate';
import { TemplateElementFormFields } from '../portal/shelflabels/templateFormFields';
import HandleOnChangeTemplateElement from '../helpers/handleOnChangeTemplateElement';

import { supportedElementTextTypes, supportedElementTypes, supportFonts } from '../selectfieldchoices/shelflabelselect.mjs';
import HandleOnChangeTemplateElementSelectField from '../helpers/handleOnChangeTemplateElementSelectField';

export default function TemplateElementForm(props) {
  const template = props?.data
  const selectedIndex = props?.elements?.selectedIndex
  const setTemplate = props?.template_data?.setTemplate

  const onChange = (event) => {
    let newTemplate = HandleOnChangeTemplateElement({ event: event, object: template, index: selectedIndex })
    setTemplate(newTemplate)
  }

  const onChangeSelect = (event) => {
    let newTemplate = HandleOnChangeTemplateElementSelectField({ event: event, object: template, index: selectedIndex })
    setTemplate(newTemplate)
  }

  const formData = {
    fields: TemplateElementFormFields(),
    field: {
      onChange: onChange
    },
    data: template?.elements[selectedIndex],
    type: {
      name: 'type',
      options: supportedElementTypes,
      selected: {},
      onChange: onChangeSelect,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    text_type: {
      name: 'text_type',
      options: supportedElementTextTypes,
      selected: {},
      onChange: onChangeSelect,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    text_font: {
      name: 'text_font',
      options: supportFonts,
      selected: {},
      onChange: onChangeSelect,
      clearable: Boolean(false),
      searchable: Boolean(true)
    }
  };

  if (template?.elements[selectedIndex]?.hasOwnProperty('type')) {
    formData.type.selected = { 'value': template?.elements[selectedIndex]?.type, 'label': supportedElementTypes.find(element => element.id === template?.elements[selectedIndex]?.type)?.name }
  }

  if (template?.elements[selectedIndex]?.hasOwnProperty('text_type')) {
    formData.text_type.selected = { 'value': template?.elements[selectedIndex]?.text_type, 'label': supportedElementTextTypes.find(element => element.id === template?.elements[selectedIndex]?.text_type)?.name }
  }

  if (template?.elements[selectedIndex]?.hasOwnProperty('text_font')) {
    formData.text_font.selected = { 'value': template?.elements[selectedIndex]?.text_font, 'label': supportFonts.find(element => element.id === template?.elements[selectedIndex]?.text_font)?.name }
  }

  let basicFields = []
  formData.fields.forEach((field) => {
    basicFields.push(CreateFieldTemplate(field, formData))
  });

  return (
    <>
      <Container fluid={'sm'}>
        <Row>
          <Form>
            <Row key={`fields-row`} xs={2}>
              {basicFields}
            </Row>
          </Form>
        </Row>
      </Container >
    </>
  );
}