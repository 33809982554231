import { createSlice } from "@reduxjs/toolkit";

export const attributeSlice = createSlice({
  name: "attribute",
  initialState: {
    attributegroup_pagesize: 10,
    attributegroup_pagination: 0,
    hash_attributegroups_all: {},
    attributegroupAddProductFilter: 'addon'
  },
  reducers: {
    setAttributeGroupId: (state, action) => {
      state.attributegroup_id = action.payload;
    },
    setAttributeGroup: (state, action) => {
      state.attributegroup = action.payload;
    },
    setAttributeGroups: (state, action) => {
      state.attributegroups = action.payload
    },
    setAttributeGroupsAll: (state, action) => {
      state.attributegroups_all = action.payload
      action.payload.forEach(attributegroup => {
        state.hash_attributegroups_all[attributegroup.id] = attributegroup
      });
    },
    setAttributeGroupModalShow: (state, action) => {
      state.attributegroupmodalshow = action.payload
    },
    setAttributeGroupPagination: (state, action) => {
      state.attributegroup_pagination = (action.payload - 1)
    }, 
    setAttributeGroupPagesize: (state, action) => {
      state.attributegroup_pagesize = action.payload
    },
    setAttributeGroupAddProductFilter: (state, action) => {
      state.attributegroupAddProductFilter = action.payload
    },
    setAttributeGroupAddProductSearch: (state, action) => {
      state.attributegroupAddProductSearch = action.payload
    },
    setAttributeGroupAddProductSearchResult: (state, action) => {
      state.attributegroupAddProductSearchResult = action.payload
    }
  }
});

export const {
  setAttributeGroupId, setAttributeGroup, setAttributeGroups, setAttributeGroupModalShow, setAttributeGroupPagination, setAttributeGroupPagesize, setAttributeGroupsAll,
  setAttributeGroupAddProductFilter, setAttributeGroupAddProductSearch, setAttributeGroupAddProductSearchResult
} = attributeSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getAttributeGroupId = (state) => state.attribute.attributegroup_id
export const getAttributeGroup = (state) => state.attribute.attributegroup
export const getAttributeGroups = (state) => state.attribute.attributegroups
export const getAttributeGroupsAll = (state) => state.attribute.attributegroups_all
export const getHashAttributeGroupsAll = (state) => state.attribute.hash_attributegroups_all
export const getAttributeGroupModalShow = (state) => state.attribute.attributegroupmodalshow
export const getAttributeGroupPagination = (state) => state.attribute.attributegroup_pagination
export const getAttributeGroupPagesize = (state) => state.attribute.attributegroup_pagesize
export const getAttributeGroupAddProductFilter = (state) => state.attribute.attributegroupAddProductFilter
export const getAttributeGroupAddProductSearch = (state) => state.attribute.attributegroupAddProductSearch
export const getAttributeGroupAddProductSearchResult = (state) => state.attribute.attributegroupAddProductSearchResult

export default attributeSlice.reducer;