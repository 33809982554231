export const statusFilterChoices = [
    { id: 'ACCEPTED', name: 'ACCEPTED' },
    { id: 'PENDING', name: 'PENDING' },
    { id: 'CANCELLED', name: 'CANCELLED' }
]

export const orderByFilterChoices = [
    // { id: 'id', name: 'ID' },
    { id: 'location', name: 'Location' },
    { id: 'userId', name: 'User' },
    { id: 'deviceId', name: 'Device' },
    { id: 'relationId', name: 'Relation' },
    { id: 'orderId', name: 'Order' },
    { id: 'planningTimestamp', name: 'Timestamp' },
    { id: 'duration', name: 'Duration' },
    { id: 'planningName', name: 'Name' },
    { id: 'planningEmail', name: 'Email' },
    { id: 'planningPhonenumber', name: 'Phonenumber' },
    { id: 'status', name: 'Status' },
    { id: 'type', name: 'Type' }
]

export const orderByFilterChoicesObject = [
    // { id: 'id', name: 'ID' },
    { id: 'administrationId', name: 'Administration' },
    { id: 'location', name: 'Location' },
    { id: 'name', name: 'Name' },
    { id: 'type', name: 'Type' },
    { id: 'capacity', name: 'Capacity' },
    { id: 'countsForCapacity', name: 'Counts for Capacity' },
    { id: 'automaticallyAssignable', name: 'Automatically assignable' }
]

export const directionFilterChoices = [
    { id: 'ascending', name: 'Ascending' },
    { id: 'descending', name: 'Descending' }
]

export const planningTypeChoices = [
    { id: 'PLANNING', name: 'PLANNING' }
]

export const planningObjectTypeChoices = [
    { id: 'BUS', name: 'BUS' },
    { id: 'CAR', name: 'CAR' }
]