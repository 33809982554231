import { Button, Table, Row, Col, Form } from 'react-bootstrap'
import TableButton from './tableButton';
import SubscribeModal from './subscribeModal';
import SubscribeButtons from '../portal/subscribe/subscribebuttons';
import { useTranslation } from 'react-i18next';
import SubscribeCopyModal from './subscribeCopyModal';
import { useSelector } from 'react-redux';
import { getSelectedLocation_id } from '../redux/dataSlices/selectLocationSlice';
import SubscribeEditModal from './subscribeEditModal';
import { getRegistration } from '../redux/dataSlices/registrationSlice';
import { editSubscriptionFormFields } from '../portal/subscribe/editSubscriptionFormFields.mjs';


export default function SubscribeEditTable(subscription) {
  const object = useSelector(getRegistration);
  const location_id = useSelector(getSelectedLocation_id)
  const buttons = SubscribeButtons.call()
  const { t: translate } = useTranslation();

  function editField(field, value, id) {
    let name = translate(field.name)
    let placeholder = translate(field.placeholder ?? field.name)

    return (
      <Form.Group as={Row} className="mb-3" controlId={field.id}>
        <Col sm="10">
          <Form.Control prefix={id}
            type={field.type}
            name={field.name}
            placeholder={placeholder ?? name}
            value={value}
            onChange={buttons.edit.modal.onChange}
            required={field.required ?? true}
            isInvalid={field?.invalid}
          />
          <Form.Control.Feedback type="invalid">
            {field?.invalidFeedback ?? 'Please fill in this field.'}
          </Form.Control.Feedback>
        </Col>
      </Form.Group>
    )
  }

  let rows = []
  let registrationRow = []
  if (subscription) {
    for (const setting of Object.values(subscription.settings)) {
      registrationRow.push(
        <tr key={setting.id}>
          <td>{setting.subscription.name}</td>
          <td>{setting.start ? new Date(setting.start).toLocaleString() : undefined}</td>
          <td>{editField({ type: 'datetime-local', name: 'stop', id:'stop'}, new Date(setting.stop).toISOString().split(".", 1)[0], setting.id)}</td>
          <td>{editField({ type: 'text', name: 'duration', id: 'duration' }, setting.duration, setting.id)}</td>
          <td><Button size="sm" type='button' onClick={() => { buttons.edit.modal.onDelete({subscription_id:setting.subscription.id, registration_id:subscription.id}) }}>{translate('delete')}</Button></td>
        </tr>
      )
    }
    rows.push(
      <tr className='product-row' key={subscription.id}>
        <td>{subscription.name}</td>
        <td>
          <Table width='100%'>
            <thead>
              <tr>
                <th width='30%'>{translate('Registration')}</th>
                <th width='25%'>{translate('Start')}</th>
                <th width='25%'>{translate('Stop')}</th>
                <th width='25%'>{translate('Length')}</th>
              </tr>
            </thead>
            <tbody>
              {registrationRow}
            </tbody>
          </Table>
        </td>
      </tr>
    )
  }
  
  return (
    <>
      {/* {add && location_id && <TableButton {...add} />} */}
      <Table hover width='100%'>
        <thead>
          <tr>
            <th>{translate('Name')}</th>
            <th>{translate('Registration')}</th>
            <th>{translate('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
    </>
  );
}