import React, { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { getTemplatesAll, setTemplatesAll } from "../../redux/dataSlices/templateSlice";
import { getScopes } from "../../redux/dataSlices/scopesSlice";
import BasicCard from "../../components/card"
import ProductTable from "../../components/productTable";
import { getSettingsSchapkaartjes, setSettingsSchapkaartjes } from "../../redux/dataSlices/settingsSlice";
import HandleError from "../../helpers/handleError";
import { getProgressModalShow, setProgressModalShow } from "../../redux/dataSlices/analyseSlice";
import ProgressModal from "../../components/progressModal";
import GetAllTemplates from "../../actablueAPI/shelflabels/GetAllTemplates";
import GetAllPrinters from "../../actablueAPI/shelflabels/GetAllPrinters";
import { setPrintersAll } from "../../redux/dataSlices/printerSlice";
import GetProductById from "../../actablueAPI/products/GetProductByID";
import GetShelflabelsSettings from "../../actablueAPI/shelflabels/GetShelflabelsSettings";

const Product = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const templates = useSelector(getTemplatesAll);
  const settings = useSelector(getSettingsSchapkaartjes)
  const scopes = useSelector(getScopes)
  const modal_show = useSelector(getProgressModalShow)
  const dispatch = useDispatch();
  const [product, setProduct] = useState({})
  const [refresh, setRefresh] = useState(false)
  const params = useParams()
  const navTitle = "Products"

  const productsCard = {
    size: 12,
    title: navTitle,
    data: <ProductTable products={product ? { content: [product] } : {}} templates={templates} settings={settings} refresh={toggleRefresh}/>,
  };

  function toggleRefresh() {
    setRefresh(!refresh)
  }

  const onViewHide = (event) => {
    dispatch(setProgressModalShow(false));
  };

  const progressModal = {
    modal: {
      show: modal_show,
      title: 'Downloading Shelflabels...',
      onHide: onViewHide
    }
  }

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    GetProductById({token:token, admin_id:admin_id, location_id:location_id, id:params.productId})
    .then(response => {
      dispatch(setProduct(response.data))
      dispatch(setLoading(false))
      if (location_id && scopes.schapkaartjes) {
        axios.all([
          GetShelflabelsSettings({token:token, admin_id:admin_id, location_id:location_id}),
          GetAllPrinters({ token: token, admin_id: admin_id, location_id: location_id }),
          GetAllTemplates({ token: token, admin_id: admin_id, location_id: location_id, base_labels: true, sorted: true, page: 1, size: 25 })
        ])
        .then(axios.spread((settingsResponse, printerResponse, templateResponse) => {
          dispatch(setSettingsSchapkaartjes(settingsResponse.data))
          dispatch(setTemplatesAll(templateResponse))
          dispatch(setPrintersAll(printerResponse))
        }))
        .catch(error => {
          HandleError(error)
          dispatch(setLoading(false))
        })
      }
    })
    .catch(error => {
      HandleError(error)
      dispatch(setLoading(false))
    })
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ProgressModal {...progressModal} />
      <BasicCard {...productsCard} />
    </>
  );
};
export default Product;