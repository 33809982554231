export default function HandleOnChange(props) {
  const event = props.event
  const object = props.object || {}
  const admin_id = props.admin_id

  const prefix = props?.prefix || event?.target?.attributes?.prefix?.nodeValue // ? event.target.attributes.prefix.nodeValue : false

  let newObject = JSON.parse(JSON.stringify(object))
  // let newObject = Object.assign({}, object)

  if (!prefix) {
    switch (event.target.type) {
      case 'checkbox':
        newObject[event.target.id] = event.target.checked ? event.target.checked : false
        break;
      case 'number':
        newObject[event.target.id] = Number(event.target.value)
        break;
      default:
        newObject[event.target.id] = event.target.value
        break;
    }

  }
  if (prefix) {
    // if (!newObject.hasOwnProperty(prefix)) { newObject[prefix] = { 'administration_id': admin_id } }
    if (!newObject.hasOwnProperty(prefix)) { newObject[prefix] = {} }
    if (admin_id) {newObject[prefix]['administration_id'] = admin_id}

    switch (event.target.type) {
      case 'checkbox':
        newObject[prefix][event.target.id] = event.target.checked ? event.target.checked : false
        break;
      case 'number':
        newObject[prefix][event.target.id] = Number(event.target.value)
        break;
      default:
        newObject[prefix][event.target.id] = event.target.value
        break;
    }
  }

  return (newObject)
}


