export const NarrowcastingViewFormFields = () => {

  const NarrowcastingViewFormFields = [
    // single option fields
    { type: 'hidden', name: 'id', id: 'id' },
    { type: 'text', name: `Name`, id: 'name', required: true },
    { type: 'number', name: `Position`, id: 'position', required: true },
    { type: 'number', name: `Position Order`, id: 'position_order', required: true },
    { type: 'number', name: `Seconds`, id: 'seconds' },
    { type: 'select', name: `Content Type`, id: 'content_type', formdatafield: 'content_type', required: true },
    { type: 'text', name: `Image`, id: 'image' },
    { type: 'text', name: `Video`, id: 'video' },
  ]

  return NarrowcastingViewFormFields
}