import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { setUsersAll, getUsersAll, getFilter, getSearch, setSearch, setUserRolesAll, getUserRolesAll } from "../../redux/dataSlices/userSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import SearchNoButtonAndFilterSingleSelect from "../../components/searchNoButtonAndFilterSingleSelectField";
import BasicCard from "../../components/card"
import UserTable from "../../components/userTable";
import HandleError from "../../helpers/handleError";
import GetUsersList from "../../actablueAPI/serials/GetUsersList";
import GetUserRoleDefinitions from "../../actablueAPI/serials/GetUserRoleDefinitions";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";

const Users = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id)
  const location_id = useSelector(getSelectedLocation_id)
  const users = useSelector(getUsersAll);
  const filter = useSelector(getFilter)
  const search = useSelector(getSearch)
  const userRoles = useSelector(getUserRolesAll);
  const dispatch = useDispatch();
  const [filteredUsers, setFilteredUsers] = useState()

  const onSearchChange = (event) => {
    let value = undefined
    if (event) {
      value = event.target.value
    }
    dispatch(setSearch(value))
    SearchAndFilter(filter, value)
  }

  const searchAndFilter = {
    search: {
      value: search,
      onChange: onSearchChange,
    }
  }

  function SearchAndFilter(filter, search) {
    if (!filter && !search) {
      return setFilteredUsers()
    }

    let filtered = users // if using a switch statement, set it to []

    if (filter) {
      filtered = (users.filter(user => user.attributes?.origin?.includes(filter)))
    }

    if (search) {
      return setFilteredUsers(filtered.filter(user => JSON.stringify(user).toLowerCase().includes(search.toLowerCase())))
    }
    return setFilteredUsers(filtered)
  }


  const usersCard = {
    size: 12,
    title: {text: 'Users', option: filteredUsers?.length || users?.length || 0},
    data: <UserTable users={filteredUsers || users} roles={userRoles} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle("Users"))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setLoading(true))
    axios.all([
      GetUsersList({ token: token }),
      admin_id ? GetUserRoleDefinitions({ token: token, admin_id: admin_id }) : [] 
    ])
    .then(axios.spread((userListData, roleDefData) => {
      dispatch(setUsersAll(userListData.data))
      dispatch(setUserRolesAll(roleDefData.data))
      SearchAndFilter(filter, search)
      dispatch(setLoading(false))
    }))
    .catch(error => {
      HandleError(error)
      dispatch(setLoading(false))
    })
    // eslint-disable-next-line
  }, [admin_id, location_id]);

  return (
    <>
      <SearchNoButtonAndFilterSingleSelect {...searchAndFilter} />
      <BasicCard {...usersCard} />
    </>
  )
};
export default Users;
