import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import BasicCard from "../../components/card"
import HandleError from "../../helpers/handleError";
import { getHashAdministrationsAll } from "../../redux/dataSlices/administrationSlice";
import { getHashLocationsAll } from "../../redux/dataSlices/locationSlice";
import { getPrinterPageSize, getPrinterPagination, getPrinters, setPrinters } from "../../redux/dataSlices/kitchenmanagerSlice";
import KmPrinterTable from "../../components/kmPrinterTable";
import GetPrinters from "../../actablueAPI/kitchenmanager/GetPrinters";

const Printers = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const hash_admins_all = useSelector(getHashAdministrationsAll);
  const hash_locations_all = useSelector(getHashLocationsAll);
  const printers = useSelector(getPrinters);
  const printer_pagination = useSelector(getPrinterPagination);
  const printer_pagesize = useSelector(getPrinterPageSize);
  const dispatch = useDispatch();
  const navTitle = "Kitchen Manager"

  const printersCard = {
    size: 12,
    title: "Printers",
    data: location_id ? <KmPrinterTable printers={printers} hash_admins_all={hash_admins_all} hash_locations_all={hash_locations_all} /> : 'Please select a location or administration',
  };

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    // eslint-disable-next-line
  }, [navTitle]);

  useEffect(() => {
    dispatch(setLoading(true))
    GetPrinters({ token: token, admin_id: admin_id, location_id: location_id, page: printer_pagination, size: printer_pagesize })
      .then(response => {
        dispatch(setPrinters(response.data))
      })
      .catch(error => {
        HandleError(error)
        dispatch(setLoading(false))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
    // eslint-disable-next-line
  }, [, admin_id, location_id, printer_pagesize, printer_pagination]);

  return (
    <>
      <BasicCard {...printersCard} />
    </>
  )
};
export default Printers;

