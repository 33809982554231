import { Button, Table } from 'react-bootstrap'
import TableButton from './tableButton';
import AdminButtons from '../portal/administrations/adminbuttons';
import AdminModal from './adminModal';
import { useTranslation } from 'react-i18next';
// import PaginationComponent from './pagination';
import './css/productTable.css';



export default function AdminTable(props) {
  const administrations = props.administrations
  const buttons = AdminButtons.call()
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const { t: translate } = useTranslation();

  let rows = []
  for (let index in administrations) {
    const admin = administrations[index]
    const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit, { id: admin.id }) : false
    const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview, { id: admin.id }) : false
    rows.push(
      <tr className='product-row' key={admin.id}>
        <td>{admin.name}</td>
        <td>{admin.hasOwnProperty('coc') ? admin.coc : ''}</td>
        <td>{admin.hasOwnProperty('country_code') ? admin.country_code : ''}</td>
        <td>{admin.hasOwnProperty('company_name') ? admin.company_name : ''}</td>
        <td>
          <Button size="sm" type='button' onClick={() => { modalview.onClick(admin) }} id={admin.id}>{translate(modalview.tag)}</Button>
          {edit && <TableButton {...edit} />}
        </td>
      </tr>
    )
  }

  if (administrations) {
    return (
      <>
        {/* {add && <TableButton {...add} />} */}
        {/* {<PaginationComponent pagination={pagination} pagesize={pagesize} pages={pages} page={page} />} */}
        <Table hover width='100%'>
          <thead>
            <tr>
              <th>
                {translate('Name')}
              </th>
              <th>
                {translate('COC')}
              </th>
              <th>
                {translate('Country')}
              </th>
              <th>
                {translate('Company name')}
              </th>
              <th>
                {translate('Action')}
              </th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
          <AdminModal modal={modalview.modal} />
        </Table>
      </>
    );
  } else {
    return (
      <>
        <Table width='100%'>
          <thead>
            <tr>
              <th></th>
              <th>{translate('Name')}</th>
              <th>COC</th>
              <th>Country</th>
              <th>Company Name</th>
              <th>
                {translate('Actions')}
              </th>
            </tr>
          </thead>
        </Table>
      </>
    );
  }
}