import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { useTranslation } from 'react-i18next';
import NotificationButtons from '../portal/notifications/notificationbuttons';
import NotificationSubscriptionModal from './notificationSubscriptionModal';

export default function NotificationSubscriptionsTable(subscriptions) {
  const buttons = NotificationButtons.call()
  const modalview = buttons.hasOwnProperty('subscriptionModalview') ? Object.assign({}, buttons.subscriptionModalview) : false
  const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit) : false
  const add = buttons.hasOwnProperty('add') ? Object.assign({}, buttons.add) : false

  const { t: translate } = useTranslation();

  let rows = []
  for (let subscription of Object.values(subscriptions)) {
    rows.push(
      <tr className='product-row' key={subscription.id}>
        <td>{subscription?.name || "N/A"}</td>
        <td>{subscription?.to_email || "N/A"}</td>
        <td>{subscription?.events?.map((event) => { return <>{event}<br /></> }) || "N/A"}</td>
        <td>
          <Button size="sm" type='button' onClick={() => { modalview.onClick(subscription) }} id={subscription.id}>{translate(modalview.tag)}</Button>
          {edit && <Button size="sm" type='button' onClick={() => { edit.onClick(subscription, 'subscription') }} id={subscription.id}>{translate(edit.tag)}</Button>}
        </td>
      </tr>
    )
  }

  return (
    <>
      {add && <Button className={add.className} size="sm" type='button' onClick={() => { add.onClick('subscription') }} >{translate(add.tag)}</Button>}
      <Table hover width='100%'>
        <thead>
          <tr key='header'>
            <th>{translate('Name')}</th>
            <th>{translate('Email')}</th>
            <th>{translate('Events')}</th>
            <th>{translate('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
        <NotificationSubscriptionModal modal={modalview.modal} />
      </Table>
    </>
  );
}