import { setActiveTab } from "../redux/dataSlices/formTabSlice";

export function validateFormObject({ object = {}, fields = [], switchTab = true, dispatch }) {
  let validFields = []
  let invalidFields = []
  let newActiveTab = "main"
  for (const field of fields) {
    if (field && !field.readonly) {
      switch (field?.type) {
        case 'object':
          for (const subField of field?.fields) {
            if (subField && subField?.required) {
              if (!object[field?.id]) {
                if (subField?.validator === undefined) {
                  invalidFields.push({ "tab": subField?.prefix, "field": subField?.id, "name": subField?.name });
                }
                if (subField?.validator !== undefined) {
                  if (!subField?.validator(object, subField, dispatch)) {
                    invalidFields.push({ "tab": subField?.prefix, "field": subField?.id, "name": subField?.name });
                  } else {
                    validFields.push({ "tab": subField?.prefix, "field": subField?.id, "name": subField?.name });
                  }
                }
              }
              if (object[field?.id]) {
                if (subField?.validator === undefined) {
                  if (!object[field?.id][subField?.id] || object[field?.id][subField?.id] === "") {
                    invalidFields.push({ "tab": subField?.prefix, "field": subField?.id, "name": subField?.name });
                  } else {
                    validFields.push({ "tab": subField?.prefix, "field": subField?.id, "name": subField?.name });
                  }
                }
                if (subField?.validator !== undefined) {
                  if (!subField?.validator(object, subField, dispatch)) {
                    invalidFields.push({ "tab": subField?.prefix, "field": subField?.id, "name": subField?.name });
                  } else {
                    validFields.push({ "tab": subField?.prefix, "field": subField?.id, "name": subField?.name });
                  }
                }
              }
            }
          }
          break;
        default:
          if (field?.required) {
            if (field?.validator === undefined) {
              if (!object[field?.id] || object[field?.id] === "") {
                invalidFields.push({ "tab": "main", "field": field?.id, "name": field?.name });
              } else {
                validFields.push({ "tab": "main", "field": field?.id, "name": field?.name });
              }
            } else {
              if (!field?.validator(object, field, dispatch)) {
                invalidFields.push({ "tab": "main", "field": field?.id, "name": field?.name });
              } else {
                validFields.push({ "tab": "main", "field": field?.id, "name": field?.name });
              }
            }
          }
      }
    }
  }

  if (invalidFields && invalidFields.length > 0) newActiveTab = invalidFields[0].tab;
  if (switchTab) dispatch(setActiveTab(newActiveTab));

  return [invalidFields, validFields, newActiveTab]
}