import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getLocationsAllAdmins, getHashLocationsAll } from "../../redux/dataSlices/locationSlice";
import { getAdministrationsAll, getHashAdministrationsAll } from "../../redux/dataSlices/administrationSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card"
import WarehouseForm from "../../components/warehouseForm";
import WarehouseFormFields from "./warehouseFormFields.jsx";
import HandleOnChange from "../../helpers/handleOnChange";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import PostWarehouseWarehouse from "../../actablueAPI/warehouse/PostWarehouseWarehouse.jsx";

const WarehouseAdd = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id)
  const location_id = useSelector(getSelectedLocation_id)
  const admins_all = useSelector(getAdministrationsAll)
  const hash_admins_all = useSelector(getHashAdministrationsAll)
  const locations_all_admins = useSelector(getLocationsAllAdmins)
  const hash_locations_all = useSelector(getHashLocationsAll)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [warehouse, setWarehouse] = useState({ administration_id: admin_id, location_id: location_id })

  const onChange = (event) => {
    let newDevice = HandleOnChange({ event: event, object: warehouse })
    setWarehouse(newDevice)
  }

  const onLocationIdChange = (event) => {
    let newDevice = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: warehouse, selectfield: 'location_id' })
    setWarehouse(newDevice)
  }

  const onAdminIdChange = (event) => {
    let newDevice = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: warehouse, selectfield: 'administration_id' })
    delete newDevice.location_id
    dispatch(setWarehouse(newDevice))
  }

  const onSubmit = () => {
    PostWarehouseWarehouse({token:token, data:warehouse})
    .then(response => {
      navigate("/warehouse");
    })
  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    fields: WarehouseFormFields(),
    field: {
      onChange: onChange
    },
    data: warehouse,
    location_id: {
      label: 'location_id',
      // eslint-disable-next-line
      options: locations_all_admins.filter((item) => { if (item.administration_id === warehouse.administration_id) { return true } }),
      hash: hash_locations_all,
      selected: {},
      onChange: onLocationIdChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    administration_id: {
      label: 'administration_id',
      options: admins_all,
      hash: hash_admins_all,
      selected: {},
      onChange: onAdminIdChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
  };

  const addCard = {
    size: 12,
    title: "Warehouse add ",
    data: <WarehouseForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle("Warehouse add"))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...addCard} />
    </>
  )

};
export default WarehouseAdd;

