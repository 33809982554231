import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card"
import HandleOnChange from "../../helpers/handleOnChange";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import HandleError from "../../helpers/handleError";
import EmployeeRoleForm from "../../components/employeeRoleForm";
import { EmployeeRoleFormFields } from "./employeeRoleFormFields";
import PostEmployeeRole from "../../actablueAPI/employee/PostEmployeeRole";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { getEmployeeRole } from "../../redux/dataSlices/employeeSlice";
import { roles } from "../../scopes/roles.mjs";

const EmployeeRoleEdit = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let [employeeRole, setEmployeeRole] = useState(JSON.parse(JSON.stringify(useSelector(getEmployeeRole))))

  const navTitle = `Employee role edit`

  const onChange = (event) => {
    let newEmployeeRole = HandleOnChange({ event: event, object: employeeRole })
    setEmployeeRole(newEmployeeRole)
  }

  const onSubmit = (event) => {
    // set scopes back to array with stings
    let newEmployeeRole = Object.assign({}, employeeRole)

    if (employeeRole.roles) {
      let newRoles = []
      for (const [role, value] of Object.entries(employeeRole.roles)) {
        // clean scopes (delete non excisting scopes)
        if (roles.includes(role)) {
          newRoles.push(role.concat(':').concat(value))
        }
      }
      newEmployeeRole.roles = newRoles
    }

    PostEmployeeRole({ token: token, admin_id: admin_id, role: newEmployeeRole })
    .then(response => {
      dispatch(setLoading(false))
      navigate("/employeeroles")
    })
    .catch(error => {
      HandleError(error)
      dispatch(setLoading(false))
    })

  };

  const addRolesToArray = () => {
    if (Array.isArray(employeeRole?.roles)) {
      let newRoles = {}
      for (const scope of Object.values(employeeRole.roles)) {
        const splitted = scope.split(':')
        const last = splitted[splitted.length - 1]
        const field = scope.replace(":".concat(last), '')
        newRoles[field] = last
      }
      employeeRole.roles = newRoles

      return employeeRole
    }
    return employeeRole
  }

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    fields: EmployeeRoleFormFields(),
    field: {
      onChange: onChange
    },
    data: addRolesToArray()
  };

  const editCard = {
    size: 12,
    title: {text: navTitle, option: employeeRole.name},
    data: <EmployeeRoleForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...editCard} />
    </>
  )

};
export default EmployeeRoleEdit;

