import GetStockPaginated from "./GetStockPaginated";

const GetAllStockPaginated = ({token, admin_id, location_id, page = 0, payload = [], resolver = null }) => {
  return new Promise((resolve, reject) => {
    GetStockPaginated({token:token, admin_id:admin_id, location_id:location_id, page:page})
    .then(response => {
      let updatedPayload = [...payload];
      if (response.data) {
        updatedPayload = [...payload, ...response.data.content];
      }
      if (!response.data.last) {
        GetAllStockPaginated({
          token:token,
          admin_id:admin_id,
          location_id:location_id,
          page: response.data.number + 1,
          payload: updatedPayload,
          resolver: resolver || resolve
        })
      } else {
        if (resolver) resolver(updatedPayload);
        resolve(updatedPayload);
      }
    })
    .catch(error => {
      reject(error)
    });
  });
};

export default GetAllStockPaginated