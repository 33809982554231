import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import PlanningCalendar from "../../components/planningCalendar";
import BasicCard from "../../components/card";
import { Button, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PlanningTable from "../../components/planningTable";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { GetAllPlannings, GetPlanningLocationAdminByID, GetPlanningObjects, GetPlannings } from "../../actablueAPI/planning/Planning";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getCalendarEnd, getCalendarLoading, getCalendarStart, getPlanningObjectPageSize, getPlanningObjectPagination, getPlanningObjects, getPlanningPageSize, getPlanningPagination, getPlannings, getRefresh, setPlanningLocation, setPlanningObjects, setPlannings, setPlanningsAll } from "../../redux/dataSlices/planningSlice";
import HandleError from "../../helpers/handleError";
import PlanningButtons from "./planningbuttons";
import TableButton from "../../components/tableButton";
import SearchAndMultiFilterSingleSelect from "../../components/searchAndMultiFilterSingleSelectField";
import { directionFilterChoices, orderByFilterChoices, orderByFilterChoicesObject, statusFilterChoices } from "../../selectfieldchoices/planningselect.mjs";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import PlanningObjectTable from "../../components/planningObjectTable";

const Planning = () => {
  const dispatch = useDispatch();
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const plannings = useSelector(getPlannings);
  const planning_objects = useSelector(getPlanningObjects);
  const refresh = useSelector(getRefresh);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [currentPage, setCurrentPage] = useState("calendar");
  const [status, setStatus] = useState(null);
  const [orderBy, setOrderBy] = useState("planningTimestamp");
  const [planningObjectsOrderBy, setPlanningObjectsOrderBy] = useState("name");
  const [direction, setDirection] = useState("ascending");
  const [planningObjectsDirection, setPlanningObjectsDirection] = useState("ascending");
  const planningPagination = useSelector(getPlanningPagination);
  const planningPageSize = useSelector(getPlanningPageSize);
  const planningObjectPagination = useSelector(getPlanningObjectPagination);
  const planningObjectPageSize = useSelector(getPlanningObjectPageSize);
  const calendarStart = useSelector(getCalendarStart);
  const calendarEnd = useSelector(getCalendarEnd);
  const calendarLoading = useSelector(getCalendarLoading);
  const [cardTitle, setCardTitle] = useState("Calendar");
  const { t: translate } = useTranslation();

  const buttons = PlanningButtons.call();
  const add = buttons.hasOwnProperty('add') ? Object.assign({}, buttons.add) : false
  const planning_object_add = buttons.hasOwnProperty('planning_object_add') ? Object.assign({}, buttons.planning_object_add) : false

  useEffect(() => {
    dispatch(setNavBarTitle("Planning"))
    // eslint-disable-next-line
  }, [])

  useEffect(() => { // This one is for the "events" tab
    dispatch(setLoading(true))
    GetPlannings({ token: token, location_id: location_id, page: planningPagination, size: planningPageSize, orderBy: orderBy, direction: direction, status: status }) // TODO: Add pagination
      .then(response => {
        dispatch(setPlannings(response.data))
        dispatch(setLoading(false))
      })
      .catch(error => {
        HandleError(error)
        dispatch(setLoading(false))
      })
    // eslint-disable-next-line
  }, [, refresh, admin_id, location_id, status, orderBy, direction, planningPagination, planningPageSize])

  useEffect(() => { // This one is for the "calendar" tab
    dispatch(setLoading(true))
    GetAllPlannings({ token: token, location_id: location_id, orderBy: orderBy, direction: direction, status: status, start: calendarStart, end: calendarEnd })
      .then(response => {
        dispatch(setPlanningsAll(response))
        dispatch(setLoading(false))
      })
      .catch(error => {
        HandleError(error)
        dispatch(setLoading(false))
      })
    // eslint-disable-next-line
  }, [, refresh, admin_id, location_id, status, orderBy, direction])

  useEffect(() => {
    dispatch(setLoading(true))
    GetPlanningObjects({ token: token, admin_id: admin_id, location_id: location_id, page: planningObjectPagination, size: planningObjectPageSize, orderBy: planningObjectsOrderBy, direction: planningObjectsDirection }) // TODO: Add pagination
      .then(response => {
        dispatch(setPlanningObjects(response.data))
        dispatch(setLoading(false))
      })
      .catch(error => {
        HandleError(error)
        dispatch(setLoading(false))
      })
    // eslint-disable-next-line
  }, [, refresh, admin_id, location_id, status, planningObjectsOrderBy, planningObjectsDirection, planningObjectPageSize, planningObjectPagination])

  useEffect(() => {
    GetPlanningLocationAdminByID({ token: token, id: location_id })
      .then(response => {
        dispatch(setPlanningLocation(response.data))
      })
      .catch(error => {
        dispatch(setPlanningLocation(null))
        HandleError(error)
      })
    // eslint-disable-next-line
  }, [, refresh, admin_id, location_id])

  useEffect(() => {
    if (calendarLoading) setCardTitle("Calendar (Loading data...)")
    if (!calendarLoading) setCardTitle("Calendar")
  }, [calendarLoading])

  const handlePageChange = (page) => {
    setIsTransitioning(true);
    setTimeout(() => {
      setCurrentPage(page);
      setIsTransitioning(false);
    }, 250); // Change this value to adjust the duration of the transition, the one in the CSS too (1000 here is 1s in CSS)
  };

  const onStatusChange = (event) => {
    let value = undefined
    if (event) {
      value = event.value
    }
    setStatus(value)
  }

  const onOrderByChange = (event) => {
    let value = undefined
    if (event) {
      value = event.value
    }
    setOrderBy(value)
  }

  const onDirectionChange = (event) => {
    let value = undefined
    if (event) {
      value = event.value
    }
    setDirection(value)
  }

  const onOrderByChangeObject = (event) => {
    let value = undefined
    if (event) {
      value = event.value
    }
    setPlanningObjectsOrderBy(value)
  }

  const onDirectionChangeObject = (event) => {
    let value = undefined
    if (event) {
      value = event.value
    }
    setPlanningObjectsDirection(value)
  }

  const calendarCard = {
    size: 12,
    title: cardTitle,
    data: <PlanningCalendar orderBy={orderBy} direction={direction} status={status} />,
    className: `transition-div ${isTransitioning ? 'transition' : ''}`
  };

  const eventsCard = {
    size: 12,
    title: 'Planning Events',
    data: <PlanningTable plannings={plannings} />,
    className: `transition-div ${isTransitioning ? 'transition' : ''}`
  };

  const planningObjectsCard = {
    size: 12,
    title: 'Planning Objects',
    data: <PlanningObjectTable planning_objects={planning_objects} />,
    className: `transition-div ${isTransitioning ? 'transition' : ''}`
  };

  const searchAndFilter = {
    filters: [
      {
        label: 'Status',
        options: statusFilterChoices,
        onChange: onStatusChange,
        selected: status ? { 'value': statusFilterChoices.find(element => element.id === status)?.id, 'label': statusFilterChoices.find(element => element.id === status)?.name } : null,
        clearable: Boolean(true),
        searchable: Boolean(true)
      },
      {
        label: 'Order By',
        options: orderByFilterChoices,
        onChange: onOrderByChange,
        selected: orderBy ? { 'value': orderByFilterChoices.find(element => element.id === orderBy)?.id, 'label': orderByFilterChoices.find(element => element.id === orderBy)?.name } : null,
        clearable: Boolean(true),
        searchable: Boolean(true)
      },
      {
        label: 'Direction',
        options: directionFilterChoices,
        onChange: onDirectionChange,
        selected: direction ? { 'value': directionFilterChoices.find(element => element.id === direction)?.id, 'label': directionFilterChoices.find(element => element.id === direction)?.name } : null,
        clearable: Boolean(true),
        searchable: Boolean(true)
      }
    ]
  }

  const searchAndFilterObject = {
    filters: [
      {
        label: 'Order By',
        options: orderByFilterChoicesObject,
        onChange: onOrderByChangeObject,
        selected: planningObjectsOrderBy ? { 'value': orderByFilterChoicesObject.find(element => element.id === planningObjectsOrderBy)?.id, 'label': orderByFilterChoicesObject.find(element => element.id === planningObjectsOrderBy)?.name } : null,
        clearable: Boolean(true),
        searchable: Boolean(true)
      },
      {
        label: 'Direction',
        options: directionFilterChoices,
        onChange: onDirectionChangeObject,
        selected: planningObjectsDirection ? { 'value': directionFilterChoices.find(element => element.id === planningObjectsDirection)?.id, 'label': directionFilterChoices.find(element => element.id === planningObjectsDirection)?.name } : null,
        clearable: Boolean(true),
        searchable: Boolean(true)
      }
    ]
  }

  return (
    <>
      <Col md={12}>
        <div className='row g-3'>
          <div className='col'>
            <div className="p-3 my-3">
              <Button className={`${currentPage === 'calendar' ? "sm-border" : "no-bg no-border"} btn-no-shadow`} type="button" onClick={() => handlePageChange('calendar')}>
                {translate('Timeline')}
              </Button>
              <Button className={`${currentPage === 'events' ? "sm-border" : "no-bg no-border"} btn-no-shadow`} type="button" onClick={() => handlePageChange('events')}>
                {translate('Events')}
              </Button>
              <Button className={`${currentPage === 'planning-objects' ? "sm-border" : "no-bg no-border"} btn-no-shadow`} type="button" onClick={() => handlePageChange('planning-objects')}>
                {translate('Planning Objects')}
              </Button>
            </div>
          </div>
          <div className='col-3'>
            <div className="mx-5">
              {currentPage === 'events' && <SearchAndMultiFilterSingleSelect {...searchAndFilter} />}
              {currentPage === 'planning-objects' && <SearchAndMultiFilterSingleSelect {...searchAndFilterObject} />}
            </div>
          </div>
          <div className='col-1'>
            <div className="p-3 my-3">
              {(add && currentPage !== 'planning-objects') && <TableButton {...add} />}
              {(planning_object_add && currentPage === 'planning-objects') && <TableButton {...planning_object_add} />}
            </div>
          </div>
        </div>
      </Col>

      {currentPage === 'calendar' &&
        <BasicCard {...calendarCard} />
      }

      {currentPage === 'events' &&
        <BasicCard {...eventsCard} />
      }

      {currentPage === 'planning-objects' &&
        <BasicCard {...planningObjectsCard} />
      }
    </>
  )
};

export default Planning;