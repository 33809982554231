import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Tab, Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import CreateField from '../helpers/createField';


function RegistrationForm(props) {
  const { t: translate } = useTranslation();

  if (props.data.hasOwnProperty('location_id')) {
    props.location_id.selected = { 'value': props.data.location_id, 'label': props.location_id.hash[props.data.location_id]?.name }
  }

  if (props.data.hasOwnProperty('administration_id')) {
    props.administration_id.selected = { 'value': props.data.administration_id, 'label': props.administration_id.hash[props.data.administration_id]?.name }
  }

  if (props.data.hasOwnProperty('user_id')) {
    props.user_id.selected = { 'value': props.data.user_id, 'label': props.user_id.hash[props.data.user_id]?.name }
  }

  if (props.data.hasOwnProperty('reseller_id')) {
    props.reseller_id.selected = { 'value': props.data.reseller_id, 'label': props.reseller_id.hash[props.data.reseller_id]?.firstName + ' ' + props.reseller_id.hash[props.data.reseller_id]?.lastName }
  }

  if (props.data.hasOwnProperty('relation_id')) {
    props.relation_id.selected = { 'value': props.data.relation_id, 'label': props.relation_id.hash[props.data.relation_id]?.name }
  }

  if (props.data.hasOwnProperty('subscriptions')) {
    if (!props.subscriptions.selected) { props.subscriptions.selected = [] }
    props.data.subscriptions.forEach(subscription => {
      props.subscriptions.selected.push({ 'value': subscription.id, 'label': subscription.name })
    });
  }

  let basicFields = []
  let formTabs = [];
  let formTabContent = [];

  props.fields.forEach(field => {
    if (field.type === 'object') {
      formTabs.push(
        <Nav.Item key={field.id}>
          <Nav.Link eventKey={field.id}>
            {translate(field.name)}
          </Nav.Link>
        </Nav.Item>
      );

      let objectFields = []
      field.fields.forEach(embeddedfield => {
        objectFields.push(CreateField(embeddedfield, props, field.id))
      });
      formTabContent.push(
        <Tab.Pane eventKey={field.id} key={field.id}>
          <div className='tab-container'>
            {objectFields}
          </div>
        </Tab.Pane>
      );
    } else {
      basicFields.push(CreateField(field, props))
    }
  });

  return (
    <>
      <Form>
        <Tab.Container defaultActiveKey="main">
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="main">
                {translate('Main')}
              </Nav.Link>
            </Nav.Item>
            {formTabs}
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="main">
              <div className='tab-container'>
                {basicFields}
              </div>
            </Tab.Pane>
            {formTabContent}
          </Tab.Content>
        </Tab.Container>
      </Form>
      <Button variant="primary" type={props.submit.type} onClick={props.submit.onClick}>
        {translate(props.submit.title)}
      </Button>
    </>
  );
}

export default RegistrationForm;