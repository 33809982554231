export const dayChoices = [
    { id: "Monday", name: "Monday" },
    { id: "Tuesday", name: "Tuesday" },
    { id: "Wednesday", name: "Wednesday" },
    { id: "Thursday", name: "Thursday" },
    { id: "Friday", name: "Friday" },
    { id: "Saturday", name: "Saturday" },
    { id: "Sunday", name: "Sunday" }
]

export const screenRotationChoices = [
    { id: "landscape", name: "Landscape" },
    { id: "portrait", name: "Portrait" },
    { id: "landscape-flipped", name: "Landscape (Flipped)" },
    { id: "portrait-flipped", name: "Portrait (Flipped)" }
]

export const layoutChoices = [
    { id: "fullscreen", name: "Fullscreen" },
    // { id: "vertical-split-screen", name: "Vertical Split Screen" },
    // { id: "horizontal-split-screen", name: "Horizontal Split Screen" },
    // { id: "cross-split-screen", name: "Cross Split Screen" },
    // { id: "t-split-screen-left", name: "T Split Screen Left" },
    // { id: "t-split-screen-right", name: "T Split Screen Right" },
    // { id: "t-split-screen-top", name: "T Split Screen Top" },
    // { id: "t-split-screen-bottom", name: "T Split Screen Bottom" }
]

export const contentTypeChoices = [
    { id: "Image", name: "Image" },
    { id: "Video", name: "Video" }
]