import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card"
import EmployeeForm from "../../components/employeeForm";
import { EmployeeFormFields } from "./employeeFormFields";
import HandleOnChange from "../../helpers/handleOnChange";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import HandleError from "../../helpers/handleError";
import PostEmployee from "../../actablueAPI/employee/PostEmployee";
import { getEmployeeRolesAll, getHashEmployeeRolesAll } from "../../redux/dataSlices/employeeSlice";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import { roles } from "../../scopes/roles.mjs";

const EmployeeAdd = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id)
  const location_id = useSelector(getSelectedLocation_id)
  const employee_roles_all = useSelector(getEmployeeRolesAll)
  const hash_employee_roles_all = useSelector(getHashEmployeeRolesAll)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navTitle = "Employee add"
  const [employee, setEmployee] = useState({administration_id:admin_id, location_id:location_id})

  const onChange = (event) => {
    let newEmployee = HandleOnChange({ event: event, object: employee })
    setEmployee(newEmployee)
  }

  const onRoleChange = (event) => {
    let newEmployee = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: employee, selectfield: 'role' })
    newEmployee.roles = hash_employee_roles_all[event.value].roles
    setEmployee(newEmployee)
  };

  const onSubmit = () => {
    let newEmployee = JSON.parse(JSON.stringify(employee))
    if (employee.roles) {
      let newRoles = []
      for (const [role, value] of Object.entries(employee.roles)) {
        // clean scopes (delete non excisting scopes)
        if (roles.includes(role)) {
          newRoles.push(role.concat(':').concat(value))
        }
      }
      newEmployee.roles = newRoles
    }
    delete newEmployee.role

    PostEmployee({token:token, admin_id:admin_id, employee:newEmployee})
    .then(response => {
      navigate("/employees");
    })
    .catch(error => {
      HandleError(error)
    })

  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    fields: EmployeeFormFields(),
    field: {
      onChange: onChange
    },
    data: employee,
    role: {
      label: 'role',
      options: employee_roles_all,
      onChange: onRoleChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    }
  };

  const editCard = {
    size: 12,
    title: navTitle,
    data: <EmployeeForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...editCard} />
    </>
  )

};
export default EmployeeAdd;

