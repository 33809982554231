import React from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import axios from "axios";
import BasicCard from "../../components/card";
import { getApplications, getChannelsEmailsAll, getTemplatesAll, setApplications, setChannelsEmailsAll, setTemplatesAll, setReservationTemplatesAll, getReservationTemplatesAll, setSubscriptionsAll, getSubscriptionsAll } from "../../redux/dataSlices/notificationSlice";
import HandleError from "../../helpers/handleError";
import ApplicationTable from "../../components/notificationApplicationTable";
import TemplateTable from "../../components/notificationTemplateTable";
import ReservationTemplateTable from "../../components/notificationReservationTemplateTable";
import ChannelsEmailTable from "../../components/notificationChannelsEmailTable";
import NotificationSubscriptionsTable from "../../components/notificationSubscriptionTable";
import GetNotificationApplications from "../../actablueAPI/notifications/GetNotificationsApplications";
import GetNotificationSubscriptions from "../../actablueAPI/notifications/GetNotificationSubscriptions";
import GetNotificationTemplates from "../../actablueAPI/notifications/GetNotificationTemplates";
import GetNotificationChannelsEmail from "../../actablueAPI/notifications/GetNotificationChannelsEmail";
import GetNotificationReservationTemplates from "../../actablueAPI/notifications/GetNotificationReservationTemplates";

const Notifications = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const applications = useSelector(getApplications)
  const subscriptions_all = useSelector(getSubscriptionsAll)
  const templates = useSelector(getTemplatesAll)
  const reservation_templates = useSelector(getReservationTemplatesAll)
  const channels_emails = useSelector(getChannelsEmailsAll)
  const dispatch = useDispatch();
  const navTitle = "Notifications"

  const applicationCard = {
    size: 12,
    title: 'Applications',
    data: <ApplicationTable {...applications} />,
  };

  const subscriptionsCard = {
    size: 12,
    title: 'Subscriptions',
    data: <NotificationSubscriptionsTable {...subscriptions_all} />,
  };

  const templateCard = {
    size: 12,
    title: 'Templates',
    data: <TemplateTable {...templates} />,
  };

  const reservationTemplateCard = {
    size: 12,
    title: 'Reservation templates',
    data: <ReservationTemplateTable {...reservation_templates} />,
  };

  const channelsEmailCard = {
    size: 12,
    title: 'Channels',
    data: <ChannelsEmailTable {...channels_emails} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setLoading(true))
    if (admin_id && location_id) {
      axios.all([
        GetNotificationApplications({ token: token, admin_id: admin_id, location_id: location_id }),
        GetNotificationSubscriptions({ token: token, admin_id: admin_id }),
        GetNotificationTemplates({ token: token, admin_id: admin_id, location_id: location_id }),
        GetNotificationChannelsEmail({ token: token, admin_id: admin_id, location_id: location_id }),
        GetNotificationReservationTemplates({ token: token, admin_id: admin_id, location_id: location_id })
      ])
        .then(axios.spread((applicationResponse, subscriptionsResponse, templatesResponse, channelsEmailsResponse, reservationTemplatesResponse) => {
          dispatch(setApplications(applicationResponse.data))
          dispatch(setSubscriptionsAll(subscriptionsResponse.data))
          dispatch(setTemplatesAll(templatesResponse.data))
          dispatch(setChannelsEmailsAll(channelsEmailsResponse.data))
          dispatch(setReservationTemplatesAll(reservationTemplatesResponse.data))
          dispatch(setLoading(false))
        }))
        .catch(error => {
          HandleError(error)
          dispatch(setLoading(false))
        })
    }

    if (!admin_id || !location_id) {
      GetNotificationApplications({ token: token, admin_id: admin_id, location_id: location_id })
        .then(axios.spread((applicationResponse) => {
          dispatch(setApplications(applicationResponse.data))
          dispatch(setLoading(false))
        }))
        .catch(error => {
          HandleError(error)
          dispatch(setLoading(false))
        })
    }
    // eslint-disable-next-line
  }, [admin_id, location_id]);

  return (
    <>
      <BasicCard {...applicationCard} />
      {admin_id && <BasicCard {...subscriptionsCard} />}
      {location_id && <BasicCard {...channelsEmailCard} />}
      {location_id && <BasicCard {...templateCard} />}
      {location_id && <BasicCard {...reservationTemplateCard} />}
    </>
  )
};
export default Notifications;
