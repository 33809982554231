import { useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { setGuestCheck, setGuestCheckPagination, getGuestCheckModalShow, setGuestCheckModalShow, setGuestCheckPagesize, getGuestCheckPagesize } from "../../redux/dataSlices/guestcheckSlice";
import { getScopes } from "../../redux/dataSlices/scopesSlice";
import { pagesize } from "../../selectfieldchoices/pagesize.mjs";
import GetOrderById from "../../actablueAPI/invoicepayments/GetOrderByID";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import HandleError from "../../helpers/handleError";

export default function GuestCheckButtons() {
  const token = useSelector(getToken)
  const modalShow = useSelector(getGuestCheckModalShow)
  const pageSize = useSelector(getGuestCheckPagesize)
  const scopes = useSelector(getScopes)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function onViewClick(guestcheck) {
    // get order
    if (guestcheck.order_id) {
      let newGuestcheck = JSON.parse(JSON.stringify(guestcheck))
      GetOrderById({token:token, admin_id:newGuestcheck.administration_id, id:newGuestcheck.order_id})
      .then(response => {
        newGuestcheck.order = response.data
        dispatch(setGuestCheck(newGuestcheck));
      })
      .catch(error => {
        HandleError(error)
        dispatch(setGuestCheck(guestcheck));
      })
      .finally(() => {
        dispatch(setGuestCheckModalShow(true));
      })
    }
  };

  const onViewHide = () => {
    dispatch(setGuestCheckModalShow(false));
  };

  const onclickEdit = (guestcheck) => {
    dispatch(setGuestCheck(guestcheck));
    navigate("/guestcheck/edit");
  };

  const onPaginationClick = (event) => {
    dispatch(setGuestCheckPagination(event.target.value))
  };

  const onPageSizeChange = (event) => {
    dispatch(setGuestCheckPagesize(event.value))
  };

  const onclickAdd = () => {
    navigate("/guestcheck/add");
  };

  let buttons = {
    modalview: {
      tag: "View",
      onClick: onViewClick,
      modal: {
        show: modalShow,
        onHide: onViewHide
      }
    },
    pagination: {
      maxbuttons: 5, //uneven number
      onClick: onPaginationClick
    },
    pagesize: {
      className: 'navbar-select',
      label: "page size selector",
      placeholder: "No selecetion",
      pagesize: pageSize,
      selected: { 'id': pageSize, 'name': pageSize },
      number_of_elements: 0,
      options: pagesize,
      onChange: onPageSizeChange
    }
  }

  // add buttons based on scopes
  // scopeOptions = ['read', 'create', 'update', 'delete'] (use index: 0:read, 1:create, 2:update, 3:delete)
  // buttonOptions = {'get':0, 'new':1, 'edit':2, 'add':2, 'del':3, 'swap':2, 'move':2, 'import':1, 'create': 1, 'export': 1, 'print': 0}
  // check level of scope, add buttons according to the level.
  if (scopes.guestchecks >= 1) {
    buttons.add = {
      tag: 'Add',
      value: 'add',
      onClick: onclickAdd,
      className: 'add-button'
    }
  }
  if (scopes.guestchecks >= 2) {
    buttons.edit = {
      tag: "Edit",
      value: 'edit',
      onClick: onclickEdit
    }
  }

  return buttons
}

