export const NarrowcastingScheduleFormFields = () => {

  const narrowcastingScheduleFormFields = [
    // single option fields
    { type: 'hidden', name: 'id', id: 'id' },
    { type: 'text', name: `Name`, id: 'name', required: true },
    { type: 'text', name: `Description`, id: 'description', required: true },
    { type: 'select', name: `Day`, id: 'day', formdatafield: 'day', required: true },
    { type: 'multiselect', name: `Playlists`, id: 'playlists', formdatafield: 'playlists' }
  ]

  return narrowcastingScheduleFormFields
}