// import TableButton from './tableButton';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from "react-bootstrap/Button";
import SelectField from "./selectField";
import { useTranslation } from 'react-i18next';

import './css/pagination.css';

export default function PaginationAndSizeComponent(paginationsettings) {
  const prev = paginationsettings.page - 1
  const next = paginationsettings.page + 1
  const { t: translate } = useTranslation();

  if (paginationsettings.pagination && paginationsettings.pages) {
    return (
      <nav className="pagination-nav" aria-label="pagination">
        <div className="pagination-buttons">
          <ul className="pagination pagination-sm row gy-2 justify-content-between">
            <InputGroup className="pagination-group col-6">
              <Button disabled={paginationsettings.page === 1 || paginationsettings.page === 0 ? true : false} className="btn-no-shadow m-0 py-1 px-2" value='1' onClick={paginationsettings.pagination.onClick} id='first' tag='first'>
                |&lt;&lt;
              </Button>
              <Button disabled={paginationsettings.page === 1 || paginationsettings.page === 0 ? true : false} className="btn-no-shadow m-0 me-0 py-1 px-2" value={prev} onClick={paginationsettings.pagination.onClick} id='prev' tag='prev'>
                &lt;
              </Button>
              <span className='form-control text-center py-1 px-2'>
                <p className='m-0 p-1'>{translate('out of', {page: paginationsettings.page ? paginationsettings.page : 1, endpage: paginationsettings.pages ? paginationsettings.pages : 1})}</p>
              </span>
              <Button disabled={paginationsettings.page === paginationsettings.pages ? true : false} className="btn-no-shadow m-0 me-0 py-1 px-2" value={next} onClick={paginationsettings.pagination.onClick} id='next' tag='next'>
                &gt;
              </Button>
              <Button disabled={paginationsettings.page === paginationsettings.pages ? true : false} className="btn-no-shadow m-0 py-1 px-2" value={paginationsettings.pages} onClick={paginationsettings.pagination.onClick} id='last' tag='last'>
                &gt;&gt;|
              </Button>
            </InputGroup>
            <div className="filter col-4">
              <label htmlFor='navbar-select'>
                {translate('Page size:')}
              </label>
              <SelectField {...paginationsettings.pagesize} />
            </div>
          </ul>
        </div>
      </nav>
    )
  }

}