import React from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card"
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import HandleOnChange from "../../helpers/handleOnChange";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import OnlinePaymentForm from "../../components/onlinePaymentForm";
import { paymentTypeChoices, sourceTypeChoices } from "../../selectfieldchoices/onlinepaymentselect.mjs";
import { onlinePaymentsFormFields } from "./onlinePaymentFormFields.mjs";
import PostOnlinePaymtentsProfile from "../../actablueAPI/onlinepayments/PostOnlinePaymentsProfile";
import HandleError from "../../helpers/handleError";
import { setLoading } from "../../redux/dataSlices/loadingSlice";

const OnlinePaymentAdd = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [onlinePayment, setOnlinePayment] = useState({ administration_id: admin_id, location_id: location_id })

  const onChange = (event) => {
    let newOnlinePayment = HandleOnChange({ event: event, object: onlinePayment })
    setOnlinePayment(newOnlinePayment)
  }

  const onSourceTypeChange = (event) => {
    let newOnlinePayment = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: onlinePayment, selectfield: 'source_type' })
    setOnlinePayment(newOnlinePayment)
  }

  const onPaymentTypeChange = (event) => {
    let newOnlinePayment = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: onlinePayment, selectfield: 'payment_type' })
    setOnlinePayment(newOnlinePayment)
  }

  // const onLocationIdChange = (event) => {
  //   let newCompany = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: snelstart_keys, selectfield: 'location_id' })
  //   setSnelstartKeys(newCompany)
  // }

  const onSubmit = () => {
    dispatch(setLoading(true));
    
    PostOnlinePaymtentsProfile({ token: token, admin_id: admin_id, location_id: location_id, type: onlinePayment.source_type.toLowerCase(), data: onlinePayment })
      .then(() => {
        navigate("/onlinepayments");
      })
      .catch(error => {
        HandleError(error);
        dispatch(setLoading(false));
      })
  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    fields: onlinePaymentsFormFields,
    field: {
      onChange: onChange
    },
    data: onlinePayment,
    source_type: {
      label: 'source_type',
      options: sourceTypeChoices,
      onChange: onSourceTypeChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    payment_type: {
      label: 'payment_type',
      options: paymentTypeChoices,
      onChange: onPaymentTypeChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
  };

  const card = {
    size: 12,
    title: "Online payment add ",
    data: <OnlinePaymentForm {...formData} />,
  };

  return (
    <>
      <BasicCard {...card} />
    </>
  )

};
export default OnlinePaymentAdd;

