import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setTaskId, setMatchColumnsForm, getMatchColumnsForm, setImportSaveModalShow } from "../../redux/dataSlices/importSlice";
import { ProductImportActablueExportFormFields } from "./importFormFields.jsx";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import { importDynamicChoices } from "../../selectfieldchoices/importselect";
import HandleError from "../../helpers/handleError";
import PostImportActablueProducts from "../../actablueAPI/import/PostImportActablueProducts.jsx";

const ImportingProductActablueFile = (importForm, headers, data) => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const match_columns_form = useSelector(getMatchColumnsForm)
  const dispatch = useDispatch();
  const fields = ProductImportActablueExportFormFields(headers, importForm)

  const onMatchColumsFormChange = (event) => {
    let newMatchColumnsForm = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: match_columns_form})
    dispatch(setMatchColumnsForm(newMatchColumnsForm))
  }

  const onDynamicChange = (event) => {
    let newMatchColumnsForm = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: match_columns_form, selectfield: event.prefix.concat('_').concat(event.name) })
    dispatch(setMatchColumnsForm(newMatchColumnsForm))
  };

  const onMatchColumnsSave = (event) => {
    dispatch(setImportSaveModalShow(true))
  }

  const onStartImport = () => {
    let ab_data = {}
    ab_data.token = token
    ab_data.access_token = token.access_token
    ab_data.admin_id = admin_id
    ab_data.location_id = location_id
    ab_data.ab_selection = {}
    ab_data.ab_selection.admin_id = admin_id
    ab_data.ab_selection.location_id = location_id
    ab_data.read_dict = data
    ab_data.matchingForm = Object.assign({}, match_columns_form)
    ab_data.matchingForm.administration_id = admin_id
    ab_data.matchingForm.location_id = location_id
    ab_data.supplier_id = importForm.supplier

    switch (importForm.importtype) {
      case 'actablueExportFile':
        PostImportActablueProducts({data:ab_data})
        .then(response => {
          dispatch(setTaskId(response.data.task_id))
        })
        .catch(error => {
          HandleError(error)
        })
        break;
      default:
        break;
    }
  };

  let importMatchColumnsFormData = {
    submit: {
      title: 'start import',
      type: 'submit',
      onClick: onStartImport
    },
    save: {
      title: 'save settings',
      type: 'submit',
      onClick: onMatchColumnsSave
    },
    fields: fields,
    data: importForm,
    headerSelection: {
      options: headers,
      onChange: onMatchColumsFormChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    }
  }

  for (const field of fields) {
    if (field.id !== 'dynamic') {
      importMatchColumnsFormData[field.id] = {
        name: field.id,
        options: headers,
        onChange: onMatchColumsFormChange,
        clearable: Boolean(true),
        searchable: Boolean(true)
      }
    }
    if (field.id === 'dynamic') {
      importMatchColumnsFormData[field.id] = {
        name: field.id,
        options: importDynamicChoices,
        onChange: onDynamicChange,
        clearable: Boolean(true),
        searchable: Boolean(true)
      }
    }
  }

  return importMatchColumnsFormData
};
export default ImportingProductActablueFile;