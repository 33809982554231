import { useTranslation } from 'react-i18next';
import Select from 'react-select';

function SelectField(props) {
  const { t: translate } = useTranslation();
  const options = props.options
  const selected = props.selected ? { value: props.selected.id, label: props.selected.name } : null

  if (options) {
    return (
      <Select
        aria-label={props.label}
        onChange={props.onChange}
        width={props.width}
        className={props.className}
        options={options.length > 0 && options.map((option) => ({ value: option.id, label: option.name }))}
        value={selected}
        defaultValue={selected}
        placeholder={props?.placeholder ? <div className='select-placeholder'>{translate(props.placeholder)}</div> : translate('Select...')}
        isClearable={props.clearable}
        isSearchable={props.searchable}
        isLoading={props.loading}
        isDisabled={props.loading}
      />
    );
  }
  return (
    <Select
      aria-label={props.label}
      onChange={props.onChange}
      width={props.width}
      className={props.className}
      value={null}
      defaultValue={null}
      placeholder={props?.placeholder ? <div className='select-placeholder'>{translate(props.placeholder)}</div> : translate('Select...')}
      isDisabled={true}
    />
  );

}

export default SelectField;