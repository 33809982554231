import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getAdministrationsAll, setAdministrationsAll, getFilter, setFilter,getSearch, setSearch } from "../../redux/dataSlices/administrationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import SearchNoButtonAndFilterSingleSelect from "../../components/searchNoButtonAndFilterSingleSelectField";
import BasicCard from "../../components/card"
import AdminTable from "../../components/adminTable";
import HandleError from "../../helpers/handleError";
import GetSerialsList from "../../actablueAPI/serials/GetSerialsList";

const Administrations = () => {
  const token = useSelector(getToken);
  const administrations = useSelector(getAdministrationsAll);
  const filter = useSelector(getFilter)
  const search = useSelector(getSearch)
  const dispatch = useDispatch();
  const [filtered, setFiltered] = useState()
  const navTitle = "Administrations"

  const onSearchChange = (event) => {
    let value = undefined
    if (event) {
      value = event.target.value
    }
    dispatch(setSearch(value))
    SearchAndFilter(filter, value)
  }

  const onFilterChange = (event) => {
    let value = undefined
    if (event) {
      value = event.value
    }
    dispatch(setFilter(value))
    SearchAndFilter(value, search)
  }

  const searchAndFilter = {
    search: {
      value: search,
      onChange: onSearchChange,
    },
    // filter: {
    //   label: '',
    //   options: filterChoices,
    //   hash: '',
    //   onChange: onFilterChange,
    //   clearable: Boolean(true),
    //   searchable: Boolean(true)
    // }
  }

  function SearchAndFilter(filter, search) {
    if (!filter && !search) {      
      return setFiltered()
    }

    let filtered = administrations // if using a switch statement, set it to []

    if (filter) {
      filtered = (administrations.filter(admin => admin.attributes?.origin?.includes(filter)))   
    }

    if (search) {
      return setFiltered(filtered.filter(admin => JSON.stringify(admin.name).toLowerCase().includes(search.toLowerCase())))
    }
    return setFiltered(filtered)
  }

  const adminCard = {
    size: 12,
    title: {text:"Administrations", option:filtered?.length || administrations?.length || 0},
    data: <AdminTable administrations={filtered || administrations } />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setLoading(true))
    GetSerialsList({token:token})
    .then(response => {
      dispatch(setAdministrationsAll(response.data))
      SearchAndFilter(filter, search)
      dispatch(setLoading(false))
    })
    .catch(error => {
      HandleError(error)
      dispatch(setLoading(false))
    })

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <SearchNoButtonAndFilterSingleSelect {...searchAndFilter} />
      <BasicCard {...adminCard} />
    </>
  )
};
export default Administrations;
