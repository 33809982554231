import { useSelector, useDispatch } from "react-redux";
import { getScopes } from "../../redux/dataSlices/scopesSlice";
import { getJournalCategoryEventData, getTaskResults, setActionSpinner, setTaskResults } from "../../redux/dataSlices/analyseSlice";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import HandleError from "../../helpers/handleError";
import PutProduct from "../../actablueAPI/products/PutProduct";
import { getHashJournalCategoriesAll } from "../../redux/dataSlices/categorySlice";
import { AnalyseExport } from "../../helpers/analyseExport";
import { JournalCategoryExport } from "../../exports/journalCategoryExport";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { ProductCategoryExport } from "../../exports/productCategoryExport";
import { FixVatDifference } from "../../helpers/fixVatDifference";
import { TransactionProcessedByAccountingExport } from "../../exports/transactionProcessedByAccountingExport";

export default function AnalyseButtons() {
  const token = useSelector(getToken)
  const admin_id = useSelector(getSelectedAdmin_id)
  const location_id = useSelector(getSelectedLocation_id)
  const scopes = useSelector(getScopes)
  // const taskData = useSelector(getTaskData)
  const taskResults = useSelector(getTaskResults)
  const journalCategoryEventData = useSelector(getJournalCategoryEventData)
  const hashJournalCategories = useSelector(getHashJournalCategoriesAll)
  const dispatch = useDispatch();

  const onExportClick = (exportData, type) => {
    console.log('Exportdata', exportData)
    switch (type) {
      case 'nojournalcategory':
        AnalyseExport({ exportData:exportData, fileName:type, dispatch: dispatch })
        JournalCategoryExport({token:token, admin_id:admin_id, location_id:location_id, dispatch:dispatch})
        break;
      case 'noproductcategory':
        AnalyseExport({ exportData:exportData, fileName:type, dispatch: dispatch })
        ProductCategoryExport({token:token, admin_id:admin_id, location_id:location_id, dispatch:dispatch})
        break;
      case 'is_not_processed_by_accounting':
        TransactionProcessedByAccountingExport({ exportData:exportData, fileName:type, dispatch: dispatch })
        break
      case 'is_processed_by_accounting':
        TransactionProcessedByAccountingExport({ exportData:exportData, fileName:type, dispatch: dispatch })
        break
      default:
        break;
    }        
  }

  const onclickFixVat= (exportData) => {
    FixVatDifference({token:token, admin_id:admin_id, location_id:location_id, exportdata:exportData, taskResults:taskResults, dispatch:dispatch})
  }

  const onclickConnect = (event) => {
    dispatch(setActionSpinner(true))
    let product = JSON.parse(JSON.stringify(event))
    if (!product.categories) {
      product.categories = []
    }
    product.categories.push(hashJournalCategories[journalCategoryEventData.value])
    PutProduct({token:token, admin_id:admin_id, data:product})
    .then(response => {
      // delete this product from nojournalcategory
      removeFromTaskDataResults({productToBeRemoved:response.data, table:'nojournalcategory'})
    })
    .catch(error => {HandleError(error)})
    .finally(() => {dispatch(setActionSpinner(false))})
  }

  function removeFromTaskDataResults({productToBeRemoved, table}) {
    if (taskResults.results?.[table]) {
      let newTaskResults = JSON.parse(JSON.stringify(taskResults))
      let newTaskTable = []
      for (const product of taskResults.results[table]) {
        if (product.id !== productToBeRemoved.id) {
          newTaskTable.push(product)
        }
      }
      newTaskResults.results[table] = newTaskTable
      dispatch(setTaskResults(newTaskResults))
    }
  }

  let buttons = {
    journalConnect: {
      tag: "Connect",
      value: 'connect',
      onClick: onclickConnect
    },
    export: {
      tag: "Export",
      onClick: onExportClick,
      className: 'add-button'
    },
    fixvat: {
      tag: "Fix Vat",
      onClick: onclickFixVat,
      className: 'add-button'
    }

  }

  // add buttons based on scopes
  // scopeOptions = ['read', 'create', 'update', 'delete'] (use index: 0:read, 1:create, 2:update, 3:delete)
  // buttonOptions = {'get':0, 'new':1, 'edit':2, 'add':2, 'del':3, 'swap':2, 'move':2, 'import':1, 'create': 1, 'export': 1, 'print': 0}
  // check level of scope, add buttons according to the level.

  return buttons
}

