import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { setWarehousesAll, getWarehousesAll, getRack, } from "../../redux/dataSlices/warehouseSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import BasicCard from "../../components/card"
import WarehouseTable from "../../components/warehouseTable";
import HandleError from "../../helpers/handleError";
import GetWarehouseWarehouse from "../../actablueAPI/warehouse/GetWarehouseWarehouse";

const Warehouse = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const warehouses = useSelector(getWarehousesAll)
  const rack = useSelector(getRack)
  const dispatch = useDispatch();
  const navTitle = "Warehouse"

  const card = {
    size: 12,
    title: navTitle,
    data: <WarehouseTable {...warehouses} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setLoading(true))
    GetWarehouseWarehouse({token:token, admin_id:admin_id, location_id:location_id})
    .then((warehousesResponse) => {
      dispatch(setWarehousesAll(warehousesResponse.data.content))
      dispatch(setLoading(false))
    })
    .catch(error => {
      HandleError(error)
      dispatch(setLoading(false))
    })
    // eslint-disable-next-line
  }, [, admin_id, location_id, rack]);

  return (
    <>
      <BasicCard {...card} />
    </>
  );
};
export default Warehouse;
