import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getDevicesAll, setDevicesAll, getDevicePagination, getDevicePagesize } from "../../redux/dataSlices/deviceSlice";
import { getHashLocationsAll } from "../../redux/dataSlices/locationSlice";
import { getHashAdministrationsAll } from "../../redux/dataSlices/administrationSlice";
import { getSelectedAdminScopes, getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocationScopes, getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import SearchAndFilterSingleSelect from "../../components/searchAndFilterSingleSelectField";
import BasicCard from "../../components/card"
import DeviceTable from "../../components/deviceTable";
import HandleError from "../../helpers/handleError";
import { filterChoices } from "../../selectfieldchoices/deviceselect.mjs";
import { getScopes } from "../../redux/dataSlices/scopesSlice";
import GetDevicesVectron from "../../actablueAPI/devices/GetDevicesVectron";
import GetDevicesSmartpos from "../../actablueAPI/devices/GetDevicesSmartpos";
import GetDevicesListAll from "../../actablueAPI/devices/GetDevicesListAll";

const Devices = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  let scopes = useSelector(getScopes)
  const admin_scopes = useSelector(getSelectedAdminScopes)
  const location_scopes = useSelector(getSelectedLocationScopes)
  const hash_administrations = useSelector(getHashAdministrationsAll)
  const location_id = useSelector(getSelectedLocation_id);
  const hash_locations = useSelector(getHashLocationsAll)
  const devices = useSelector(getDevicesAll)
  const pagination = useSelector(getDevicePagination);
  const pageSize = useSelector(getDevicePagesize);
  const dispatch = useDispatch();
  const [filtered, setFiltered] = useState()
  const [search, setSearch] = useState()
  const [filter, setFilter] = useState()

  if (admin_scopes) {
    scopes = admin_scopes
  }

  if (location_scopes) {
    scopes = location_scopes
  }

  const onSearchChange = (event) => {
    let value = undefined
    if (event) {
      value = event.target.value
    }
    setSearch(value)
    SearchAndFilter(filter, value, devices)    
  }

  const onSearchKeyDown = (key) => {
    if (key.keyCode === 13) {
      onSearchClick()
    }
  }

  const onSearchClick = () => {
    SearchAndFilter()
  }

  const onFilterChange = (event) => {
    let value = undefined
    if (event) {
      value = event.value
    }
    setFilter(value)
    SearchAndFilter(value, search, devices)
  }

  const searchAndFilter = {
    search: {
      value: search,
      onChange: onSearchChange,
      onClick: onSearchClick,
      onKeyDown: onSearchKeyDown,
    },
    filter: {
      label: '',
      options: filterChoices,
      hash: '',
      onChange: onFilterChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    }
  }

  function SearchAndFilter(filter, search, devices) {
    if (!filter && !search && !location_id) {
      return setFiltered()
    }

    let filtered = []

    switch (filter) {
      case 'active_true':
        filtered = devices.filter(device => device.active)
        break;
      case 'active_false':
        filtered = devices.filter(device => !device.active)
        break;
      case 'vectron_active':
        filtered = devices.filter(device => device.type === 'VECTRON' && device.active)
        break;
      case 'smartpos_active':
        filtered = devices.filter(device => device.type === 'SMARTPOS' && device.active)
        break;
      case 'vectron_not_active':
        filtered = devices.filter(device => device.type === 'VECTRON' && !device.active)
        break;
      case 'smartpos_not_active':
        filtered = devices.filter(device => device.type === 'SMARTPOS' && !device.active)
        break;
      default:
        filtered = devices
        break;
    }

    if (search) {
      return setFiltered(filtered.filter(device => JSON.stringify(device).toLowerCase().includes(search.toLowerCase())))
    }
    
    return setFiltered(filtered)
  }

  const devicesCard = {
    size: 12,
    title: `Devices, max number: ${scopes?.NumberOfDevices}`,
    data: <DeviceTable devices={filtered || devices} hashAdmins={hash_administrations} hashLocs={hash_locations} maxNumberOfDevices={scopes?.NumberOfDevices}/>,
  };

  useEffect(() => {
    dispatch(setNavBarTitle("Devices"))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setLoading(true))
    // clear filtered
    setFiltered()
    if (admin_id) {
      axios.all([
        GetDevicesVectron({token:token, admin_id:admin_id}),
        GetDevicesSmartpos({token:token, admin_id:admin_id})
      ])
        .then(axios.spread((vectronList, smartposList) => {
          let deviceList = vectronList.data.concat(smartposList.data)
          if (location_id) {
            deviceList = deviceList.filter(device => device.location_id === location_id)
          }
          SearchAndFilter(filter, search, deviceList)
          //  do something with those arrays
          dispatch(setDevicesAll(deviceList))
          dispatch(setLoading(false))
        }))
        .catch(error => {
          HandleError(error)
          dispatch(setLoading(false))
        })
    }

    if (!admin_id) {
      GetDevicesListAll({token:token})
        .then(response => {
          SearchAndFilter(filter, search, response.data)
          dispatch(setDevicesAll(response.data))
          dispatch(setLoading(false))
        })
        .catch(error => {
          HandleError(error)
          dispatch(setLoading(false))
        })
    }

    // eslint-disable-next-line
  }, [, admin_id, location_id, pagination, pageSize]);

  return (
    <>
      <SearchAndFilterSingleSelect {...searchAndFilter} />
      <BasicCard {...devicesCard} />
    </>
  )
};
export default Devices;
