import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';

import CreateField from '../helpers/createField';

function VatForm(props) {
  const { t: translate } = useTranslation();

  let basicFields = []
  props.fields.forEach(field => {
    basicFields.push(CreateField(field, props))
  });

  return (
    <>
      {/* {JSON.stringify(props)} */}
      <Form>
        {basicFields}
      </Form>
      <Button variant="primary" type={props.submit.type} onClick={props.submit.onClick}>
        {translate(props.submit.title)}
      </Button>
    </>
  );
}

export default VatForm;