import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getCcvshops, setCcvshops, getCcvshopWebhooks, setCcvshopWebhooks } from "../../redux/dataSlices/ccvshopSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import BasicCard from "../../components/card"
import CcvshopTable from "../../components/ccvshopTable";
import CcvshopWebhookTable from "../../components/ccvshopWebhookTable";
import HandleError from "../../helpers/handleError";
import GetCcvshopCcvKeys from "../../actablueAPI/ccvshop/GetCcvshopCcvKeys";
import GetCcvshopWebhookList from "../../actablueAPI/ccvshop/GetCcvshopWebhookList";

const Ccvshops = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const ccvshops = useSelector(getCcvshops)
  const webhooks = useSelector(getCcvshopWebhooks)
  const dispatch = useDispatch();

  const ccvshopCard = {
    size: 12,
    title: "CCVShop",
    data: <CcvshopTable {...ccvshops} />,
  };

  const ccvshopWebhookCard = {
    size: 12,
    title: "CCVShop webhooks",
    data: <CcvshopWebhookTable {...webhooks} />,
  };


  useEffect(() => {
    dispatch(setNavBarTitle("CCVShop"))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setLoading(true))
    // get ccv keys
    GetCcvshopCcvKeys({ token: token, admin_id: admin_id, location_id: location_id })
      .then((response) => {
        dispatch(setCcvshops(response.data))
      })
      .catch(error => {
        HandleError(error)
      })

    // get ccv webhooks
    GetCcvshopWebhookList({ token: token, admin_id: admin_id })
      .then((response) => {
        dispatch(setCcvshopWebhooks(response.data))
        dispatch(setLoading(false))
      })
      .catch(error => {
        HandleError(error)
        dispatch(setLoading(false))
      })

    // eslint-disable-next-line
  }, [, admin_id, location_id]);



  return (
    <>
      <BasicCard {...ccvshopCard} />
      <BasicCard {...ccvshopWebhookCard} />
    </>
  )
};
export default Ccvshops;
