import axios from "axios";
import moment from "moment"

const GetStatusReportDay = ({ token, admin_id, location_id, startDate, location, signal }) => {
  return new Promise((resolve, reject) => {
    let year = moment.unix(startDate).year()
    let month = moment.unix(startDate).month() + 1 // returns index !!
    // let week = moment.unix(startDate).week()
    let day = moment.unix(startDate).date()
    let timezone = location?.timezone ? location?.timezone : "Europe/Amsterdam"

    axios({
      method: 'get',
      url: process.env.REACT_APP_API_BASE_ENV.concat("/reporting/dailystatus/day"),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      },
      params: {
        'administration': admin_id,
        'location': location_id,
        'tz': timezone,
        'year': year,
        'month': month,
        'day': day
      },
      signal: signal
    })
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      });
  })

};

export default GetStatusReportDay
