import { roles } from "../../scopes/roles.mjs"

export const EmployeeFormFields = () => {

  let roleFields = []

  const employeeFormFields = [
    // single option fields
    { type: 'hidden', name: 'id', id: 'id' },
    { type: 'text', name: `Name`, id: 'name' },
    { type: 'text', name: `Initials`, id: 'initials' },
    { type: 'text', name: `Operator id`, id: 'operator_id' },
    { type: 'text', name: `Pin code`, id: 'pin_code', maxlength:"4" },
    { type: 'select', name: 'Role', id: 'role', formdatafield: 'role' },

  ]

  for (const role of Object.values(roles)) {
    roleFields.push({ type: 'checkbox', name: role, id: role, placeholder: role, formdatafield: 'roles', prefix: 'roles' })
  }

  return { employeeFormFields: employeeFormFields, roleFields: roleFields }
}