import { createSlice } from "@reduxjs/toolkit";

export const productsupplierSlice = createSlice({
  name: "productsupplier",
  initialState: {
    productsupplier_pagesize: 10,
    productsupplier_pagination: 10,
    hash_product_suppliers_all: {},
  },
  reducers: {
    setProductSupplierId: (state, action) => {
      state.productsupplier_id = action.payload;
    },
    setProductSupplier: (state, action) => {
      state.productsupplier = action.payload;
    },
    setProductSuppliers: (state, action) => {
      if (action.payload.hasOwnProperty('content')) {state.productsuppliers = action.payload}
      if (!action.payload.hasOwnProperty('content')) {state.productsuppliers = {'content':action.payload}}
      if (typeof(action.payload)=== Object) {
        action.payload.forEach(productsupplier => {
          state.hash_product_suppliers_all[productsupplier.id] = productsupplier
        });
      }
    },
    editProductSuppliers: (state, action) => {
      for (let productsupplier in state.productsuppliers.content) {
        if (state.productsuppliers.content[productsupplier].id === action.payload.id) {
          state.productsuppliers.content[productsupplier] = action.payload
        }
      }
    },
    setProductSupplierModalShow: (state, action) => {
      state.productsuppliermodalshow = action.payload
    },
    setProductSupplierPagination: (state, action) => {
      state.productsupplier_pagination = (action.payload - 1)
    },
    setProductSupplierPagesize: (state, action) => {
      state.productsupplier_pagesize = action.payload
    },
  }
});

export const {
  setProductSupplierId, setProductSupplier, setProductSuppliers, editProductSuppliers, setProductSupplierModalShow, setProductSupplierPagination, setProductSupplierPagesize,
} = productsupplierSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getProductSupplierId = (state) => state.productsupplier.productsupplier_id
export const getProductSupplier = (state) => state.productsupplier.productsupplier
export const getProductSuppliers = (state) => state.productsupplier.productsuppliers
export const getHash_product_suppliers_all = (state) => state.productsupplier.hash_product_suppliers_all
export const getProductSupplierModalShow = (state) => state.productsupplier.productsuppliermodalshow
export const getProductSupplierPagination = (state) => state.productsupplier.productsupplier_pagination
export const getProductSupplierPagesize = (state) => state.productsupplier.productsupplier_pagesize

export default productsupplierSlice.reducer;