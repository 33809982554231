export const attributegroupAddProductFilterChoices = [
  // { 'id': 'discount', 'name': 'discount' },
  // { 'id': 'emballage', 'name': 'emballage' },
  // { 'id': 'paidin', 'name': 'paidin' },
  // { 'id': 'paidout', 'name': 'paidout' },
  { 'id': 'article', 'name': 'article' },
  // { 'id': 'service', 'name': 'service' },
  // { 'id': 'deposit', 'name': 'deposit' },
  // { 'id': 'noturnover', 'name': 'noturnover' },
  { 'id': 'addon', 'name': 'addon' },
  // { 'id': 'addoncombination', 'name': 'addoncombination' },
  // { 'id': 'subscription', 'name': 'subscription' }
]
