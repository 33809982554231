import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getRole, getToken } from "../../redux/dataSlices/tokenSlice";
import { getOrders, getOrdersHighlights, setOrders, setOrdersHighLights, getOrderPagesize, getOrderPagination } from "../../redux/dataSlices/orderSlice";
import { setStartDate, setEndDate, getStartDate, getEndDate } from "../../redux/dataSlices/reportingDateSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import axios from "axios";
import BasicCard from "../../components/card"
import Datepicker from "../../components/datepicker";
import moment from "moment"
import WeatherWidget from "../../components/WeatherWidget";
import OrderTable from "../../components/orderTable";
import HighlightsCard from "../../components/HighlightsCard";
import HighlightTable from "../../components/highlightTable";
import SearchAndMultiFilterSingleSelect from "../../components/searchAndMultiFilterSingleSelectField";
import OrderHighLightCalculation from "./highlightcalculation"
import HandleError from "../../helpers/handleError";
import PostOrderSearch from "../../actablueAPI/invoicepayments/PostOrderSearch";
import TableButton from "../../components/tableButton";
import { filterOriginChoices, filterStatusChoices } from "../../selectfieldchoices/orderselect.mjs";
import GetTransactionOrderById from "../../actablueAPI/invoicepayments/GetTransactionOrderByID";
import { getHashEmployeesAll, setEmployeesAll } from "../../redux/dataSlices/employeeSlice";
import GetEmployees from "../../actablueAPI/employee/GetEmployees";

const Orders = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const orders = useSelector(getOrders);
  const ordershighlights = useSelector(getOrdersHighlights);
  const pagination = useSelector(getOrderPagination);
  const pageSize = useSelector(getOrderPagesize)
  const startDate = useSelector(getStartDate)
  const endDate = useSelector(getEndDate)
  const role = useSelector(getRole)
  const hashEmployees = useSelector(getHashEmployeesAll)
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(false)
  const [filtered, setFiltered] = useState()
  const [search, setSearch] = useState()
  const [filterOrigin, setFilterOrigin] = useState()
  const [filterStatus, setFilterStatus] = useState()
  const [progressEnabled, setProgressEnabled] = useState(false);
  const [total, setTotal] = useState(0);
  const [current, setCurrent] = useState(0);

  const onSearchChange = (event) => {
    let value = undefined
    if (event) {
      value = event.target.value
    }
    setSearch(value)
    SearchAndFilter(filterOrigin, filterStatus, value, orders)    
  }

  const onFilterOriginChange = (event) => {
    let value = undefined
    if (event) {
      value = event.value
    }
    setFilterOrigin(value)
    SearchAndFilter(value, filterStatus, search, orders)
  }

  const onFilterStatusChange = (event) => {
    let value = undefined
    if (event) {
      value = event.value
    }
    setFilterStatus(value)
    SearchAndFilter(filterOrigin, value, search, orders)
  }

  const onRefreshClick = () => {
    setRefresh(!refresh)
  }

  function SearchAndFilter(origin, status, search, orders) {
    if (!origin && !status && !search && !orders) {return setFiltered()}

    if (orders) {
      let filtered = orders

      if (origin && filtered) {filtered = filtered.filter(order => order.origin === origin.toUpperCase())}
      if (status && filtered) {filtered = filtered.filter(order => order.status === status.toUpperCase())}
      if (search && filtered) {filtered = filtered.filter(order => JSON.stringify(order).toLowerCase().includes(search.toLowerCase()))}
      
      return setFiltered(filtered)
    }
  }

  const refreshButton = {
    tag: "Refresh",
    value: 'refresh',
    onClick: onRefreshClick,
    className: 'add-button',
    disabled: progressEnabled
  }

  const onDatePickerChange = (start, end) => {
    dispatch(setStartDate(start.unix()))
    dispatch(setEndDate(end.unix()))
  }

  const datePicker = {
    startDate: moment.unix(startDate),
    endDate: moment.unix(endDate),
    locale: {
      format: "DD/MM/YYYY",
    },
    alwaysShowCalendars: true,
    showDropdowns: true,
    opens: 'right',
    linkedCalendars: false,
    autoApply: true,
    singleDatePicker: false,
    className: 'reporting-date-picker',
    disabled: progressEnabled,
    onChange: onDatePickerChange
  }

  const highlightCard = {
    size: 6,
    title: "Highlights",
    data: <HighlightTable {...ordershighlights} />,
  };

  const orderCard = {
    size: 12,
    title: 'Orders',
    subtitle: <><Datepicker {...datePicker} /><TableButton {...refreshButton} /></>,
    data: <OrderTable orders={filtered || orders} employees={hashEmployees}/>,
    loadingData: {
      total: total,
      current: current,
      enabled: progressEnabled
    }
  };

  const weatherWidgetCard = {
    size: 6
  };

  const searchAndFilter = {
    search: {
      value: search,
      onChange: onSearchChange,
    },
    filters: [
      {
        label: 'Origin',
        options: filterOriginChoices,
        onChange: onFilterOriginChange,
        clearable: Boolean(true),
        searchable: Boolean(true)
      },
      {
        label: 'Status',
        options: filterStatusChoices,
        onChange: onFilterStatusChange,
        clearable: Boolean(true),
        searchable: Boolean(true)
      }
    ]
  }

  useEffect(() => {
    dispatch(setNavBarTitle('Orders'))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (location_id) {
      dispatch(setLoading(true))
      // start progressmodal
      setProgressEnabled(true);
      getOrdersFromApi()
    }
    if (!location_id) {
      dispatch(setOrders())
      setFiltered()
    }
    // eslint-disable-next-line
  }, [admin_id, location_id, pagination, pageSize, startDate, endDate, refresh]);

  async function getOrdersFromApi() {
    // check how many days and calculate how many hours 
    let hours = (moment.unix(endDate).diff(moment.unix(startDate), 'days')+1)*24
    // get transactions by hour
    let tempOrders = []
    for (var hour=0; hour<hours; hour++) {
      setTotal(hours);
      setCurrent(hour);
      const searchObject = {
        date_range: {
          from: moment.unix(startDate).add(hour, 'hour').format(`YYYY-MM-DDTHH:mm:ss.SSSZ`),
          to: moment.unix(startDate).add(hour+1, 'hour').format(`YYYY-MM-DDTHH:mm:ss.SSSZ`),
          field: "creation"
        },
        limit: 999
      }
      await PostOrderSearch({ token: token, admin_id: admin_id, location_id: location_id, searchObject: searchObject })
      // eslint-disable-next-line no-loop-func
      .then(response => {
        tempOrders = [...tempOrders, ...response.data]
      })
      .catch(error => {
        HandleError(error)
      })
    }

    if (role !== 'super' && role !== 'admin') {
      SearchAndFilter(filterOrigin, filterStatus, search, tempOrders)
      dispatch(setOrders(tempOrders))
      dispatch(setOrdersHighLights(OrderHighLightCalculation(tempOrders)))
      dispatch(setLoading(false))
      setProgressEnabled(false);
    }

    if (role === 'super' || role === 'admin') {
      let newOrders = []
      let orderCount = tempOrders.length
      let orderCounter  = 0
      for (const order of tempOrders) {
        setTotal(orderCount);
        setCurrent(orderCounter);
        orderCounter = orderCounter + 1
        await GetTransactionOrderById({token:token, admin_id:admin_id, id:order.id})
        // eslint-disable-next-line no-loop-func
        .then(transaction => {
          let newOrder = JSON.parse(JSON.stringify(order))
          if (transaction.length > 0) {
            newOrder.transactions = transaction
          }
          newOrders = [...newOrders, newOrder]
        })
        // eslint-disable-next-line no-loop-func
        .catch(() => {
          newOrders = [...newOrders, order]
        })
      }
      SearchAndFilter(filterOrigin, filterStatus, search, newOrders)
      dispatch(setOrders(newOrders))
      dispatch(setOrdersHighLights(OrderHighLightCalculation(newOrders)))
    }

    axios.all([
      GetEmployees({token:token, admin_id:admin_id})
    ])
    .then(axios.spread((responseEmployees) => {
      dispatch(setEmployeesAll(responseEmployees.data))
      dispatch(setLoading(false))
      setProgressEnabled(false);
    }))
    .catch(error => {
      HandleError(error)
      dispatch(setLoading(false))
      setProgressEnabled(false);
    })
  }

  return (
    <>
      <script type="text/javascript" src="https://cdn.jsdelivr.net/jquery/latest/jquery.min.js"></script>
      <script type="text/javascript" src="https://cdn.jsdelivr.net/momentjs/latest/moment.min.js"></script>
      <script type="text/javascript" src="https://cdn.jsdelivr.net/npm/daterangepicker/daterangepicker.min.js"></script>
      <link rel="stylesheet" type="text/css" href="https://cdn.jsdelivr.net/npm/daterangepicker/daterangepicker.css" />
      <WeatherWidget {...weatherWidgetCard} />
      <HighlightsCard {...highlightCard} />
      <SearchAndMultiFilterSingleSelect {...searchAndFilter}/>
      <BasicCard {...orderCard} />
    </>
  )
};
export default Orders;
