import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getContacts, setContacts, getContactPagination, getContactPagesize } from "../../redux/dataSlices/contactSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import BasicCard from "../../components/card"
import ContactTable from "../../components/contactTable";
import HandleError from "../../helpers/handleError";
import GetRelationsContactListAll from "../../actablueAPI/relations/GetRelationsContactListAll";

const Contacts = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const contacts = useSelector(getContacts)
  const pagination = useSelector(getContactPagination);
  const pageSize = useSelector(getContactPagesize)
  const dispatch = useDispatch();
  const navTitle = "Contacts"

  const contactsCard = {
    size: 12,
    title: navTitle,
    data: <ContactTable {...contacts} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setLoading(true))
    GetRelationsContactListAll({token:token, admin_id:admin_id, page:pagination, size:pageSize})
      .then(response => {
        dispatch(setContacts(response.data))
        dispatch(setLoading(false))
      })
      .catch(error => {
        HandleError(error)
        dispatch(setLoading(false))
      })
    // eslint-disable-next-line
  }, [, admin_id, location_id, pagination, pageSize]);

  return (
    <>
      <BasicCard {...contactsCard} />
    </>
  )
};
export default Contacts;

