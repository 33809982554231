const CheckNewObjects = (object) => {

  if(object?.phonenumbers) {
    for(let phonenumber of object?.phonenumbers) {
      if(phonenumber?.new) {
        delete phonenumber.new
        delete phonenumber.id
      }
    }
  }

  if(object?.phone_numbers) {
    for(let phonenumber of object?.phone_numbers) {
      if(phonenumber?.new) {
        delete phonenumber.new
        delete phonenumber.id
      }
    }
  }

  if(object?.addresses) {
    for(let address of object?.addresses) {
      if(address?.new) {
        delete address.new
        delete address.id
      }
    }
  }

  if(object?.emails) {
    for(let email of object?.emails) {
      if(email?.new) {
        delete email.new
        delete email.id
      }
    }
  }

};

export default CheckNewObjects
