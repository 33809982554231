import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import TableButton from './tableButton';
import ReservationButtons from "../portal/reservations/reservationbuttons";
import ReservationModal from "../components/reservationModal";
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { getReservationLocation } from '../redux/dataSlices/reservationSlice';

function ReservationTable(props) {
  const reservation_location = useSelector(getReservationLocation)
  const buttons = ReservationButtons.call()
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const { t: translate } = useTranslation();

  let actablue = true
  if (window.location.href.includes('humbee')) { actablue = false }

  let rows = []
  if (props.reservations) {
    props.reservations.forEach(reservation => {
      const status = reservation.status;
      const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview, { id: reservation.id }) : false
      const accept = buttons.hasOwnProperty('accept') ? Object.assign({}, buttons.accept, { id: reservation.id }) : false
      const cancel = buttons.hasOwnProperty('cancel') ? Object.assign({}, buttons.cancel, { id: reservation.id }) : false
      const pending = buttons.hasOwnProperty('pending') ? Object.assign({}, buttons.pending, { id: reservation.id }) : false
      const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit, { id: reservation.id }) : false
      rows.push(
        <tr key={reservation.id}>
          <td>{reservation.reservation_name}</td>
          <td>{reservation.reservation_phonenumber}</td>
          <td>{reservation.reservation_email}</td>
          {/* <td>{moment(reservation.reservation_timestamp).utc().format('DD/MM/YYYY HH:mm:ss')}</td> */}
          {/* <td>{moment(reservation.reservation_timestamp).local().format('DD/MM/YYYY HH:mm:ss')}</td> */}
          <td>{moment(reservation.reservation_timestamp).tz(reservation_location?.timezone || 'Europe/Amsterdam').format('DD/MM/YYYY HH:mm:ss')}</td>
          <td>{reservation.number_of_people}</td>
          <td>{reservation.reservation_objects ? reservation.reservation_objects.map((reservation_object) => { return (<>{reservation_object.name}<br></br></>) }) : 'None'}</td>
          <td>{reservation.remark}</td>
          <td>{reservation.status}</td>
          {actablue &&
            <td>
              <Button size="sm" type='button' onClick={() => { modalview.onClick(reservation) }} id={reservation.id}>{translate(modalview.tag)}</Button>
              {accept && status !== 'ACCEPTED' && <TableButton {...accept} />}
              {pending && status !== 'PENDING' && <TableButton {...pending} />}
              {cancel && status !== 'CANCELLED' && <TableButton {...cancel} />}
              {edit && <TableButton {...edit} />}
            </td>
          }
        </tr>
      )
    });    
  }

  return (
    <>
      <Table width='100%'>
        <thead>
          <tr>
            <th>
              {translate('Name')}
            </th>
            <th width='10%'>
              {translate('Phone')}
            </th>
            <th width='10%'>
              {translate('Email')}
            </th>
            <th>
              {translate('Time')}
            </th>
            <th>
              {translate('People')}
            </th>
            <th>
              {translate('Table')}
            </th>
            <th width='15%'>
              {translate('Remark')}
            </th>
            <th>
              {translate('Status')}
            </th>
            {actablue &&
              <th width='25%'>
                {translate('Actions')}
              </th>
            }
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
        <ReservationModal modal={modalview.modal} />
      </Table>
    </>
  );
}

export default ReservationTable;