import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { useNavigate } from "react-router-dom";
import { DrawerFormFields } from "./drawerFormFields";
import BasicCard from "../../components/card"
import HandleOnChange from "../../helpers/handleOnChange";
import DrawerForm from "../../components/drawerForm";
import { countAsChoices } from "../../selectfieldchoices/cashcountselect.mjs";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import PostCashCountDrawer from "../../actablueAPI/cashcount/PostCashCountDrawer";

const DrawerAdd = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [drawer, setDrawer] = useState({ admin_id: admin_id, location_id: location_id })

  const onChange = (event) => {
    let newDrawer = HandleOnChange({ event: event, object: drawer })
    setDrawer(newDrawer)
  }

  const onChangeCountAs = (event) => {
    let newDrawer = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: drawer, selectfield: 'count_as' })
    setDrawer(newDrawer)
  }

  const onSubmit = (event) => {

    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    PostCashCountDrawer({token:token, data:drawer})
      .then(response => {
        navigate("/cashcount");
      })
  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    fields: DrawerFormFields(),
    field: {
      onChange: onChange
    },
    data: drawer,
    count_as: {
      name: 'count_as',
      options: countAsChoices,
      selected: [],
      onChange: onChangeCountAs,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
  };

  const addCard = {
    size: 12,
    title: "Drawer add",
    data: <DrawerForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle("Drawer add"))
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...addCard} />
    </>
  )

};



export default DrawerAdd;

