import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getContactId } from "../../redux/dataSlices/contactSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card"
import ContactForm from "../../components/contactForm";
import { ContactFormFields } from "./contactFormFields";
import HandleOnChange from "../../helpers/handleOnChange";
import HandleOnChangeMltiObjectField from "../../helpers/handleOnChangeMultiObjectFields";
import HandleOnChangeCheckbox from "../../helpers/handleOnChangeCheckbox";
import AddObject from "../../helpers/addObject";
import RemoveObject from "../../helpers/removeObject";
import { phoneNumberTypeChoices, emailTypeChoices } from "../../selectfieldchoices/phonenumberselect.mjs";
import HandleOnChangeMultiObjectSingleSelectFieldWithValue from "../../helpers/handleOnChangeMultiObjectSingleSelectFieldWithValue";
import PostRelationsContact from "../../actablueAPI/relations/PostRelationsContact";
import GetRelationsContactById from "../../actablueAPI/relations/GetRelationsContactById";
import CheckNewObjects from "../../helpers/checkNewObjects";

const ContactEdit = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const contact_id = useSelector(getContactId)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [contact, setContact] = useState({})
  const [checkedList, setCheckedList] = useState([])
  const [id, setId] = useState(0)

  // const onAddressChange = (event) => {
  // }

  const onChange = (event) => {
    let newContact = HandleOnChange({ event: event, object: contact })
    setContact(newContact)
  }
  const onChangeAdresses = (event) => {
    let newContact = HandleOnChangeMltiObjectField({ event: event, object: contact, field: 'addresses' })
    setContact(newContact)
  }
  const onChangePhonenumbers = (event) => {
    let newContact = HandleOnChangeMltiObjectField({ event: event, object: contact, field: 'phone_numbers' })
    setContact(newContact)
  }

  const onPhonenumbersTypeChange = (event) => {
    let newContact = HandleOnChangeMultiObjectSingleSelectFieldWithValue({ event: event, object: contact, selectfield: 'type', field: 'phone_numbers' })
    setContact(newContact)
  }

  const onChangeEmails = (event) => {
    let newContact = HandleOnChangeMltiObjectField({ event: event, object: contact, field: 'emails' })
    setContact(newContact)
  }
  
  const onEmailTypeChange = (event) => {
    let newContact = HandleOnChangeMultiObjectSingleSelectFieldWithValue({ event: event, object: contact, selectfield: 'type', field: 'emails' })
    setContact(newContact)
  }

  const addObject = (event) => {
    let newId = id + 1
    setId(newId)
    let newContact = AddObject({ event: event, object: contact, id: newId })
    setContact(newContact)
  }

  const onRemoveObjects = (event) => {
    let newContact = RemoveObject({ event: event, object: contact, checkedList: checkedList })
    setContact(newContact)
    setCheckedList([])
  }

  const onChangeCheckbox = (event) => {
    let newCheckedList = HandleOnChangeCheckbox({ event: event, object: checkedList })
    setCheckedList(newCheckedList)
  }

  const onChangeAllCheckbox = (event) => {
    // const checked = event.target?.checked
    // const id = event.target?.attributes?.id?.nodeValue
    // let newCheckedList = Object.assign([], checkedList)
    // if (checked) {newCheckedList.push(id)}
    // if (!checked) {newCheckedList.pop(id)}
    // console.log('newCheckedList', newCheckedList)
    // setCheckedList(newCheckedList)
  }

  const onSubmit = () => {
    CheckNewObjects(contact)
    PostRelationsContact({token:token, admin_id:admin_id, data:contact})
      .then(response => {
        navigate("/contacts");
      })

  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    fields: ContactFormFields(),
    field: {
      onChange: onChange
    },
    data: contact,
    addresses: {
      onChange: onChangeAdresses,
      add: {
        title: '+',
        type: 'button',
        name: 'addresses',
        onClick: addObject
      }
    },
    phone_numbers: {
      onChange: onChangePhonenumbers,
      add: {
        title: 'Add',
        type: 'button',
        name: 'phone_numbers',
        onClick: addObject
      },
      remove: {
        title: 'Remove',
        type: 'button',
        name: 'phone_numbers',
        onClick: onRemoveObjects
      },
      check: {
        onChange: onChangeCheckbox,
        onChangeAll: onChangeAllCheckbox
      },
      selected: checkedList,
      type: {
        label: 'type',
        options: phoneNumberTypeChoices,
        // selected: {},
        onChange: onPhonenumbersTypeChange,
        clearable: Boolean(false),
        searchable: Boolean(true)
      }
    },
    emails: {
      onChange: onChangeEmails,
      add: {
        title: 'Add',
        type: 'button',
        name: 'emails',
        onClick: addObject
      },
      remove: {
        title: 'Remove',
        type: 'button',
        name: 'emails',
        onClick: onRemoveObjects
      },
      check: {
        onChange: onChangeCheckbox,
        onChangeAll: onChangeAllCheckbox
      },
      selected: checkedList,
      type: {
        label: 'type',
        options: emailTypeChoices,
        // selected: {},
        onChange: onEmailTypeChange,
        clearable: Boolean(false),
        searchable: Boolean(true)
      }
    }
  };

  const editCard = {
    size: 12,
    title: "Contact edit",
    data: <ContactForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle("Contact edit"))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setLoading(true))
    GetRelationsContactById({token:token, admin_id:admin_id, id:contact_id})
      .then(response => {
        setContact(response.data)
        dispatch(setLoading(false))
      })
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...editCard} />
    </>
  )

};
export default ContactEdit;

