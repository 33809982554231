import { Col, Form } from 'react-bootstrap';
import MultiSelectField from '../components/multiselectField';
import MultiSelectCreateField from '../components/multiselectcreateField';
import SingleSelectField from '../components/singleselectField';
import SingleSelectCreateField from '../components/singleselectcreateField';
import { useTranslation } from 'react-i18next';

export default function CreateFieldTemplate(field, props, pref) {
  const { t: translate } = useTranslation();

  let checked = undefined
  if (!pref && props.data && (props.data[field.id] === true || props.data[field.id] === 'true')) {
    checked = true
  }
  if (pref && props.data && props.data[pref] && (props.data[pref][field.id] === true || props.data[pref][field.id] === 'true')) {
    checked = true
  }

  let value = undefined
  if (!pref && props.data && props.data[field.id]) {
    value = props.data[field.id]
  }
  if (pref && props.data && props.data[pref] && props.data[pref][field.id]) {
    value = props.data[pref][field.id]
  }

  let name = field.name
  let placeholder = field.placeholder

  if (field.type !== 'hidden') {
    name = translate(field.name)
    placeholder = translate(field.placeholder ?? field.name)
  }

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur()
  
    // Prevent the page/container scrolling
    e.stopPropagation()
  
    // Refocus immediately, on the next tick (after the current function is done)
    setTimeout(() => {
      e.target.focus()
    }, 0)
  }

  switch (field.type) {
    case 'hidden':
      return (
        <Form.Control prefix={field.prefix}
          type={field.type}
          name={name}
          placeholder={placeholder ?? name}
          value={value}
          onChange={props[field?.id]?.onChange ?? props.field.onChange}
          readOnly={field.readonly}
          step={field.step}
          disabled={field.disabled}
          required={field.required ?? true}
          maxLength={field.maxlength}
        />
      )
    case 'checkbox':
      return (
        <Form.Group as={Col} key={name} controlId={field.id} md={field.md ?? 3} className='mb-3'>
          <Form.Check
            label={
              <>
                {name}
                {field.required && <p className='form-required-text'>*</p>}
              </>
            }
            prefix={field.prefix}
            type='checkbox'
            name={name}
            placeholder={placeholder ?? name}
            value={Boolean(value)}
            onChange={props[field?.id]?.onChange ?? props.field.onChange}
            id={field.id}
            disabled={field.disabled}
            checked={Boolean(checked)}
            isInvalid={field?.invalid}
          />
        </Form.Group>
      )
    case 'switch':
      return (
        <Form.Group as={Col} key={name} controlId={field.id} md={field.md ?? 3} className='mb-3'>
          <Form.Label>{name} {field.required && <p className='form-required-text'>*</p>}</Form.Label>
          <input className="form-check-input"
            prefix={field.prefix}
            type='checkbox'
            role='switch'
            name={name}
            placeholder={placeholder ?? name}
            value={value}
            onChange={props[field?.id]?.onChange ?? props.field.onChange}
            id={field.id}
            disabled={field.disabled}
            checked={checked}
            isInvalid={field?.invalid}>
          </input>
        </Form.Group>
      )
    case 'select':
      return (
        <Form.Group as={Col} key={name} controlId={field.id} md={field.md ?? 3} className='mb-3'>
          <Form.Label>{name} {field.required && <p className='form-required-text'>*</p>}</Form.Label>
          <SingleSelectField {...props[field.formdatafield]} prefix={field.prefix} name={name} required={field.required} id={field.id} className={field?.invalid && `is-invalid`} />
          {field?.invalid &&
            <div className="invalid-feedback">{field?.invalidFeedback ?? 'Please fill in this field.'}</div>
          }
        </Form.Group>
      )
    case 'multiselect':
      return (
        <Form.Group as={Col} key={name} controlId={field.id} md={field.md ?? 3} className='mb-3'>
          <Form.Label>{name} {field.required && <p className='form-required-text'>*</p>}</Form.Label>
          <MultiSelectField {...props[field.formdatafield]} prefix={field.prefix} required={field.required} id={field.id} className={field?.invalid && `is-invalid`} />
          {field?.invalid &&
            <div className="invalid-feedback">{field?.invalidFeedback ?? 'Please fill in this field.'}</div>
          }
        </Form.Group>
      )
    case 'multi+create':
      return (
        <Form.Group as={Col} key={name} controlId={field.id} md={field.md ?? 3} className='mb-3'>
          <Form.Label>{name} {field.required && <p className='form-required-text'>*</p>}</Form.Label>
          <MultiSelectCreateField {...props[field.formdatafield]} prefix={field.prefix} required={field.required} id={field.id} className={field?.invalid && `is-invalid`} />
          {field?.invalid &&
            <div className="invalid-feedback">{field?.invalidFeedback ?? 'Please fill in this field.'}</div>
          }
        </Form.Group>
      )
    case 'select+create':
      return (
        <Form.Group as={Col} key={name} controlId={field.id} md={field.md ?? 3} className='mb-3'>
          <Form.Label>{name} {field.required && <p className='form-required-text'>*</p>}</Form.Label>
          <SingleSelectCreateField {...props[field.formdatafield]} prefix={field.prefix} required={field.required} id={field.id} className={field?.invalid && `is-invalid`} />
          {field?.invalid &&
            <div className="invalid-feedback">{field?.invalidFeedback ?? 'Please fill in this field.'}</div>
          }
        </Form.Group>
      )
    case 'color':
      return (
        <Form.Group as={Col} key={name} controlId={field.id} md={field.md ?? 3} className='mb-3'>
          <Form.Label>{name} {field.required && <p className='form-required-text'>*</p>}</Form.Label>
          <Form.Control prefix={field.prefix}
            type={field.type}
            name={name}
            placeholder={placeholder ?? name}
            value={value ?? '#000000'}
            onChange={props[field?.id]?.onChange ?? props.field.onChange}
            readOnly={field.readonly}
            step={field.step}
            disabled={field.disabled}
            required={field.required ?? true}
            maxLength={field.maxlength}
            isInvalid={field?.invalid}
          />
          <Form.Control.Feedback type="invalid">
            {field?.invalidFeedback ?? 'Please fill in this field.'}
          </Form.Control.Feedback>
        </Form.Group>
      )
    case 'number':
      return (
        <Form.Group as={Col} key={name} controlId={field.id} md={field.md ?? 3} className='mb-3'>
          <Form.Label>{name} {field.required && <p className='form-required-text'>*</p>}</Form.Label>
          <Form.Control prefix={field.prefix}
            type={field.type}
            name={name}
            placeholder={placeholder ?? name}
            value={value}
            onChange={props[field?.id]?.onChange ?? props.field.onChange}
            readOnly={field.readonly}
            step={field.step}
            disabled={field.disabled}
            required={field.required ?? true}
            maxLength={field.maxlength}
            min={field.min}
            max={field.max}
            isInvalid={field?.invalid}
            onWheel={field.onWheel || numberInputOnWheelPreventChange}
          />
          <Form.Control.Feedback type="invalid">
            {field?.invalidFeedback ?? 'Please fill in this field.'}
          </Form.Control.Feedback>
        </Form.Group>
      )
    case 'object':
      return
    default:
      return (
        <Form.Group as={Col} key={name} controlId={field.id} md={field.md ?? 3} className='mb-3'>
          <Form.Label>{name} {field.required && <p className='form-required-text'>*</p>}</Form.Label>
          <Form.Control prefix={field.prefix}
            type={field.type}
            name={name}
            placeholder={placeholder ?? name}
            value={value ?? ""}
            onChange={props[field?.id]?.onChange ?? props.field.onChange}
            readOnly={field.readonly}
            step={field.step}
            disabled={field.disabled}
            required={field.required ?? true}
            maxLength={field.maxlength}
            isInvalid={field?.invalid}
          />
          <Form.Control.Feedback type="invalid">
            {field?.invalidFeedback ?? 'Please fill in this field.'}
          </Form.Control.Feedback>
        </Form.Group>
      )
  }
}