import { Line } from 'react-chartjs-2';
import { graphColor } from '../defaultcolorvalues/graphColors.mjs';

export const statusReportGraphSingleDay = (report) => {
  let newCash = []
  let newExchange = []
  let newNettoCash = []
  let newEft = []
  let newOnAccount = []
  let newTotal = []

  if (report?.hourly) {
    for (let hour of Object.values(report.hourly)) {
      newCash.push(hour.cash)
      newExchange.push(hour.exchange)
      newNettoCash.push(hour.cash - hour.exchange)
      newEft.push(hour.eft)
      newOnAccount.push(hour.on_account)
      newTotal.push(hour.total)
    }
  }

  if (Object.keys(report?.hourly).length === 0) {
    newCash = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    newExchange = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    newNettoCash = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    newEft = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    newOnAccount = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    newTotal = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
  }

  // const graphLabels = ['0:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00']
  const graphLabels = ['01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '24:00']
  const graphDatasets = [
        {
          label: 'Netto Cash',
          data: newNettoCash,
          borderColor: 'rgb(22, 129, 24)',
          backgroundColor: 'rgba(22, 129, 24, 0.5)',
        },
        {
          label: 'Eft',
          data: newEft,
          borderColor: 'rgb(2, 149, 225)',
          backgroundColor: 'rgba(2, 149, 225, 0.5)',
        },
        {
          label: 'On Account',
          data: newOnAccount,
          borderColor: 'rgb(255, 0, 0)',
          backgroundColor: 'rgba(255, 0, 0, 0.5)',
        },
    {
      label: 'Total',
      data: newTotal,
      borderColor: `rgba(${graphColor.r}, ${graphColor.g}, ${graphColor.b}, 1)`,
      backgroundColor: `rgba(${graphColor.r}, ${graphColor.g}, ${graphColor.b}, 0.5)`,
    },
  ]

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
      }
    },
  };

  const data = {
    labels: graphLabels,
    datasets: graphDatasets
  }

  let graphCardData = <div className='report-chart'><Line options={options} data={data} /></div>

  return graphCardData
};