import { useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { getProgramModalShow, getProgramPageSize, setProgram, setProgramModalShow, setProgramPageSize, setProgramPagination } from "../../redux/dataSlices/loyaltySlice";
import { getScopes } from "../../redux/dataSlices/scopesSlice";

import { pagesize } from "../../selectfieldchoices/pagesize.mjs";

export default function ProgramButtons() {
  const modalShow = useSelector(getProgramModalShow)
  const pageSize = useSelector(getProgramPageSize)
  const scopes = useSelector(getScopes)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function onViewClick(program) {
    dispatch(setProgram(program));
    dispatch(setProgramModalShow(true));
  };

  const onViewHide = () => {
    dispatch(setProgramModalShow(false));
  };

  const onClickEdit = (program) => {
    dispatch(setProgram(program));
    navigate("/program/edit");
  };

  const onPaginationClick = (event) => {
    dispatch(setProgramPagination(parseInt(event.target.value)))
  };

  const onPageSizeChange = (event) => {
    dispatch(setProgramPageSize(event.value))
  }

  const onClickAdd = () => {
    navigate("/program/add");
  };

  let buttons = {
    modalview: {
      tag: "View",
      onClick: onViewClick,
      modal: {
        show: modalShow,
        onHide: onViewHide
      }
    },
    pagination: {
      maxbuttons: 5, //uneven number
      onClick: onPaginationClick
    },
    pagesize: {
      className: 'navbar-select',
      label: "page size selector",
      placeholder: "No selecetion",
      pagesize: pageSize,
      selected: { 'id': pageSize, 'name': pageSize },
      number_of_elements: 0,
      options: pagesize,
      onChange: onPageSizeChange
    },

  }

  // add buttons based on scopes
  // scopeOptions = ['read', 'create', 'update', 'delete'] (use index: 0:read, 1:create, 2:update, 3:delete)
  // buttonOptions = {'get':0, 'new':1, 'edit':2, 'add':2, 'del':3, 'swap':2, 'move':2, 'import':1, 'create': 1, 'export': 1, 'print': 0}
  // check level of scope, add buttons according to the level.
  if (scopes.loyalty >= 1) {
    buttons.add = {
      tag: "Add",
      value: 'add',
      onClick: onClickAdd,
      className: 'add-button'
    }
  }
  if (scopes.loyalty >= 2) {
    buttons.edit = {
      tag: "Edit",
      value: 'edit',
      onClick: onClickEdit
    }
  }

  return buttons
}

