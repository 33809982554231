import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getLocationsAllAdmins, getHashLocationsAll } from "../../redux/dataSlices/locationSlice";
import { getAdministrationsAll, getHashAdministrationsAll } from "../../redux/dataSlices/administrationSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import BasicCard from "../../components/card"
import StockMutationForm from "../../components/stockMutationForm";
import StockMutationFormFields from "./stockMutationFormFields";
import HandleOnChange from "../../helpers/handleOnChange";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import PostWarehouseStockMutation from "../../actablueAPI/warehouse/PostWarehouseStockMutation";

const StockAdd = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const admins_all = useSelector(getAdministrationsAll)
  const hash_admins_all = useSelector(getHashAdministrationsAll)
  const locations_all_admins = useSelector(getLocationsAllAdmins)
  const hash_locations_all = useSelector(getHashLocationsAll)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [stockMutation, setStockMutation] = useState({ administration_id: admin_id, location_id: location_id })
  const navTitle = "Stock add"

  const onChange = (event) => {
    let newStockMutation = HandleOnChange({ event: event, object: stockMutation })
    setStockMutation(newStockMutation)
  }

  const onLocationIdChange = (event) => {
    let newStockMutation = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: stockMutation, selectfield: 'location_id' })
    setStockMutation(newStockMutation)
  }

  const onAdminIdChange = (event) => {
    let newStockMutation = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: stockMutation, selectfield: 'administration_id' })
    delete newStockMutation.location_id
    dispatch(setStockMutation(newStockMutation))
  }

  const onSubmit = () => {
    PostWarehouseStockMutation({token:token, data:stockMutation})
    .then(putResponse => {
      navigate("/stock");
    })
  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    fields: StockMutationFormFields(),
    field: {
      onChange: onChange
    },
    data: stockMutation,
    location_id: {
      label: 'location_id',
      // eslint-disable-next-line
      options: locations_all_admins.filter((item) => { if (item.administration_id === stockMutation.administration_id) { return true } }),
      hash: hash_locations_all,
      selected: {},
      onChange: onLocationIdChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    administration_id: {
      label: 'administration_id',
      options: admins_all,
      hash: hash_admins_all,
      selected: {},
      onChange: onAdminIdChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    product_id: {
      label: 'product_id',
      options: [],
      // hash: hash_admins_all,
      selected: {},
      onChange: onAdminIdChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
  };

  const card = {
    size: 12,
    title: navTitle,
    data: <StockMutationForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...card} />
    </>
  );
};
export default StockAdd;
