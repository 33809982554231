import { Button, Table } from 'react-bootstrap'
import TableButton from './tableButton';
import LocationButtons from '../portal/locations/locationbuttons';
import LocationModal from './locationModal';
import PaginationComponent from './pagination';
import { useTranslation } from 'react-i18next';

import './css/productTable.css';

export default function LocationTable(props) {
  const locations = props?.locations;
  const hash_reservation_locations = props?.hash_reservation_locations_all;
  const hash_planning_locations = props?.hash_planning_locations_all;

  const buttons = LocationButtons.call()
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const add = buttons.hasOwnProperty('add') ? Object.assign({}, buttons.add) : false
  const { t: translate } = useTranslation();

  let pagination = buttons.pagination
  let pagesize = buttons.pagesize

  pagesize['number_of_elements'] = locations?.number_of_elements

  let rows = []
  for (let index in locations) {
    const location = locations[index]
    const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit, { id: location.id }) : false
    const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview, { id: location.id }) : false
    rows.push(
      <tr className='product-row' key={location.id}>
        <td>
          {location?.img_urls && <img className="product-thumbnail" src={location?.img_urls} alt='' width='192'></img>}
          {hash_reservation_locations[location.id] && hash_reservation_locations[location.id]?.img_urls &&
            <img className="product-thumbnail" src={hash_reservation_locations[location.id] && hash_reservation_locations[location.id]?.img_urls} alt='' width='192'></img>
          }
        </td>
        <td>{location.name}</td>
        <td>
          {location.hasOwnProperty('address') ? `${location.address.street} ${location.address.street_number}` : ''} <br></br>
          {location.hasOwnProperty('address') ? `${location.address.proverence}, ${location.address.zip_code}` : ''} <br></br>
          {location.hasOwnProperty('address') ? `${location.address.country}` : ''}
        </td>
        <td>
          <Button size="sm" type='button' onClick={() => { modalview.onClick(location, hash_reservation_locations[location.id], hash_planning_locations[location.id]) }} id={location.id}>{translate(modalview.tag)}</Button>
          {edit && <TableButton {...edit} />}
        </td>
      </tr>
    )
  }

  const pages = locations?.total_pages
  const page = (locations?.number + 1)
  return (
    <>
      {add && <TableButton {...add} />}
      {<PaginationComponent pagination={pagination} pagesize={pagesize} pages={pages} page={page} />}
      <Table hover width='100%'>
        <thead>
          <tr key='header'>
            <th></th>
            <th>{translate('Name')}</th>
            <th>
              {translate('Place')}
            </th>
            <th>
              {translate('Actions')}
            </th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
        <LocationModal modal={modalview.modal} />
      </Table>
    </>
  );
}