import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card"
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import HandleOnChange from "../../helpers/handleOnChange";
import { registrationEditFormFields } from "./registrationFormFields.mjs";
import RegistrationForm from "../../components/registrationForm";
import { getLocationsAllAdmins, getHashLocationsAll } from "../../redux/dataSlices/locationSlice";
import { getAdministrationsAll, getHashAdministrationsAll } from "../../redux/dataSlices/administrationSlice";
import { getHashUsersAll, getUsersAll } from "../../redux/dataSlices/userSlice";
import { getHashRelationsAll, getRelationsAll } from "../../redux/dataSlices/relationSlice";
import HandleOnChangeMultiSelectFieldWithObjects from "../../helpers/handleOnChangeMultiSelectFieldWithObjects";
import { getHashSubscriptionsAll, getSubscriptionsAll } from "../../redux/dataSlices/subscriptionSlice";
import PostRegistration from "../../actablueAPI/registrations/PostRegistration";

const RegistrationEdit = () => {
  const token = useSelector(getToken);
  const admins_all = useSelector(getAdministrationsAll)
  const hash_admins_all = useSelector(getHashAdministrationsAll)
  const locations_all_admins = useSelector(getLocationsAllAdmins)
  const hash_locations_all = useSelector(getHashLocationsAll)
  const users_all = useSelector(getUsersAll)
  const hash_users_all = useSelector(getHashUsersAll)
  const relations_all = useSelector(getRelationsAll)
  const hash_relations_all = useSelector(getHashRelationsAll)
  const subscriptions_all = useSelector(getSubscriptionsAll)
  const hash_subscriptions_all = useSelector(getHashSubscriptionsAll)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [registration, setRegistration] = useState({})

  const onChange = (event) => {
    let newRegistration = HandleOnChange({ event: event, object: registration })
    setRegistration(newRegistration)
  }

  const onLocationIdChange = (event) => {
    let newRegistration = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: registration, selectfield: 'location_id' })
    setRegistration(newRegistration)
  }

  const onAdminIdChange = (event) => {
    let newRegistration = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: registration, selectfield: 'administration_id' })
    delete newRegistration.location_id
    setRegistration(newRegistration)
  }

  const onUserIdChange = (event) => {
    let newRegistration = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: registration, selectfield: 'user_id' })
    setRegistration(newRegistration)
  }

  const onResellerIdChange = (event) => {
    let newRegistration = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: registration, selectfield: 'reseller_id' })
    setRegistration(newRegistration)
  }

  const onRelationIdChange = (event) => {
    let newRegistration = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: registration, selectfield: 'relation_id' })
    setRegistration(newRegistration)
  }

  const onSubscriptionsChange = (event) => {
    let newRegistration = HandleOnChangeMultiSelectFieldWithObjects({ event: event, object: registration, selectfield: 'subscriptions', hash: hash_subscriptions_all })
    setRegistration(newRegistration)
  };

  const onSubmit = () => {
    // rebuild 
    // api (put registration) accepts list of id's not objects
    let newRegistration = JSON.parse(JSON.stringify(registration))
    newRegistration.subscriptions = []
    newRegistration.subscription_settings = []
    for (const subscription of registration.subscriptions) {
      newRegistration.subscriptions.push(subscription.id)
      // create subscriptionsettings
      // create start and end
      const start = new Date()
      let stop = new Date()
      stop.setMonth(stop.getMonth() + subscription.duration)
      let newSubscription = {
        subscription_id:subscription.id,
        administration_id: newRegistration.administration_id,
        location_id: newRegistration.location_id,
        name: subscription.name,
        start: start.toISOString(),
        stop: stop.toISOString(),
        interval: subscription.interval,
        grace_period: subscription.grace_period,
        cancel_period: subscription.cancel_period,
        duration: subscription.duration,
        prolongation: subscription.prolongation
      }
      newRegistration.subscription_settings.push(newSubscription)
    }

    PostRegistration({token:token, data:newRegistration})
    .then(response => {
      navigate("/registrations");
    })
  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    fields: registrationEditFormFields,
    field: {
      onChange: onChange
    },
    data: registration,
    location_id: {
      label: 'location_id',
      // eslint-disable-next-line
      options: locations_all_admins.filter((item) => { if (item.administration_id === registration.administration_id) { return true } }),
      hash: hash_locations_all,
      // selected: {},
      onChange: onLocationIdChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    administration_id: {
      label: 'administration_id',
      options: admins_all,
      hash: hash_admins_all,
      // selected: {},
      onChange: onAdminIdChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    user_id: {
      label: 'user_id',
      options: users_all,
      hash: hash_users_all,
      // selected: {},
      onChange: onUserIdChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    reseller_id: {
      label: 'reseller_id',
      options: users_all,
      hash: hash_users_all,
      // selected: {},
      onChange: onResellerIdChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    relation_id: {
      label: 'relation_id',
      options: relations_all,
      hash: hash_relations_all,
      // selected: {},
      onChange: onRelationIdChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    subscriptions: {
      name: 'subscriptions',
      options: subscriptions_all,
      // selected: new Set(),
      onChange: onSubscriptionsChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
  };

  const card = {
    size: 12,
    title: "Registration add",
    data: <RegistrationForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle("Registration add"))
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...card} />
    </>
  )

};
export default RegistrationEdit;

