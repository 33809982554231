import { Accordion, Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';

import PrinterTable from './printerTable';
import { settingsAcceptedCurrency } from '../selectfieldchoices/shelflabelselect.mjs';

import { useSelector } from "react-redux"
import CreateFieldTemplate from '../helpers/createFieldTemplate';
import { getScopes } from '../redux/dataSlices/scopesSlice';


function SettingsForm(props) {
  let formdata = props.formdata
  const token = props.token
  const admin = props.admin
  const location = props.location
  const printers = formdata.printers;
  const templates = formdata.templates;
  const scopes = useSelector(getScopes);
  const { t: translate } = useTranslation();

  if (formdata.data) {
    // eslint-disable-next-line
    formdata.master_product.selected = { 'value': formdata.data.master_product, 'label': formdata.data.master_product }
  } else {
    formdata.master_product.selected = { 'value': 'NONE', 'label': 'NONE' }
  }

  if (formdata.data) {
    // eslint-disable-next-line
    if (formdata.data.default_printer && printers && !printers.message) {
      const printer = printers.find(printer => {
        return printer.id === formdata.data.default_printer;
      });
      if (printer) formdata.default_printer.selected = { 'value': formdata.data.default_printer, 'label': printer?.name }
    }
    formdata.code_to_print.selected = { 'value': formdata.data.code_to_print, 'label': formdata.data.code_to_print }
    formdata.accepted_currency.selected = { 'value': formdata.data?.accepted_currency, 'label': settingsAcceptedCurrency.find(currency => currency.id === formdata.data?.accepted_currency)?.name }
  }

  if (formdata.hasOwnProperty('esl_default_template') && templates) {
    templates?.forEach(template => {
      if (formdata?.data?.esl_default_template === template.id) {
        formdata.esl_default_template.selected = { 'value': template.id, 'label': template.name }
      }
      if (template.esl_model !== 'other') {
        formdata.esl_default_template.options.push({ 'id': template.id, 'name': template.name })
      }
    });
  }

  if (formdata.hasOwnProperty('print_default_template') && templates) {
    templates?.forEach(template => {
      if (formdata?.data?.print_default_template === template.id) {
        formdata.print_default_template.selected = { 'value': template.id, 'label': template.name }
      }
      formdata.print_default_template.options.push({ 'id': template.id, 'name': template.name })
    });
  }

  if (formdata.hasOwnProperty('allowed_currency') && formdata?.currency?.allowed_currency?.length > 0) {
    formdata?.currency?.allowed_currency?.forEach(currency => {
      formdata.allowed_currency.selected.push({ 'value': currency, 'label': currency })
      formdata.allowed_currency.options.push({ 'id': currency, 'name': currency })
    });
  }

  if (formdata.km_default_printer && formdata.kmSettings) {
    if (!formdata.data.km_default_printer) formdata.km_default_printer.selected = null
    if (formdata.data.km_default_printer) {
      if (formdata.data.km_default_printer instanceof Object) {
        formdata.km_default_printer.selected = { 'value': formdata.data.km_default_printer?.id, 'label': formdata.data.km_default_printer?.name }
      } else {
        formdata.km_default_printer.selected = { 'value': formdata.data.km_default_printer, 'label': formdata.hash_kmPrinters[formdata.data.km_default_printer]?.name }
      }
    }
  }

  let formTabs = [];
  let defaultActiveTabs = ['info', 'shelflabels-printers']
  formdata.fields.forEach(field => {
    if (field.type === 'object') {

      let objectFields = []
      field.fields.forEach(embeddedfield => {
        objectFields.push(CreateFieldTemplate(embeddedfield, formdata, embeddedfield?.prefix))
      });

      formTabs.push(
        <Accordion.Item eventKey={field.name} className='mb-3 settings-accordion'>
          <Accordion.Header><h4>{translate(field.name)}</h4></Accordion.Header>
          <Accordion.Body>
            <Row key={field.name} className='pt-3 mx-1'>
              {objectFields}
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      );

      defaultActiveTabs.push(field.name)
    }
  });

  return (
    <>
      <Form>
        <Row className='mx-1 mt-3'>
          <Accordion defaultActiveKey={defaultActiveTabs} alwaysOpen>
            <Accordion.Item eventKey="info" className='mb-3 settings-accordion'>
              <Accordion.Header><h4>{translate('Userinfo')}</h4></Accordion.Header>
              <Accordion.Body className='mt-3'>
                <Row className='mx-1'>
                  <Col md={6}>
                    <p>{translate('User')}: {token?.payload?.name}</p>
                    <p>{translate('Email')}: {token?.payload?.email}</p>
                    <p>{translate('Selected_admin')}: {admin?.name}</p>
                    <p>{translate('Selected_location')}: {location?.name}</p>
                  </Col>
                  <Col md={6}>
                    <p>{translate('ID')}: {token?.payload?.sub}</p>
                    <p>{translate('Administration_id')}: {admin?.id}</p>
                    <p>{translate('Location_id')}: {location?.id}</p>
                  </Col>
                </Row>
              </Accordion.Body>
            </Accordion.Item>
            {formTabs}
            {scopes.schapkaartjes &&
              <Accordion.Item eventKey="shelflabels-printers" className='mb-3 settings-accordion'>
                <Accordion.Header><h4>{translate('Shelflabels Printers')}</h4></Accordion.Header>
                <Accordion.Body className='mt-3'>
                  <Row className='mx-1'>
                    <Col md={12}>
                      <PrinterTable {...printers} />
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            }
          </Accordion>
        </Row>
      </Form>
      <Button variant="primary" type={formdata.submit.type} onClick={formdata.submit.onClick}>
        {translate(formdata.submit.title)}
      </Button>
    </>
  );
}

export default SettingsForm;