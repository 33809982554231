import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card"
import HandleOnChange from "../../helpers/handleOnChange";
import { getHashTemplatesAll, getTemplatesAll } from "../../redux/dataSlices/notificationSlice";
import { NotificationReservationTemplateFormFields } from "./notificationsFormFields";
import HandleOnChangeSingleSelectFieldWithObject from "../../helpers/handleOnChangeSingleSelectFieldWithObject";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import NotificationReservationTemplateForm from "../../components/notificationReservationTemplateForm";
import { reservationStatusChoices } from "../../selectfieldchoices/reservationselect.mjs";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import PostNotificationReservationTemplate from "../../actablueAPI/notifications/PostNotificationReservationTemplate";

const NotificationReservationTemplateAdd = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id)
  const templates_all = useSelector(getTemplatesAll)
  const hash_templates_all = useSelector(getHashTemplatesAll)
  const navigate = useNavigate();
  const [reservationTemplate, setReservationTemplate] = useState({ administration_id: admin_id, location_id: location_id })

  const onChange = (event) => {
    let newChannel = HandleOnChange({ event: event, object: reservationTemplate })
    setReservationTemplate(newChannel)
  }

  const onChangeTemplate = (event) => {
    let newChannel = HandleOnChangeSingleSelectFieldWithObject({ event: event, object: reservationTemplate, selectfield: 'template', hash: hash_templates_all })
    setReservationTemplate(newChannel)
  }

  const onChangeStatus = (event) => {
    let newChannel = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: reservationTemplate, selectfield: 'reservation_status' })
    setReservationTemplate(newChannel)
  }

  const onSubmit = () => {
    PostNotificationReservationTemplate({ token: token, admin_id: admin_id, data: reservationTemplate })
      .then(() => {
        navigate("/notifications");
      })
  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    fields: NotificationReservationTemplateFormFields(),
    field: {
      onChange: onChange
    },
    data: reservationTemplate,
    status: {
      label: 'status',
      options: reservationStatusChoices,
      onChange: onChangeStatus,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    template: {
      label: 'template',
      options: templates_all,
      onChange: onChangeTemplate,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
  };

  const card = {
    size: 12,
    title: "Reservation template add",
    data: <NotificationReservationTemplateForm {...formData} />,
  };

  return (
    <>
      <BasicCard {...card} />
    </>
  )

};
export default NotificationReservationTemplateAdd;

