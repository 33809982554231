import { getScopes } from "../../redux/dataSlices/scopesSlice";
import { getRole } from "../../redux/dataSlices/tokenSlice"
import { useSelector } from "react-redux";

export const SettingsFormFields = () => {
  const role = useSelector(getRole)
  const scopes = useSelector(getScopes);

  const settingsProductsFormFields =
    [
      { type: 'hidden', name: 'id', id: 'id', readonly: true },
      { type: 'select', name: 'Master product', id: 'master_product', formdatafield: 'master_product', md: 3 },
      { type: 'hidden', name: 'Sync active', id: 'sync_active', readonly: true, md: 12 },
      { type: 'hidden', name: 'Replace eans', id: 'replace_eans', readonly: true, md: 12 },
    ]

  if (role === 'super' || role === 'admin') {
    settingsProductsFormFields.splice(2, 1, // overwrite index 2 
      { type: 'checkbox', name: 'Sync active', id: 'sync_active', md: 12 },
    )
    settingsProductsFormFields.splice(3, 1, // overwrite index 3   
      { type: 'checkbox', name: 'Replace eans', id: 'replace_eans', md: 12 },
    )
  }

  const settingsFormFields =
    [
      { type: 'object', name: 'Products', id: 'products', fields: settingsProductsFormFields }
    ]


  if (scopes.schapkaartjes) {
    const settingsSchapkaartjesFormFields =
      [
        { type: 'hidden', name: 'admin_id', id: 'admin_id', readonly: true },
        { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true },
        { type: 'select', name: 'Code type on template', id: 'code_to_print', formdatafield: 'code_to_print' },
        { type: 'select', name: 'Default printer', id: 'default_printer', formdatafield: 'default_printer' },
        { type: 'select', name: 'Default print template', id: 'print_default_template', formdatafield: 'print_default_template' },
        { type: 'select', name: 'Default ESL template', id: 'esl_default_template', formdatafield: 'esl_default_template' },
        { type: 'select', name: 'Accepted currency on template', id: 'accepted_currency', formdatafield: 'accepted_currency' },
        { type: 'checkbox', name: 'Inverted print', id: 'inverted_print', md: 12 },
      ]

    settingsFormFields.push({ type: 'object', name: 'Shelflabels', id: 'schapkaartjes', fields: settingsSchapkaartjesFormFields })
  }

  if (scopes.cashcount) {
    const settingsCashcountFormFields =
      [
        { type: 'hidden', name: 'admin_id', id: 'admin_id', readonly: true },
        { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true },
        { type: 'multi+create', name: 'Accepted currency', id: 'allowed_currency', formdatafield: 'allowed_currency', required: true, md: 6 },
      ]

    settingsFormFields.push({ type: 'object', name: 'Cashcount', id: 'cash-count', fields: settingsCashcountFormFields })
  }

  if (scopes.sortcategories) {
    const kitchenManagerSettingsFormFields =
      [
        { type: 'hidden', name: 'admin_id', id: 'admin_id', readonly: true },
        { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true },
        { type: 'select', name: 'Default printer', id: 'km_default_printer', formdatafield: 'km_default_printer', required: true, md: 6 },
        { type: 'checkbox', name: 'Enabled', id: 'kitchen_manager_enabled', md: 12 }
      ]

    settingsFormFields.push({ type: 'object', name: 'Kitchen manager', id: 'kitchenmanager', fields: kitchenManagerSettingsFormFields })
  }

  return settingsFormFields
}