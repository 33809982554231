import * as React from "react";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from "react-bootstrap/Button";
import Search from "../static/icons/search.svg";
import "./css/searchAndFilter.css"
import SingleSelectField from "./singleselectField";
import { Col } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

const SearchAndFilterSingleSelect = (props) => {
  const { t: translate } = useTranslation();
  
  let list = []

  if (props.hasOwnProperty('search')) {
    list.push(
      <li>
        <InputGroup className="search">
          <Form.Control placeholder={translate('Search...')} className="search-input" onChange={props.search.onChange} value={props.search.value} onKeyDown={props.search.onKeyDown} />
          <Button className='btn-no-shadow' onClick={props.search.onClick}>
            <img className="search-button" src={Search} alt=''></img>
            {translate('Search')}
          </Button>
        </InputGroup>
      </li>
    )
  }

  if (props.hasOwnProperty('filter')) {
   list.push(
    <li>
      <div className="filter">
        <label htmlFor='navbar-select'>
          {translate('Filter by:')}
        </label>
        <SingleSelectField {...props.filter} name='filter' />
      </div>
    </li>
   ) 
  }
  
  return (
    <Col md={12}>
      <ul className="horizontal-list">
        {list}
      </ul>
    </Col>
  );
};
export default SearchAndFilterSingleSelect;
