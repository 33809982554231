import React from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getTemplates, getTemplatePagination, getTemplatePageSize, setTemplates, setTemplateImages } from "../../redux/dataSlices/templateSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import BasicCard from "../../components/card"
import TemplateTable from "../../components/templatesTable";
import axios from "axios";
import HandleError from "../../helpers/handleError";
import { setSettingsSchapkaartjes } from "../../redux/dataSlices/settingsSlice";
import GetTemplates from "../../actablueAPI/shelflabels/GetTemplates";
import GetShelflabelsSettings from "../../actablueAPI/shelflabels/GetShelflabelsSettings";
import GetAllPrinters from "../../actablueAPI/shelflabels/GetAllPrinters";
import { setPrintersAll } from "../../redux/dataSlices/printerSlice";

const Shelflabels = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const templates = useSelector(getTemplates);
  const pagination = useSelector(getTemplatePagination);
  const pageSize = useSelector(getTemplatePageSize);
  const dispatch = useDispatch();
  const navTitle = "Shelflabels"

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    dispatch(setTemplateImages([]))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setLoading(true))
    axios.all([
      GetShelflabelsSettings({ token: token, admin_id: admin_id, location_id: location_id }),
      GetTemplates({ token: token, admin_id: admin_id, location_id: location_id, base_labels: true, page: pagination, size: pageSize, sorted: true }),
      GetAllPrinters({ token: token, admin_id: admin_id, location_id: location_id })
    ])
      .then(axios.spread((schapkaartjesSettings, templateResponse, printerResponse) => {
        dispatch(setSettingsSchapkaartjes(schapkaartjesSettings.data));
        dispatch(setTemplates(templateResponse));
        dispatch(setPrintersAll(printerResponse))
        dispatch(setLoading(false));
      }))
      .catch(error => {
        HandleError(error)
        dispatch(setLoading(false))
      })
    // eslint-disable-next-line
  }, [admin_id, location_id, pagination, pageSize]);

  const templatesCard = {
    size: 12,
    title: "Templates",
    data: <TemplateTable {...templates} />,
  };

  return (
    <>
      <BasicCard {...templatesCard} />
    </>
  );
};
export default Shelflabels;
