import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card"
import { apiScopes, apiScopesMap } from "../../scopes/scopes.mjs"
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { UserRoleFormFields } from "./userRoleFormFields";
import UserRoleForm from "../../components/userRoleForm";
import HandleOnChange from "../../helpers/handleOnChange";
import PostUserRoleDefinitions from "../../actablueAPI/serials/PostUserRoleDefinitions";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import HandleError from "../../helpers/handleError";
import { getUserRole, setUserRole } from "../../redux/dataSlices/userSlice";
import { getHashAdminLocationsAll } from "../../redux/dataSlices/locationSlice";
import GetRegistration from "../../actablueAPI/registrations/GetRegistration";
import GetLocationRawScopes from "../../helpers/getLocationRawScopes";
import DeepCopy from "../../helpers/DeepCopy";

const UserRoleEdit = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const hash_admin_locations_all = useSelector(getHashAdminLocationsAll);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navTitle = "Userrole edit";
  const userRole = useSelector(getUserRole);
  const [combinedScopes, setCombinedScopes] = useState([]);
  const [formFields, setFormFields] = useState({ 'userRoleFormFields': UserRoleFormFields() });

  const convertRoles = async (object) => {
    if (!object || !object.roles || !Array.isArray(object.roles) || (combinedScopes?.length === 0)) {
      navigate('/userroles');
      return;
    }

    let newUserRole = DeepCopy(object);

    let temp_role_hash = {}
    object.roles.forEach(role => {
      const splitString = role.split(":")
      const location = DeepCopy(splitString[2]);
      splitString.splice(1, 2)
      splitString.splice(3, 1)
      const key = splitString.join(":")
      if (splitString[0] === 'portal') temp_role_hash[`${location}+${key}`] = role
      if (splitString[0] === 'api') {

        const splitRole = role.split(":")

        switch (splitRole[5]) {
          case 'read':
            if (temp_role_hash[`${location}+${key}`]?.split(":")[5] === 'update' ||
              temp_role_hash[`${location}+${key}`]?.split(":")[5] === 'create' ||
              temp_role_hash[`${location}+${key}`]?.split(":")[5] === 'delete') return
            temp_role_hash[`${location}+${key}`] = role
            break;
          case 'update':
            if (temp_role_hash[`${location}+${key}`]?.split(":")[5] === 'create' ||
              temp_role_hash[`${location}+${key}`]?.split(":")[5] === 'delete') return
            temp_role_hash[`${location}+${key}`] = role
            break;
          case 'create':
            if (temp_role_hash[`${location}+${key}`]?.split(":")[5] === 'delete') return
            temp_role_hash[`${location}+${key}`] = role
            break;
          case 'delete':
            temp_role_hash[`${location}+${key}`] = role
            break;
          default:
            return
        }
      }
    });

    let newRoles = {}
    for (const [key, value] of Object.entries(temp_role_hash)) {
      try {
        const keyParts = key.split("+");
        if (keyParts.length !== 2) {
          console.error(`Invalid key format: ${key}`);
          continue;
        }

        let matchingScope = combinedScopes.find(scope => scope.includes(key));

        if (!matchingScope) {
          console.warn(`No matching scope found for key: ${key}`);
          continue;
        }

        newRoles[matchingScope] = value;
      } catch (error) {
        console.error(`Error processing key: ${key}`, error);
      }
    }

    newUserRole.roles = newRoles;

    dispatch(setUserRole(newUserRole));
  }

  const onChange = (event) => {
    let newObject = HandleOnChange({ event: event, object: userRole });
    dispatch(setUserRole(newObject));
  }

  const onChangeScope = (event) => {
    const generateUserRoleScope = (scope) => {
      // event.target.alt is location_id
      let location_id = event.target.alt;
      let splitScope = scope.split(":");
      if (scope.split("+").length > 1) splitScope = scope.split("+")[1].split(":");
      let newScopeArray = [splitScope[0], admin_id, location_id, splitScope[1], splitScope[2], event.target.name];
      let newScope = newScopeArray.join(':');
      if (scope.split("+").length > 1) newObject.roles[scope] = newScope;
      if (scope.split("+").length <= 1) newObject.roles[`${location_id}+${scope}`] = newScope;
    }

    let newObject = DeepCopy(userRole);
    if (event.target.checked) {
      generateUserRoleScope(event.target.id);
      let splitScope = event.target.id.split("+")[1].split(":");
      if (splitScope[0] === 'portal') {
        splitScope.splice(3, 1)
        let scopeWithoutValue = splitScope.join(":")
        if (apiScopesMap[scopeWithoutValue]) {
          apiScopesMap[scopeWithoutValue].forEach(apiScope => {
            generateUserRoleScope(apiScope);
          });
        }
      }
    } else {
      if (newObject.roles[event.target.id]?.split(":")[5] === event.target.name) {
        delete newObject.roles[event.target.id];
        let splitScope = event.target.id.split("+")[1].split(":");
        if (splitScope[0] === 'portal') {
          splitScope.splice(3, 1)
          let scopeWithoutValue = splitScope.join(":")
          if (apiScopesMap[scopeWithoutValue]) {
            apiScopesMap[scopeWithoutValue].forEach(apiScope => {
              delete newObject.roles[`${event.target.alt}+${apiScope}`];
            });
          }
        }
      } else {
        generateUserRoleScope(event.target.id);
        let splitScope = event.target.id.split("+")[1].split(":");
        if (splitScope[0] === 'portal') {
          splitScope.splice(3, 1)
          let scopeWithoutValue = splitScope.join(":")
          if (apiScopesMap[scopeWithoutValue]) {
            apiScopesMap[scopeWithoutValue].forEach(apiScope => {
              generateUserRoleScope(apiScope);
            });
          }
        }
      }
    }

    dispatch(setUserRole(newObject));
  }

  const onSubmit = () => {
    dispatch(setLoading(true))
    // set scopes back to array with strings
    let newUserRole = Object.assign({}, userRole)

    if (userRole.roles) {
      let newRoles = []
      for (const value of Object.values(userRole.roles)) {
        if (value.split(":")[0] === 'portal') newRoles.push(value)
        if (value.split(":")[0] === 'api') {
          let newValue;

          switch (value.split(":")[5]) {
            case 'read':
              newRoles.push(value)
              break;
            case 'update':
              newValue = value.replace(/:[^:]+$/, ':read');
              newRoles.push(newValue)

              newRoles.push(value)
              break;
            case 'create':
              newValue = value.replace(/:[^:]+$/, ':read');
              newRoles.push(newValue)
              newValue = value.replace(/:[^:]+$/, ':update');
              newRoles.push(newValue)

              newRoles.push(value)
              break;
            case 'delete':
              newValue = value.replace(/:[^:]+$/, ':read');
              newRoles.push(newValue)
              newValue = value.replace(/:[^:]+$/, ':update');
              newRoles.push(newValue)
              newValue = value.replace(/:[^:]+$/, ':create');
              newRoles.push(newValue)

              newRoles.push(value)
              break;
            default:
              break;
          }
        }
      }
      newUserRole.roles = newRoles
    }


    PostUserRoleDefinitions({ token: token, admin_id: admin_id, object: newUserRole })
      .then(() => {
        navigate("/userroles")
      })
      .catch(error => {
        HandleError(error)
        dispatch(setLoading(false))
      })

  };

  const createFormFields = async () => {
    let newFormFields = DeepCopy(formFields);
    let combinedLocationScopes = [];
    newFormFields.roleFields = [];
    newFormFields.apiRoleFields = [];

    for (const location of Object.values(hash_admin_locations_all)) {
      const response = await GetRegistration({ token: token, admin_id: admin_id, location_id: location?.id });
      const location_scopes = GetLocationRawScopes(response.data, true, location?.id);

      combinedLocationScopes = [...location_scopes, ...combinedLocationScopes];

      location_scopes.forEach((scope) => {
        let scopeWithoutLocation = scope.split("+")[1];
        newFormFields.roleFields.push({ type: 'select', name: scopeWithoutLocation, id: scope, placeholder: scopeWithoutLocation, formdatafield: 'roles', prefix: 'roles', location_id: location?.id });
      });

      for (const scope of Object.values(apiScopes)) {
        combinedLocationScopes = [`${location?.id}+${scope}`, ...combinedLocationScopes];
        newFormFields.apiRoleFields.push({ type: 'select', name: scope, id: `${location?.id}+${scope}`, placeholder: scope, formdatafield: 'roles', prefix: 'roles', location_id: location?.id });
      }
    }

    // combinedLocationScopes = [...apiScopes, ...combinedLocationScopes];
    setCombinedScopes(combinedLocationScopes);

    if (newFormFields.roleFields.length < 1 && newFormFields.apiRoleFields.length < 1) {
      navigate('/userroles');
      return;
    }

    setFormFields(newFormFields);
    dispatch(setLoading(false))
  };

  useEffect(() => {
    const getFormFields = async () => {
      await createFormFields();
    };

    dispatch(setNavBarTitle(navTitle));
    dispatch(setLoading(true));

    getFormFields();
    // eslint-disable-next-line
  }, [admin_id]);

  useEffect(() => {
    // We only want this useEffect to run after the createFormFields() function is done.
    // By doing a check on the length of the state of combinedScopes which we change at the end of createFormFields() we can determine
    // if the function was ran or not.
    const convertUserRoles = async () => {
      await convertRoles(userRole);
    };

    if (combinedScopes.length > 0) {
      if (!userRole) {
        // Exit early if userRole is not defined
        navigate('/userroles');
        return;
      }
      convertUserRoles();
    }
    // eslint-disable-next-line
  }, [combinedScopes.length]);

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    fields: formFields,
    field: {
      onChange: onChange,
      onChangeScope: onChangeScope
    },
    data: userRole
  };

  const editCard = {
    size: 12,
    title: navTitle,
    data: <UserRoleForm {...formData} />,
  };

  return (
    <>
      <BasicCard {...editCard} />
    </>
  )

};
export default UserRoleEdit;

