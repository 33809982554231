import React from "react";
import { useState, useEffect } from "react";
import moment from "moment-timezone";
import { useSelector, useDispatch } from "react-redux";
import { setError, setResponseError } from "../../redux/dataSlices/errorSlice";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card";
import HandleOnChange from "../../helpers/handleOnChange";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import { reservationStatusChoices, reservationTypeChoices } from "../../selectfieldchoices/reservationselect.mjs";
import HandleError from "../../helpers/handleError";
import { planningFormFields } from "./planningFormFields";
import PlanningForm from "../../components/planningForm";
import DeepCopy from "../../helpers/DeepCopy";
import { GetAllPlanningObjects, GetPlanningById, PutPlanning } from "../../actablueAPI/planning/Planning";
import { getHashPlanningObjectsAll, getPlanningId, getPlanningLocation, getPlanningObjectsAll, setPlanningObjectsAll } from "../../redux/dataSlices/planningSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import HandleOnChangeMultiSelectFieldWithObjects from "../../helpers/handleOnChangeMultiSelectFieldWithObjects";
import { validateFormObject } from "../../helpers/validateFormObject";

const PlanningEdit = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const planning_id = useSelector(getPlanningId);
  const planning_location = useSelector(getPlanningLocation);
  const planning_objects = useSelector(getPlanningObjectsAll);
  const hash_planning_objects = useSelector(getHashPlanningObjectsAll);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [planning, setPlanning] = useState({});
  const [invalidFields, setInvalidFields] = useState([]);

  const onChange = (event) => {
    let newPlanning = HandleOnChange({ event: event, object: planning })
    setPlanning(newPlanning)
  };

  const onTypeChange = (event) => {
    let newPlanning = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: planning, selectfield: 'type' })
    setPlanning(newPlanning)
  };

  const onStatusChange = (event) => {
    let newPlanning = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: planning, selectfield: 'status' })
    setPlanning(newPlanning)
  };

  const onPlanningObjectChange = (event) => {
    let newPlanning = HandleOnChangeMultiSelectFieldWithObjects({ event: event, object: planning, selectfield: 'planning_objects', hash: hash_planning_objects })
    setPlanning(newPlanning)
  };

  const onCancel = () => {
    navigate("/planning");
  };

  const onSubmit = (event) => {
    const [invalid] = validateFormObject({ object: planning, fields: formData?.fields, switchTab: true, dispatch: dispatch })

    if (invalid?.length > 0) {
      setInvalidFields(invalid)
      return;
    }

    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    let newPlanning = DeepCopy(planning)
    newPlanning.type = planning.type.toUpperCase()
    newPlanning.status = planning.status.toUpperCase()

    newPlanning.planning_timestamp = moment(planning.planning_timestamp).utc().format();

    if (newPlanning?.planning_objects) {
      for (const planning_object of newPlanning?.planning_objects) {
        planning_object.planning_object_id = planning_object.id
        delete planning_object.id
      }
    }


    PutPlanning({ token: token, data: newPlanning })
      .then(() => {
        navigate("/planning");
      })
      .catch(error => {
        const type = error.response?.type
        const data = error.response?.data
        if (error.response) { dispatch(setResponseError({ type, data })) }
        if (!error.response) { dispatch(setError(JSON.stringify(error))) }
      })
  };

  const formData = {
    cancel: {
      title: 'Cancel',
      type: 'submit',
      onClick: onCancel
    },
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    invalidFields: invalidFields,
    fields: planningFormFields,
    field: {
      onChange: onChange
    },
    data: planning,
    type: {
      name: 'type',
      options: reservationTypeChoices,
      selected: '',
      onChange: onTypeChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    status: {
      name: 'status',
      options: reservationStatusChoices,
      selected: '',
      onChange: onStatusChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    planning_objects: {
      name: 'planning_objects',
      options: planning_objects,
      selected: [],
      onChange: onPlanningObjectChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
  };

  const editCard = {
    size: 12,
    title: "Planning edit",
    data: <PlanningForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setLoading(true))

    axios.all([
      GetPlanningById({ token: token, id: planning_id }),
      GetAllPlanningObjects({ token: token, admin_id: admin_id, location_id: location_id })
    ])
      .then(axios.spread((planningResponse, planningObjectsResponse) => {
        let planning = planningResponse.data
        planning.planning_timestamp = moment(planning.planning_timestamp).tz(planning_location?.timezone || 'Europe/Amsterdam').format('YYYY-MM-DDTHH:mm:ss')
        setPlanning(planning)
        dispatch(setPlanningObjectsAll(planningObjectsResponse))
        dispatch(setLoading(false))
      }))
      .catch(function (error) {
        HandleError(error)
        dispatch(setLoading(false))
      })
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...editCard} />
    </>
  )
};
export default PlanningEdit;
