export const onlinePaymentsFormFields = [
  // single option fields
  { type: 'hidden', name: 'id', id: 'id', readonly: true },
  { type: 'hidden', name: 'adminstration_id', id: 'administration_id', readonly: true },
  { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true },
  { type: 'select', name: 'source_type', id: 'source_type', formdatafield: 'source_type' },
  { type: 'select', name: 'payment_type', id: 'payment_type', formdatafield: 'payment_type' },
  { type: 'text', name: 'url', id: 'url' },
  { type: 'text', name: 'apikey', id: 'apikey' },
  { type: 'text', name: 'terminal_id', id: 'terminal_id' },
  { type: 'text', name: 'listening_url', id: 'listening_url' },
  { type: 'text', name: 'operating_environment', id: 'operating_environment' },
  { type: 'text', name: 'managementsystem_id', id: 'managementsystem_id' },
]