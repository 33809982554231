import { createSlice } from "@reduxjs/toolkit";

export const importSlice = createSlice({
  name: "import",
  initialState: {
    hash_import_profiles_all: {},
    match_columns_form: {},
    import_profile: {}
  },
  reducers: {
    setTaskId: (state, action) => {
      state.task_id = action.payload;
    },
    setTaskData: (state, action) => {
      state.task_data = action.payload
    },
    setImportModalShow: (state, action) => {
      state.import_modal_show = action.payload
    },
    setImportProfileModalShow: (state, action) => {
      state.import_modal_profile_show = action.payload
    },
    setImportProfiles: (state, action) => {
      state.import_profiles = action.payload
      action.payload.forEach(profile => {
        state.hash_import_profiles_all[profile.id] = profile
      })
    },
    setImportProfile: (state, action) => {
      state.import_profile = action.payload
    },
    setMatchColumnsForm: (state, action) => {
      state.match_columns_form = action.payload
    },
    setImportSaveModalShow: (state, action) => {
      state.import_save_modal_show = action.payload
    },
    
  }
});

export const {
  setTaskId, setTaskData, setImportModalShow, setImportProfile, setImportProfiles, setMatchColumnsForm, setImportSaveModalShow, setImportProfileModalShow
} = importSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getTaskId = (state) => state.import.task_id
export const getTaskData = (state) => state.import.task_data
export const getImportModalShow = (state) => state.import.import_modal_show
export const getImportProfileModalShow = (state) => state.import.import_modal_profile_show
export const getImportSaveModalShow = (state) => state.import.import_save_modal_show
export const getImportProfile = (state) => state.import.import_profile
export const getImportProfiles = (state) => state.import.import_profiles
export const getHashImportProfiles = (state) => state.import.hash_import_profiles_all
export const getMatchColumnsForm = (state) => state.import.match_columns_form

export default importSlice.reducer;