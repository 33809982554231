import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card"
import HandleOnChange from "../../helpers/handleOnChange";
import { getHashChannelsEmailsAll, getChannelsEmailsAll } from "../../redux/dataSlices/notificationSlice";
import { NotificationApplicationFormFields } from "./notificationsFormFields";
import NotificationApplicationForm from "../../components/notificationApplicationForm";
import { eventChoices } from "../../selectfieldchoices/notificationselect.mjs";
import HandleOnChangeMultiSelectFieldWithObjects from "../../helpers/handleOnChangeMultiSelectFieldWithObjects";
import HandleOnChangeMultiSelectFieldWithValues from "../../helpers/handleOnChangeMultiSelectFieldWithValues";
import { getSelectedLocation, getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import PostNotificationApplication from "../../actablueAPI/notifications/PostNotificationsApplication";

const NotificationApplicationAdd = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id)
  const location_id = useSelector(getSelectedLocation_id)
  const location = useSelector(getSelectedLocation)
  const channels_all = useSelector(getChannelsEmailsAll)
  const hash_channels_all = useSelector(getHashChannelsEmailsAll)
  const navigate = useNavigate();
  const [application, setApplication] = useState({ administration_id: admin_id, location_id: location_id, name: location.name, enabled: true })

  const onChange = (event) => {
    let newApplication = HandleOnChange({ event: event, object: application })
    setApplication(newApplication)
  }

  const onHandleOnChangeMultiSelectFieldWithObjects = (event) => {
    let newApplication = HandleOnChangeMultiSelectFieldWithObjects({ event: event, object: application, selectfield: 'channels', hash: hash_channels_all })
    setApplication(newApplication)
  }

  const onHandleOnChangeMultiSelectFieldWithValues = (event) => {
    let newApplication = HandleOnChangeMultiSelectFieldWithValues({ event: event, object: application, selectfield: 'events' })
    setApplication(newApplication)
  }

  const onSubmit = () => {
    PostNotificationApplication({ token: token, data: application })
      .then(() => {
        navigate("/notifications");
      })
  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    fields: NotificationApplicationFormFields(),
    field: {
      onChange: onChange
    },
    data: application,
    channels: {
      label: 'channels',
      options: channels_all,
      onChange: onHandleOnChangeMultiSelectFieldWithObjects,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    events: {
      label: 'events',
      options: eventChoices,
      onChange: onHandleOnChangeMultiSelectFieldWithValues,
      clearable: Boolean(false),
      searchable: Boolean(true)
    }
  };

  const card = {
    size: 12,
    title: "Application add",
    data: <NotificationApplicationForm {...formData} />,
  };

  return (
    <>
      <BasicCard {...card} />
    </>
  )

};
export default NotificationApplicationAdd;

