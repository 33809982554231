import React from "react";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getJournalCategoryPagination, getJournalCategoryPagesize, setJournalCategoriesAll, getJournalCategoriesAll, getSelectedJournalCategories, setSelectedJournalCategories } from "../../redux/dataSlices/categorySlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { filterChoices } from "../../selectfieldchoices/categoryselect.mjs";
import BasicCard from "../../components/card"
import CategoryTable from "../../components/journalcategoryTable";
import GetAllCategoriesWithType from "../../actablueAPI/products/getAllCategoriesWithType";
import SearchNoButtonAndFilterSingleSelect from "../../components/searchNoButtonAndFilterSingleSelectField";
import HandleError from "../../helpers/handleError";
import AddParentToChildren from "../../helpers/addParentToChildren";
import { confirmAlert } from "react-confirm-alert";
import ChangeHashObjectsKeyValueMulti from "../../helpers/changeHashObjectsKeyValueMulti";
import PutCategory from "../../actablueAPI/products/PutCategory";
import { CategoryMultiEditFormFields } from "./journalcategoryFormFields";
import GetAllVats from "../../helpers/getAllVats";
import { getHashVatsAll, getVatsAll, setVatsAll } from "../../redux/dataSlices/vatSlice";
import HandleOnChangeSingleSelectFieldWithObject from "../../helpers/handleOnChangeSingleSelectFieldWithObject";
import axios from "axios";

const Journalcategories = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const journalcategories = useSelector(getJournalCategoriesAll);
  const selectedCategories = useSelector(getSelectedJournalCategories);
  const vats = useSelector(getVatsAll);
  const hash_vats = useSelector(getHashVatsAll);
  const pagination = useSelector(getJournalCategoryPagination);
  const pageSize = useSelector(getJournalCategoryPagesize);
  const dispatch = useDispatch();

  const [filteredCategories, setFilteredCategories] = useState()
  const [modalShow, setModalShow] = useState(false)
  const [search, setSearch] = useState()
  const [multiEditData, setMultiEditData] = useState({})
  const [filter, setFilter] = useState()
  const [reload, setReload] = useState(false)

  const onSearchChange = (event) => {
    let value = undefined
    if (event) {
      value = event.target.value
    }
    setSearch(value)
    SearchAndFilter(filter, value)
  }

  const onFilterChange = (event) => {
    let value = undefined
    if (event) {
      value = event.value
    }
    setFilter(value)
    SearchAndFilter(value, search)
  }

  const searchAndFilter = {
    search: {
      value: search,
      onChange: onSearchChange,
    },
    filter: {
      label: '',
      options: filterChoices,
      hash: '',
      onChange: onFilterChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    }
  }

  const onVatChange = (event) => {
    let newData = HandleOnChangeSingleSelectFieldWithObject({ event: event, object: multiEditData, selectfield: 'vat', hash: hash_vats })
    setMultiEditData(newData)
  };

  const onSaveClick = () => {

    confirmAlert({
      title: 'Confirm to change multiple categories',
      message: `Are you sure you wish to change multiple categories?\n\nPlease make sure all intended categories are selected.`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            let newCategories = ChangeHashObjectsKeyValueMulti(selectedCategories, multiEditData)
            const promises = [];

            for (const [, object] of Object.entries(newCategories)) {
              promises.push(PutCategory({ token: token, admin_id: admin_id, category: object }));
            }

            Promise.all(promises)
            .then(() => {
              setReload(!reload);
              setModalShow(false);
              dispatch(setSelectedJournalCategories({}));
            })
            .catch((error) => {
              console.error("Error occurred during requests:", error);
            });
          }
        },
        {
          label: 'No',
        }
      ]
    });
  };

  const toggleModal = (event) => {
    event.stopPropagation();
    setModalShow(!modalShow)
  };

  const multiEditformData = {
    modal: {
      title: "Category multi edit",
      show: modalShow,
      onSaveClick: onSaveClick,
      toggleModal: toggleModal,
      fields: CategoryMultiEditFormFields(),
      vats: {
        label: 'vats',
        placeholder: 'Select...',
        options: vats,
        selected: [],
        onChange: onVatChange,
        clearable: Boolean(false),
        searchable: Boolean(false)
      },
      data: multiEditData,
      selected: selectedCategories
    }
  }

  const categoriesCard = {
    size: 12,
    title: `Journal categories (Total: ${journalcategories?.length || 0})`,
    data: <CategoryTable categories={filteredCategories || journalcategories} search={search} filter={filter} multiEdit={multiEditformData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle('Journal categories'))
    // eslint-disable-next-line
  }, []);

  function SearchAndFilter(filter, search) {
    if (!filter && !search) {      
      return setFilteredCategories()
    }

    let filtered = []
    switch (filter) {
      case 'visible_true':
        filtered = journalcategories.filter(category => category.is_visible)
        break;
      case 'visible_false':
        filtered = journalcategories.filter(category => !category.is_visible)
        break;
      default:
        filtered = journalcategories
        break;
    }
   
    if (search) {
      return setFilteredCategories(filtered.filter(categorie => JSON.stringify(categorie.title).toLowerCase().includes(search.toLowerCase())))
    }
    return setFilteredCategories(filtered)
  }

  useEffect(() => {
    dispatch(setLoading(true))

    axios.all([
      GetAllCategoriesWithType({token: token, admin_id:admin_id, location_id:location_id, type:'JOURNAL'}),
      GetAllVats({ token: token, admin_id: admin_id, location_id: location_id })
    ])
    .then(axios.spread((categoryResponse, vatResponse) => {
        // create array with unique ojects
        const unique = [...new Map(categoryResponse.map(object => [object['id'], object])).values()]
        // add parent ids to children
        const modifiedCategoryList = AddParentToChildren(unique)
        dispatch(setJournalCategoriesAll(modifiedCategoryList))
        dispatch(setVatsAll(vatResponse))
        dispatch(setLoading(false))
    }))
    .catch(error => {
      HandleError(error)
      dispatch(setLoading(false))
    })
    // eslint-disable-next-line
  }, [, admin_id, location_id, pagination, pageSize, reload]);

  return (
    <>
      <SearchNoButtonAndFilterSingleSelect {...searchAndFilter} />
      <BasicCard {...categoriesCard} />
    </>
  );
};
export default Journalcategories;
