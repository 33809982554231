import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getAdministrationsAll, getHashAdministrationsAll } from "../../redux/dataSlices/administrationSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card"
import UserForm from "../../components/userForm";
import { KeyCloakUserFormAddFields } from "./userFormFields.jsx";
import HandleOnChange from "../../helpers/handleOnChange";
import HandleOnChangeMultiSelectFieldWithObjects from "../../helpers/handleOnChangeMultiSelectFieldWithObjects";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import { userTypeChoices } from "../../selectfieldchoices/userselect.mjs";
import PostUser from "../../actablueAPI/serials/PostUser.jsx";
import { getHashUserRolesAll, getUserRolesAll } from "../../redux/dataSlices/userSlice.js";
import HandleError from "../../helpers/handleError.jsx";
import { setLoading } from "../../redux/dataSlices/loadingSlice.js";

const KeycloakUserAdd = () => {
  const token = useSelector(getToken);
  const administrations = useSelector(getAdministrationsAll)
  const hash_administrations = useSelector(getHashAdministrationsAll)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navTitle = "User add"
  const userRoles = useSelector(getUserRolesAll);
  const hash_user_roles = useSelector(getHashUserRolesAll);
  const [user, setUser] = useState({})

  const onChange = (event) => {
    let newUser = HandleOnChange({ event: event, object: user })
    setUser(newUser)
  }

  const onAdminsChange = (event) => {
    let newUser = HandleOnChangeMultiSelectFieldWithObjects({ event: event, object: user, selectfield: 'administrations', hash: hash_administrations })
    setUser(newUser)
  }

  const onTypeChange = (event) => {
    let newUser = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: user, selectfield: 'type' })
    setUser(newUser)
  }

  const onRoleChange = (event) => {
    let newUser = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: user, selectfield: 'roles' })
    setUser(newUser)
  };

  const onSubmit = () => {
    dispatch(setLoading(true))
    let newUser = JSON.parse(JSON.stringify(user))
    if (newUser.roles) {
      newUser.roles = hash_user_roles[newUser.roles]?.roles
    }
    PostUser({ token: token, user: newUser })
    .then(() => {
      navigate("/users");
    })
    .catch(error => {
      HandleError(error)
    })
  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    fields: KeyCloakUserFormAddFields(),
    field: {
      onChange: onChange
    },
    data: user,
    administrations: {
      label: 'images',
      options: administrations,
      hash: hash_administrations,
      selected: [],
      onChange: onAdminsChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    type: {
      label: 'type',
      options: userTypeChoices,
      selected: [],
      onChange: onTypeChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    roles: {
      label: 'roles',
      options: userRoles,
      selected: [],
      onChange: onRoleChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
  };

  const editCard = {
    size: 12,
    title: navTitle,
    data: <UserForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...editCard} />
    </>
  )

};
export default KeycloakUserAdd;

