import { useSelector } from 'react-redux';

import { getRole } from '../redux/dataSlices/tokenSlice';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Nav, Tab } from 'react-bootstrap';
import './css/modal.css'
import './css/productModal.css'

import JsonHighlighter from './serviceJsonHighlight';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { getPlanning, getPlanningLocation } from '../redux/dataSlices/planningSlice';

export default function PlanningModal(props) {
  const object = useSelector(getPlanning);
  const planning_location = useSelector(getPlanningLocation)
  const role = useSelector(getRole)
  const { t: translate } = useTranslation();

  let service = false
  if (role === 'super' || role === 'admin') {
    service = true
  }

  if (object) {
    return (
      <Modal
        show={props.modal.show}
        size="xl"
        dialogClassName="modal-md modal-fullscreen-md-down"
        contentClassName='w-100 h-100'
        className='product-modal'
        aria-labelledby="contained-modal-title-vcenter"
        centered scrollable>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Planning
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tab.Container defaultActiveKey="info">
            <Nav variant="tabs">
              <Nav.Item><Nav.Link eventKey="info">{translate('Info')}</Nav.Link></Nav.Item>
              {service && <Nav.Item><Nav.Link eventKey="service">{translate('Service')}</Nav.Link></Nav.Item>}
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="info">
                <div className="container text-center">
                  <div className="row">
                    <div className="col">
                      <p>{translate('Name')}: {object.planning_name}</p>
                      <p>Phone: {object.planning_phonenumber}</p>
                      <p>{translate('Email')}: {object.planning_email}</p>
                      <p>Remark: {object.remark || "N/A"}</p>
                    </div>
                    <div className="col">
                      <p>Time: {moment(object.planning_timestamp).tz(planning_location?.timezone || 'Europe/Amsterdam').format('DD/MM/YYYY HH:mm:ss')}</p>
                      <p>Duration: {object.duration || "N/A"}</p>
                      <p>Status: {object.status || "N/A"}</p>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="service">
                <div className="container text-center">
                  <div className="row">
                    <div className="col">
                      <p>Id: {object.id}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <p>Location: {object.location_id}</p>
                    </div>
                  </div>
                  <div className="col">
                    <JsonHighlighter {...object} />
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Modal.Body>
        <Modal.Footer>
          {props.calendar &&
            <>
              {(object.status === 'PENDING' || object.status === 'CANCELLED') && <Button onClick={(event) => { props.modal.onStatusChange(event, object.id, 'ACCEPTED') }}>{translate('Accept')}</Button>}
              {(object.status === 'PENDING' || object.status === 'ACCEPTED') && <Button onClick={(event) => { props.modal.onStatusChange(event, object.id, 'CANCELLED') }}>{translate('Cancel')}</Button>}
            </>
          }
          <Button onClick={props.modal.onHide}>{translate('Close')}</Button>
        </Modal.Footer>
      </Modal>
    );
  }

}