import Button from 'react-bootstrap/Button';
// import Form from 'react-bootstrap/Form';
import { Tab, Nav, Form } from 'react-bootstrap';

import CreateField from '../helpers/createField';
import OpeningtimesEditTable from './openingtimesEditTable';
import PhoneNumbersEditTable from './phonenumbersEditTable';
import EmailsEditTable from './emailsEditTable';
import { useTranslation } from 'react-i18next';
import ServicehoursEditTable from './servicehoursEditTable';


function LocationForm(props) {
  const { t: translate } = useTranslation();

  let basicFields = []
  let formTabs = [];
  let formTabContent = [];

  if (props.location) {
    if (props.location.data.hasOwnProperty('img_urls')) {
      props.location.data.img_urls.forEach(img_url => {
        props.location.img_urls.selected.push({ 'value': img_url, 'label': img_url })
        props.location.img_urls.options.push({ 'id': img_url, 'name': img_url })
      });
    }
  
    if (props.location.data.hasOwnProperty('price_indication')) {
      props.location.price_indication.selected.push({ 'value': props.location.data.price_indication, 'label': props.location.data.price_indication })
    }
  
    props.location.fields.forEach(field => {
      if (field.type === 'object') {
        formTabs.push(
          <Nav.Item key={field.id}>
            <Nav.Link eventKey={field.id}>
              {translate(field.name)}
            </Nav.Link>
          </Nav.Item>
        );
  
        let objectFields = []
        field.fields.forEach(embeddedfield => {
          objectFields.push(CreateField(embeddedfield, props.location, field.id))
        });
        formTabContent.push(
          <Tab.Pane eventKey={field.id} key={field.id}>
            <div className='tab-container'>
              {objectFields}
            </div>
          </Tab.Pane>
        );
      } else {
        basicFields.push(CreateField(field, props.location))
      }
    });
  
    // openingtimes tab
    formTabs.push(
      <Nav.Item key='opening_times'>
        <Nav.Link eventKey='opening_times'>
          {translate('Opening times')}
        </Nav.Link>
      </Nav.Item>
    );
    formTabContent.push(
      <Tab.Pane eventKey='opening_times' key='opening_times'>
        <div className='tab-container'>
          <OpeningtimesEditTable {...props.location} />
        </div>
      </Tab.Pane>
    );
  
    // phonenumbers
    formTabs.push(
      <Nav.Item key='phonenumbers'>
        <Nav.Link eventKey='phonenumbers'>
          {translate('Phonenumbers')}
        </Nav.Link>
      </Nav.Item>
    );
    formTabContent.push(
      <Tab.Pane eventKey='phonenumbers' key='phonenumbers'>
        <div className='tab-container'>
          <PhoneNumbersEditTable {...props.location} />
        </div>
      </Tab.Pane>
    );
  
    // emails
    formTabs.push(
      <Nav.Item key='emails'>
        <Nav.Link eventKey='emails'>
          {translate('Emails')}
        </Nav.Link>
      </Nav.Item>
    );
    formTabContent.push(
      <Tab.Pane eventKey='emails' key='emails'>
        <div className='tab-container'>
          <EmailsEditTable {...props.location} />
        </div>
      </Tab.Pane>
    );
  }

  if (props.reservationLocation) {
    if (props?.reservationLocation.data?.hasOwnProperty('img_urls')) {
      props?.reservationLocation.data?.img_urls?.forEach(img_url => {
        props.reservationLocation.img_urls.selected.push({ 'value': img_url, 'label': img_url })
        props.reservationLocation.img_urls.options.push({ 'id': img_url, 'name': img_url })
      });
    }

    if (props?.reservationLocation.data?.hasOwnProperty('application')) {
      props.reservationLocation.application.selected = { value: props?.reservationLocation.data?.application, label: props?.reservationLocation.data?.application }
    }

    if (props.reservationLocation.data?.hasOwnProperty('areas')) {
      props?.reservationLocation.data?.areas.forEach(area => {
        props.reservationLocation.areas.selected.push({ 'value': area, 'label': area })
        props.reservationLocation.areas.options.push({ 'id': area, 'name': area })
      });
    }

      // reservation location
      formTabs.push(
        <Nav.Item key='reservation_location'>
          <Nav.Link eventKey='reservation_location'>
            {translate('reservation_location_settings')}
          </Nav.Link>
        </Nav.Item>
      );
  
      let objectFields = []
      props.reservationLocation.fields?.forEach(embeddedfield => {
        objectFields.push(CreateField(embeddedfield, props.reservationLocation))
      });
      formTabContent.push(
        <Tab.Pane eventKey='reservation_location' key='reservation_location'>
          <div className='tab-container'>
            {objectFields}
          </div>
        </Tab.Pane>
      );
  
      // service hours tab
      formTabs.push(
        <Nav.Item key='service_hours'>
          <Nav.Link eventKey='service_hours'>
            {translate('service_hours')}
          </Nav.Link>
        </Nav.Item>
      );
      formTabContent.push(
        <Tab.Pane eventKey='service_hours' key='service_hours'>
          <div className='tab-container'>
            <ServicehoursEditTable {...props.reservationLocation} />
          </div>
        </Tab.Pane>
      );
  }

  if (props.planningLocation) {

    if (props?.planningLocation.data?.hasOwnProperty('application')) {
      props.planningLocation.application.selected = { value: props?.planningLocation.data?.application, label: props?.planningLocation.data?.application }
    }

      // reservation location
      formTabs.push(
        <Nav.Item key='planning_location'>
          <Nav.Link eventKey='planning_location'>
            {translate('planning_location_settings')}
          </Nav.Link>
        </Nav.Item>
      );
  
      let objectFields = []
      props.planningLocation.fields?.forEach(embeddedfield => {
        objectFields.push(CreateField(embeddedfield, props.planningLocation))
      });
      formTabContent.push(
        <Tab.Pane eventKey='planning_location' key='planning_location'>
          <div className='tab-container'>
            {objectFields}
          </div>
        </Tab.Pane>
      );
  
      // service hours tab
      formTabs.push(
        <Nav.Item key='planning_opening_times'>
          <Nav.Link eventKey='planning_opening_times'>
            {translate('planning_opening_times')}
          </Nav.Link>
        </Nav.Item>
      );
      formTabContent.push(
        <Tab.Pane eventKey='planning_opening_times' key='planning_opening_times'>
          <div className='tab-container'>
            <OpeningtimesEditTable {...props.planningLocation} />
          </div>
        </Tab.Pane>
      );
  }

  return (
    <>
      <Form onSubmit={props.submit.onClick}>
        <Tab.Container defaultActiveKey="main">
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="main">
                {translate('Main')}
              </Nav.Link>
            </Nav.Item>
            {formTabs}
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="main">
              <div className='tab-container'>
                {basicFields}
              </div>
            </Tab.Pane>
            {formTabContent}
          </Tab.Content>
        </Tab.Container>
        <Button variant="primary" type={props.submit.type}>
          {translate(props.submit.title)}
        </Button>
        <p className='form-required-text-hint'>{translate("Fields with * are required")}</p>
      </Form>
    </>
  );
}

export default LocationForm;