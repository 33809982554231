import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Tab, Nav } from 'react-bootstrap';
import moment from 'moment';

import CreateField from '../helpers/createField';
import { useTranslation } from 'react-i18next';


export default function ReservationForm(props) {
  const { t: translate } = useTranslation();

  if (props.data.hasOwnProperty('reservation_timestamp') && typeof (props.data.reservation_timestamp) === 'string') {
    let newTimestamp = props.data.reservation_timestamp.slice(0, 16)
    props.data.reservation_timestamp = moment(newTimestamp).format('YYYY-MM-DDTHH:mm')
  }

  if (props.data.hasOwnProperty('type')) {
    props.type.selected = { 'value': props.data.type, 'label': props.data.type }
  }

  if (props.data.hasOwnProperty('status')) {
    props.status.selected = { 'value': props.data.status, 'label': props.data.status }
  }

  if (props.data.hasOwnProperty('reservation_objects')) {
    // eslint-disable-next-line
    props.data.reservation_objects.map((reservation_object) => {
      props.reservation_objects.selected.push({ 'value': reservation_object.reservation_object_id, 'label': reservation_object.name ? reservation_object.name : reservation_object.title })
    })
  }

  let basicFields = []
  let formTabs = [];
  let formTabContent = [];
  props.fields.forEach(field => {
    if (field.type === 'object') {
      formTabs.push(
        <Nav.Item key={field.id}>
          <Nav.Link eventKey={field.id}>
            {translate(field.name)}
          </Nav.Link>
        </Nav.Item>
      );

      let objectFields = []
      field.fields.forEach(embeddedfield => {
        objectFields.push(CreateField(embeddedfield, props, field.id))
      });
      formTabContent.push(
        <Tab.Pane eventKey={field.id} key={field.id}>
          <div className='tab-container'>
            {objectFields}
          </div>
        </Tab.Pane>
      );
    } else {
      basicFields.push(CreateField(field, props))
    }
  });

  return (
    <>
      <Form onSubmit={props.submit.onClick}>
        <Tab.Container defaultActiveKey="main">
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="main">
                {translate('Main')}
              </Nav.Link>
            </Nav.Item>
            {formTabs}
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="main">
              <div className='tab-container'>
                {basicFields}
              </div>
            </Tab.Pane>
            {formTabContent}
          </Tab.Content>
        </Tab.Container>
        {/* <Button variant="primary" type={props.submit.type}>
          {translate(props.submit.title)}
        </Button> */}
        <Button variant="primary" type={props.submit.type} onClick={props.submit.onClick}>{translate(props.submit.title)}</Button>
        <Button variant="primary" type={props.cancel.type} onClick={props.cancel.onClick}>{translate(props.cancel.title)}</Button>
        <p className='form-required-text-hint'>{translate("Fields with * are required")}</p>
      </Form>
    </>
  );
}