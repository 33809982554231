import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setError, setResponseError } from "../../redux/dataSlices/errorSlice.js";
import { getToken } from "../../redux/dataSlices/tokenSlice.js";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice.js";
import { setLoading } from "../../redux/dataSlices/loadingSlice.js";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card.jsx";
import { planningObjectFormFields } from "./planningFormFields.jsx"
import HandleOnChange from "../../helpers/handleOnChange.jsx";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue.jsx"
import DeepCopy from "../../helpers/DeepCopy.jsx";
import { planningObjectTypeChoices } from "../../selectfieldchoices/planningselect.mjs";
import { PostPlanningObject } from "../../actablueAPI/planning/Planning.jsx";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice.js";
import PlanningObjectForm from "../../components/planningObjectForm.jsx";
import HandleOnChangeOpeningTimes from "../../helpers/handleOnChangeOpeningTimes.jsx";
import { validateFormObject } from "../../helpers/validateFormObject.jsx";
import { setActiveTab } from "../../redux/dataSlices/formTabSlice.js";

const PlanningObjectAdd = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [planningObject, setPlanningObject] = useState({ administration_id: admin_id, location_id: location_id, type: 'BUS' })
  const [invalidFields, setInvalidFields] = useState([]);

  const onChange = (event) => {
    let newPlanningObject = HandleOnChange({ event: event, object: planningObject })
    setPlanningObject(newPlanningObject)
  };

  const onTypeChange = (event) => {
    let newPlanningObject = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: planningObject, selectfield: 'type' })
    setPlanningObject(newPlanningObject)
  };

  const onChangeOpeningTimes = (event) => {
    let newPlanningObject = HandleOnChangeOpeningTimes({ event: event, object: planningObject })
    setPlanningObject(newPlanningObject)
  };

  const onCancel = () => {
    navigate("/planning");
  }

  const onSubmit = (event) => {
    const [invalid] = validateFormObject({ object: planningObject, fields: formData?.fields, switchTab: true, dispatch: dispatch })

    if (invalid?.length > 0) {
      setInvalidFields(invalid)
      return;
    }

    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    dispatch(setLoading(true))

    let newPlanningObject = DeepCopy(planningObject)
    newPlanningObject.type = planningObject.type.toUpperCase()

    if (newPlanningObject.opening_times) {
      for (const opening_time of newPlanningObject.opening_times) {
        opening_time.week_day = opening_time.day
        delete opening_time.day
      }
    }

    PostPlanningObject({ token: token, data: newPlanningObject })
      .then(() => {
        navigate("/planning");
      })
      .catch(error => {
        dispatch(setLoading(false))
        const type = error.response?.type
        const data = error.response?.data
        if (error.response) { dispatch(setResponseError({ type, data })) }
        if (!error.response) { dispatch(setError(JSON.stringify(error))) }
      })

  };

  const formData = {
    cancel: {
      title: 'Cancel',
      type: 'submit',
      onClick: onCancel
    },
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    invalidFields: invalidFields,
    fields: planningObjectFormFields,
    field: {
      onChange: onChange
    },
    data: planningObject,
    type: {
      name: 'type',
      options: planningObjectTypeChoices,
      selected: '',
      onChange: onTypeChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    opening_times: {
      onChange: onChangeOpeningTimes
    }
  };

  const addCard = {
    size: 12,
    title: "Planning object add",
    data: <PlanningObjectForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setActiveTab('main'));
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...addCard} />
    </>
  )
};
export default PlanningObjectAdd;
