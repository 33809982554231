import { createSlice } from "@reduxjs/toolkit";

export const locationSlice = createSlice({
  name: "location",
  initialState: {
    location_pagesize: 10,
    location_pagination: 0,
    hash_locations_all: {},
    hash_admin_locations_all: {},
  },
  reducers: {
    setLocationId: (state, action) => {
      state.location_id = action.payload;
    },
    setLocation: (state, action) => {
      state.location = action.payload;
    },
    setLocations: (state, action) => {
      state.locations = action.payload
    },
    setLocationsAllAdmins: (state, action) => {
      state.locations_all_admins = action.payload
      action.payload.forEach(location => {
        state.hash_locations_all[location.id] = location
      });
    },
    setLocationsAll: (state, action) => {
      state.locations_all = action.payload
      action.payload.forEach(location => {
        state.hash_admin_locations_all[location.id] = location
      });
    },
    setLocationModalShow: (state, action) => {
      state.location_modalshow = action.payload
    },
    setLocationPagination: (state, action) => {
      state.location_pagination = (action.payload - 1)
    },
    setLocationPagesize: (state, action) => {
      state.location_pagesize = action.payload
    },
    clearReduxLocation: state => {}
  }
});

export const {
  setLocationId, setLocation, setLocations, setLocationModalShow, setLocationPagination, setLocationPagesize, setLocationsAll, setLocationsAllAdmins,
  clearReduxLocation
} = locationSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getLocationId = (state) => state.location.location_id
export const getLocation = (state) => state.location.location
export const getLocations = (state) => state.location.locations
export const getLocationsAll = (state) => state.location.locations_all
export const getLocationsAllAdmins = (state) => state.location.locations_all_admins
export const getHashLocationsAll = (state) => state.location.hash_locations_all
export const getHashAdminLocationsAll = (state) => state.location.hash_admin_locations_all
export const getLocationsHighlights = (state) => state.location.locationshighlights
export const getLocationModalShow = (state) => state.location.location_modalshow
export const getLocationPagination = (state) => state.location.location_pagination
export const getLocationPagesize = (state) => state.location.location_pagesize

export default locationSlice.reducer;