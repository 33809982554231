import axios from "axios";

const GetStockPaginated = ({token, admin_id, location_id, page, size}) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_BASE_ENV.concat("/warehouse/stock/paginated"),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json',
        'administration': admin_id,
        'location': location_id
      },
      params: {
        page: page,
        pagesize:size
      }

    })
    .then(response => {
      resolve(response)
    })
    .catch(error => {
      reject(error)
    });
  });
};

export default GetStockPaginated