import { isExpired } from "react-jwt"
import axios from "axios";
import { setToken } from '../redux/dataSlices/tokenSlice';

export default async function IsTokenExpiredV2(props) {
  const storedToken = props.token
  const dispatch = props.dispatch
  let newToken = storedToken

  const access_token_expired = isExpired(storedToken.access_token)
  const refresh_token_expired = isExpired(storedToken.refresh_token)

  // calculate delta time 
  const expiretime = storedToken.payload.exp // in seconds
  const now = Date.now() / 1000 // original in milliseconds
  const delta = expiretime - now

  if (refresh_token_expired) {
    newToken = undefined
    dispatch(setToken())
    return false
  }

  if (access_token_expired || delta < 60) {
    // console.log('access_token_expired or delta < 60', access_token_expired)
    await axios({
      method: 'post',
      url: `${process.env.REACT_APP_KEYCLOAK_URL}/auth/realms/${process.env.REACT_APP_KEYCLOAK_REALM}/protocol/openid-connect/token`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: {
        client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
        grant_type: 'refresh_token',
        refresh_token: storedToken.refresh_token
      }
    })
      .then(response => {
        dispatch(setToken(response.data))
        newToken = response.data
      })
      .catch(error => {
        console.error('token refresh error', error);
        newToken = undefined
        dispatch(setToken())
      })
  }

  return newToken

}