import React from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setImportProfiles, getImportProfiles } from "../../redux/dataSlices/importSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import BasicCard from "../../components/card"
import ImportProfileTable from "../../components/importProfileTable";
import HandleError from "../../helpers/handleError";
import GetImportProfilesAll from "../../actablueAPI/import/GetImportProfilesAll";

const ImportProfiles = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const import_profiles = useSelector(getImportProfiles)
  const dispatch = useDispatch();

  const importProfileCard = {
    size: 12,
    title: "Import Profiles",
    data: <ImportProfileTable {...import_profiles} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle("Import Profiles"))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setLoading(true))
    GetImportProfilesAll({token:token, admin_id:admin_id, location_id:location_id})
    .then(response => {
      dispatch(setImportProfiles(response))
      dispatch(setLoading(false))
      })
    .catch(error => {
      HandleError(error)
      dispatch(setLoading(false))
    })
    // eslint-disable-next-line
  }, [location_id]);

  return (
    <>
      <BasicCard {...importProfileCard} />
    </>
  )
};
export default ImportProfiles;
