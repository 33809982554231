import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card"
import HandleOnChange from "../../helpers/handleOnChange";
import { getChannelsEmail, getHashTemplatesAll, getTemplatesAll, setChannelsEmail } from "../../redux/dataSlices/notificationSlice";
import { NotificationChannelsEmailFormFields } from "./notificationsFormFields";
import NotificationChannelsEmailForm from "../../components/notificationChannelsEmailForm";
import HandleOnChangeSingleSelectFieldWithObject from "../../helpers/handleOnChangeSingleSelectFieldWithObject";
import PostNotificationChannelsEmail from "../../actablueAPI/notifications/PostNotificationChannelsEmail";
import { useTranslation } from "react-i18next";

const NotificationChannelsEdit = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const channel = useSelector(getChannelsEmail)
  const templates_all = useSelector(getTemplatesAll)
  const hash_templates_all = useSelector(getHashTemplatesAll)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t: translate } = useTranslation();

  const onChange = (event) => {
    let newChannel = HandleOnChange({ event: event, object: channel })
    dispatch(setChannelsEmail(newChannel))
  }

  const onHandleOnChangeSingleSelectFieldWithObject = (event) => {
    let newChannel = HandleOnChangeSingleSelectFieldWithObject({ event: event, object: channel, selectfield: 'template', hash: hash_templates_all })
    dispatch(setChannelsEmail(newChannel))
  }

  const onSubmit = () => {
    PostNotificationChannelsEmail({ token: token, admin_id: admin_id, data: channel })
      .then(() => {
        navigate("/notifications");
      })
  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    fields: NotificationChannelsEmailFormFields(),
    field: {
      onChange: onChange
    },
    data: channel,
    template: {
      label: 'tenplate',
      options: templates_all,
      onChange: onHandleOnChangeSingleSelectFieldWithObject,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
  };

  const card = {
    size: 12,
    title: translate('Channel Edit', {name: channel?.name || "N/A"}),
    data: <NotificationChannelsEmailForm {...formData} />,
  };

  return (
    <>
      <BasicCard {...card} />
    </>
  )

};
export default NotificationChannelsEdit;

