import { Button, Table } from 'react-bootstrap'
import TableButton from './tableButton';
import StockButtons from '../portal/stock/stockbuttons';
import StockModal from './stockModal';
import { useTranslation } from 'react-i18next';
import PaginationComponent from './pagination';
import PaginationAndSizeComponent from './paginationAndSize';


export default function StockTable(stock) {
  const buttons = StockButtons.call()
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const add = buttons.hasOwnProperty('add') ? Object.assign({}, buttons.add) : false
  const exportStock = buttons.hasOwnProperty('export') ? Object.assign({}, buttons.export) : false
  const { t: translate } = useTranslation();

  let pagination = buttons.pagination
  let pagesize = buttons.pagesize

  let stockRows = []
  const pages = stock.total_pages
  const page = (stock.number + 1)

  if (stock && stock.content && stock.content.length > 0) {
    for (let stockProduct of Object.values(stock.content)) {
      const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit, { id: stockProduct.id }) : false
      let stockDetail = []
      if (stockProduct.product.product_locations) {
        for (const stockLocation of stockProduct.product.product_locations  ) {
          stockDetail.push(
            <tr>
              <td>{stockLocation.warehouse_name}</td>
              <td>{stockLocation.rack_name}</td>
              <td>{stockLocation.quantity}</td>
            </tr>
          )
        }
      } 

      stockRows.push(
        <tr className='category-row' key={stockProduct.id}>
          <td>{stockProduct.product?.sku_code}</td>
          <td>{stockProduct.product?.name}</td>
          <td>{stockProduct.quantity}</td>
          <td>
            <Table width={100}>
              <thead>
                <tr>
                  <th>{translate('Warehouse')}</th>
                  <th>{translate('Rack')}</th>
                  <th>{translate('Quantity')}</th>
                </tr>
              </thead>
              <tbody>
                {stockDetail}
              </tbody>
            </Table>
          </td>
          <td>
            <Button size="sm" type='button' onClick={() => { modalview.onClick(stockProduct) }} id={stockProduct.id}>{translate(modalview.tag)}</Button>
            {edit && <Button size="sm" type='button' onClick={() => { edit.onClick(stockProduct) }} id={stockProduct.id}>{translate(edit.tag)}</Button>}
          </td>
        </tr>
      )
    }
  }
  return (
    <>
      {exportStock && <TableButton {...exportStock} />}{add && <TableButton {...add} />}
      {<PaginationAndSizeComponent pagination={pagination} pagesize={pagesize} pages={pages} page={page} />}
      <Table width='100%'>
        <thead>
          <tr key='header'>
            <th>{translate('SKU code')}</th>
            <th>{translate('Name')}</th>
            <th>{translate('Stock Total')}</th>
            <th>{translate('Stock Detail')}</th>
            <th>{translate('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {stockRows}
        </tbody>
        <StockModal modal={modalview.modal} />
      </Table>
    </>
  );
}