import React from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import BasicCard from "../../components/card";
import GuestCheckTable from "../../components/guestcheckTable";
import { getGuestChecks, setGuestChecks } from "../../redux/dataSlices/guestcheckSlice";
import HandleError from "../../helpers/handleError";
import GetGuestChecksStatus from "../../actablueAPI/guestchecks/GetGuestCheckByState";

const Guestchecks = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const guestchecks = useSelector(getGuestChecks)
  const dispatch = useDispatch();
  const navTitle = "Guestchecks"

  const card = {
    size: 12,
    title: navTitle,
    data: <GuestCheckTable guestchecks={guestchecks} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setLoading(true))
    GetGuestChecksStatus({token:token, admin_id:admin_id, location_id:location_id, state:"OPEN"})
    .then(response => {
      dispatch(setGuestChecks(response.data))
      dispatch(setLoading(false))
    })
    .catch(error => {
      HandleError(error)
      dispatch(setLoading(false))
    })
    // eslint-disable-next-line
  }, [admin_id, location_id]);

  return (
    <>
      <BasicCard {...card} />
    </>
  )
};
export default Guestchecks;
