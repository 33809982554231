import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card"
import HandleOnChange from "../../helpers/handleOnChange";
import { getTemplate, setTemplate } from "../../redux/dataSlices/notificationSlice";
import { NotificationTemplateFormFields } from "./notificationsFormFields";
import NotificationTemplateForm from "../../components/notificationTemplateForm";
import HandleError from "../../helpers/handleError";
import PostNotificationTemplate from "../../actablueAPI/notifications/PostNotificationTemplate";
import PostNotificationTemplateTemplate from "../../actablueAPI/notifications/PostNotificationTemplateTemplate";
import { useTranslation } from "react-i18next";

const NotificationTemplateEdit = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const template = useSelector(getTemplate)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [file, setFile] = useState({})
  const { t: translate } = useTranslation();

  const onChange = (event) => {
    let newTemplate = HandleOnChange({ event: event, object: template })
    if (event.target?.files) {
      setFile(event.target?.files[0])
    }
    dispatch(setTemplate(newTemplate))
  }

  const onSubmit = () => {
    let newTemplate = Object.assign({}, template)
    delete newTemplate.file

    axios.all([
      PostNotificationTemplate({ token: token, admin_id: admin_id, data: template }),
      PostNotificationTemplateTemplate({ token: token, admin_id: admin_id, id: newTemplate.id, data: { file: file } })
    ])
      .then(axios.spread(() => {
        navigate("/notifications");
      }))
      .catch(error => {
        HandleError(error)
      })
  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    fields: NotificationTemplateFormFields(),
    field: {
      onChange: onChange
    },
    data: template,
  };

  const card = {
    size: 12,
    title: translate('Template Edit', {name: template?.name || "N/A"}),
    data: <NotificationTemplateForm {...formData} />,
  };

  return (
    <>
      <BasicCard {...card} />
    </>
  )

};
export default NotificationTemplateEdit;

