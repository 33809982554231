import { createSlice } from "@reduxjs/toolkit";

export const orderSlice = createSlice({
  name: "order",
  initialState: {
    order_pagesize: 10,
    order_pagination: 0,
  },
  reducers: {
    setOrderId: (state, action) => {
      state.order_id = action.payload;
    },
    setOrder: (state, action) => {
      state.order = action.payload;
    },
    setOrders: (state, action) => {
      state.orders = action.payload
    },
    editOrders: (state, action) => {
      for (let order in state.orders.content) {
        if (state.orders.content[order].id === action.payload.id) {
          state.orders.content[order] = action.payload
        }
      }
    },
    setOrdersHighLights: (state, action) => {
      state.ordershighlights = action.payload
    },
    setOrderModalShow: (state, action) => {
      state.ordermodalshow = action.payload
    },
    setOrderPagination: (state, action) => {
      state.order_pagination = (action.payload - 1)
    },
    setOrderPagesize: (state, action) => {
      state.order_pagesize = action.payload
    },
  }
});

export const {
  setOrderId, setOrder, setOrders, editOrders, setOrdersHighLights, setOrderModalShow, setOrderPagination, setOrderPagesize,
} = orderSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getOrderId = (state) => state.order.order_id
export const getOrder = (state) => state.order.order
export const getOrders = (state) => state.order.orders
export const getOrdersHighlights = (state) => state.order.ordershighlights
export const getOrderModalShow = (state) => state.order.ordermodalshow
export const getOrderPagination = (state) => state.order.order_pagination
export const getOrderPagesize = (state) => state.order.order_pagesize

export default orderSlice.reducer;