import Table from 'react-bootstrap/Table';

import { useTranslation } from 'react-i18next';

function CardHeaderTable(props) {
  let datalines = []
  const { t: translate } = useTranslation();

  for (let item in props) {
    for (const [key, value] of Object.entries(props[item])) {
      datalines.push(
        <td className="col-3" key={key}>
          <p align='center'>
            {translate(key)}
          </p>
          <h1 align='center'>{value}</h1>
        </td>
      )
    }
  }

  if (props) {
    return (
      <Table hover width='100%'>
        <tbody>
          <tr>
            {datalines}
          </tr>
        </tbody>
      </Table>
    );
  }
  return <>No props</>
}

export default CardHeaderTable;