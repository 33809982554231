const websiteUrl = window.location.href;

export const ReservationLocationAddFormFields = () => {
  let reservationLocationAddFormFields = [
    // single option fields
    { type: 'select', name: `Location`, id: 'id', formdatafield: 'id' },
    { type: 'hidden', name: 'administration_id', id: 'administration_id', readonly: true },
    // {type:'text', name:'name', id:'name'},
    { type: 'select', name: `Application`, id: 'application', formdatafield: 'application' },
    { type: 'number', name: `Capacity`, id: 'capacity' },
    { type: 'number', name: `Duration`, id: 'duration' },
    { type: 'number', name: `Walk-in-time`, id: 'walk_in_time' },
    { type: 'number', name: `Walk-out-time`, id: 'walk_out_time' },

    { type: 'checkbox', name: `Eco`, id: 'eco' },
    { type: 'checkbox', name: `Can reserve events`, id: 'can_reserve_events' },
    { type: 'checkbox', name: `Automatically accept reservations`, id: 'automatically_accept_reservations' },

    // multi option select fields + self create
    { type: 'multi+create', name: `Image URLs`, id: 'img_urls', formdatafield: 'img_urls' },
    { type: 'multi+create', name: `Areas`, id: 'areas', formdatafield: 'areas' },

    { type: 'select', name: `Timezone`, id: 'timezone', formdatafield: 'timezone' },
  ]

  if (websiteUrl.includes('humbee')) {
    reservationLocationAddFormFields = [
      // single option fields
      { type: 'select', name: `Location`, id: 'id', formdatafield: 'id' },
      { type: 'hidden', name: 'administration_id', id: 'administration_id', readonly: true },
      // {type:'text', name:'name', id:'name'},

      { type: 'number', name: `Capacity`, id: 'capacity' },
      { type: 'number', name: `Duration`, id: 'duration' },
      { type: 'checkbox', name: `Automatically accept reservations`, id: 'automatically_accept_reservations' },

    ]
  }

  return reservationLocationAddFormFields
}

export const ReservationLocationEditFormFields = () => {

  let reservationLocationEditFormFields = [
    // single option fields
    { type: 'select', name: `Location`, id: 'id', formdatafield: 'id' },
    { type: 'hidden', name: 'administration_id', id: 'administration_id', readonly: true },
    // {type:'text', name:'name', id:'name'},

    { type: 'select', name: `Application`, id: 'application', formdatafield: 'application' },
    { type: 'number', name: `Capacity`, id: 'capacity' },
    { type: 'number', name: `Duration`, id: 'duration' },
    { type: 'number', name: `Walk-in-time`, id: 'walk_in_time' },
    { type: 'number', name: `Walk-out-time`, id: 'walk_out_time' },

    { type: 'checkbox', name: `Eco`, id: 'eco' },
    { type: 'checkbox', name: `Can reserve events`, id: 'can_reserve_events' },
    { type: 'checkbox', name: `Automatically accept reservations`, id: 'automatically_accept_reservations' },

    // multi option select fields + self create
    { type: 'multi+create', name: `Image URLs`, id: 'img_urls', formdatafield: 'img_urls' },
    { type: 'multi+create', name: `Areas`, id: 'areas', formdatafield: 'areas' },

    { type: 'select', name: `Timezone`, id: 'timezone', formdatafield: 'timezone' },

  ]

  if (websiteUrl.includes('humbee')) {
    reservationLocationEditFormFields = [
      // single option fields
      { type: 'select', name: `Location`, id: 'id', formdatafield: 'id' },
      { type: 'hidden', name: 'administration_id', id: 'administration_id', readonly: true },
      // {type:'text', name:'name', id:'name'},

      { type: 'number', name: `Capacity`, id: 'capacity' },
      { type: 'number', name: `Duration`, id: 'duration' },
      { type: 'checkbox', name: `Automatically accept reservations`, id: 'automatically_accept_reservations' },

    ]
  }

  return reservationLocationEditFormFields
}