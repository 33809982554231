import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card"
import HandleOnChange from "../../helpers/handleOnChange";
import { NotificationTemplateFormFields } from "./notificationsFormFields";
import NotificationTemplateForm from "../../components/notificationTemplateForm";
import HandleError from "../../helpers/handleError";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import PostNotificationTemplate from "../../actablueAPI/notifications/PostNotificationTemplate";
import PostNotificationTemplateTemplate from "../../actablueAPI/notifications/PostNotificationTemplateTemplate";

const NotificationTemplateAdd = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id)
  const navigate = useNavigate();
  const [template, setTemplate] = useState({ administration_id: admin_id, location_id: location_id })
  const [file, setFile] = useState({})

  const onChange = (event) => {
    let newTemplate = HandleOnChange({ event: event, object: template })
    if (event.target?.files) {
      setFile(event.target?.files[0])
    }
    setTemplate(newTemplate)
  }

  const onSubmit = () => {
    let newTemplate = Object.assign({}, template)
    delete newTemplate.file

    PostNotificationTemplate({ token: token, admin_id: admin_id, data: template })
      .then((responseTemplates) => {
        PostNotificationTemplateTemplate({ token: token, admin_id, id: responseTemplates.data.id, data: { file: file } })
          .then(() => {
            navigate("/notifications");
          })
          .catch((error) => {
            HandleError(error)
          })
      })
      .catch((error) => {
        HandleError(error)
      })
  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    fields: NotificationTemplateFormFields(),
    field: {
      onChange: onChange
    },
    data: template,
  };

  const card = {
    size: 12,
    title: "Template add",
    data: <NotificationTemplateForm {...formData} />,
  };

  return (
    <>
      <BasicCard {...card} />
    </>
  )

};
export default NotificationTemplateAdd;

