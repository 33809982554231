import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getLocationsAll, setLocationsAll, getHashLocationsAll } from "../../redux/dataSlices/locationSlice";
import { setReservationLocationsAll, getHashReservationLocationsAll } from "../../redux/dataSlices/reservationSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import BasicCard from "../../components/card"
import LocationTable from "../../components/locationTable";
import HandleError from "../../helpers/handleError";
import GetLocationsList from "../../actablueAPI/locations/GetLocationsList";
import GetReservationLocationList from "../../actablueAPI/reservations/GetReservationLocationList";
import { getScopes } from "../../redux/dataSlices/scopesSlice";
import { GetAllPlanningLocations } from "../../actablueAPI/planning/Planning";
import { getHashPlanningLocationsAll, setPlanningLocationsAll } from "../../redux/dataSlices/planningSlice";

const Locations = () => {
  const token = useSelector(getToken);
  const scopes = useSelector(getScopes);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const locations = useSelector(getLocationsAll)
  const hash_locations_all = useSelector(getHashLocationsAll)
  const hash_reservation_locations_all = useSelector(getHashReservationLocationsAll)
  const hash_planning_locations_all = useSelector(getHashPlanningLocationsAll)
  const dispatch = useDispatch();

  const locationsCard = {
    size: 12,
    title: "Locations",
    data: <LocationTable locations={locations} hash_locations_all={hash_locations_all} hash_reservation_locations_all={hash_reservation_locations_all}
                         hash_planning_locations_all={hash_planning_locations_all} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle("Locations"))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setLoading(true))
    GetLocationsList({ token: token, admin_id: admin_id })
      .then(response => {
        dispatch(setLocationsAll(response.data))
        if (!scopes.reservations) dispatch(setLoading(false))
      })
      .catch(error => {
        HandleError(error)
        if (!scopes.reservations) dispatch(setLoading(false))
      })

    if (scopes.reservations) {
      GetReservationLocationList({ token: token, admin_id: admin_id })
        .then(response => {
          dispatch(setReservationLocationsAll(response.data))
          if (!scopes.planning) dispatch(setLoading(false))
        })
        .catch(error => {
          HandleError(error)
          if (!scopes.planning) dispatch(setLoading(false))
        })
    }

    if (scopes.planning) {
      GetAllPlanningLocations({ token: token, admin_id: admin_id })
        .then(response => {
          dispatch(setPlanningLocationsAll(response))
          dispatch(setLoading(false))
        })
        .catch(error => {
          HandleError(error)
          dispatch(setLoading(false))
        })
    }
    // eslint-disable-next-line
  }, [, admin_id, location_id]);

  return (
    <>
      <BasicCard {...locationsCard} />
    </>
  )
};
export default Locations;
