import React, { useEffect } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import BasicCard from "../../components/card"
import HandleError from "../../helpers/handleError";
import GetPrograms from "../../actablueAPI/loyalty/GetPrograms";
import { getCardPageSize, getCardPagination, getCards, getProgramPageSize, getProgramPagination, getPrograms, setCards, setPrograms } from "../../redux/dataSlices/loyaltySlice";
import ProgramsTable from "../../components/programTable";
import { getHashAdministrationsAll } from "../../redux/dataSlices/administrationSlice";
import { getHashLocationsAll } from "../../redux/dataSlices/locationSlice";
import GetCards from "../../actablueAPI/loyalty/GetCards";
import CardsTable from "../../components/cardTable";

const Loyalty = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const hash_admins_all = useSelector(getHashAdministrationsAll);
  const hash_locations_all = useSelector(getHashLocationsAll);
  const programPage = useSelector(getProgramPagination);
  const programSize = useSelector(getProgramPageSize);
  const programs = useSelector(getPrograms);
  const cardPage = useSelector(getCardPagination);
  const cardSize = useSelector(getCardPageSize);
  const cards = useSelector(getCards);
  const dispatch = useDispatch();

  const programsCard = {
    size: 12,
    title: "Programs",
    data: <ProgramsTable programs={programs} hash_admins_all={hash_admins_all} hash_locations_all={hash_locations_all} />,
  };

  const cardsCard = {
    size: 12,
    title: "Cards",
    data: <CardsTable cards={cards} hash_admins_all={hash_admins_all} hash_locations_all={hash_locations_all} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle("Loyalty"))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setLoading(true))
    axios.all([
      GetPrograms({ token: token, admin_id: admin_id, location_id: location_id, page: programPage, size: programSize }),
      GetCards({ token: token, admin_id: admin_id, location_id: location_id, page: cardPage, size: cardSize })
    ])
      .then(axios.spread((programsData, cardsData) => {
        dispatch(setPrograms(programsData?.data));
        dispatch(setCards(cardsData?.data))
      }))
      .catch(error => {
        HandleError(error)
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
    // eslint-disable-next-line
  }, [, admin_id, location_id, programPage, programSize, cardPage, cardSize]);

  return (
    <>
      <BasicCard {...programsCard} />
      <BasicCard {...cardsCard} />
    </>
  )
};
export default Loyalty;

