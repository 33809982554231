import { Button, Table } from 'react-bootstrap'
import TableButton from './tableButton';
import DeviceButtons from '../portal/devices/devicebuttons';
import DeviceModal from './deviceModal';
import { useTranslation } from 'react-i18next';
import { getRole } from '../redux/dataSlices/tokenSlice';
import './css/productTable.css';
import { useSelector } from 'react-redux';

export default function DeviceTable(props) {
  const role = useSelector(getRole)
  let devices = props.devices
  const hash_admins = props.hashAdmins
  const hash_locs = props.hashLocs
  const maxNumberOfDevices = props.maxNumberOfDevices
  const buttons = DeviceButtons.call()
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false    
  let add = buttons.hasOwnProperty('add') ? Object.assign({}, buttons.add) : false
  const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit) : false
  const { t: translate } = useTranslation();
  
  if (role !== 'super' && role !== 'admin') {
    devices = props.devices?.slice(0, maxNumberOfDevices)
    add = props.devices?.length < maxNumberOfDevices && buttons.hasOwnProperty('add') ? Object.assign({}, buttons.add) : false
  }

  let rows = []
  if (devices) {
    
    for (const device of devices) {
      let version = device.info?.version || 'N/A'
      rows.push(
        <tr key={device.id} className='product-row'>
          <td>{device.name}</td>
          <td>{device.type}</td>
          <td><div className='table-icons'>{device?.active ? <i className="bi bi-check-circle true"></i> : <i className="bi bi-x-square false"></i>}</div></td>
          <td>{hash_admins[device.administration_id] ? hash_admins[device.administration_id].name : ''}</td>
          <td>{hash_locs[device.location_id] ? hash_locs[device.location_id].name : ''}</td>
          <td>{device.serial_number}</td>
          <td>{version}</td>
          {/* <td><div className='table-icons'>{device?.active ? <i className="bi bi-check-circle true" onClick={() => { toggleIsVisible.onClick(category, refresh) }}></i> : <i className="bi bi-x-square false" onClick={() => { toggleIsVisible.onClick(category, refresh) }}></i>}</div></td> */}
          <td>
            <Button size="sm" type='button' onClick={() => { modalview.onClick(device) }} id={device.id}>{translate(modalview.tag)}</Button>
            {edit && <Button size="sm" type='button' onClick={() => { edit.onClick(device) }} id={device.id}>{translate(edit.tag)}</Button>}
          </td>
        </tr>
      )
    }
  }
  
  return (
    <>
      {add && <TableButton {...add} />}
      <Table hover width='100%'>
        <thead>
          <tr key='header'>
            <th width='15%'>{translate('Name')}</th>
            <th width='5%'>{translate('Type')}</th>
            <th width='5%'>{translate('Active')}</th>
            <th width='15%'>{translate('Administration')}</th>
            <th width='15%'>{translate('Location')}</th>
            <th width='5%'>{translate('Serial Number')}</th>
            <th width='5%'>{translate('Version')}</th>
            <th width='15%'>{translate('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
        <DeviceModal modal={modalview.modal} />
      </Table>
    </>
  );
}