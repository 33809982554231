import axios from "axios";

const GetProductLink = async ({ token, product_id, admin_id, location_id }) => {
  return new Promise((resolve, reject) => {
    let opticonResponse = null
    let schapkaartjesResponse = null

    axios({
      method: 'get',
      url: process.env.REACT_APP_API_BASE_ENV.concat("/opticon/product/", product_id),
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        opticonResponse = response
      })
      .finally(() => {
        axios({
          method: 'get',
          url: process.env.REACT_APP_API_BASE_ENV.concat("/schapkaartjes/link"),
          headers: {
            'Authorization': `Bearer ${token['access_token']}`,
            'Content-Type': 'application/json'
          },
          params: {
            'product_id': product_id,
            'admin_id': admin_id,
            'location_id': location_id
          }
        })
          .then(response => {
            schapkaartjesResponse = response
            let productEsl = schapkaartjesResponse?.data;
            if (opticonResponse && opticonResponse?.data) productEsl = Object.assign({}, opticonResponse?.data, schapkaartjesResponse?.data);
            resolve(productEsl);
          })
          .catch(error => {
            if (error.response?.status === 500) {
              resolve(error.response.data)
            }
            if (error.response?.status === 404) {
              resolve(error.response.data)
            }
            reject(error);
          })
      })
  });
};

export default GetProductLink