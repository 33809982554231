import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSubscriptions, getSubscription } from "../../redux/dataSlices/subscriptionSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id, getSelectedLocation } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import BasicCard from "../../components/card"
import AddSubscriptionForm from "../../components/addSubscriptionForm";
import { addSubscriptionFormFields, createNewSubscriptionFormFields } from "./addSubscriptionFormFields.mjs";
import HandleOnChange from "../../helpers/handleOnChange";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import { countryCodeChoices } from "../../selectfieldchoices/country_code.mjs";
import PostRegistrationByIdSubscription from "../../actablueAPI/registrations/PostRegistrationByIdSubscription";
import PostRegistration from "../../actablueAPI/registrations/PostRegistration";

const SubscribeAddSubscription = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const selected_location = useSelector(getSelectedLocation)
  const your_subscriptions = useSelector(getSubscriptions)
  const hash_your_subscriptions = {}
  const add_subscription = useSelector(getSubscription)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [toRegistration, setToRegistration] = useState({})

  for (const subscription of Object.values(your_subscriptions)) {
    hash_your_subscriptions[subscription.id] = subscription
  }

  const onChange = (event) => {
    let newToRegistration = HandleOnChange({ event: event, object: toRegistration })
    setToRegistration(newToRegistration)
  }

  const onRegistrationIdChange = (event) => {
    let newToRegistration = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: toRegistration, selectfield: 'registration_id' })
    setToRegistration(newToRegistration)
  }

  const onCountryCodeChange = (event) => {
    let newToRegistration = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: toRegistration, selectfield: 'country_code' })
    setToRegistration(newToRegistration)
  }

  const onSubmit = () => {
    // create start and end
    const start = new Date()
    let stop = new Date()
    stop.setMonth(stop.getMonth() + add_subscription.duration)

    // create object
    let newSubscription = {}
    newSubscription.administration_id = admin_id
    newSubscription.location_id = location_id
    // newSubscription.name = add_subscription.name
    newSubscription.start = start.toISOString()
    newSubscription.stop = stop.toISOString()
    newSubscription.interval = add_subscription.interval
    newSubscription.grace_period = add_subscription.grace_period
    newSubscription.cancel_period = add_subscription.cancel_period
    newSubscription.duration = add_subscription.duration
    newSubscription.prolongation = add_subscription.prolongation


    if (your_subscriptions.length > 0) {
      newSubscription.id = add_subscription.id

      dispatch(setLoading(true))
      PostRegistrationByIdSubscription({token:token, admin_id:admin_id, registration_id:toRegistration.registration_id, data:newSubscription})
      .then(response => {
        navigate("/subscribe")
      })
      dispatch(setLoading(false))
    }

    if (your_subscriptions.length === 0) {
      newSubscription.subscription_id = add_subscription.id

      let newRegistration = {}
      newRegistration.user_id = token.payload.sub
      newRegistration.administration_id = admin_id
      newRegistration.location_id = location_id
      newRegistration.name = selected_location.name
      newRegistration.person = {}
      newRegistration.person.formal = toRegistration.formal
      newRegistration.person.initials = toRegistration.initials
      newRegistration.person.first_name = toRegistration.first_name
      newRegistration.person.middle_name = toRegistration.middle_name
      newRegistration.person.sur_name = toRegistration.sur_name
      newRegistration.person.gender = toRegistration.gender
      newRegistration.addresses = [{
        type: 'BILLING',
        street: toRegistration.street,
        street_number: toRegistration.street_number,
        street_additional_number: toRegistration.street_additional_number,
        zip_code: toRegistration.zip_code,
        place: toRegistration.place,
        proverence: toRegistration.proverence,
        country: toRegistration.country,
      }]
      newRegistration.emails = [{
        type: 'BILLING',
        email: toRegistration.email
      }]
      newRegistration.phone_numbers = [{
        type: 'BILLING',
        number: toRegistration.phone_number
      }]
      newRegistration.coc = toRegistration.coc
      newRegistration.iban = toRegistration.iban
      newRegistration.bic = toRegistration.bic
      newRegistration.website_url = toRegistration.website_url
      newRegistration.vat_number = toRegistration.vat_number
      newRegistration.country_code = toRegistration.country_code
      newRegistration.subscriptions = []
      newRegistration.subscriptions.push(add_subscription.id)
      newRegistration.subscription_settings = []
      newRegistration.subscription_settings.push(newSubscription)

      dispatch(setLoading(true))
      PostRegistration({token:token, data:newRegistration})
      .then(response => {
        navigate("/subscribe")
        dispatch(setLoading(false))
      })
      

    }

  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    fields: your_subscriptions.length > 0 ? addSubscriptionFormFields : createNewSubscriptionFormFields,
    field: {
      onChange: onChange
    },
    data: toRegistration,
    registration_id: {
      label: 'registration_id',
      options: your_subscriptions,
      hash: hash_your_subscriptions,
      selected: {},
      onChange: onRegistrationIdChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    country_code: {
      label: 'country_code',
      options: countryCodeChoices,
      selected: [],
      onChange: onCountryCodeChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
  }

  const card = {
    size: 12,
    title: "Add subscription",
    data: <AddSubscriptionForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle("Add subscription"))
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...card} />
    </>
  )
};
export default SubscribeAddSubscription;
