export default function RemoveObject(props) {
    const event = props.event
    const object = props.object
    const checkedList = props.checkedList

    const field = event.target?.attributes?.name?.nodeValue

    let newObject = Object.assign({}, object)

    let newObjectList = []
    for (let object of newObject[field]) {
      if (!checkedList.includes(object.id)) {newObjectList.push(object)}
    }
    newObject[field] = newObjectList

    return (newObject)
}