import { createSlice } from "@reduxjs/toolkit";

export const keycloakSlice = createSlice({
  name: "keycloak",
  initialState: {
    keycloak_pagesize: 10,
    keycloak_pagination: 0,
    hash_keycloak_users_all: {},
  },
  reducers: {
    setKeycloakUser: (state, action) => {
      state.keycloak_user = action.payload;
    },
    setKeycloakUsers: (state, action) => {
      state.keycloak_users = action.payload
    },
    setKeycloakUsersAll: (state, action) => {
      state.keycloak_users_all = action.payload
      action.payload.forEach(keycloak => {
        state.hash_keycloak_users_all[keycloak.id] = keycloak
      });
    },
    setKeycloakUserModalShow: (state, action) => {
      state.keycloak_user_modalshow = action.payload
    },
    setKeycloakUserPagination: (state, action) => {
      state.keycloak_user_pagination = (action.payload - 1)
    },
    setKeycloakUserPagesize: (state, action) => {
      state.keycloak_user_pagesize = action.payload
    },
    setFilter: (state, action) => {
      state.filter = action.payload
    },
    setSearch: (state, action) => {
      state.search = action.payload
    }
  }
});

export const {
  setKeycloakUser, setKeycloakUsers, setKeycloakUsersAll, setKeycloakUserModalShow, setKeycloakUserPagesize, setKeycloakUserPagination, setFilter, setSearch
} = keycloakSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const getKeycloakUser = (state) => state.keycloak.keycloak_user
export const getKeycloakUsers = (state) => state.keycloak.keycloak_users
export const getKeycloakUsersAll = (state) => state.keycloak.keycloak_users_all
export const getHashKeycloakUsersAll = (state) => state.keycloak.hash_keycloak_users_all
export const getKeycloakUserModalShow = (state) => state.keycloak.keycloak_user_modalshow
export const getKeycloakUserPagination = (state) => state.keycloak.keycloak_user_pagination
export const getKeycloakUserPagesize = (state) => state.keycloak.keycloak_user_pagesize
export const getFilter = (state) => state.keycloak.filter
export const getSearch = (state) => state.keycloak.search

export default keycloakSlice.reducer;