import { useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { setVat, setVatId, setVatModalShow, getVatModalShow, setVatPagination, setVatPagesize, getVatPagesize } from "../../redux/dataSlices/vatSlice";
import { getScopes } from "../../redux/dataSlices/scopesSlice";

export default function VatButtons() {
  const modalShow = useSelector(getVatModalShow)
  const scopes = useSelector(getScopes)
  const pageSize = useSelector(getVatPagesize)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function onViewClick(vat) {
    dispatch(setVat(vat));
    dispatch(setVatModalShow(true));
  };

  const onViewHide = (event) => {
    dispatch(setVatModalShow(false));
  };

  const onclickEdit = (vat) => {
    dispatch(setVat(vat));
    dispatch(setVatId(vat.id));
    navigate("/vat/edit");
  };

  const onclickAdd = (event) => {
    dispatch(setVatId(event.target.id));
    navigate("/vat/add");
  };

  const onPaginationClick = (event) => {
    dispatch(setVatPagination(event.target.value))
  }

  const onProductPageSizeChange = (event) => {
    dispatch(setVatPagesize(event.value))
  };
  let buttons = {
    modalview: {
      tag: "View",
      onClick: onViewClick,
      modal: {
        show: modalShow,
        onHide: onViewHide
      }
    },
    pagination: {
      maxbuttons: 5, //uneven number
      onClick:onPaginationClick
    },
    pagesize: {
      className: 'navbar-select',
      label:"page size selector",
      placeholder:"No selecetion",
      pagesize:pageSize,
      selected:{'id':pageSize, 'name':pageSize},
      number_of_elements: 0,
      options: pageSize,
      onChange:onProductPageSizeChange
    },
  }

  // add buttons based on scopes
  // scopeOptions = ['read', 'create', 'update', 'delete'] (use index: 0:read, 1:create, 2:update, 3:delete)
  // buttonOptions = {'get':0, 'new':1, 'edit':2, 'add':2, 'del':3, 'swap':2, 'move':2, 'import':1, 'create': 1, 'export': 1, 'print': 0}
  // check level of scope, add buttons according to the level.
  if (scopes.vats >= 1) {
    buttons.add = {
      tag: "Add",
      value: 'add',
      onClick: onclickAdd,
      className: 'add-button'
    }
  }
  if (scopes.vats >= 2) {
    buttons.edit = {
      tag: "Edit",
      value: 'edit',
      onClick: onclickEdit
    }
  }

  return buttons
}

