import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { editReservations, setReservation, setReservationModalShow, getReservationModalShow, getReservationWithoutObjectModalShow, getReservationTimelineModalShow, setReservationWithoutObjectModalShow, setReservationTimelineModalShow, setReservationId, } from "../../redux/dataSlices/reservationSlice";
import { getScopes } from "../../redux/dataSlices/scopesSlice";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { setError } from "../../redux/dataSlices/errorSlice"
import PatchReservationsReservationById from "../../actablueAPI/reservations/PatchReservationsReservationById";

export default function ReservationButtons() {
  const token = useSelector(getToken);
  const modalShow = useSelector(getReservationModalShow)
  const timelineModalShow = useSelector(getReservationTimelineModalShow)
  const withoutObjectModalShow = useSelector(getReservationWithoutObjectModalShow)
  const scopes = useSelector(getScopes)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onclickChangeStatus = (event) => {
    PatchReservationsReservationById({token:token, id:event.target.id, status:event.target.value.toUpperCase()})
    .then(response => {
      dispatch(setReservation(response.data))
      dispatch(editReservations(response.data))
    })
    .catch(error => {
      dispatch(setError(JSON.stringify(error)))
    })
  };

  const onClickCancel = (event) => {
    confirmAlert({
      title: 'Confirm to cancel',
      message: 'Are you certain you wish to remove this reservation?\nThis action cannot be undone, and thus removed permanently.\n\nPlease confirm your decision before proceeding.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => onclickChangeStatus(event)
        },
        {
          label: 'No',
        }
      ]
    });

  };

  function onViewClick(reservation) {
    dispatch(setReservation(reservation));
    dispatch(setReservationModalShow(true));
  };

  function onViewClickTimeline(reservation) {
    dispatch(setReservation(reservation));
    dispatch(setReservationTimelineModalShow(true));
  };

  function onViewClickWithoutObject(reservation) {
    dispatch(setReservation(reservation));
    dispatch(setReservationWithoutObjectModalShow(true));
  };

  const onViewHide = (event) => {
    dispatch(setReservationModalShow(false));
    dispatch(setReservationWithoutObjectModalShow(false));
    dispatch(setReservationTimelineModalShow(false));
  };

  const onclickEdit = (event) => {
    dispatch(setReservationId(event.target.id));
    dispatch(setReservationTimelineModalShow(false));
    navigate("/reservation/edit");
  };

  const onclickAdd = () => {
    navigate("/reservation/add");
  };

  let buttons = {
    modalview: {
      tag: 'View',
      onClick: onViewClick,
      modal: {
        show: modalShow,
        onHide: onViewHide
      }
    },
    timelineModalView: {
      tag: 'View',
      onClick: onViewClickTimeline,
      modal: {
        show: timelineModalShow,
        onHide: onViewHide
      }
    },
    withoutObjectModalView: {
      tag: 'Assign table',
      onClick: onViewClickWithoutObject,
      modal: {
        show: withoutObjectModalShow,
        onHide: onViewHide
      }
    }
    // pagination: {
    //   maxbuttons: 5, //uneven number
    //   onClick:onPaginationClick
    // },
    // pagesize: {
    //   className: 'navbar-select',
    //   label:"page size selector",
    //   placeholder:"No selecetion",
    //   pagesize:pageSize,
    //   selected:{'id':pageSize, 'name':pageSize},
    //   number_of_elements: 0,
    //   options: pagesize,
    //   onChange:onProductPageSizeChange
    // },
  }

  // add buttons based on scopes
  // scopeOptions = ['read', 'create', 'update', 'delete'] (use index: 0:read, 1:create, 2:update, 3:delete)
  // buttonOptions = {'get':0, 'new':1, 'edit':2, 'add':2, 'del':3, 'swap':2, 'move':2, 'import':1, 'create': 1, 'export': 1, 'print': 0}
  // check level of scope, add buttons according to the level.
  if (scopes.reservations >= 1) {
    buttons.add = {
      tag: 'Add',
      value: 'add',
      onClick: onclickAdd,
      className: 'add-button'
    }
  }
  if (scopes.reservations >= 2) {
    buttons.edit = {
      tag: 'Edit',
      value: 'edit',
      onClick: onclickEdit
    }
    buttons.accept = {
      tag: 'Accept',
      value: 'Accepted',
      onClick: onclickChangeStatus
    }
    buttons.pending = {
      tag: 'Pending',
      value: 'Pending',
      onClick: onclickChangeStatus
    }
    buttons.cancel = {
      tag: 'Cancel',
      value: 'Cancelled',
      onClick: onClickCancel
    }
  }

  return buttons
}

