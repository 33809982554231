import React, { useEffect } from "react";
import ProgressModal from "../../components/progressModal";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { setStocks, getStocks, getStockPagination, getStockPagesize } from "../../redux/dataSlices/warehouseSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { getProgressModalShow, setProgressModalShow } from "../../redux/dataSlices/analyseSlice";
import BasicCard from "../../components/card"
import StockTable from "../../components/stockTable";
import HandleError from "../../helpers/handleError";
import GetStockPaginated from "../../actablueAPI/warehouse/GetStockPaginated";

const Stock = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const stocks = useSelector(getStocks)
  const stock_pagination = useSelector(getStockPagination)
  const stock_pagesize = useSelector(getStockPagesize)
  const modal_show = useSelector(getProgressModalShow)
  const dispatch = useDispatch();
  const navTitle = "Stock"

  const card = {
    size: 12,
    title: navTitle,
    data: <StockTable {...stocks} />,
  };

  const onViewHide = (event) => {
    dispatch(setProgressModalShow(false));
  };

  const progressModal = {
    modal: {
      show: modal_show,
      title: 'Creating and downloading export.....',
      onHide: onViewHide
    }
  }

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setLoading(true))
    GetStockPaginated({token:token, admin_id:admin_id, location_id:location_id, page:stock_pagination, size:stock_pagesize})
    .then((stockResponse) => {
      dispatch(setStocks(stockResponse.data))
      dispatch(setLoading(false))
    })
    .catch(error => {
      HandleError(error)
      dispatch(setLoading(false))
    })
    // eslint-disable-next-line
  }, [, admin_id, location_id, stock_pagination, stock_pagesize]);

  return (
    <>
      <ProgressModal {...progressModal} />
      <BasicCard {...card} />
    </>
  );
};
export default Stock;
