import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { setUserRolesAll, getUserRolesAll } from "../../redux/dataSlices/userSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import BasicCard from "../../components/card"
import HandleError from "../../helpers/handleError";
import GetUserRoleDefinitions from "../../actablueAPI/serials/GetUserRoleDefinitions";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import UserRoleTable from "../../components/userRoleTable";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";

const UserRoles = () => {
  const token = useSelector(getToken);
  const userRoles = useSelector(getUserRolesAll);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id)
  const dispatch = useDispatch();

  const usersCard = {
    size: 12,
    title: `Userroles`,
    data: <UserRoleTable userRoles={userRoles} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle("Userroles"))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setLoading(true))
    GetUserRoleDefinitions({ token: token, admin_id: admin_id })
    .then(response => {
      dispatch(setUserRolesAll(response.data))
      dispatch(setLoading(false))
    })
    .catch(error => {
      HandleError(error)
      dispatch(setLoading(false))
    })
    // eslint-disable-next-line
  }, [admin_id, location_id]);

  return (
    <>
      {/* <SearchNoButtonAndFilterSingleSelect {...searchAndFilter} /> */}
      <BasicCard {...usersCard} />
    </>
  )
};
export default UserRoles;
