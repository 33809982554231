import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import TableButton from './tableButton';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { getPlanningLocation } from '../redux/dataSlices/planningSlice';
import PlanningButtons from '../portal/planning/planningbuttons';
import PlanningModal from './planningModal';
import PaginationAndSizeComponent from './paginationAndSize';

function PlanningTable(props) {
  const planning_location = useSelector(getPlanningLocation);
  const pages = (props?.plannings?.total_pages) || 0;
  const page = (props?.plannings?.number + 1) || 0;
  const buttons = PlanningButtons.call()
  const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview) : false
  const { t: translate } = useTranslation();
  
  let pagination = buttons.paginationPlanning
  let pagesize = buttons.pagesizePlanning

  let actablue = true
  if (window.location.href.includes('humbee')) { actablue = false }

  if (!planning_location) {
    return (
      <>
        <Table width='100%'>
          Selected location has no planning settings, please go to the locations page and setup your planning location.
        </Table>
      </>
    )
  }

  let rows = []
  if (props?.plannings?.content && planning_location) {
    props?.plannings?.content.forEach(planning => {
      const modalview = buttons.hasOwnProperty('modalview') ? Object.assign({}, buttons.modalview, { id: planning.id }) : false
      const edit = buttons.hasOwnProperty('edit') ? Object.assign({}, buttons.edit, { id: planning.id }) : false
      rows.push(
        <tr key={planning.id} className='product-row'>
          <td>{planning.planning_name || "N/A"}</td>
          <td>{planning.planning_phonenumber || "N/A"}</td>
          <td>{planning.planning_email || "N/A"}</td>
          <td>{moment(planning.planning_timestamp).tz(planning_location?.timezone || 'Europe/Amsterdam').format('DD/MM/YYYY HH:mm:ss') || "N/A"}</td>
          <td>{planning.type || "N/A"}</td>
          <td>{planning.remark || "N/A"}</td>
          <td>{planning.status || "N/A"}</td>
          {actablue &&
            <td>
              <Button size="sm" type='button' onClick={() => { modalview.onClick(planning) }} id={planning.id}>{translate(modalview.tag)}</Button>
              {edit && <TableButton {...edit} />}
            </td>
          }
        </tr>
      )
    });
  }

  return (
    <>
      {<PaginationAndSizeComponent pagination={pagination} pagesize={pagesize} pages={pages} page={page} />}
      <Table width='100%'>
        <thead>
          <tr>
            <th>
              {translate('Name')}
            </th>
            <th width='10%'>
              {translate('Phone')}
            </th>
            <th width='10%'>
              {translate('Email')}
            </th>
            <th>
              {translate('Time')}
            </th>
            <th>
              {translate('Type')}
            </th>
            <th width='15%'>
              {translate('Remark')}
            </th>
            <th>
              {translate('Status')}
            </th>
            {actablue &&
              <th width='15%'>
                {translate('Actions')}
              </th>
            }
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
        <PlanningModal modal={modalview.modal} />
      </Table>
    </>
  );
}

export default PlanningTable;