import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Tab, Nav } from 'react-bootstrap';

import CreateField from '../helpers/createField';
import { useTranslation } from 'react-i18next';
import PhoneNumbersEditTable from './phone_numbersEditTable';
import EmailsEditTable from './emailsEditTable';
import AddressesEditTable from './addressesEditTable';

function RelationForm(props) {
  const { t: translate } = useTranslation();

  if (props.data.hasOwnProperty('administration_id')) {
    props.administration_id.selected = { 'value': props.data.administration_id, 'label': props.administration_id.hash[props.data.administration_id]?.name }
  }

  if (props.data.hasOwnProperty('user_id')) {
    props.user_id.selected = { 'value': props.data.user_id, 'label': props.user_id.hash[props.data.user_id]?.name }
  }

  if (props.data.hasOwnProperty('type')) {
    props.type.selected = { 'value': props.data.type, 'label': props.data.type }
  }

  let basicFields = []
  let formTabs = [];
  let formTabContent = [];
  props.fields.forEach(field => {
    if (field.type === 'object') {
      formTabs.push(
        <Nav.Item key={field.id}>
          <Nav.Link eventKey={field.id}>
            {translate(field.name)}
          </Nav.Link>
        </Nav.Item>
      );

      let objectFields = []
      field.fields.forEach(embeddedfield => {
        objectFields.push(CreateField(embeddedfield, props, field.id))
      });
      formTabContent.push(
        <Tab.Pane eventKey={field.id} key={field.id}>
          <div className='tab-container'>
            {objectFields}
          </div>
        </Tab.Pane>
      );
    }
    if (field.type === 'multiobject') {
      formTabs.push(
        <Nav.Item key={field.id}>
          <Nav.Link eventKey={field.id}>{field.name}</Nav.Link>
        </Nav.Item>
      );
      let objectFields = []
      if (props.data[field.id]) {
        props.data[field.id].forEach(element => {
          objectFields.push(<>{element.id}</>)
          field.fields.forEach(embeddedfield => {
            objectFields.push(CreateField(embeddedfield, props, field.id, element))
          });
        });
      }
      // field.fields.forEach(embeddedfield => {
      //   objectFields.push(CreateField(embeddedfield, props, field.id))
      // });
      formTabContent.push(
        <Tab.Pane eventKey={field.id} key={field.id}>
          <div className='tab-container'>
            {objectFields}
          </div>
        </Tab.Pane>
      );
    }


    if (field.type !== 'object' && field.type !== 'multiobject') {
      basicFields.push(CreateField(field, props))
    }
  });

  // addresses
  formTabs.push(
    <Nav.Item key='addresses'>
      <Nav.Link eventKey='addresses'>
        {translate('Addresses')}
      </Nav.Link>
    </Nav.Item>
  );
  formTabContent.push(
    <Tab.Pane eventKey='addresses' key='addresses'>
      <div className='tab-container'>
        <AddressesEditTable {...props} />
      </div>
    </Tab.Pane>
  );
  // phonenumbers
  formTabs.push(
    <Nav.Item key='phone_numbers'>
      <Nav.Link eventKey='phone_numbers'>
        {translate('Phone_numbers')}
      </Nav.Link>
    </Nav.Item>
  );
  formTabContent.push(
    <Tab.Pane eventKey='phone_numbers' key='phone_numbers'>
      <div className='tab-container'>
        <PhoneNumbersEditTable {...props} />
      </div>
    </Tab.Pane>
  );

  // emails
  formTabs.push(
    <Nav.Item key='emails'>
      <Nav.Link eventKey='emails'>
        {translate('Emails')}
      </Nav.Link>
    </Nav.Item>
  );
  formTabContent.push(
    <Tab.Pane eventKey='emails' key='emails'>
      <div className='tab-container'>
        <EmailsEditTable {...props} />
      </div>
    </Tab.Pane>
  );

  return (
    <>
      <Form>
        <Tab.Container defaultActiveKey="main">
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="main">
                {translate('Main')}
              </Nav.Link>
            </Nav.Item>
            {formTabs}
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="main">
              <div className='tab-container'>
                {basicFields}
              </div>
            </Tab.Pane>
            {formTabContent}
          </Tab.Content>
        </Tab.Container>
      </Form>
      <Button variant="primary" type={props.submit.type} onClick={props.submit.onClick}>
        {translate(props.submit.title)}
      </Button>
    </>
  );
}

export default RelationForm;