import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id, getSelectedLocation } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { getReservations, setReservations, getReservationsHighlights, setReservationsHighLights } from "../../redux/dataSlices/reservationSlice";
import axios from "axios";
import BasicCard from "../../components/card"
import WeatherWidget from "../../components/WeatherWidget";
import HighlightRevenueTable from "../../components/highlightRevenueTable";
import ReservationTable from "../../components/reservationDashboardTable";
import HighlightTable from "../../components/highlightTable"
import CardHeaderTable from "../../components/cardheaderTable";
import ReservationHighLightCalculation from "../reservations/highlightcalculation"
import moment from "moment"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import HandleError from "../../helpers/handleError";
import GetStatusReportRealtime from "../../actablueAPI/reporting/getStatusReportRealtime";
import GetReservationsListByLocationId from "../../actablueAPI/reservations/GetReservationsListByLocationId";
import { statusReportHighlightsSingleDay } from "../../helpers/statusReportSingleDayHighlight";
import { statusReportGraphSingleDay } from "../../helpers/statusReportSingleDayGraph";
import { statusReportAverageSingleDay } from "../../helpers/statusReportSingleDayAverage";

const Dashboard = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const location = useSelector(getSelectedLocation)
  const reservations = useSelector(getReservations);
  const reservationshighlights = useSelector(getReservationsHighlights);
  const dispatch = useDispatch();
  const [highlightsdata, setHighlightsdata] = useState();
  const [averagedata, setAveragedata] = useState();
  const [graphCardData, setGraphCardData] = useState();

  let weatherWidgetCard = {}

  const highlightCard = {
    size: 6,
    title: 'Highlights',
    data: <HighlightTable {...reservationshighlights} />,
  };

  const reservationCard = {
    size: 12,
    title: 'Today\'s Reservations',
    subtitle: <CardHeaderTable {...reservationshighlights} />,
    data: <ReservationTable reservations={reservations} />,
  };

  // actablue dashboard
    // chart stuff
    ChartJS.register(
      CategoryScale,
      LinearScale,
      PointElement,
      LineElement,
      Title,
      Tooltip,
      Legend
    );

    const paymentsCard = {
      size: 4,
      title: 'Payments',
      data: <HighlightRevenueTable {...highlightsdata} />,
    };

    const averageCard = {
      size: 4,
      title: 'Average',
      data: <HighlightRevenueTable {...averagedata} />,
    };

    const graphCard = {
      size: 12,
      title: location_id ? 'Today\'s revenue' : 'Choose administration and location.',
      data: graphCardData,
    };
  

  useEffect(() => {
    dispatch(setNavBarTitle("Today's Overview"))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (admin_id && location_id) {
      if (window.location.href.includes('humbee')) {
        const currentDate = moment();
        const start = currentDate.format('YYYY-MM-DD').concat('T00:00:00Z')
        const end = currentDate.format('YYYY-MM-DD').concat('T23:59:59Z')

        GetReservationsListByLocationId({token:token, admin_id:admin_id, location_id:location_id, start:start, end:end})
        .then(axios.spread((reservationsData) => {
          dispatch(setReservations(reservationsData.data))
          dispatch(setReservationsHighLights(ReservationHighLightCalculation(reservationsData.data)))
          dispatch(setLoading(false))
        }))
        .catch(error => {
          HandleError(error)
          dispatch(setLoading(false))
        })
      }
      else {
        GetStatusReportRealtime({token:token, admin_id:admin_id, location_id:location_id, location:location})
        .then(realtimeResponse => {
          // lifedata(realtimeResponse.data)
          setHighlightsdata(statusReportHighlightsSingleDay(realtimeResponse.data))
          setAveragedata(statusReportAverageSingleDay(realtimeResponse.data))
          setGraphCardData(statusReportGraphSingleDay(realtimeResponse.data))
          dispatch(setLoading(false))
        })
        .catch(error => {
          HandleError(error)
          dispatch(setLoading(false))
        })
      }
    }
    if (!admin_id || !location_id) {
      dispatch(setReservations())
      dispatch(setReservationsHighLights())
    }
    // eslint-disable-next-line
  }, [admin_id, location_id]);

  if (window.location.href.includes('humbee')) {
    weatherWidgetCard = {
      size: 6
    }
    return (
      <>
        <WeatherWidget {...weatherWidgetCard} />
        <BasicCard {...highlightCard} />
        <BasicCard {...reservationCard} />
      </>
    ) 
  } else {
    weatherWidgetCard = {
      size: 4
    }
    return (
      <>
        <WeatherWidget {...weatherWidgetCard} />
        <BasicCard {...paymentsCard} />
        <BasicCard {...averageCard} />
        <BasicCard {...graphCard} />
      </>
    )
  }
};
export default Dashboard;

