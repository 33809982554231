import React, { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import BasicCard from "../../components/card"
import HandleOnChange from "../../helpers/handleOnChange";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import { AnalyseFormFields } from "./analyseFormFields";
import { getTaskId, setTaskId, setTaskData, getProgressModalShow, setProgressModalShow, setTaskResults, getTaskResults } from "../../redux/dataSlices/analyseSlice";
import AnalyseForm from "../../components/analyseForm";
import AnalyseDataTable from "../../components/anlayseDataTable";
import AnalyseProgressModal from "../../components/analyseprogressModal";
import HandleError from "../../helpers/handleError";
import { AnalyseSelectChoices } from "../../selectfieldchoices/analyseselect";
import GetAllCategoriesWithType from "../../actablueAPI/products/getAllCategoriesWithType";
import { setJournalCategoriesAll } from "../../redux/dataSlices/categorySlice";
import PostAnalyseProducts from "../../actablueAPI/analyse/PostAnalyseProducts";
import PostAnalyseOrders from "../../actablueAPI/analyse/PostAnalyseOrders";
import PostAnalyseOrdersPaidTrue from "../../actablueAPI/analyse/PostAnalyseOrdersPaidTrue";
import PostAnalyseDoubleSkuCodes from "../../actablueAPI/analyse/PostAnalyseDoubleSkuCodes";
import PostAnalyseDeleteNonActiveProducts from "../../actablueAPI/analyse/PostAnalyseDeleteNonActiveProducts";
import PostAnalyseTaskProgress from "../../actablueAPI/analyse/PostAnalyseTaskPostgress";
import PostAnalyseTransactionProcessedByAccounting from "../../actablueAPI/analyse/PostAnalyseTransactionProcessedByAccounting";

const Analyses = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const task_id = useSelector(getTaskId)
  const taskResults = useSelector(getTaskResults)
  const modal_show = useSelector(getProgressModalShow)
  const dispatch = useDispatch();
  const [analyseForm, setAnalyseForm] = useState({})

  const { t: translate } = useTranslation();

  const onChange = (event) => {
    let newAnalyseForm = HandleOnChange({ event: event, object: analyseForm, admin_id: admin_id })
    setAnalyseForm(newAnalyseForm)
  }

  const onAnalyseChange = (event) => {
    let newAnalyseForm = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: analyseForm, selectfield: 'analysetype' })
    setAnalyseForm(newAnalyseForm)
  };

  const onAnalyse = (event) => {
    let ab_data = {}
    ab_data.token = token
    ab_data.access_token = token.access_token
    ab_data.admin_id = admin_id
    ab_data.location_id = location_id

    switch (analyseForm.analysetype) {
      case 'products':
        PostAnalyseProducts({data:ab_data})
        .then(response => {
          dispatch(setTaskId(response.data.task_id))
        })
        .catch(error => {
          HandleError(error)
        })

        GetAllCategoriesWithType({token:token, admin_id: admin_id, location_id:location_id, type:'JOURNAL'})
        .then(response => {
          dispatch(setJournalCategoriesAll(response))
        })
        .catch(error => {
          HandleError(error)
        })
        break;
      case 'orders':
        PostAnalyseOrders({data:ab_data})
        .then(response => {
          dispatch(setTaskId(response.data.task_id))
        })
        .catch(error => {
          HandleError(error)
        })
        break;
      case 'orders_is_paid_true':
        PostAnalyseOrdersPaidTrue({data:ab_data})
        .then(response => {
          dispatch(setTaskId(response.data.task_id))
        })
        .catch(error => {
          HandleError(error)
        })
        break;
      case 'double_skucodes_active_false':
        PostAnalyseDoubleSkuCodes({data:ab_data})
        .then(response => {
          dispatch(setTaskId(response.data.task_id))
        })
        .catch(error => {
          HandleError(error)
        })
        break;
      case 'delete_non_active_products':
        PostAnalyseDeleteNonActiveProducts({data:ab_data})
        .then(response => {
          dispatch(setTaskId(response.data.task_id))
        })
        .catch(error => {
          HandleError(error)
        })
        break;
      case 'transaction_processed_by_accounting':
        ab_data.start_date = analyseForm.start_date
        ab_data.end_date = analyseForm.end_date
        ab_data.also_snelstart = analyseForm.also_snelstart
        ab_data.also_processed = analyseForm.also_processed
        PostAnalyseTransactionProcessedByAccounting({data:ab_data})
        .then(response => {
          dispatch(setTaskId(response.data.task_id))
        })
        .catch(error => {
          HandleError(error)
        })
        break
      default:
        break;
    }

  };

  const onViewHide = (event) => {
    dispatch(setProgressModalShow(false));
  };
  
  const progressModal = {
    modal: {
      show: modal_show,
      onHide: onViewHide
    }
  }

  const analyseFormData = {
    submit: {
      title: 'Analyse',
      type: 'submit',
      onClick: onAnalyse
    },
    fields: AnalyseFormFields(analyseForm),
    field: {
      onChange: onChange
    },
    data: analyseForm,
    analysetype: {
      name: 'analysetype',
      options: AnalyseSelectChoices(),
      // selected: {},
      onChange: onAnalyseChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
  }

  const analyseSelectcard = {
    size: 12,
    title: 'Analyse',
    data: location_id ? <AnalyseForm {...analyseFormData} /> : "Choose Location" ,
  };

  const dataCard = {
    size: 12,
    title: 'Analyse results',
    data: <AnalyseDataTable taskData={taskResults} type={analyseForm.analysetype} />,
  }

  useEffect(() => {
    dispatch(setNavBarTitle('Analyses'))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let intervalId = {}
    function getProgres() {
      if (task_id) {
        PostAnalyseTaskProgress({data:{task_id:task_id}})
        .then(response => {
          if (response?.data?.result?.state === 'PENDING') {
            dispatch(setTaskData(response.data.result))
          }

          if (response?.data?.result?.state === 'PROGRESS') {
            dispatch(setTaskData(response.data.result))
          }        

          if (response?.data?.result?.state === 'SUCCESS') {
            dispatch(setTaskData(response.data.result))
            dispatch(setTaskResults(response.data.result))
            clearInterval(intervalId)
            dispatch(setTaskId(undefined))
            dispatch(setProgressModalShow(false))
          } 
          if (response?.data?.result?.state === 'FAILURE') {
            dispatch(setTaskData(response.data.result))
            clearInterval(intervalId)
            dispatch(setTaskId(undefined))
            dispatch(setProgressModalShow(false))
          } 
        })
        .catch(error => {
            clearInterval(intervalId)
            dispatch(setTaskId(undefined))
            dispatch(setProgressModalShow(false))
            HandleError(error)
        })
      } 
    }

    if (task_id) {
      dispatch(setProgressModalShow(true))
      intervalId = setInterval(() => {
        getProgres()
      }, 1000);
    } 
    
    if (!task_id) {
      // dispatch(setTaskData(undefined))
      clearInterval(intervalId)
    }
    // eslint-disable-next-line
  }, [, task_id]);

  return (
    <>
      <AnalyseProgressModal {...progressModal} />
      <BasicCard {...analyseSelectcard} />
      <BasicCard {...dataCard} />
    </>
  )
};
export default Analyses;