import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getScopes } from "../../redux/dataSlices/scopesSlice";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { getPlanningModalShow, getPlanningObjectModalShow, getPlanningObjectPageSize, getPlanningPageSize, getRefresh, setPlanning, setPlanningId, setPlanningModalShow, setPlanningObject, setPlanningObjectId, setPlanningObjectModalShow, setPlanningObjectPageSize, setPlanningObjectPagination, setPlanningPageSize, setPlanningPagination, setRefresh } from "../../redux/dataSlices/planningSlice";
import { pagesize } from "../../selectfieldchoices/pagesize.mjs";
import { PatchPlanningStatus } from "../../actablueAPI/planning/Planning";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { setError, setResponseError } from "../../redux/dataSlices/errorSlice";

export default function PlanningButtons() {
  const modalShow = useSelector(getPlanningModalShow);
  const objectModalShow = useSelector(getPlanningObjectModalShow);
  const scopes = useSelector(getScopes)
  const token = useSelector(getToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const refresh = useSelector(getRefresh);
  const planningPageSize = useSelector(getPlanningPageSize);
  const planningObjectPageSize = useSelector(getPlanningObjectPageSize);

  function onViewClick(planning) {
    dispatch(setPlanning(planning));
    dispatch(setPlanningModalShow(true));
  };

  function onViewClickObject(planning_object) {
    dispatch(setPlanningObject(planning_object));
    dispatch(setPlanningObjectModalShow(true));
  };

  const onViewHide = (event) => {
    dispatch(setPlanningModalShow(false));
    dispatch(setPlanningObjectModalShow(false));
  };

  const onclickEdit = (event) => {
    dispatch(setPlanningId(event.target.id));
    navigate("/planning/edit");
  };

  const onclickEditObject = (event) => {
    dispatch(setPlanningObjectId(event.target.id));
    navigate("/planning/object/edit");
  };

  const onPlanningPaginationClick = (event) => {
    dispatch(setPlanningPagination(event.target.value))
  };

  const onPlanningObjectPaginationClick = (event) => {
    dispatch(setPlanningObjectPagination(event.target.value))
  };

  const onPlanningPageSizeChange = (event) => {
    dispatch(setPlanningPageSize(event.value))
  };

  const onPlanningObjectPageSizeChange = (event) => {
    dispatch(setPlanningObjectPageSize(event.value))
  };

  const onclickAdd = () => {
    navigate("/planning/add");
  };

  const onclickAddObject = () => {
    navigate("/planning/object/add");
  };

  const onStatusChange = (event, id, status) => {
    PatchPlanningStatus({ token: token, id: id, status: status })
      .then(() => {
        dispatch(setRefresh(!refresh))
        dispatch(setPlanningModalShow(false));
      })
      .catch(error => {
        const type = error.response?.type
        const data = error.response?.data
        if (error.response) { dispatch(setResponseError({ type, data })) }
        if (!error.response) { dispatch(setError(JSON.stringify(error))) }
      })
  };

  let buttons = {
    modalview: {
      tag: 'View',
      onClick: onViewClick,
      modal: {
        show: modalShow,
        onHide: onViewHide,
        onStatusChange: onStatusChange
      }
    },
    objectmodalview: {
      tag: 'View',
      onClick: onViewClickObject,
      modal: {
        show: objectModalShow,
        onHide: onViewHide
      }
    },
    paginationPlanning: {
      maxbuttons: 5, //uneven number
      onClick: onPlanningPaginationClick
    },
    paginationObjectPlanning: {
      maxbuttons: 5, //uneven number
      onClick: onPlanningObjectPaginationClick
    },
    pagesizePlanning: {
      className: 'navbar-select',
      label: "page size selector",
      placeholder: "No selection",
      pagesize: pagesize,
      selected: { 'id': planningPageSize, 'name': planningPageSize },
      number_of_elements: 0,
      options: pagesize,
      onChange: onPlanningPageSizeChange
    },
    pagesizePlanningObject: {
      className: 'navbar-select',
      label: "page size selector",
      placeholder: "No selection",
      pagesize: planningObjectPageSize,
      selected: { 'id': planningObjectPageSize, 'name': planningObjectPageSize },
      number_of_elements: 0,
      options: pagesize,
      onChange: onPlanningObjectPageSizeChange
    },
  }

  // add buttons based on scopes
  // scopeOptions = ['read', 'create', 'update', 'delete'] (use index: 0:read, 1:create, 2:update, 3:delete)
  // buttonOptions = {'get':0, 'new':1, 'edit':2, 'add':2, 'del':3, 'swap':2, 'move':2, 'import':1, 'create': 1, 'export': 1, 'print': 0}
  // check level of scope, add buttons according to the level.
  if (scopes.reservations >= 1) {
    buttons.add = {
      tag: 'Add',
      value: 'add',
      onClick: onclickAdd,
      className: 'add-button'
    }
    buttons.planning_object_add = {
      tag: 'Add',
      value: 'add',
      onClick: onclickAddObject,
      className: 'add-button'
    }
  }
  if (scopes.reservations >= 2) {
    buttons.edit = {
      tag: 'Edit',
      value: 'edit',
      onClick: onclickEdit
    }
    buttons.planning_object_edit = {
      tag: 'Edit',
      value: 'edit',
      onClick: onclickEditObject
    }
  }

  return buttons
}

