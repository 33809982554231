import { Row, Col, Form } from 'react-bootstrap';
import MultiSelectField from '../components/multiselectField';
import MultiSelectCreateField from '../components/multiselectcreateField';
import SingleSelectField from '../components/singleselectField';
import SingleSelectCreateField from '../components/singleselectcreateField';
import { useTranslation } from 'react-i18next';

export default function CreateField(field, props, pref) {
  const { t: translate } = useTranslation();

  let checked = undefined
  if (!pref && props.data && (props.data[field.id] === true || props.data[field.id] === 'true')) {
    checked = true
  }
  if (pref && props.data && props.data[pref] && (props.data[pref][field.id] === true || props.data[pref][field.id] === 'true')) {
    checked = true
  }

  let value = undefined
  if (!pref && props.data && props.data[field.id]) {
    value = props.data[field.id]
  }
  if (pref && props.data && props.data[pref] && props.data[pref][field.id]) {
    value = props.data[pref][field.id]
  }

  let name = field.name
  let placeholder = field.placeholder

  if (field.type !== 'hidden') {
    name = translate(field.name,  {keySeparator: ':', nsSeparator: ':'})
    placeholder = translate(field.placeholder ?? field.name)
  }

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur()
  
    // Prevent the page/container scrolling
    e.stopPropagation()
  
    // Refocus immediately, on the next tick (after the current function is done)
    setTimeout(() => {
      e.target.focus()
    }, 0)
  }

  switch (field.type) {
    case 'hidden':
      return (
        <Form.Control prefix={field.prefix}
          type={field.type}
          name={field.name}
          placeholder={field.placeholder}
          id={props.data ? props.data[field.id] : undefined}
          value={pref ? props.data[pref] ? props.data[pref][field.id] : undefined : props.data ? props.data[field.id] : undefined}
          onChange={props[field?.id]?.onChange ?? props.field.onChange}
          readOnly={field.readonly}
          disabled={field.disabled} />
      )
    case 'checkbox':
      return (
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">{name} {field.required && <p className='form-required-text'>*</p>}</Form.Label>
          <Col sm="10">
            <input className="form-check-input"
              prefix={field.prefix}
              type='checkbox'
              name={name}
              placeholder={placeholder ?? name}
              value={Boolean(value)}
              onChange={props[field?.id]?.onChange ?? props.field.onChange}
              id={field.id}
              disabled={field.disabled}
              checked={Boolean(checked)}
              isInvalid={field?.invalid}
            >
            </input>
          </Col>
        </Form.Group>
      )
    case 'switch':
      return (
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2">{name} {field.required && <p className='form-required-text'>*</p>}</Form.Label>
          <Col sm="10">
            <input className="form-check-input"
              prefix={field.prefix}
              type='checkbox'
              role='switch'
              name={name}
              placeholder={placeholder ?? name}
              value={value}
              onChange={props[field?.id]?.onChange ?? props.field.onChange}
              id={field.id}
              disabled={field.disabled}
              checked={checked}
              isInvalid={field?.invalid}>
            </input>
          </Col>
        </Form.Group>
      )
    case 'select':
      return (
        <Form.Group as={Row} className="mb-3" controlId={field.id}>
          <Form.Label column sm="2">{name} {field.required && <p className='form-required-text'>*</p>}</Form.Label>
          <Col sm="10">
            <SingleSelectField {...props[field.formdatafield]} prefix={field.prefix} name={name} required={field.required} id={field.id} className={field?.invalid && `is-invalid`} />
            {field?.invalid &&
              <div className="invalid-feedback">{field?.invalidFeedback ?? 'Please fill in this field.'}</div>
            }
          </Col>
        </Form.Group>
      )
    case 'multiselect':
      return (
        <Form.Group as={Row} className="mb-3" controlId={field.id}>
          <Form.Label column sm="2">{name} {field.required && <p className='form-required-text'>*</p>}</Form.Label>
          <Col sm="10">
            <MultiSelectField {...props[field.formdatafield]} prefix={field.prefix} required={field.required} id={field.id} className={field?.invalid && `is-invalid`} />
            {field?.invalid &&
              <div className="invalid-feedback">{field?.invalidFeedback ?? 'Please fill in this field.'}</div>
            }
          </Col>
        </Form.Group>
      )
    case 'multi+create':
      return (
        <Form.Group as={Row} className="mb-3" controlId={field.id}>
          <Form.Label column sm="2">{name} {field.required && <p className='form-required-text'>*</p>}</Form.Label>
          <Col sm="10">
            <MultiSelectCreateField {...props[field.formdatafield]} prefix={field.prefix} required={field.required} id={field.id} className={field?.invalid && `is-invalid`} />
            {field?.invalid &&
              <div className="invalid-feedback">{field?.invalidFeedback ?? 'Please fill in this field.'}</div>
            }
          </Col>
        </Form.Group>
      )
    case 'select+create':
      return (
        <Form.Group as={Row} className="mb-3" controlId={field.id}>
          <Form.Label column sm="2">{name} {field.required && <p className='form-required-text'>*</p>}</Form.Label>
          <Col sm="10">
            <SingleSelectCreateField {...props[field.formdatafield]} prefix={field.prefix} required={field.required} id={field.id} className={field?.invalid && `is-invalid`} />
            {field?.invalid &&
              <div className="invalid-feedback">{field?.invalidFeedback ?? 'Please fill in this field.'}</div>
            }
          </Col>
        </Form.Group>
      )
    case 'object':
      return
    default:
      return (
        <Form.Group as={Row} className="mb-3" controlId={field.id}>
          <Form.Label column sm="2">{name} {field.required && <p className='form-required-text'>*</p>}</Form.Label>
          <Col sm="10">
            <Form.Control prefix={field.prefix}
              type={field.type}
              name={name}
              accept={field.accept}
              placeholder={placeholder ?? name}
              value={value}
              onChange={props[field?.id]?.onChange ?? props.field.onChange}
              readOnly={field.readonly}
              step={field.step}
              disabled={field.disabled}
              required={field.required ?? true}
              maxLength={field.maxlength}
              isInvalid={field?.invalid}
              inputMode={field.inputmode}
              onWheel={field.onWheel || numberInputOnWheelPreventChange}
            />
            <Form.Control.Feedback type="invalid">
              {field?.invalidFeedback ?? 'Please fill in this field.'}
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
      )
  }
}