import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card"
import HandleOnChange from "../../helpers/handleOnChange";
import { getHashTemplatesAll, getTemplatesAll } from "../../redux/dataSlices/notificationSlice";
import { NotificationChannelsEmailFormFields } from "./notificationsFormFields";
import NotificationChannelsEmailForm from "../../components/notificationChannelsEmailForm";
import HandleOnChangeSingleSelectFieldWithObject from "../../helpers/handleOnChangeSingleSelectFieldWithObject";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import PostNotificationChannelsEmail from "../../actablueAPI/notifications/PostNotificationChannelsEmail";

const NotificationChannelsAdd = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id)
  const templates_all = useSelector(getTemplatesAll)
  const hash_templates_all = useSelector(getHashTemplatesAll)
  const navigate = useNavigate();
  const [channel, setChannel] = useState({ administration_id: admin_id, location_id: location_id, type: 'EMAIL' })

  const onChange = (event) => {
    let newChannel = HandleOnChange({ event: event, object: channel })
    setChannel(newChannel)
  }

  const onHandleOnChangeSingleSelectFieldWithObject = (event) => {
    let newChannel = HandleOnChangeSingleSelectFieldWithObject({ event: event, object: channel, selectfield: 'template', hash: hash_templates_all })
    setChannel(newChannel)
  }

  const onSubmit = () => {
    PostNotificationChannelsEmail({ token: token, admin_id: admin_id, data: channel })
      .then(() => {
        navigate("/notifications");
      })
  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    fields: NotificationChannelsEmailFormFields(),
    field: {
      onChange: onChange
    },
    data: channel,
    template: {
      label: 'template',
      options: templates_all,
      onChange: onHandleOnChangeSingleSelectFieldWithObject,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
  };

  const card = {
    size: 12,
    title: "Channel add",
    data: <NotificationChannelsEmailForm {...formData} />,
  };

  return (
    <>
      <BasicCard {...card} />
    </>
  )

};
export default NotificationChannelsAdd;

