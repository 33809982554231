import React, { useEffect } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getEmployeeRolesAll, getEmployeesAll, setEmployeeRolesAll, setEmployeesAll } from "../../redux/dataSlices/employeeSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import BasicCard from "../../components/card"
import EmployeeTable from "../../components/employeeTable";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import HandleError from "../../helpers/handleError";
import GetEmployees from "../../actablueAPI/employee/GetEmployees";
import GetEmployeeRoles from "../../actablueAPI/employee/GetEmployeeRoles";

const Employees = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id)
  const employees = useSelector(getEmployeesAll);
  const roles = useSelector(getEmployeeRolesAll)
  const dispatch = useDispatch();

  const employeesCard = {
    size: 12,
    title: "Employees",
    data: <EmployeeTable employees={employees} roles={roles} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle("Employees"))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setLoading(true))
    axios.all([
      GetEmployees({token:token, admin_id:admin_id}),
      GetEmployeeRoles({token:token, admin_id:admin_id})
    ])
    .then(axios.spread((responseEmployees, responseRoles) => {
      dispatch(setEmployeesAll(responseEmployees.data))
      dispatch(setEmployeeRolesAll(responseRoles.data))
      dispatch(setLoading(false))
    }))
    .catch(error => {
      HandleError(error)
      dispatch(setLoading(false))
    })
    // eslint-disable-next-line
  }, [, admin_id]);

  return (
    <>
      <BasicCard {...employeesCard} />
    </>
  )
};
export default Employees;
