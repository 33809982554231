const ReservationobjectFormFields = () => {
  const websiteUrl = window.location.href;

  let reservationobjectFormFields = [
    { type: 'hidden', name: 'id', id: 'id', readonly: true },
    { type: 'hidden', name: 'administration_id', id: 'administration_id', readonly: true },
    { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true },
    { type: 'text', name: 'Name', id: 'name' },
    { type: 'text', name: 'Description', id: 'description' },
    { type: 'number', name: 'Capacity', id: 'capacity' },
    { type: 'checkbox', name: 'Automatically maximize capacity', id: 'automatically_maximize_capacity' },
    { type: 'select', name: 'Type', id: 'type', formdatafield: 'type' },
    { type: 'multiselect', name: 'Reservationoptions', id: 'reservation_options', formdatafield: 'reservation_options' }

  ]

  if (websiteUrl.includes('humbee')) {
    reservationobjectFormFields = [
      { type: 'hidden', name: 'id', id: 'id', readonly: true },
      { type: 'hidden', name: 'administration_id', id: 'administration_id', readonly: true },
      { type: 'hidden', name: 'location_id', id: 'location_id', readonly: true },
      { type: 'text', name: 'Name', id: 'name' },
      { type: 'text', name: 'Description', id: 'description' },
      { type: 'number', name: 'Capacity', id: 'capacity' },
      { type: 'checkbox', name: 'Automatically maximize capacity', id: 'automatically_maximize_capacity' },
      { type: 'select', name: 'Type', id: 'type', formdatafield: 'type' },
    ]
  }

  return reservationobjectFormFields
}

export default ReservationobjectFormFields