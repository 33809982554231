import { Button, Table } from 'react-bootstrap';
import { Accordion } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ReportingButtons from '../portal/reporting/reportingbuttons';

export default function SalesReportAccordionStyle(salesReport) {
  const buttons = ReportingButtons.call()
  const exportButton = buttons.hasOwnProperty('export') ? Object.assign({}, buttons.export) : false
  const pdfButton = buttons.hasOwnProperty('pdf') ? Object.assign({}, buttons.pdf) : false
  const { t: translate } = useTranslation();

  let accordions = []

  if (salesReport.cumulated && Object.keys(salesReport.cumulated).length > 0) {
    accordions.push(topLevel(salesReport.cumulated, salesReport.hashCategories, exportButton, pdfButton, translate, salesReport))

    accordions.push(totalAccordion(salesReport.totalRevenue))

    // add export button
    if (exportButton) {
      accordions.push(
        <Button size="sm" type='button' onClick={() => { exportButton.onClick({ report: salesReport, type: 'salesReportMultipleDays' }) }}>{translate(exportButton?.tag)}</Button>
      )
    }
    // add pdf button
    if (pdfButton) {
      accordions.push(
        <Button size="sm" type='button' onClick={() => { pdfButton.onClick({ report: salesReport, type: 'salesReportMultipleDays' }) }}>{translate(pdfButton?.tag)}</Button>
      )
    }
  }

  return accordions?.length > 0 ? accordions : 'No data';
}

function totalAccordion(totalRevenue) {
  return (
    <Accordion flush>
      <Accordion.Item eventKey='totalline'>
        <Accordion.Header className='journal-category-header'>
          <Table width='100%'>
            <thead>
              <tr>
                <th width='50%'>
                  {'Cumulated'}
                </th>
                <th width='10%' className="text-end">
                  {'Percentage'}
                </th>
                <th width='10%' className="text-end">
                  {'Quantity'}
                </th>
                <th width='20%' className="text-end">
                  {'Amount'}
                </th>
                <th width='10%'></th>
              </tr>
            </thead>
            <tbody>
              <tr key='totalline'>
                <td>Total</td>
                <td align='right'>100.00%</td>
                <td align='right'>{totalRevenue?.totalQuantity}</td>
                <td align='right'>{totalRevenue?.totalRevenue?.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
                <td></td>
              </tr>
            </tbody>
          </Table>
        </Accordion.Header>
      </Accordion.Item>
    </Accordion>
  )
}

function topLevel(cumulated, hashCategories, exportButton, pdfButton, translate, salesReport) {
  let topLevelRows = []
  for (const [categoryId, categoryData] of Object.entries(cumulated).sort(([a, adata], [b, bdata]) => bdata.totals?.total - adata.totals?.total)) {
    if (categoryData.toplevel) {

      // find this levels childs
      let childRows = []
      childRows.push(getChildAccordion(childRows, categoryId, hashCategories, cumulated, exportButton, pdfButton, translate, salesReport))

      topLevelRows.push(
        <Accordion flush>
          <Accordion.Item eventKey={categoryId}>
            <Accordion.Header className='journal-category-header'>
              <Table width='100%'>
                <thead>
                  <tr>
                    <th width='50%'>
                      {'Journal category'}
                    </th>
                    <th width='10%' className="text-end">
                      {'Percentage'}
                    </th>
                    <th width='10%' className="text-end">
                      {'Quantity'}
                    </th>
                    <th width='20%' className="text-end">
                      {'Amount'}
                    </th>
                    <th width='10%'></th>
                  </tr>
                </thead>
                <tbody>
                  <tr key={categoryId}>
                    <td>
                      {categoryData.name}
                    </td>
                    <td align='right'>{categoryData.totals?.percentage}%</td>
                    <td align='right'>{categoryData.totals?.amount}</td>
                    <td align='right'>{categoryData.totals?.total?.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
                    <td>
                      <Button size="sm" type='button' onClick={() => { exportButton.onClick({ report: salesReport, type: 'salesReportMultipleDays', categoryId:categoryId }) }}>{translate(exportButton?.tag)}</Button>
                      <Button size="sm" type='button' onClick={() => { pdfButton.onClick({ report: salesReport, type: 'salesReportMultipleDays', categoryId:categoryId }) }}>{translate(pdfButton?.tag)}</Button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Accordion.Header>
            <Accordion.Body className='journal-category-body'>{childRows}</Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )
    }
  }
  return topLevelRows
}

function getChildAccordion(childRows, categoryId, hashCategories, cumulated, exportButton, pdfButton, translate, salesReport) {
  let childTable = []
  let childRow = []

  if (hashCategories[categoryId]?.hasOwnProperty('children') && hashCategories[categoryId].children.length > 0) {
    let childArray = []
    for (const child of hashCategories[categoryId].children) {
      if (cumulated[child.id]) {
        let newChild = Object.assign({}, child, cumulated[child.id])
        childArray.push(newChild)
      }
    }

    // for (const child of hashCategories[categoryId].children) {
    for (const child of childArray.sort((a, b) => b.totals?.total - a.totals?.total)) {
      let childSubRows = []
      childSubRows.push(getChildAccordion(childRows, child.id, hashCategories, cumulated, exportButton, pdfButton, translate, salesReport))

      childRow.push(
        <Accordion flush>
          <Accordion.Item eventKey={child.id}>
            <Accordion.Header className='journal-category-header'>
              <Table width='100%'>
                <thead>
                  <tr>
                    <th width='50%'>
                      {'Journal category'}
                    </th>
                    <th width='10%' className="text-end">
                      {'Percentage'}
                    </th>
                    <th width='10%' className="text-end">
                      {'Quantity'}
                    </th>
                    <th width='20%' className="text-end">
                      {'Amount'}
                    </th>
                    <th width='10%'></th>
                  </tr>
                </thead>
                <tbody>
                  <tr key={child.id}>
                    <td>
                      {cumulated[child.id]?.name}
                    </td>
                    <td align='right'>{cumulated[child.id].totals?.percentage}%</td>
                    <td align='right'>{cumulated[child.id].totals?.amount}</td>
                    <td align='right'>{cumulated[child.id].totals?.total?.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
                    <td>
                      <Button size="sm" type='button' onClick={() => { exportButton.onClick({ report: salesReport, type: 'salesReportMultipleDays', categoryId:child.id }) }}>{translate(exportButton.tag)}</Button>
                      <Button size="sm" type='button' onClick={() => { pdfButton.onClick({ report: salesReport, type: 'salesReportMultipleDays', categoryId:child.id }) }}>{translate(pdfButton.tag)}</Button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Accordion.Header>
            <Accordion.Body>{childSubRows}</Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )
    }
    if (childRow.length > 0) {
      childTable.push(childRow)
    }
  }

  if (cumulated[categoryId].bottomlevel) {
    let productRows = getProductTable(cumulated[categoryId])
    childTable.push(productRows)
  }

  return childTable
}

function getProductTable(lowest) {
  let productTable = []
  let productRows = []
  for (let [productId, product] of Object.entries(lowest.products).sort(([a, adata], [b, bdata]) => bdata.total - adata.total)) {
    productRows.push(
      <tr key={productId}>
        <td>{product.name}</td>
        <td align="right">{product.percentage}%</td>
        <td align="right">{product.amount}</td>
        <td align="right">{product.total.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
        <td></td>
      </tr>
    )
  }

  productTable.push(
    <Table width='100%'>
      <thead>
        <tr>
          <th width='50%'>
            {'Product'}
          </th>
          <th width='10%' className="text-end">
            {'Percentage'}
          </th>
          <th width='10%' className="text-end">
            {'Quantity'}
          </th>
          <th width='20%' className="text-end">
            {'Amount'}
          </th>
          <th width='10%'></th>
        </tr>
      </thead>
      <tbody>
        {productRows}
      </tbody>
    </Table>
  )
  return productTable

}