import { useTranslation } from 'react-i18next';
import Select from 'react-select';

function SingleSelectField(props) {
  const { t: translate } = useTranslation();
  const selected = props.selected
  const propOptions = props.hasOwnProperty('options') && props.options !== undefined ? props.options.hasOwnProperty('content') ? props.options.content : props.options : false
  let options = []
  if (propOptions) {
    // eslint-disable-next-line
    propOptions.map((option) => {
      options.push({ 'value': option.id, 'label': option.name || option.title || option.omschrijving || option.domain || (option.firstName && option.firstName+' '+option.lastName) || option.id, 'name': props.name, 'prefix':props.prefix, 'id': props.id })
    })
  }

  if (options) {
    return (
      <Select
        prefix={props.prefix}
        name={props.name}
        id={props.id}
        placeholder={props?.placeholder ? translate(props.placeholder) : translate('Select...')}
        options={options}
        className={`basic-multi-select ${props.className}`}
        classNamePrefix="select"
        onChange={props.onChange}
        value={selected}
        isClearable={props.clearable}
        isSearchable={props.searchable}
        required={props.required ?? true}
        isDisabled={props.disabled}
        isLoading={props.loading}
      />
    );
  }
}

export default SingleSelectField;