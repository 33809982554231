import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import moment from "moment"
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation, getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { getAllowedCurrency, getDrawersAll, getHashDrawersAll, setAllowedCurrency, setDrawersAll, setEmployeePasswordModalShow } from "../../redux/dataSlices/cashcountSlice";
import { getEmployee, getEmployeesAll, getHashEmployeesAll, setEmployee, setEmployeesAll } from "../../redux/dataSlices/employeeSlice";
import BasicCard from "../../components/card"
import CountForm from "../../components/countForm";
import HandleError from "../../helpers/handleError";
import HandleOnChangeCurrencyCount from "../../helpers/handleOnChangeCurrencyCount";
import GetAllDrawers from "../../helpers/getAllDrawers";

import { CountFormFields, CountFormFields2 } from "./countFormFields";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import EmployeePasswordModal from "../../components/employeePasswordModal";
import GetStatusReportRange from "../../actablueAPI/reporting/GetStatusReportRange";
import CalculateReportingTotals from "../../helpers/calculateReportingTotals";
import HandleOnChange from "../../helpers/handleOnChange";
import PostCashCountCount from "../../actablueAPI/cashcount/PostCashCountCount";
import GetEmployees from "../../actablueAPI/employee/GetEmployees";
import GetCashCountCurrency from "../../actablueAPI/cashcount/GetCashCountCurrency";

const CountAdd = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const location = useSelector(getSelectedLocation);
  const allowedCurrency = useSelector(getAllowedCurrency)
  const drawers = useSelector(getDrawersAll)
  const hash_drawers_all = useSelector(getHashDrawersAll)
  const employee = useSelector(getEmployee)
  const employees = useSelector(getEmployeesAll)
  const hash_employees_all = useSelector(getHashEmployeesAll)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [count, setCount] = useState({ admin_id: admin_id, location_id: location_id, reports: [] })
  const [verifying, setVerifying] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [employeeEvent, setEmployeeEvent] = useState(false);
  const [reportingData, setReportingData] = useState({});
  const [selectedDrawer, setSelectedDrawer] = useState("");
  const [reportingLoading, setReportingLoading] = useState(false);

  const onChange = (event) => {
    let newCount = HandleOnChangeCurrencyCount({ event: event, object: count, hash_drawers_all: hash_drawers_all })
    newCount.next_day_amount = selectedDrawer.default_amount;
    newCount.skimming = (newCount.counted.total - selectedDrawer.default_amount).toFixed(2);
    setCount(newCount)
  }

  const onChangeSkimming = (event) => {
    let newCount = HandleOnChange({ event: event, object: count })
    newCount.next_day_amount = (newCount?.counted?.total ? newCount.counted.total - newCount.skimming : 0 - newCount.skimming).toFixed(2);
    setCount(newCount)
  }

  const onChangeNextAmount = (event) => {
    let newCount = HandleOnChange({ event: event, object: count })
    newCount.skimming = (newCount?.counted?.total ? newCount.counted.total - newCount.next_day_amount : 0 - newCount.next_day_amount).toFixed(2);
    setCount(newCount)
  }

  const onChangeEmployee = (event) => {
    dispatch(setEmployee(hash_employees_all[event.value]))
    dispatch(setEmployeePasswordModalShow(true))
    setEmployeeEvent(event)
  }

  const onChangeEftTotal = (event) => {
    let newCount = HandleOnChange({ event: event, object: count })
    newCount.eft_discrepancy = Math.abs(Number(reportingData.eft) - Number(newCount.eft_total)).toFixed(2)
    setCount(newCount)
  }

  const onChangeOnAccTotal = (event) => {
    let newCount = HandleOnChange({ event: event, object: count })
    newCount.on_account_discrepancy = Math.abs(Number(reportingData.on_account) - Number(newCount.on_account_total)).toFixed(2)
    setCount(newCount)
  }

  const onPincodeChange = (event) => {
    if (event.target.value.length === 4) {
      setVerifying(true)
      if (event.target.value === employee.pin_code) {
        setVerifying(false)
        setInvalid(false)
        dispatch(setEmployeePasswordModalShow(false))
        let newCount = HandleOnChangeSingleSelectFieldWithValue({ event: employeeEvent, object: count, selectfield: 'employee_id' })
        setCount(newCount)
      } else {
        setVerifying(false)
        setInvalid(true)
      }
    }
  }

  const onChangeDrawer = (event) => {
    let newCount = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: count, selectfield: 'drawer_id' })
    setCount(newCount)
  }

  const onSubmit = (event) => {

    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    if (!count.reports) count.reports = []

    PostCashCountCount({token:token, data:count})
      .then(() => {
        navigate("/cashcount");
      })
  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    fields: CountFormFields(),
    fields2: CountFormFields2(),
    field: {
      onChange: onChange
    },
    next_day_amount: {
      onChange: onChangeNextAmount
    },
    skimming: {
      onChange: onChangeSkimming
    },
    eft_total: {
      onChange: onChangeEftTotal
    },
    on_account_total: {
      onChange: onChangeOnAccTotal
    },
    modal: {
      onPincodeChange: onPincodeChange,
      verifying: verifying,
      invalid: invalid
    },
    data: count,
    reportingData: reportingData,
    selectedDrawer: selectedDrawer,
    reportingLoading: reportingLoading,
    employees: employees,
    drawers: drawers,
    allowedCurrency: allowedCurrency,
    employee_id: {
      name: 'employee_id',
      options: [],
      selected: [],
      onChange: onChangeEmployee,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    drawer_id: {
      name: 'drawer_id',
      options: [],
      selected: [],
      onChange: onChangeDrawer,
      clearable: Boolean(false),
      searchable: Boolean(true)
    }
  };

  const addCard = {
    size: 12,
    title: "Count add",
    data: <CountForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle("Count add"))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!count.drawer_id || !drawers) return

    const getData = async () => {
      setReportingLoading(true);
      setReportingData(null);

      let newSelectedDrawer = drawers.find(drawer => drawer.id === count.drawer_id);
      setSelectedDrawer(newSelectedDrawer);

      let newCount = JSON.parse(JSON.stringify(count));

      newCount.reports = [];

      let startDate = moment();

      if (newSelectedDrawer.previous_count && moment(newSelectedDrawer.previous_count.creation).format('YYYY-MM-DD') !== moment().format('YYYY-MM-DD')) {
        startDate = moment(newSelectedDrawer.previous_count.creation).add(1, 'day');
      }

      if (newSelectedDrawer.previous_count && startDate.format('YYYY-MM-DD') !== moment().add(1, 'day').format('YYYY-MM-DD')) {
        await GetStatusReportRange({ token: token, admin_id: admin_id, location_id: location_id, startDate: startDate.format('YYYY-MM-DD'), endDate: moment().add(1, 'day').format('YYYY-MM-DD'), location: location })
          .then(response => {
            const totals = CalculateReportingTotals(response.data);
            if (totals.total === 0) return
            setReportingData(totals);
            newCount.reports = [totals]
          })
      }

      let previous_day_amount = 0

      if (hash_drawers_all && newCount?.drawer_id && hash_drawers_all[newCount?.drawer_id]) {
        if (hash_drawers_all[newCount?.drawer_id].previous_count) {
          previous_day_amount = hash_drawers_all[newCount?.drawer_id].previous_count.next_day_amount
        }
      }

      let reporting_total = 0.00
      let reporting_eft_total = 0.00
      let reporting_on_account_total = 0.00
      let count_total = newCount?.counted?.total ? newCount?.counted?.total : 0.00
      let eft_total = newCount?.eft_total ? newCount?.eft_total : 0.00
      let on_account_total = newCount?.on_account_total ? newCount?.on_account_total : 0.00
      for (let report in newCount?.reports) {
        reporting_total += (Number(newCount?.reports[report]?.cash) - Number(newCount?.reports[report].exchange)).toFixed(2)
        reporting_eft_total += (Number(newCount?.reports[report]?.eft)).toFixed(2)
        reporting_on_account_total += (Number(newCount?.reports[report]?.on_account)).toFixed(2)
      }
      newCount.discrepancy = Math.abs(Number(reporting_total) + Number(previous_day_amount) - Number(count_total)).toFixed(2)
      newCount.eft_discrepancy = Math.abs(Number(reporting_eft_total) - Number(eft_total)).toFixed(2)
      newCount.on_account_discrepancy = Math.abs(Number(reporting_on_account_total) - Number(on_account_total)).toFixed(2)

      newCount.next_day_amount = newSelectedDrawer.default_amount;
      if (count?.counted?.total) newCount.skimming = (count.counted.total - newSelectedDrawer.default_amount).toFixed(2);

      setReportingLoading(false)
      setCount(newCount)
    }

    getData()
    // eslint-disable-next-line
  }, [count.drawer_id]);

  useEffect(() => {
    dispatch(setLoading(true))
    axios.all([
      GetCashCountCurrency({token:token, admin_id:admin_id, location_id:location_id}),
      GetEmployees({token:token, admin_id:admin_id}),
      GetAllDrawers({ token: token, admin_id: admin_id, location_id: location_id, page: 1, size: 25 })
    ])
      .then(axios.spread((currencyData, employeeData, drawerData) => {
        dispatch(setAllowedCurrency(currencyData.data))
        dispatch(setEmployeesAll(employeeData.data))
        dispatch(setDrawersAll(drawerData))
        dispatch(setLoading(false))
      }))
      .catch(error => {
        HandleError(error)
        navigate("/cashcount");
      })
    // eslint-disable-next-line
  }, []);

  if (!hash_drawers_all || Object.keys(hash_drawers_all).length === 0) navigate('/cashcount')

  return (
    <>
      <BasicCard {...addCard} />
      <EmployeePasswordModal {...formData} />
    </>
  )

};



export default CountAdd;

