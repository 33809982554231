import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';

function TableButton(props) {
  const { t: translate } = useTranslation();
  const buttonprops = props
  if (buttonprops) {
    return (
      <Button size="sm" type='button' className={buttonprops.className} variant={buttonprops.variant} value={buttonprops.value} onClick={buttonprops.onClick} id={buttonprops.id} disabled={buttonprops.disabled}>
        {translate(buttonprops.tag)}
      </Button>
    );
  } else {
    return (
      <Button size="sm" type='button' className={buttonprops.className}>Button</Button>
    );
  }

}

export default TableButton;