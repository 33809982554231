import React from "react";
import { useState, useEffect } from "react";
import moment from "moment-timezone";
import { useSelector, useDispatch } from "react-redux";
import { setError, setResponseError } from "../../redux/dataSlices/errorSlice";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getReservationObjects, setReservationObjects } from "../../redux/dataSlices/reservationSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card";
import ReservationForm from "../../components/reservationForm";
import { reservationFormFields } from "./reservationFormFields.mjs"
import HandleOnChange from "../../helpers/handleOnChange";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue"
import HandleOnChangeMultiSelectFieldReservationObject from "../../helpers/handleOnChangeMultiSelectFieldReservationObject";
import { reservationStatusChoices, reservationTypeChoices } from "../../selectfieldchoices/reservationselect.mjs";
import HandleError from "../../helpers/handleError";
import PostReservation from "../../actablueAPI/reservations/PostReservation";
import GetReservationObjects from "../../actablueAPI/reservations/GetReservationObjects";

const ReservationAdd = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const reservation_objects = useSelector(getReservationObjects)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [reservation, setReservation] = useState({ administration_id: admin_id, location_id: location_id, type: 'RESERVATION', status: 'PENDING' })

  const onChange = (event) => {
    let newReservation = HandleOnChange({ event: event, object: reservation })
    setReservation(newReservation)
  };

  const onTypeChange = (event) => {
    let newReservation = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: reservation, selectfield: 'type' })
    setReservation(newReservation)
  };

  const onStatusChange = (event) => {
    let newReservation = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: reservation, selectfield: 'status' })
    setReservation(newReservation)
  };

  const onReservationObjectChange = (event) => {
    let newReservation = HandleOnChangeMultiSelectFieldReservationObject({ event: event, object: reservation, selectfield: 'reservation_objects', hash: reservation_objects })
    setReservation(newReservation)
  };

  const onCancel = (event) => {
    navigate("/reservations");
  }

  const onSubmit = (event) => {

    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    let newReservation = Object.assign({}, reservation)
    newReservation.type = reservation.type.toUpperCase()
    newReservation.status = reservation.status.toUpperCase()

    newReservation.reservation_timestamp = moment(reservation.reservation_timestamp).utc().format();

    if (!reservation.reservation_objects) reservation.reservation_objects = []

    PostReservation({token:token, data:newReservation})
    .then(response => {
      navigate("/reservations");
    })
    .catch(error => {
      const type = error.response?.type
      const data = error.response?.data
      if (error.response) { dispatch(setResponseError({type, data})) }
      if (!error.response) { dispatch(setError(JSON.stringify(error))) }
    })

  };

  const formData = {
    cancel: {
      title: 'Cancel',
      type: 'submit',
      onClick: onCancel
    },
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    fields: reservationFormFields,
    field: {
      onChange: onChange
    },
    data: reservation,
    type: {
      name: 'type',
      options: reservationTypeChoices,
      selected: '',
      onChange: onTypeChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    status: {
      name: 'status',
      options: reservationStatusChoices,
      selected: '',
      onChange: onStatusChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    reservation_objects: {
      name: 'reservation_objects',
      options: reservation_objects,
      selected: [],
      onChange: onReservationObjectChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
  };

  const addCard = {
    size: 12,
    title: "Reservation add ",
    data: <ReservationForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle("Reservation Add"))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setLoading(true))
    GetReservationObjects({token:token, admin_id:admin_id, location_id:location_id})
    .then(response => {
      dispatch(setReservationObjects(response.data))
      dispatch(setLoading(false))
    })
    .catch(function (error) {
      HandleError(error)
      // dispatch(setError(JSON.stringify(error)))
      dispatch(setLoading(false))
    })
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...addCard} />
    </>
  )
};
export default ReservationAdd;
