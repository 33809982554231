import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getStockModalShow, getStockPagesize, setStock, setStockModalShow, setStockPageSize, setStockPagination } from "../../redux/dataSlices/warehouseSlice";
import { getScopes } from "../../redux/dataSlices/scopesSlice";
import { pagesize } from "../../selectfieldchoices/pagesize.mjs";
import { StockExport } from "../../helpers/stockExport";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { getToken } from "../../redux/dataSlices/tokenSlice";

export default function StockButtons() {
  const modalShow = useSelector(getStockModalShow)
  const pageSize = useSelector(getStockPagesize)
  const admin_id = useSelector(getSelectedAdmin_id)
  const location_id = useSelector(getSelectedLocation_id)
  const token = useSelector(getToken)
  const scopes = useSelector(getScopes)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function onViewClick(stockMutation) {
    dispatch(setStock(stockMutation));
    dispatch(setStockModalShow(true));
  };

  const onViewHide = (event) => {
    dispatch(setStockModalShow(false));
  };

  const onclickEdit = (stockMutation) => {
    dispatch(setStock(stockMutation));
    navigate("/stock/edit");
  };

  const onPaginationClick = (event) => {
    dispatch(setStockPagination(event.target.value))
  };

  const onProductPageSizeChange = (event) => {
    dispatch(setStockPageSize(event.value))
  };

  const onclickAdd = () => {
    navigate("/stock/add");
  };

  const onExportClick = () => {
    StockExport({ token: token, admin_id: admin_id, location_id: location_id, dispatch: dispatch })
  }

  let buttons = {
    modalview: {
      tag: "View",
      onClick: onViewClick,
      modal: {
        show: modalShow,
        onHide: onViewHide
      }
    },
    pagination: {
      maxbuttons: 5, //uneven number
      onClick: onPaginationClick
    },
    pagesize: {
      className: 'navbar-select',
      label: "page size selector",
      placeholder: "No selecetion",
      pagesize: pageSize,
      selected: { 'id': pageSize, 'name': pageSize },
      number_of_elements: 0,
      options: pagesize,
      onChange: onProductPageSizeChange
    },
    export: {
      tag: "Export",
      onClick: onExportClick,
      className: 'add-button'
    }
  }

  // add buttons based on scopes
  // scopeOptions = ['read', 'create', 'update', 'delete'] (use index: 0:read, 1:create, 2:update, 3:delete)
  // buttonOptions = {'get':0, 'new':1, 'edit':2, 'add':2, 'del':3, 'swap':2, 'move':2, 'import':1, 'create': 1, 'export': 1, 'print': 0}
  // check level of scope, add buttons according to the level.
  if (scopes.stock >= 1) {
    buttons.add = {
      tag: "Add",
      value: 'add',
      onClick: onclickAdd,
      className: 'add-button'
    }
  }
  if (scopes.stock >= 2) {
    buttons.edit = {
      tag: "Edit",
      value: 'edit',
      onClick: onclickEdit
    }
  }

  return buttons
}

