export const statusReportHighlightsSingleDay = (report) => {
  let newTotalCash = 0
  let newTotalEft = 0
  let newTotalOnAccount = 0
  let newTotalExchange = 0
  let newTotalTotal = 0
  let newTotalNettoCash = 0
  let newOGs = []
  let newTaxs = []

  if (report.totals) {
    newTotalCash = report.totals.cash ? report.totals.cash : 0
    newTotalEft = report.totals.eft ? report.totals.eft : 0
    newTotalOnAccount = report.totals.on_account ? report.totals.on_account : 0
    newTotalExchange = report.totals.exchange ? report.totals.exchange : 0
    newTotalTotal = report.totals.total ? report.totals.total : 0
  }

  if (report.journals) {
    for (const [journalId, journalObject] of Object.entries(report.journals)) {
      if (journalId !== 'total' && !newOGs.hasOwnProperty(journalId)) {
        newOGs[journalId] = {name:journalObject.name, total:0}
      }
      if (journalId !== 'total' && newOGs.hasOwnProperty(journalId)) {
        newOGs[journalId]['total'] += journalObject.total
      }
    }
  }

  if (report.taxs) {
    for (const [name, value] of Object.entries(report.taxs)) {
      if (name !== 'total' && !newTaxs.hasOwnProperty(name)) {
        newTaxs[name] = 0
      }
      if (name !== 'total' && newTaxs.hasOwnProperty(name)) {
        newTaxs[name] += value
      }
    }
  }

  newTotalNettoCash = newTotalCash - newTotalExchange

  let newReportHighlights = [
    {Total:{amount:Number(newTotalTotal).toLocaleString('nl-NL', { style:'currency', currency:'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}},
    {Cash:{amount:Number(newTotalNettoCash).toLocaleString('nl-NL', { style:'currency', currency:'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}},
    {EFT:{amount:Number(newTotalEft).toLocaleString('nl-NL', { style:'currency', currency:'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}},
    {OnAccount:{amount:Number(newTotalOnAccount).toLocaleString('nl-NL', { style:'currency', currency:'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}},
    {Transactions:{amount:Number(report.transactions)}},
  ]

  // average
  let newReportAverage = [
    {Total:{amount:Number(newTotalTotal).toLocaleString('nl-NL', { style:'currency', currency:'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}},
    {Cash:{amount:Number(newTotalNettoCash).toLocaleString('nl-NL', { style:'currency', currency:'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}},
    {EFT:{amount:Number(newTotalEft).toLocaleString('nl-NL', { style:'currency', currency:'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}},
    {OnAccount:{amount:Number(newTotalOnAccount).toLocaleString('nl-NL', { style:'currency', currency:'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}},
    {Transactions:{amount:Number(report.transactions)}},
  ]

  if (Number(report.transactions) > 0) {
    newReportAverage = [
      {Total:{amount:(Number(newTotalTotal)/Number(report.transactions)).toLocaleString('nl-NL', { style:'currency', currency:'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}},
      {Cash:{amount:(Number(newTotalNettoCash)/Number(report.transactions)).toLocaleString('nl-NL', { style:'currency', currency:'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}},
      {EFT:{amount:(Number(newTotalEft)/Number(report.transactions)).toLocaleString('nl-NL', { style:'currency', currency:'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}},
      {OnAccount:{amount:(Number(newTotalOnAccount)/Number(report.transactions)).toLocaleString('nl-NL', { style:'currency', currency:'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })}},
      {Transactions:{amount:Number(report.transactions)}},
    ]
  }

  report.average = newReportAverage

  report.calculatedTotals = {
    transactions:report.transactions,
    payments:{cash:newTotalCash, eft:newTotalEft, onAccount:newTotalOnAccount, exchange:newTotalExchange, total:newTotalTotal, nettoCash:newTotalNettoCash},
    journals:newOGs,
    taxs:newTaxs
  }

  return newReportHighlights
};