import React, { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getJournalCategoriesAll, getProductCategoriesAll, getHashJournalCategoriesAll, getHashProductCategoriesAll, setJournalCategoriesAll, setProductCategoriesAll } from "../../redux/dataSlices/categorySlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import BasicCard from "../../components/card"
import MatchingForm from "../../components/matchingForm";
import { MatchingAllProductsFormFields, MatchingProductCatgoriesFormFields, MatchingProductCatgoryFormFields } from "./matchingFormFields";
import HandleOnChangeSingleSelectFieldWithObject from "../../helpers/handleOnChangeSingleSelectFieldWithObject";
import HandleError from "../../helpers/handleError";
import GetAllCategoriesWithType from "../../actablueAPI/products/getAllCategoriesWithType";
import { confirmAlert } from "react-confirm-alert";
import { getMatchingModalShow, setTaskData, setMatchingModalShow, getTaskId, setTaskId } from "../../redux/dataSlices/matchingSlice";
import MatchingProgressModal from "../../components/matchingprogressModal";
import PostMatchingTaskProgress from "../../actablueAPI/matching/PostMatchingTaskPostgress";
import PostMatchingAllProducts from "../../actablueAPI/matching/PostMatchingAllProducts";
import PostMatchingCategories from "../../actablueAPI/matching/PostMatchingCategories";
import PostMatchingCategory from "../../actablueAPI/matching/PostMatchingCategory";

const Matching = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const journalcategories_all = useSelector(getJournalCategoriesAll)
  const hash_journal_categories = useSelector(getHashJournalCategoriesAll)
  const productcategories_all = useSelector(getProductCategoriesAll)
  const hash_productpage_categories = useSelector(getHashProductCategoriesAll)
  const modal_show = useSelector(getMatchingModalShow)
  const task_id = useSelector(getTaskId)
  const dispatch = useDispatch();
  const [matchingForm, setMatchingForm] = useState({})
  let [intervalId, setIntervalId] = useState({})

  const onViewHide = (event) => {
    dispatch(setMatchingModalShow(false));
    dispatch(setTaskId(undefined))
  };

  const matchingProgressModal = {
    modal: {
      show: modal_show,
      onHide: onViewHide
    }
  }

  const onProductCatgorySubmit = () => {
    confirmAlert({
      title: 'Confirm to match',
      message: `Are you certain you wish to match all products from ${matchingForm.productcategory.title} to journalcategory ${matchingForm.journalcategory.title}?\nThis action cannot be undone, and thus removed permanently.\nPlease confirm your decision before proceeding.`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            let ab_data = {}
            ab_data.token = token
            ab_data.access_token = token.access_token
            ab_data.journalcategoryid = matchingForm.journalcategory.id
            ab_data.productcategoryid = matchingForm.productcategory.id
            ab_data.ab_selection = {}
            ab_data.ab_selection.admin_id = admin_id
            ab_data.ab_selection.location_id = location_id
    
            PostMatchingCategory({data:ab_data})
            .then(response => {
              dispatch(setTaskId(response.data.task_id))
            })
            .catch(error => {
              HandleError(error)
            })
          }
        },
        {
          label: 'No',
        }
      ]
    });
};

  const onProductCatgoriesSubmit = () => {
    confirmAlert({
      title: 'Confirm to match',
      message: `Are you certain you wish to match all products from all productcategories to journalcategory ${matchingForm.journalcategory.title}?\nThis action cannot be undone, and thus removed permanently.\nPlease confirm your decision before proceeding.`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            let ab_data = {}
            ab_data.token = token
            ab_data.access_token = token.access_token
            ab_data.journalcategoryid = matchingForm.journalcategory.id
            ab_data.ab_selection = {}
            ab_data.ab_selection.admin_id = admin_id
            ab_data.ab_selection.location_id = location_id

            PostMatchingCategories({data:ab_data})
            .then(response => {
              dispatch(setTaskId(response.data.task_id))
            })
            .catch(error => {
              HandleError(error)
            })
      }
        },
        {
          label: 'No',
        }
      ]
    });
};

  const onAllProductsSubmit = () => {
    confirmAlert({
      title: 'Confirm to match',
      message: `Are you certain you wish to match all products to journalcategory ${matchingForm.journalcategory.title}?\nThis action cannot be undone, and thus removed permanently.\nPlease confirm your decision before proceeding.`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            let ab_data = {}
            ab_data.token = token
            ab_data.access_token = token.access_token
            ab_data.journalcategoryid = matchingForm.journalcategory.id
            ab_data.ab_selection = {}
            ab_data.ab_selection.admin_id = admin_id
            ab_data.ab_selection.location_id = location_id
    
            PostMatchingAllProducts({data:ab_data})
            .then(response => {
              dispatch(setTaskId(response.data.task_id))
            })
            .catch(error => {
              HandleError(error)
            })
      }
        },
        {
          label: 'No',
        }
      ]
    });
};

  const onJournalCategoriesChange = (event) => {
    let newForm = HandleOnChangeSingleSelectFieldWithObject({ event: event, object: matchingForm, selectfield: 'journalcategory', hash: hash_journal_categories })
    setMatchingForm(newForm)
  };

  const onProductCategoriesChange = (event) => {
    let newForm = HandleOnChangeSingleSelectFieldWithObject({ event: event, object: matchingForm, selectfield: 'productcategory', hash: hash_productpage_categories })
    setMatchingForm(newForm)
  };

  const formData = {
    data: matchingForm,
    matchingProductCatgoryFormFields: MatchingProductCatgoryFormFields(),
    matchingProductCatgorySubmit: {
      title: 'Connect',
      onClick: onProductCatgorySubmit
    },
    matchingProductCatgoriesFormFields: MatchingProductCatgoriesFormFields(),
    matchingProductCatgoriesSubmit: {
      title: 'Connect',
      onClick: onProductCatgoriesSubmit
    },
    matchingAllProductsFormFields: MatchingAllProductsFormFields(),
    matchingAllProductsSubmit: {
      title: 'Connect',
      onClick: onAllProductsSubmit
    },
    journalcategory: {
      name: 'journalcategory',
      options: journalcategories_all,
      selected: {},
      onChange: onJournalCategoriesChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    productcategory: {
      name: 'productcategory',
      options: productcategories_all,
      selected: {},
      onChange: onProductCategoriesChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
  }

  const card = {
    size: 12,
    title: 'Matching',
    data: <MatchingForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle('Matching'))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setLoading(true))
    axios.all([
      GetAllCategoriesWithType({ type: 'JOURNAL', token: token, admin_id: admin_id, location_id: location_id }),
      GetAllCategoriesWithType({ type: 'PRODUCTPAGE', token: token, admin_id: admin_id, location_id: location_id })
    ])
    .then(axios.spread((responseJournal, responseProductpage) => {
      dispatch(setJournalCategoriesAll(responseJournal))
      dispatch(setProductCategoriesAll(responseProductpage))
      dispatch(setLoading(false))
    }))
    .catch(error => {
      HandleError(error)
      dispatch(setLoading(false))
    })    // eslint-disable-next-line
  }, [admin_id, location_id]);

  useEffect(() => {
    function getProgres() {
      if (task_id) {
        PostMatchingTaskProgress({data:{task_id:task_id}})
        .then(response => {
          if (response?.data?.result?.state === 'PENDING') {
            dispatch(setTaskData(response.data.result))
          }

          if (response?.data?.result?.state === 'PROGRESS') {
            dispatch(setTaskData(response.data.result))
          }        

          if (response?.data?.result?.state === 'SUCCESS') {
            dispatch(setTaskData(response.data.result))
            clearInterval(intervalId)
            dispatch(setTaskId(undefined))
            dispatch(setMatchingModalShow(false))
          } 
        })
        .catch(error => {
            clearInterval(intervalId)
            dispatch(setTaskId(undefined))
            dispatch(setMatchingModalShow(false))
            HandleError(error)
        })
  } 
    }

    if (task_id) {
      dispatch(setMatchingModalShow(true))
      setIntervalId(setInterval(() => {getProgres()}, 1000))
    } 
    
    if (!task_id) {
      dispatch(setTaskData(undefined))
      clearInterval(intervalId)
    }
    // eslint-disable-next-line
  }, [task_id]);

  return (
    <>
      <MatchingProgressModal {...matchingProgressModal} />
      <BasicCard {...card} />
    </>
  )
};
export default Matching;