export default function TransactionHighLightCalculation (transactions) {
  let totalRevenue = 0
  let amount_exchange = 0
  let amount_paid = 0
  let amount_open = 0
  let transactioncount = 0 
  let average = 0
  let cash = 0
  let eft = 0
  let on_account = 0

  for (let transaction of transactions) {
    amount_exchange = amount_exchange + Number(transaction.amount_exchange)
    amount_open = amount_open + Number(transaction.amount_open)
    amount_paid = amount_paid + Number(transaction.amount_paid)
    transactioncount = transactioncount + 1
    if (transaction.payments) {
      for (let payment of transaction.payments) {
        switch(payment.type) {
          case 'CASH':
            cash = cash + Number(payment.amount)
            break
          case 'EFT':
            eft = eft + Number(payment.amount)
            break
          case 'ON_ACCOUNT':
            on_account = on_account + Number(payment.amount)
            break
          default:
            break
        }
      }
    }
    
  };

  totalRevenue = amount_paid - amount_exchange + amount_open
  if (transactioncount > 0) {
    average = totalRevenue/transactioncount
  }

  cash = cash - amount_exchange
  
  // create locale string
  totalRevenue = Number(totalRevenue).toLocaleString('nl-NL', { style:'currency', currency:'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })
  amount_exchange = Number(amount_exchange).toLocaleString('nl-NL', { style:'currency', currency:'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })
  amount_paid = Number(amount_paid).toLocaleString('nl-NL', { style:'currency', currency:'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })
  amount_open = Number(amount_open).toLocaleString('nl-NL', { style:'currency', currency:'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })
  average = Number(average).toLocaleString('nl-NL', { style:'currency', currency:'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })
  cash = Number(cash).toLocaleString('nl-NL', { style:'currency', currency:'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })
  eft = Number(eft).toLocaleString('nl-NL', { style:'currency', currency:'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })
  on_account = Number(on_account).toLocaleString('nl-NL', { style:'currency', currency:'EUR', maximumFractionDigits: 2, minimumFractionDigits: 2 })

  return [{Total:totalRevenue}, {Count:transactioncount}, {Average:average}, {Cash:cash}, {EFT:eft}, {OnAccount:on_account}]
}

