import React from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getReservationPagination, getReservationOptions, setReservationOptions, getReservationPagesize } from "../../redux/dataSlices/reservationSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import BasicCard from "../../components/card"
import ReservationOptionTable from "../../components/reservationoptionTable";
import { setError } from "../../redux/dataSlices/errorSlice";
import GetReservationOptions from "../../actablueAPI/reservations/GetReservationOptions";

const ReservationOptions = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const reservationoptions = useSelector(getReservationOptions);
  const pagination = useSelector(getReservationPagination);
  const pageSize = useSelector(getReservationPagesize)
  const dispatch = useDispatch();
  const navTitle = "Reservation options"


  const reservationOptionCard = {
    size: 12,
    title: navTitle,
    data: <ReservationOptionTable {...reservationoptions} />
  }

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setLoading(true))
    GetReservationOptions({token:token, admin_id:admin_id})
    .then(response => {
      dispatch(setReservationOptions(response.data))
      dispatch(setLoading(false))
    })
    .catch(function (error) {
      dispatch(setError(JSON.stringify(error)))
      dispatch(setLoading(false))
    })
    // eslint-disable-next-line
  }, [admin_id, location_id, pagination, pageSize]);


  return (
    <>
      <BasicCard {...reservationOptionCard} />
    </>
  )
};
export default ReservationOptions;
