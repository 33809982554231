export default function HandleOnChangeMultiObjectSingleSelectFieldWithValue(props) {
  const event = props.event
  const object = props.object || {}
  const selectfield = props.selectfield || props.event.id
  const field = props.field

  const prefix = event?.prefix ?? props.prefix

  const elementId = event.elementid

  let newObject = JSON.parse(JSON.stringify(object))

  if (prefix === 'add') {
    let newSubObject = {}
    newSubObject[selectfield] = event.value
    newObject[field].push(newSubObject)
  }

  if (!prefix) {
    newObject[field].forEach(element => {
      if (!element.hasOwnProperty('id')) {
        element[selectfield] = event.value
      }
    });
  }

  if (elementId) {
    newObject[field].forEach(element => {
      if (element.id.toString() === elementId.toString()) {
        element[selectfield] = event.value
      }
    });
  }

  return (newObject)
}