import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import TableButton from './tableButton';
import ReservationButtons from "../portal/reservations/reservationbuttons";
import { useTranslation } from 'react-i18next';



function ReservationTable(props) {
  const reservationButtons = ReservationButtons.call()
  const { t: translate } = useTranslation();

  if (props.reservations && props.reservations.length > 0) {
    return (
      <Table hover width='100%'>
        <thead>
          <tr>
            <th width='20%'>
              {translate('Name')}
            </th>
            <th width='20%'>
              {translate('Time')}
            </th>
            <th width='20%'>
              {translate('People')}
            </th>
            <th width='20%'>
              {translate('Table')}
            </th>
            <th width='20%'>
              {translate('Action')}
            </th>
          </tr>
        </thead>
        <tbody>
          {props.reservations.map((reservation) => {
            const status = reservation.status;
            const view = reservationButtons.hasOwnProperty('view') ? Object.assign({}, reservationButtons.view, { id: reservation.id }) : false
            const accept = reservationButtons.hasOwnProperty('accept') ? Object.assign({}, reservationButtons.accept, { id: reservation.id }) : false
            const cancel = reservationButtons.hasOwnProperty('cancel') ? Object.assign({}, reservationButtons.cancel, { id: reservation.id }) : false
            const pending = reservationButtons.hasOwnProperty('pending') ? Object.assign({}, reservationButtons.pending, { id: reservation.id }) : false
            const edit = reservationButtons.hasOwnProperty('edit') ? Object.assign({}, reservationButtons.edit, { id: reservation.id }) : false
            return (
              <tr key={reservation.id}>

                <td>{reservation.reservation_name}</td>
                <td>{new Date(reservation.reservation_timestamp).toLocaleString()}</td>
                <td>{reservation.number_of_people}</td>
                <td></td>
                {/* <td>{reservation.status}</td> */}
                <td>
                  {view && <TableButton {...view} />}
                  {accept && status !== 'ACCEPTED' && <TableButton {...accept} />}
                  {pending && status !== 'PENDING' && <TableButton {...pending} />}
                  {cancel && status !== 'CANCELLED' && <TableButton {...cancel} />}
                  {edit && <TableButton {...edit} />}
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    );
  } else {
    return (
      <Table width='100%'>
        <thead>
          <tr>
            <th width='20%'>
              {translate('Name')}
            </th>
            <th width='20%'>
              {translate('Time')}
            </th>
            <th width='20%'>
              {translate('People')}
            </th>
            <th width='20%'>
              {translate('Table')}
            </th>
            <th width='20%'>
              {translate('Action')}
            </th>
          </tr>
        </thead>
      </Table>
    );
  }
}

export default ReservationTable;