import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Tab, Nav, Table, Row, Col, Accordion } from 'react-bootstrap';
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';

import CreateField from '../helpers/createField';
import { getRole } from '../redux/dataSlices/tokenSlice';
import { getHashAdminLocationsAll } from '../redux/dataSlices/locationSlice';

function UserRoleForm(props) {
  const role = useSelector(getRole);
  const { t: translate } = useTranslation();
  const hash_admin_locations_all = useSelector(getHashAdminLocationsAll);

  let basicFields = []
  let formTabs = [];
  let formTabContent = [];
  let locationAccordions = []
  props.fields?.userRoleFormFields?.forEach(field => {
    basicFields.push(CreateField(field, props))
  });

  // roles
  formTabs.push(
    <Nav.Item key='roles'>
      <Nav.Link eventKey='roles'>
        {translate('Roles')}
      </Nav.Link>
    </Nav.Item>
  );


  Object.values(hash_admin_locations_all).forEach(location => {
    let portalScopeFields = [];
    let apiScopeFields = [];

    const filteredRoleFields = props?.fields?.roleFields?.filter(item => item.location_id === location?.id);
    if (filteredRoleFields?.length < 1) return;
    
    filteredRoleFields?.forEach(field => {
      if (field.name.startsWith("portal:") && field.name.split(":").length > 3) {
        portalScopeFields.push(
          <>
            <tr>
              {/* <td>{field.name}</td> */}
              <td>{translate(field.name.split(":")[2].charAt(0).toUpperCase() + field.name.split(":")[2].slice(1))}</td>
              <td>
                <input className="form-check-input"
                  prefix={field.prefix}
                  type='checkbox'
                  name={'read'}
                  placeholder={field.placeholder ?? field.name}
                  value={field.value}
                  onChange={props.field.onChangeScope}
                  id={field.id}
                  disabled={field.name.split(":")[3] !== 'read' && field.name.split(":")[3] !== 'update' && field.name.split(":")[3] !== 'create' && field.name.split(":")[3] !== 'delete'}
                  checked={props?.data?.roles[field.id]?.split(":")[5] === 'read' ||
                    props?.data?.roles[field.id]?.split(":")[5] === 'update' ||
                    props?.data?.roles[field.id]?.split(":")[5] === 'create' ||
                    props?.data?.roles[field.id]?.split(":")[5] === 'delete'}
                  alt={field.location_id}
                >
                </input>
              </td>
              <td>
                <input className="form-check-input"
                  prefix={field.prefix}
                  type='checkbox'
                  name={'update'}
                  placeholder={field.placeholder ?? field.name}
                  value={field.value}
                  onChange={props.field.onChangeScope}
                  id={field.id}
                  disabled={field.name.split(":")[3] !== 'update' && field.name.split(":")[3] !== 'create' && field.name.split(":")[3] !== 'delete'}
                  checked={props?.data?.roles[field.id]?.split(":")[5] === 'update' || props?.data?.roles[field.id]?.split(":")[5] === 'create' || props?.data?.roles[field.id]?.split(":")[5] === 'delete'}
                  alt={field.location_id}
                >
                </input>
              </td>
              <td>
                <input className="form-check-input"
                  prefix={field.prefix}
                  type='checkbox'
                  name={'create'}
                  placeholder={field.placeholder ?? field.name}
                  value={field.value}
                  onChange={props.field.onChangeScope}
                  id={field.id}
                  disabled={field.name.split(":")[3] !== 'create' && field.name.split(":")[3] !== 'delete'}
                  checked={props?.data?.roles[field.id]?.split(":")[5] === 'create' || props?.data?.roles[field.id]?.split(":")[5] === 'delete'}
                  alt={field.location_id}
                >
                </input>
              </td>
              <td>
                <input className="form-check-input"
                  prefix={field.prefix}
                  type='checkbox'
                  name={'delete'}
                  placeholder={field.placeholder ?? field.name}
                  value={field.value}
                  onChange={props.field.onChangeScope}
                  id={field.id}
                  disabled={field.name.split(":")[3] !== 'delete'}
                  checked={props?.data?.roles[field.id]?.split(":")[5] === 'delete'}
                  alt={field.location_id}
                >
                </input>
              </td>
            </tr>
          </>
        )
      }
    })

    const filteredApiRoleFields = props?.fields?.apiRoleFields?.filter(item => item.location_id === location?.id);
    filteredApiRoleFields?.forEach(field => {

      const searchValueInObjects = (string, array) => {
        const filteredObjects = array.filter(obj => {
          return Object.values(obj).some(value => value.includes(string));
        });

        return filteredObjects;
      };

      const filteredObjects = searchValueInObjects(`:${field.name.split(":")[1]}`, props.fields.roleFields)
      const correctObject = filteredObjects.find(obj => obj.location_id === field.location_id);

      apiScopeFields.push(
        <>
          <tr>
            <td>{translate(field.name.split(":")[1] + "/" + field.name.split(":")[2])}</td>
            <td>
              <input className="form-check-input"
                prefix={field.prefix}
                type='checkbox'
                name={'read'}
                placeholder={field.placeholder ?? field.name}
                value={field.value}
                onChange={props.field.onChangeScope}
                id={field.id}
                disabled={correctObject?.name?.split(":")[3] !== 'read' &&
                  correctObject?.name?.split(":")[3] !== 'update' &&
                  correctObject?.name?.split(":")[3] !== 'create' &&
                  correctObject?.name?.split(":")[3] !== 'delete'}
                checked={props?.data?.roles[field.id]?.split(":")[5] === 'read' ||
                  props?.data?.roles[field.id]?.split(":")[5] === 'update' ||
                  props?.data?.roles[field.id]?.split(":")[5] === 'create' ||
                  props?.data?.roles[field.id]?.split(":")[5] === 'delete'}
                alt={field.location_id}
              >
              </input>
            </td>
            <td>
              <input className="form-check-input"
                prefix={field.prefix}
                type='checkbox'
                name={'update'}
                placeholder={field.placeholder ?? field.name}
                value={field.value}
                onChange={props.field.onChangeScope}
                id={field.id}
                disabled={correctObject?.name?.split(":")[3] !== 'update' && correctObject?.name?.split(":")[3] !== 'create' && correctObject?.name?.split(":")[3] !== 'delete'}
                checked={props?.data?.roles[field.id]?.split(":")[5] === 'update' || props?.data?.roles[field.id]?.split(":")[5] === 'create' || props?.data?.roles[field.id]?.split(":")[5] === 'delete'}
                alt={field.location_id}
              >
              </input>
            </td>
            <td>
              <input className="form-check-input"
                prefix={field.prefix}
                type='checkbox'
                name={'create'}
                placeholder={field.placeholder ?? field.name}
                value={field.value}
                onChange={props.field.onChangeScope}
                id={field.id}
                disabled={correctObject?.name?.split(":")[3] !== 'create' && correctObject?.name?.split(":")[3] !== 'delete'}
                checked={props?.data?.roles[field.id]?.split(":")[5] === 'create' || props?.data?.roles[field.id]?.split(":")[5] === 'delete'}
                alt={field.location_id}
              >
              </input>
            </td>
            <td>
              <input className="form-check-input"
                prefix={field.prefix}
                type='checkbox'
                name={'delete'}
                placeholder={field.placeholder ?? field.name}
                value={field.value}
                onChange={props.field.onChangeScope}
                id={field.id}
                disabled={correctObject?.name?.split(":")[3] !== 'delete'}
                checked={props?.data?.roles[field.id]?.split(":")[5] === 'delete'}
                alt={field.location_id}
              >
              </input>
            </td>
          </tr>
        </>
      )
    });

    locationAccordions.push(
      <Accordion.Item eventKey={location.id} className='mb-3 settings-accordion'>
        <Accordion.Header><h4>{translate(location.name)}</h4></Accordion.Header>
        <Accordion.Body>
          <Row key={location.id} className='pt-3 mx-1'>
            <Col md={12}>
              <Table>
                <thead>
                  <tr>
                    <th width="25%">{translate('Portal scopes')}</th>
                    <th width="5%">{translate('Read')}</th>
                    <th width="5%">{translate('Update')}</th>
                    <th width="5%">{translate('Create')}</th>
                    <th width="5%">{translate('Delete')}</th>
                  </tr>
                </thead>
                <tbody>
                  {portalScopeFields}
                </tbody>
              </Table>
              {(role === 'admin' || role === 'super') && (
                <>
                  <br />
                  <Table>
                    <thead>
                      <tr>
                        <th width="25%">{translate('Api scopes')}</th>
                        <th width="5%">{translate('Read')}</th>
                        <th width="5%">{translate('Update')}</th>
                        <th width="5%">{translate('Create')}</th>
                        <th width="5%">{translate('Delete')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {apiScopeFields}
                    </tbody>
                  </Table>
                </>
              )}
            </Col>
          </Row>
        </Accordion.Body>
      </Accordion.Item>
    );
  })

  formTabContent.push(
    <Tab.Pane eventKey='roles' key='role'>
      <Row className='mx-1 mt-3'>
        <Accordion alwaysOpen>
          {locationAccordions}
        </Accordion>
      </Row>
    </Tab.Pane>
  );

  return (
    <>
      <Form>
        <Tab.Container defaultActiveKey="main">
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="main">
                {translate('Main')}
              </Nav.Link>
            </Nav.Item>
            {formTabs}
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="main">
              <div className='tab-container'>
                {basicFields}
              </div>
            </Tab.Pane>
            {formTabContent}
          </Tab.Content>
        </Tab.Container>
      </Form>
      <Button variant="primary" type={props.submit.type} onClick={props.submit.onClick}>
        {translate(props.submit.title)}
      </Button>
    </>
  );
}

export default UserRoleForm;