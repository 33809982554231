import React from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card"
import NarrowcastingForm from "../../components/narrowcastingViewForm";
import { NarrowcastingViewFormFields } from "./narrowcastingViewFormFields";
import HandleOnChange from "../../helpers/handleOnChange";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getView, setView } from "../../redux/dataSlices/narrowcastingSlice";
import HandleError from "../../helpers/handleError";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import PostView from "../../actablueAPI/narrowcasting/PostView";
import { contentTypeChoices } from "../../selectfieldchoices/narrowcastingselect.mjs";

const NarrowcastingViewEdit = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id)
  const view = useSelector(getView)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navTitle = "View edit"

  const onChange = (event) => {
    let newView = HandleOnChange({ event: event, object: view })
    dispatch(setView(newView))
  }

  const onContentTypeChange = (event) => {
    let newView = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: view, selectfield: 'content_type' })
    dispatch(setView(newView))
  }

  const onSubmit = () => {
    PostView({ token: token, admin_id: admin_id, view: view })
      .then(() => {
        navigate("/narrowcastingviews");
      })
      .catch(error => {
        HandleError(error)
      })
  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    fields: NarrowcastingViewFormFields(),
    field: {
      onChange: onChange
    },
    data: view,
    content_type: {
      label: 'content_type',
      options: contentTypeChoices,
      onChange: onContentTypeChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    }
  };

  const editCard = {
    size: 12,
    title: navTitle,
    data: <NarrowcastingForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...editCard} />
    </>
  )

};
export default NarrowcastingViewEdit;

