import axios from "axios";
import HandleError from "../../helpers/handleError";

const GetAllCategoriesWithType = ({ type, token, admin_id, location_id, page = 0, payload = [], resolver = null }) => {
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_ENV}/products/category/type/list`,
      headers: {
        'Authorization': `Bearer ${token['access_token']}`,
        'Content-Type': 'application/json',
        'administration': admin_id
      },
      params: {
        'administration': admin_id,
        'location': location_id,
        'type': type,
        'page': page,
        'size': 25
      },
    })
      .then(response => {
        let updatedPayload = [...payload];
        if (response.data.content) {
          updatedPayload = [...payload, ...response.data.content];
        }

        if (response.data.last !== true) {
          const nextPage = response.data.number + 1
          GetAllCategoriesWithType({
            type: type,
            token: token,
            admin_id: admin_id,
            location_id: location_id,
            page: nextPage,
            payload: updatedPayload,
            resolver: resolver || resolve
          });
        } else {
          if (resolver) resolver(updatedPayload);
          resolve(updatedPayload);
        }
      })
      .catch(error => {
        HandleError(error)
      });
  });
};

export default GetAllCategoriesWithType