import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card";
import NarrowcastingForm from "../../components/narrowcastingPlaylistForm";
import { NarrowcastingPlaylistFormFields } from "./narrowcastingPlaylistFormFields";
import HandleOnChange from "../../helpers/handleOnChange";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import HandleError from "../../helpers/handleError";
import PostPlaylist from "../../actablueAPI/narrowcasting/PostPlaylist";
import GetAllViews from "../../actablueAPI/narrowcasting/GetAllViews";
import { setViewsAll, getViewsAll, getHashViewsAll } from "../../redux/dataSlices/narrowcastingSlice";
import HandleOnChangeMultiSelectFieldWithObjects from "../../helpers/handleOnChangeMultiSelectFieldWithObjects";
import { layoutChoices } from "../../selectfieldchoices/narrowcastingselect.mjs";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";


const NarrowcastingPlaylistAdd = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id)
  const location_id = useSelector(getSelectedLocation_id)
  const views_all = useSelector(getViewsAll)
  const hash_views_all = useSelector(getHashViewsAll)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navTitle = "Playlist add"
  const [playlist, setPlaylist] = useState({ admin_id: admin_id, location_id: location_id })

  const onChange = (event) => {
    let newPlaylist = HandleOnChange({ event: event, object: playlist })
    setPlaylist(newPlaylist)
  }

  const onHandleOnChangeMultiSelectFieldWithObjects = (event) => {
    let newPlaylist = HandleOnChangeMultiSelectFieldWithObjects({ event: event, object: playlist, selectfield: 'views', hash: hash_views_all })
    setPlaylist(newPlaylist)
  }

  const onLayoutChange = (event) => {
    let newPlaylist = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: playlist, selectfield: 'layout' })
    setPlaylist(newPlaylist)
  }

  const onSubmit = () => {
    PostPlaylist({ token: token, admin_id: admin_id, playlist: playlist })
      .then(() => {
        navigate("/narrowcastingplaylists");
      })
      .catch(error => {
        HandleError(error)
      })

  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    fields: NarrowcastingPlaylistFormFields(),
    field: {
      onChange: onChange
    },
    data: playlist,
    views: {
      label: 'views',
      options: views_all,
      onChange: onHandleOnChangeMultiSelectFieldWithObjects,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    layout: {
      label: 'layout',
      options: layoutChoices,
      onChange: onLayoutChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    }
  };

  const editCard = {
    size: 12,
    title: navTitle,
    data: <NarrowcastingForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    GetAllViews({ token: token, admin_id: admin_id, location_id: location_id })
      .then(response => {
        dispatch(setViewsAll(response))
      })
      .catch(error => {
        HandleError(error)
      })
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...editCard} />
    </>
  )

};
export default NarrowcastingPlaylistAdd;

