import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getOpticonEsl, setOpticonEsl } from "../../redux/dataSlices/opticonSlice";
import { getLocationsAllAdmins, getHashLocationsAll } from "../../redux/dataSlices/locationSlice";
import { getAdministrationsAll, getHashAdministrationsAll } from "../../redux/dataSlices/administrationSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import BasicCard from "../../components/card"
import OpticonEslFormFields from "./opticonEslFormFields";
import OpticonEslForm from "../../components/opticonEslForm";
import HandleOnChange from "../../helpers/handleOnChange";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import PostOpticonProductLink from "../../actablueAPI/opticon/PostOpticonProductLink";

const OpticonEslEdit = () => {
  const token = useSelector(getToken);
  const admins_all = useSelector(getAdministrationsAll)
  const hash_admins_all = useSelector(getHashAdministrationsAll)
  const locations_all_admins = useSelector(getLocationsAllAdmins)
  const hash_locations_all = useSelector(getHashLocationsAll)
  const esl = useSelector(getOpticonEsl)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onChange = (event) => {
    let newConnection = HandleOnChange({ event: event, object: esl })
    dispatch(setOpticonEsl(newConnection))
  }

  const onLocationIdChange = (event) => {
    let newConnection = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: esl, selectfield: 'location_id' })
    dispatch(setOpticonEsl(newConnection))
  }

  const onAdminIdChange = (event) => {
    let newConnection = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: esl, selectfield: 'administration_id' })
    delete newConnection.location_id
    dispatch(dispatch(setOpticonEsl(newConnection)))
  }

  const onSubmit = () => {
    PostOpticonProductLink({token:token, data:esl})
    .then(postResponse => {
      navigate("/opticon");
    })  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    fields: OpticonEslFormFields(),
    field: {
      onChange: onChange
    },
    data: esl,
    location_id: {
      label: 'location_id',
      // eslint-disable-next-line
      options: locations_all_admins.filter((item) => { if (item.administration_id === esl.administration_id) { return true } }),
      hash: hash_locations_all,
      selected: {},
      onChange: onLocationIdChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
    administration_id: {
      label: 'administration_id',
      options: admins_all,
      hash: hash_admins_all,
      selected: {},
      onChange: onAdminIdChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    product_id: {
      label: 'product_id',
      options: [],
      // hash: hash_admins_all,
      selected: {},
      onChange: onAdminIdChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
  };

  const card = {
    size: 12,
    title: 'Opticon esl edit',
    data: <OpticonEslForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle('Opticon esl edit'))
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...card} />
    </>
  );
};
export default OpticonEslEdit;
