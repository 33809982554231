import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import BasicCard from "../../components/card"
import HandleError from "../../helpers/handleError";
import { getHashAdministrationsAll } from "../../redux/dataSlices/administrationSlice";
import { getHashLocationsAll } from "../../redux/dataSlices/locationSlice";
import SortCategoryTable from "../../components/sortCategoryTable";
import { getSortCategories, getSortCategoryPageSize, getSortCategoryPagination, setSortCategories } from "../../redux/dataSlices/kitchenmanagerSlice";
import GetCategories from "../../actablueAPI/kitchenmanager/GetCategories";

const SortCategories = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const hash_admins_all = useSelector(getHashAdministrationsAll);
  const hash_locations_all = useSelector(getHashLocationsAll);
  const sortCategories = useSelector(getSortCategories);
  const sortcategory_pagination = useSelector(getSortCategoryPagination);
  const sortcategory_pageSize = useSelector(getSortCategoryPageSize);
  const dispatch = useDispatch();
  const navTitle = "Kitchen Manager"

  const drawersCard = {
    size: 12,
    title: "Sort Categories",
    data: location_id ? <SortCategoryTable sortCategories={sortCategories} hash_admins_all={hash_admins_all} hash_locations_all={hash_locations_all} /> : 'Please select a location or administration',
  };

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    // eslint-disable-next-line
  }, [navTitle]);

  useEffect(() => {
    dispatch(setLoading(true))
    GetCategories({ token: token, admin_id: admin_id, location_id: location_id, page: sortcategory_pagination, size: sortcategory_pageSize })
      .then(response => {
        dispatch(setSortCategories(response.data))
      })
      .catch(error => {
        HandleError(error)
        dispatch(setLoading(false))
      })
      .finally(() => {
        dispatch(setLoading(false))
      })
    // eslint-disable-next-line
  }, [, admin_id, location_id, sortcategory_pagination, sortcategory_pageSize]);

  return (
    <>
      <BasicCard {...drawersCard} />
    </>
  )
};
export default SortCategories;

