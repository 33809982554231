import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { setVatsAll, getVatsAll, getHashVatsAll } from "../../redux/dataSlices/vatSlice";
import { getJournalCategoriesAll, getHashJournalCategoriesAll } from "../../redux/dataSlices/categorySlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card"
import CategoryForm from "../../components/categoryForm"
import { CategoryFormFields } from "./journalcategoryFormFields"
import HandleOnChangeSingleSelectFieldWithObject from "../../helpers/handleOnChangeSingleSelectFieldWithObject";
import HandleOnChange from "../../helpers/handleOnChange";
import GetAllVats from "../../helpers/getAllVats";
import HandleError from "../../helpers/handleError";
import PostCategory from "../../actablueAPI/products/PostCategory";

const CategoryAdd = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const vats = useSelector(getVatsAll)
  const hash_vats = useSelector(getHashVatsAll)
  const journalCategories = useSelector(getJournalCategoriesAll)
  const hash_journal_categories = useSelector(getHashJournalCategoriesAll)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [category, setCategory] = useState({ administration_id: admin_id, location_id: location_id, is_visible: true, type: 'JOURNAL' })

  const onChange = (event) => {
    let newCategory = HandleOnChange({ event: event, object: category })
    setCategory(newCategory)
  }

  const onVatChange = (event) => {
    let newCategory = HandleOnChangeSingleSelectFieldWithObject({ event: event, object: category, selectfield: 'vat', hash: hash_vats })
    setCategory(newCategory)
  };

  const onParentChange = (event) => {
    let newCategory = HandleOnChangeSingleSelectFieldWithObject({ event: event, object: category, selectfield: 'parent', hash: hash_journal_categories })
    setCategory(newCategory)
  };

  const onSubmit = () => {
    PostCategory({token:token, admin_id:admin_id, category:category})
    .then(response => {
      navigate("/journalcategories");
    })
    .catch((error) => {
      HandleError(error)
    })
  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    fields: CategoryFormFields(),
    field: {
      onChange: onChange
    },
    data: category,
    vats: {
      label: 'vat',
      options: vats,
      onChange: onVatChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    parent: {
      label: 'parent',
      placeholder: 'parent',
      options: journalCategories,
      selected: {},
      onChange: onParentChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
  };

  const addCard = {
    size: 12,
    title: "Category add ",
    data: <CategoryForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle("Category add"))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setLoading(true))
    GetAllVats({ token: token, admin_id: admin_id, location_id: location_id })
    .then(response => {
      dispatch(setVatsAll(response))
      dispatch(setLoading(false))
    })
    .catch(error => {
      HandleError(error)
      dispatch(setLoading(false))
    })
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...addCard} />
    </>
  )

};
export default CategoryAdd;