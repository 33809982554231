import PostRegistrationByIdSubscriptionSettingsById from "../actablueAPI/registrations/PostRegistrationByIdSubscriptionSettingsById"
import { setInvalidRegistrations } from "../redux/dataSlices/registrationSlice"
import HandleError from "./handleError"

export default function RegistrationIsValid(props) {
  const setting = props?.setting
  const token = props?.token
  const dispatch = props?.dispatch

  if (setting) {
    let newSetting = JSON.parse(JSON.stringify(setting))
    // check enddate = stop
    if (!setting.stop) {
      let stop = new Date(setting.start)
      newSetting.stop = stop.setMonth(stop.getMonth() + setting.duration)
      delete newSetting.subscription
      if (setting.registration_id) {
        PostRegistrationByIdSubscriptionSettingsById({token: token, registration_id:setting.registration_id, settings_id:newSetting.id, data:newSetting})
        .then(response => {
          console.log('updated subscriptionsetings with stop date', response)
        })
        .catch(error => {HandleError(error)})
      }
    }
    // still valid? check stop date versus now
    const now = new Date()
    const stop = new Date(newSetting.stop)
    if (now.valueOf() <= stop.valueOf()) {
      return true
    }
    dispatch(setInvalidRegistrations(setting))
  }
  return false
}