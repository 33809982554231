import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getHashSubscriptionProductsAll, getSubscriptionProductsAll } from "../../redux/dataSlices/productSlice";
import { getSubscriptionTypes, setSubscriptionTypes, getHashSubscriptionTypes, getSubscription } from "../../redux/dataSlices/subscriptionSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import { useNavigate } from "react-router-dom";
import BasicCard from "../../components/card"
import SubscriptionForm from "../../components/subscriptionForm";
import SubscriptionFormFields from "./subscriptionFormFields";
import HandleOnChange from "../../helpers/handleOnChange";
import HandleOnChangeSingleSelectFieldWithValue from "../../helpers/handleOnChangeSingleSelectFieldWithValue";
import { scopeChoices } from "../../selectfieldchoices/scopeselect.mjs";
import { scopes } from "../../scopes/scopes.mjs";
import GetSubscriptionTypes from "../../actablueAPI/registrations/GetSubscriptionTypes";
import PostSubscription from "../../actablueAPI/registrations/PostSubscription";
import HandleOnChangeSubscriptionType from "../../helpers/handleOnChangeSubscriptionType";

const SubscriptionAdd = () => {
  const token = useSelector(getToken);
  const subscription_types = useSelector(getSubscriptionTypes)
  const hash_subscription_types = useSelector(getHashSubscriptionTypes)
  const subscription_products_all = useSelector(getSubscriptionProductsAll)
  const hash_subscription_products_all = useSelector(getHashSubscriptionProductsAll)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let [subscription, setSubscription] = useState(JSON.parse(JSON.stringify(useSelector(getSubscription) || {})))
  const navTitle = "Subscription add"

  const onChange = (event) => {
    let newSubscription = HandleOnChange({ event: event, object: subscription })
    setSubscription(newSubscription)
  }

  const onScopeChange = (value, event) => {
    const fieldname = event.name
    const scopevalue = value.value
    let newSubscription = Object.assign({}, subscription)
    if (!newSubscription.hasOwnProperty('scopes')) {
      newSubscription.scopes = []
    }
    newSubscription.scopes[fieldname] = scopevalue
    setSubscription(newSubscription)
  }

  const onTypeChange = (event) => {
    let newSubscription = HandleOnChangeSubscriptionType({ event: event, object: subscription, selectfield: 'type', hash: hash_subscription_types })
    setSubscription(newSubscription)
  }

  const onProductIdChange = (event) => {
    let newSubscription = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: subscription, selectfield: 'product_id' })
    setSubscription(newSubscription)
  }

  // const onLocationIdChange = (event) => {
  //   let newSubscription = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: subscription, selectfield: 'location_id' })
  //   setSubscription(newSubscription)
  // }

  // const onAdminIdChange = (event) => {
  //   let newSubscription = HandleOnChangeSingleSelectFieldWithValue({ event: event, object: subscription, selectfield: 'administration_id' })
  //   delete newSubscription.location_id
  //   setSubscription(newSubscription)
  // }

  const onSubmit = () => {
    // set scopes back to array with stings
    let newSubscription = Object.assign({}, subscription)

    if (subscription.scopes) {
      let newScopes = []
      for (const [scope, value] of Object.entries(subscription.scopes)) {
        // clean scopes (delete non excisting scopes)
        if (scopes.includes(scope)) {
          newScopes.push(scope.concat(':').concat(value))
        }
      }
      newSubscription.scopes = newScopes
    }

    PostSubscription({token:token, data:newSubscription})
    .then(response => {
      navigate("/subscriptions");
    })

  };

  const formData = {
    submit: {
      title: 'Save',
      type: 'submit',
      onClick: onSubmit
    },
    fields: SubscriptionFormFields(),
    field: {
      onChange: onChange
    },
    data: subscription,
    type: {
      label: 'type',
      options: subscription_types,
      selected: {},
      onChange: onTypeChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    product_id: {
      label: 'product_id',
      options: subscription_products_all,
      hash: hash_subscription_products_all,
      selected: {},
      onChange: onProductIdChange,
      clearable: Boolean(false),
      searchable: Boolean(true)
    },
    scopes: {
      label: 'scopes',
      options: scopeChoices,
      selected: {},
      onChange: onScopeChange,
      clearable: Boolean(true),
      searchable: Boolean(true)
    },
  };

  const editCard = {
    size: 12,
    title: navTitle,
    data: <SubscriptionForm {...formData} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle(navTitle))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // if (typeof(subscription.scopes) === 'object') {
    if (Array.isArray(subscription.scopes)) {
      let newScopes = {}
      for (const scope of Object.values(subscription.scopes)) {
        const splitted = scope.split(':')
        const last = splitted[splitted.length - 1]
        const field = scope.replace(":".concat(last), '')
        newScopes[field] = last
      }
      subscription.scopes = newScopes
    }

    delete subscription.id
    
    GetSubscriptionTypes({token:token})
    .then(response => {
      dispatch(setSubscriptionTypes(response.data))
    })
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <BasicCard {...editCard} />
    </>
  )

};
export default SubscriptionAdd;

