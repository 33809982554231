import React, { useEffect } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../../redux/dataSlices/tokenSlice";
import { getOpticonEsls, setOpticonEsls, getOpticonConnections, setOpticonConnections, } from "../../redux/dataSlices/opticonSlice";
import { getHashLocationsAll } from "../../redux/dataSlices/locationSlice";
import { getHashAdministrationsAll } from "../../redux/dataSlices/administrationSlice";
import { getSelectedAdmin_id } from "../../redux/dataSlices/selectAdminSlice";
import { getSelectedLocation_id } from "../../redux/dataSlices/selectLocationSlice";
import { setLoading } from "../../redux/dataSlices/loadingSlice";
import { setNavBarTitle } from "../../redux/dataSlices/navbartitleSlice";
import BasicCard from "../../components/card"
import OpticonConnectionsTable from "../../components/opticonConnectionsTable";
import OpticonESLTable from "../../components/opticonESLTable";
import HandleError from "../../helpers/handleError";
import { getHashProductLinksAll, getHashTemplatesAll, setProductLinksAll, setTemplatesAll } from "../../redux/dataSlices/templateSlice";
import GetAllTemplates from "../../actablueAPI/shelflabels/GetAllTemplates";
import GetOpticonConnectioncList from "../../actablueAPI/opticon/GetOpticonConnectionList";
import GetOpticonProducts from "../../actablueAPI/opticon/GetOpticonProducts";
import GetShelflabelsLinkAll from "../../actablueAPI/shelflabels/GetShelflabelsLinkAll";

const Opticon = () => {
  const token = useSelector(getToken);
  const admin_id = useSelector(getSelectedAdmin_id);
  const location_id = useSelector(getSelectedLocation_id);
  const connections = useSelector(getOpticonConnections)
  const hash_administrations = useSelector(getHashAdministrationsAll)
  const hash_locations = useSelector(getHashLocationsAll)
  const esls = useSelector(getOpticonEsls)
  const hash_product_links = useSelector(getHashProductLinksAll)
  const hash_templates = useSelector(getHashTemplatesAll)
  const dispatch = useDispatch();

  const connectionsCard = {
    size: 12,
    title: `Opticon connections`,
    data: <OpticonConnectionsTable connections={connections} hash_administrations={hash_administrations} hash_locations={hash_locations} />,
  };

  const eslsCard = {
    size: 12,
    title: `Opticon ESLs`,
    data: <OpticonESLTable esls={esls} hash_links={hash_product_links} hash_templates={hash_templates} />,
  };

  useEffect(() => {
    dispatch(setNavBarTitle('Opticon'))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setLoading(true))
    axios.all([
      // get all connections
      GetOpticonConnectioncList({token:token}),
      // get ESLs
      GetOpticonProducts({token:token, location_id:location_id, page:1, size:100}),
      GetShelflabelsLinkAll({token:token, admin_id:admin_id, location_id:location_id, page:1, size:100})
    ])
      .then(axios.spread((connectionsResponse, productsResponse, shelflabelsResponse) => {
        dispatch(setOpticonConnections(connectionsResponse.data))
        dispatch(setOpticonEsls(productsResponse.data))
        dispatch(setProductLinksAll(shelflabelsResponse.data))
        GetAllTemplates({ token: token, admin_id: admin_id, location_id: location_id, base_labels: true, page: 1, size: 25 })
          .then(data => {
            dispatch(setTemplatesAll(data))
            dispatch(setLoading(false))
          })
      }))
      .catch(error => {
        HandleError(error)
        dispatch(setLoading(false))
      })
    // eslint-disable-next-line
  }, [, admin_id, location_id]);

  return (
    <>
      <BasicCard {...connectionsCard} />
      <BasicCard {...eslsCard} />
    </>
  );
};
export default Opticon;
