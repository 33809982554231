import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { pdf } from "@react-pdf/renderer"
import Transaction from "../pdfcreators/createTransactionPDF";
import { setProgressModalShow, setTaskData } from "../redux/dataSlices/analyseSlice";

async function exportMultipleTransactionPDF(transactions, hash_locations_all, relations_hash, dispatch) {

  const zip = new JSZip();


  try {
    dispatch(setProgressModalShow(true))
    let current = 0
    let task_data = {
      current: current,
      total: Object.keys(transactions).length,
      progress: current === 0 ? 0 : current / Object.keys(transactions).length
    }
    dispatch(setTaskData(task_data))
    const fileNames = {};

    for (const [, transaction] of Object.entries(transactions)) {
      let fileName = transaction?.invoice?.number ? `transaction-${transaction?.invoice?.number.replace(/[^\w\s]/gi, "")}` : 'Unknown Transaction';

      if (zip.files[`${fileName}.pdf`]) {
        if (fileNames[fileName] === undefined) {
          fileNames[fileName] = 1;
        } else {
          fileNames[fileName]++;
        }
        fileName = transaction?.invoice?.number ? `transaction-${transaction?.invoice?.number.replace(/[^\w\s]/gi, "")}_${fileNames[fileName]}` : `Unknown Transaction_${fileNames[fileName]}`;
      }

      const blob = await pdf(<Transaction transaction={transaction} hash_locations={hash_locations_all} relation={relations_hash[transaction?.invoice?.relation_id]} />).toBlob()
      if (Object.keys(transactions).length > 1) {
        zip.file(`${fileName}.pdf`, blob);
        current++
        let task_data = {
          current: current,
          total: Object.keys(transactions).length,
          progress: current === 0 ? 0 : current / Object.keys(transactions).length
        }
        dispatch(setTaskData(task_data))
      } else {
        saveAs(blob, `${fileName}.pdf`)
        dispatch(setProgressModalShow(false))
      }
    }
  } catch (error) {
    console.error(error);
    dispatch(setProgressModalShow(false))
  }

  if (Object.keys(transactions).length > 1) {
    const zipBlob = await zip.generateAsync({ type: 'blob' });
    saveAs(zipBlob, 'transactions.zip');
    dispatch(setProgressModalShow(false))
  }
}

export default exportMultipleTransactionPDF