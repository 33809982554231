import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Tab, Nav, Col, Row, ListGroup } from 'react-bootstrap';

import TemplateEditCanvas from './templateEditCanvas';
import { useRef } from 'react';
import TemplateImagesTable from './templateImagesTable';
import { useTranslation } from 'react-i18next';
import CreateFieldTemplate from '../helpers/createFieldTemplate';
import TemplateElementListGroup from './templateElementListGroup';
import TemplateElementOffcanvas from './templateElementOffcanvas';

import { supportFonts, templateAlignChoices, templateESLModelChoices, templateTypeChoices } from '../selectfieldchoices/shelflabelselect.mjs';


export default function TemplateAddForm(props) {
  const imageUploaderRef = useRef();
  const { t: translate } = useTranslation();

  if (props.data?.hasOwnProperty('type')) {
    props.type.selected = { 'value': props.data.type, 'label': templateTypeChoices.find(element => element.id === props.data.type)?.name }
  }

  if (props.data?.hasOwnProperty('esl_model')) {
    props.esl_model.selected = { 'value': props.data.esl_model, 'label': templateESLModelChoices.find(element => element.id === props.data.esl_model)?.name }
  }

  if (props.data?.hasOwnProperty('text_align')) {
    props.text_align.selected = { 'value': props.data.text_align.toLowerCase(), 'label': templateAlignChoices.find(element => element.id === props.data.text_align.toLowerCase())?.name }
  }

  if (props.data?.hasOwnProperty('title_text_font')) {
    props.title_text_font.selected = { 'value': props.data.title_text_font.toLowerCase(), 'label': supportFonts.find(element => element.id === props.data.title_text_font)?.name }
  }

  if (props.data?.hasOwnProperty('contents_text_font')) {
    props.contents_text_font.selected = { 'value': props.data.contents_text_font.toLowerCase(), 'label': supportFonts.find(element => element.id === props.data.contents_text_font)?.name }
  }

  if (props.data?.hasOwnProperty('price_text_font')) {
    props.price_text_font.selected = { 'value': props.data.price_text_font.toLowerCase(), 'label': supportFonts.find(element => element.id === props.data.price_text_font)?.name }
  }

  let basicFields = []
  let formTabs = [];
  let formTabContent = [];
  props.fields.forEach(field => {
    if (field.type === 'object') {
      formTabs.push(
        <Nav.Item key={field.id}>
          <Nav.Link eventKey={field.id}>
            {translate(field.name)}
          </Nav.Link>
        </Nav.Item>
      );

      let objectFields = []
      field.fields.forEach(embeddedfield => {
        objectFields.push(CreateFieldTemplate(embeddedfield, props))
      });
      formTabContent.push(
        <Tab.Pane eventKey={field.id} key={field.id}>
          <div className='tab-container'>
            <Row key={`fields-row`}>
              {objectFields}
            </Row>
          </div>
        </Tab.Pane>
      );
    } else {
      basicFields.push(CreateFieldTemplate(field, props))
    }
  });

  if (props.data) {
    return (
      <>
        <Row>
          <Col sm='6'>
            <Form>
              <Tab.Container defaultActiveKey="main">
                <Nav variant="tabs">
                  <Nav.Item>
                    <Nav.Link eventKey="main">
                      {translate('Main')}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="images">
                      {translate('Images')}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="elements">
                      {translate('Elements')}
                    </Nav.Link>
                  </Nav.Item>
                  {formTabs}
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="main">
                    <div className='tab-container'>
                      <Row key={`fields-row`}>
                        {basicFields}
                      </Row>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="images">
                    <div className='tab-container'>
                      <Form.Group controlId="formImageMultiple" className="mb-3">
                        <Form.Control ref={imageUploaderRef} type="file" accept='.png' onChange={props?.images?.onUpload} />
                      </Form.Group>
                      <TemplateImagesTable {...props} />
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="elements">
                    <div className='tab-container'>
                      <Row>
                        <Col md={12}>
                          <Button variant="primary" className='mb-2' size='sm' onClick={props?.elements?.onAddClick}>Add</Button>
                          {props?.data?.elements?.length > 0 ?
                            <ListGroup>
                              {props?.data?.elements.map((element, index) => (
                                <TemplateElementListGroup
                                  key={element.id ?? index}
                                  element={element}
                                  index={index}
                                  onClick={props?.elements?.onClick}
                                  onRemove={props?.elements?.onRemoveClick}
                                  selected={props?.elements?.selected}
                                />
                              ))}
                            </ListGroup>
                            :
                            <h5>No Elements</h5>
                          }
                        </Col>
                      </Row>
                    </div>
                  </Tab.Pane>
                  {formTabContent}
                </Tab.Content>
              </Tab.Container>
              <p className='form-required-text-hint mt-2'>{translate("* Downloaded or printed result may vary from preview")}</p>
            </Form>
            <Button variant="primary" type={props.submit.type} onClick={props.submit.onClick}>
              {translate(props.submit.title)}
            </Button>
          </Col>
          <Col sm='1'>
            <div className="vl"></div>
          </Col>
          <Col sm='4' className='konvajs-canvas-parent'>
            <TemplateEditCanvas {...props} />
          </Col>
          <TemplateElementOffcanvas {...props} />
        </Row >
      </>
    );
  }


}